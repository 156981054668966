import { WeatherWidget } from './WeatherWidget/WeatherWidget';
import { Activity } from './Widgets/Activity/Activity';
import { JobsWidget } from './Widgets/Jobs/JobsWidget';
import { ProjectsWidget } from './Widgets/Projects/ProjectsWidget';
import { Statistics } from './Widgets/Statistics/Statistics';
import { TasksWidget } from './Widgets/Tasks/TasksWidget';
import { Time } from './Widgets/Time/Time';

export interface WidgetType {
  id: number;
  columns: number;
  rows: number;
  name: string;
  index: string;
}
export const ACTIVITY_DATA = [
  {
    title: 'New user created',
  },
  {
    title: 'New Booking created',
  },
];
// will be replaced with the one from db
export const WIDGET_DATA: WidgetType[] = [
  { id: 1, columns: 2, rows: 1, name: 'Weather', index: 'weather' },
  { id: 2, columns: 1, rows: 1, name: '', index: '' },
  { id: 3, columns: 1, rows: 1, name: 'Time', index: 'time' },
  { id: 4, columns: 4, rows: 1, name: 'Statistics', index: 'statistics' },
  { id: 5, columns: 1, rows: 1, name: '', index: '' },
  { id: 6, columns: 1, rows: 1, name: '', index: '' },
  { id: 7, columns: 1, rows: 1, name: '', index: '' },
  { id: 8, columns: 2, rows: 1, name: '', index: '' },
  { id: 9, columns: 1, rows: 1, name: '', index: '' },
  { id: 10, columns: 1, rows: 1, name: '', index: '' },
  { id: 11, columns: 1, rows: 1, name: '', index: '' },
  { id: 12, columns: 1, rows: 1, name: '', index: '' },
  { id: 13, columns: 1, rows: 1, name: '', index: '' },
  { id: 14, columns: 1, rows: 1, name: '', index: '' },
  { id: 15, columns: 1, rows: 1, name: '', index: '' },
  { id: 16, columns: 1, rows: 1, name: '', index: '' },
  { id: 17, columns: 1, rows: 1, name: '', index: '' },
  { id: 18, columns: 1, rows: 1, name: '', index: '' },
  { id: 19, columns: 1, rows: 1, name: '', index: '' },
  { id: 20, columns: 1, rows: 1, name: '', index: '' },
];

//
export const WIDGET_LIST: WidgetType[] = [
  { id: 1, columns: 1, rows: 1, name: 'dashboard.widgets.time.title', index: 'time' },
  { id: 2, columns: 2, rows: 1, name: 'dashboard.widgets.activity.title', index: 'activity' },
  { id: 3, columns: 2, rows: 1, name: 'dashboard.widgets.weather.title', index: 'weather' },
  { id: 4, columns: 4, rows: 1, name: 'dashboard.widgets.statistics.title', index: 'statistics' },
  { id: 5, columns: 1, rows: 1, name: 'dashboard.widgets.leads.title', index: 'leads' },
  { id: 6, columns: 1, rows: 1, name: 'dashboard.widgets.bookings.title', index: 'bookings' },
  { id: 7, columns: 1, rows: 1, name: 'dashboard.widgets.tasks.title', index: 'tasks' },
  { id: 8, columns: 1, rows: 1, name: 'dashboard.widgets.projects.title', index: 'projects' },
];

export const WIDGETS: { [key: string]: React.ReactNode } = {
  time: <Time />,
  activity: <Activity />,
  statistics: <Statistics />,
  weather: <WeatherWidget />,
  leads: <JobsWidget status='UNCONFIRMED' />,
  bookings: <JobsWidget status='CONFIRMED' />,
  tasks: <TasksWidget />,
  projects: <ProjectsWidget />,
};
