export enum EGranularity {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum EDatePeriod {
  LAST_7_DAYS = 'last_7_days',
  LAST_4_WEEKS = 'last_4_weeks',
  LAST_3_MONTHS = 'last_3_months',
  LAST_6_MONTHS = 'last_6_months',
  LAST_12_MONTHS = 'last_12_months',
  LAST_2_YEARS = 'last_2_years',
  CUSTOM = 'custom',
}

export interface IEntitiesReportData {
  period: string;
  bookings: number;
  leads: number;
  total_entities: number;
}

export interface IEntitiesReportPayload {
  business_id: string;
  start_date: string;
  end_date: string;
  entity_type: string;
  date_argument: string;
  granularity: EGranularity;
}
export interface IFinancialReportPayload {
  business_id: string;
  start_date: string;
  end_date: string;
  granularity: EGranularity;
}

export interface IFinancialReportData {
  period: string;
  total_revenue: number;
  payment_received: number;
  remaining_balances: number;
}

export interface IFinancialOverviewData {
  period: string;
  total: number;
  received: number;
  remaining: number;
  canceled: number;
  pending: number;
}

export interface ITransactionReportData {
  period: string;
  transactions: {
    COMPLETED: number;
    FAILED: number;
    PENDING: number;
    CANCELED: number;
    UNKNOWN: number;
  };
}

export interface ITransactionReportPayload {
  business_id: string;
  start_date: string;
  end_date: string;
  granularity: EGranularity;
}

export interface IPopularProductsData {
  period: string;
  popular_products: {
    product_id: string;
    product_name: string;
    product_count: number;
  }[];
}
export interface IPopularServicesData {
  period: string;
  popular_services: {
    service_id: string;
    service_name: string;
    service_count: number;
  }[];
}

export interface ILeadsByFieldData {
  period: string;
  values: {
    total_leads: number;
    list_value: string;
  }[];
}

export interface ILeadsByFieldPayload {
  business_id: string;
  start_date: string;
  end_date: string;
  field_id: string;
  granularity: EGranularity;
  date_argument: string;
}

export interface IStaffShiftsData {
  period: string;
  staff_shifts: {
    user_id: string;
    first_name: string;
    last_name: string;
    total_shifts: number;
  }[];
}
