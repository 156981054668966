import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

import { IQuote } from '@aduvi/types';

export function useQuoteFieldValue(fieldId: string, quotes: IQuote[]) {
  const { t: translate } = useTranslation();

  switch (fieldId) {
    case 'total_paid':
      return quotes?.map((quote) => String(quote?.total)).join(', ');

    case 'quote_table':
      return (
        quotes?.length !== 0 && (
          <div>
            {quotes?.map((quote) => {
              return (
                <div key={quote?.id}>
                  <span>{quote?.name}</span>
                  {quote?.line_items?.map((el) => {
                    return (
                      <Row style={{ borderBottom: '1px solid gray', borderTop: '1px solid gray' }} key={el?.product_id || el.package_id}>
                        <Col>
                          {el?.quantity} x {el?.package?.service_name} - {el?.product?.name || el?.package?.name}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )
      );

    case 'unpaid_paid_stamp': {
      if (!quotes || quotes?.length === 0) {
        return <span>Example Text</span>;
      }

      return (
        <div>
          {quotes.map((quote, index) => {
            const total = Number(quote.total);
            const subTotal = Number(quote.sub_total);

            if (isNaN(total) || isNaN(subTotal)) {
              return <span key={index}>Example Text</span>;
            }

            const remainingBalance = total - subTotal;
            const statusLabel = remainingBalance === 0 ? translate('formatFields.paid') : translate('formatFields.unpaid');
            const statusStyle =
              remainingBalance === 0
                ? { borderBottom: '1px solid green', borderTop: '1px solid green', color: 'green' }
                : { borderBottom: '1px solid red', borderTop: '1px solid red', color: 'red' };

            return (
              <span key={index} style={statusStyle}>
                {statusLabel}
              </span>
            );
          })}
        </div>
      );
    }

    case 'remaining_balance':
      return quotes
        ?.map((quote) => {
          const remainingBalance = Number(quote?.total) - Number(quote?.sub_total);
          return remainingBalance.toFixed(2);
        })
        .join(', ');

    case 'tax':
      return quotes
        .map((quote) => {
          const taxPercents = quote?.tax_rates?.map((tax) => tax?.tax_percentage)?.join(', ');
          return taxPercents;
        })
        .join(', ');

    case 'subtotal':
      return quotes.map((quote) => String(quote?.sub_total)).join(', ');

    case 'packages':
      return quotes
        ?.map((quote) => {
          const packageNames =
            quote?.line_items
              ?.filter((item) => item?.package)
              ?.map((item) => `${item?.package?.service_name} - ${item?.package?.name}`)
              ?.filter(Boolean) ?? [];
          return packageNames.join(', ');
        })
        .join(', ');

    case 'products':
      return quotes
        ?.map((quote) => {
          const productNames =
            quote?.line_items
              ?.filter((item) => item?.product)
              ?.map((item) => item?.product?.name)
              ?.filter(Boolean) ?? [];
          return productNames.join(', ');
        })
        .join(', ');

    default:
      return [];
  }
}
