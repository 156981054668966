import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Active, closestCenter, DndContext, KeyboardSensor, Over, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import debounce from 'lodash/debounce';
import { ContactDetails } from 'views/contacts/contact-card/ContactDetails';
import { ContactsRows } from 'views/contacts/contacts-rows/ContactsRows';

import { Button, Col, Form, Input, Row, Spin, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './Entity.scss';

import { useDebounce, useIsMobile } from '@aduvi/hooks';
import { EPersonalizedView, EPersonalizedViewOrigin, ICustomFieldViewStyle, IFilter, IKeyValueEditedEntityCustomFields } from '@aduvi/types';
import { IEntityType, IEntityWithFields, IFieldData } from '@aduvi/types/entity';
import { onDragEndCustomFields } from '@aduvi/utils/custom-fields-helper';

import { deleteEntity, editEntity, resetEntities, setCurrentPage, setSelectedEntity } from 'store/features/entity-slice';
import { getEntities } from 'store/features/entity-slice';
import { getEntityFields } from 'store/features/fields-slice';
import { setSelectedEntityForm } from 'store/features/form-slice';
import {
  getPersonalizedViewFilters,
  setHoveredPipelineFilterId,
  setSelectedViewFilters,
  setShowPersonalizedViewDrawer,
} from 'store/features/personalized-views-slice';
import { useAppSelector } from 'store/hooks';
import { getEntitiesCountWithFilters } from 'store/services/entity.service';

import { DroppableMenu } from '../PersonalizedViewComponents/DroppableMenu/DroppableMenu';
import { DroppableRow } from '../PersonalizedViewComponents/DroppableRow/DroppableRow';
import { EditableTable } from '../PersonalizedViewComponents/EditableTable/EditableTable';
import { EditRowLayoutDrawer } from '../PersonalizedViewComponents/EditRowLayoutDrawer/EditRowLayoutDrawer';
import { PersonalizedViewTabs } from '../PersonalizedViewComponents/PersonalizedViewTabs/PersonalizedViewTabs';
import { AddFormModal } from '../PersonalizedViewComponents/PredefinedViews/Forms/modal/AddFormModal';
import { getStatusForEntities } from '../PersonalizedViewComponents/PredefinedViews/helper';
import { usePredefinedViews } from '../PersonalizedViewComponents/PredefinedViews/usePredefinedViews';

import { EntityHeaderActions } from './components/EntityHeaderActions';
import { createFiltersFromFormValues } from './components/EntityHeaderActions';
import { FilterInputs } from './components/FilterInputs';
import { UpsertEntityDrawer } from './components/UpsertEntityDrawer';
import { UpsertJobDrawer } from './components/UpsertJobDrawer';
import { useEntitiesTableColumns, useEntity } from './Entity.hooks';
import { mapEditedEntityFieldsToPayload, mapEntityFieldsToForm } from './helper';

interface IProps {
  entityType?: IEntityType;
  personalizedViewOrigin: EPersonalizedViewOrigin;
  isConfirmed?: boolean;
}

interface IFormValues {
  custom_fields?: Record<string, Record<string, IFieldData>>;
}

export const Entity = ({ entityType, personalizedViewOrigin, isConfirmed = false }: IProps) => {
  const { t: translate } = useTranslation();
  const {
    editRowLayoutDrawer,
    selectedBusiness,
    showFormModal,
    initialColumns,
    entityColumns,
    droppedActions,
    isDragging,
    showEntityDrawer,
    fields,
    formOnlyFields,
    formType,
    dispatch,
    setEditRowLayoutDrawer,
    setShowFormModal,
    setEntityColumns,
    setShowEntityDrawer,
    setDroppedActions,
    setIsDragging,
  } = useEntity({ entityType, isConfirmed, origin: personalizedViewOrigin });

  const { entities } = useAppSelector((state) => state.entity);
  const { action_buttons } = useAppSelector((state) => state.entity.entityTypes);
  const { selectedViewId, selectedView, hoveredPipelineFilterId, selectedViewFilters } = useAppSelector((state) => state.personalizedViews);
  const [form] = useForm<IFormValues>();
  const [searchKey, setSearchKey] = useState('');
  const [pinnedFields, setPinnedFields] = useState<IFilter[]>([]);

  const debouncedSearchKey = useDebounce(searchKey);

  const [filterResultCount, setFilterResultCount] = useState(0);
  const [loadingFilterResult, setLoadingFilterResult] = useState(false);

  const debouncedFetchEntities = useMemo(
    () =>
      debounce(async (businessId: string, entityTypeId: string, filters: IFilter[], showResults = false) => {
        try {
          setLoadingFilterResult(true);

          const formValues: IFormValues = form.getFieldsValue();
          const filters = createFiltersFromFormValues(formValues);

          if (filters?.length > 0) {
            const response = await getEntitiesCountWithFilters(businessId, entityTypeId, {
              paginate: false,
              show_results: showResults,
              status: getStatusForEntities(personalizedViewOrigin),
              view_id: selectedViewId,
              view_display: selectedView?.view_display,
              field_id: selectedView?.status_field,
              display_label_field_id: selectedView?.display_label_field_id,
              start_date: selectedView?.start_date,
              end_date: selectedView?.start_date,

              ...filters.reduce(
                (acc, filter, index) => {
                  acc[`filters[${index}][field_id]`] = filter.field_id;
                  acc[`filters[${index}][operator]`] = filter.operator;
                  Object.entries(filter.value).forEach(([valueIndex, value]) => {
                    acc[`filters[${index}][value][${valueIndex}]`] = value;
                  });
                  return acc;
                },
                {} as Record<string, string | number | boolean>,
              ),
            });
            setFilterResultCount(response.data.filtered_entities_count);
          }
        } catch (error) {
          () => {};
        } finally {
          setLoadingFilterResult(false);
        }
      }, 500),
    [form],
  );
  const isMobile = useIsMobile();

  const sortableSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onEntityDelete = () => {
    if (!entities.selectedEntity?.id || !selectedBusiness?.id || !entityType?.id) return;

    dispatch(
      deleteEntity({
        businessId: selectedBusiness?.id,
        entityTypeId: entityType?.id,
        entityId: entities.selectedEntity?.id,
        entityTypeName: entityType.name,
      }),
    );
  };

  const removeColumn = (column: string) => {
    if (!column.includes('column_')) {
      return setEntityColumns((prev) => prev?.filter((item) => item.id !== column));
    }
  };

  const changeColumnOfVerticalField = (activeColumn: string, overColumn: string) => {
    const activeColumnParts = activeColumn?.split('_');
    const activeFieldId = activeColumnParts[2];
    const activeColumnId = activeColumnParts[1];
    const overColumnParts = overColumn?.split('_');
    const overColumnId = overColumnParts[1];
    const entityColumn = entityColumns?.find((entityColumn) => entityColumn?.id === overColumnId);
    const activefieldIndex = entityColumn?.field_id?.findIndex((fieldId) => fieldId === activeFieldId);
    const overfieldIndex = entityColumn?.field_id?.findIndex((fieldId) => fieldId === overColumnParts[2]);
    const activeTableColumnIndex = entityColumns?.findIndex((entityColumn) => entityColumn?.id === activeColumnId);
    const overTableColumnIndex = entityColumns?.findIndex((entityColumn) => entityColumn?.id === overColumnId);

    if (activeColumnId === overColumnId) {
      const newFieldIDS = arrayMove(entityColumn?.field_id!, activefieldIndex!, overfieldIndex!);
      if (entityColumn) {
        entityColumn.field_id = newFieldIDS.filter((id) => !id?.includes('column_'));
        const updatedColumns = [...entityColumns];
        updatedColumns[overTableColumnIndex] = entityColumn;

        return setEntityColumns(updatedColumns);
      }
    }
    if (activeTableColumnIndex >= 0) {
      const updatedColumns = [...entityColumns];
      const activeTableColumn = { ...updatedColumns?.[activeTableColumnIndex] };

      if (activeTableColumn?.field_id) {
        if (updatedColumns?.[overTableColumnIndex]?.field_id?.includes(activeFieldId)) {
          return;
        }
        activeTableColumn.field_id = activeTableColumn?.field_id?.filter((field) => field !== activeFieldId);
        updatedColumns[activeTableColumnIndex] = activeTableColumn;
      }

      if (overTableColumnIndex >= 0) {
        const overTableColumn = { ...updatedColumns[overTableColumnIndex] };

        if (overTableColumn?.field_id) {
          if (!overTableColumn?.field_id?.includes(activeFieldId)) {
            overTableColumn.field_id = [...overTableColumn?.field_id, activeFieldId].filter((field) => !field.includes('column_'));
          }

          updatedColumns[overTableColumnIndex] = overTableColumn;
        }
      }

      setEntityColumns(updatedColumns);
    }
  };

  const removeVerticalField = (column: string) => {
    const columnParts = column?.split('_');
    const fieldId = columnParts?.[2];
    const columnId = columnParts?.[1];

    const tableColumnIndex = entityColumns?.findIndex((entityColumn) => entityColumn?.id === columnId);

    if (tableColumnIndex >= 0) {
      const tableColumn = entityColumns?.[tableColumnIndex];

      if (tableColumn?.field_id) {
        const updatedFieldIds = tableColumn?.field_id?.filter((field) => field !== fieldId);
        const updatedTableColumn = { ...tableColumn, field_id: updatedFieldIds };

        const updatedColumns = [...entityColumns];
        updatedColumns[tableColumnIndex] = updatedTableColumn;

        setEntityColumns(updatedColumns);
      }
    }
  };

  const onDragEnd = (over: Over | null, active: Active) => {
    setIsDragging(false);

    if (over?.id === 'droppable-menu') {
      const draggedAction = action_buttons?.find((action) => action?.id === active?.id);
      if (draggedAction) {
        setDroppedActions((prev) => {
          if (!prev?.find((action) => action?.id === draggedAction?.id)) {
            return [...prev, draggedAction];
          }
          return prev;
        });
      }
      setIsDragging(false);
    }

    if (over?.id === 'droppableContainer') {
      if (active?.id?.toString().includes('column_')) {
        removeVerticalField(active?.id?.toString());
      }
      if (entityColumns?.length <= 2) return;

      removeColumn(active?.id?.toString());
    }

    if (!over || !over?.data?.current || over?.id === active?.id) return;

    setEntityColumns(onDragEndCustomFields(over, active, entityColumns, fields) || entityColumns);

    if (active?.id?.toString().includes('column_') && over?.id?.toString().includes('column_')) {
      changeColumnOfVerticalField(String(active?.id), String(over?.id));
    }
    setIsDragging(false);
  };

  const onEntityPipelineReorder = (over: Over | null, active: Active) => {
    if (!selectedBusiness?.id || !active?.id || over?.id !== 'pipeline-filter' || !hoveredPipelineFilterId) return;

    const entity = entities?.data?.find((item) => item?.id === active?.id);

    if (!entity) return;

    const data = mapEntityFieldsToForm(entity, fields);

    const isAllowedMultiple = entity?.custom_fields?.find((item) => item.id === selectedView?.status_field)?.multiple;

    const reorderedCustomFields = data?.map((item) => {
      if (selectedView?.status_field && item?.[selectedView?.status_field]) {
        const pipelineStatusesIds = [...(item[String(selectedView?.status_field)] as string[]), hoveredPipelineFilterId];
        return { [String(selectedView?.status_field)]: isAllowedMultiple ? Array.from(new Set(pipelineStatusesIds)) : [hoveredPipelineFilterId] };
      }
      return item;
    });

    if (!selectedBusiness?.id || !entityType?.id || !entity?.id) return;

    dispatch(
      editEntity({
        id: entity?.id,
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEditedEntityFieldsToPayload(reorderedCustomFields as unknown as IKeyValueEditedEntityCustomFields[], fields, entity),
      }),
    )
      .unwrap()
      .then()
      .catch(() => {});
  };

  const handleUpdateColumns = (column: ICustomFieldViewStyle) => {
    const selectedColumnIndex = entityColumns?.findIndex((item) => item.id === column.id);

    if (selectedColumnIndex !== -1) {
      const updatedColumns = [...entityColumns];
      updatedColumns[selectedColumnIndex] = column;
      setEntityColumns(updatedColumns);
    }
  };

  const onRowClick = (entity: IEntityWithFields) => {
    dispatch(setSelectedEntity(entity));
    setShowEntityDrawer(true);
  };

  const onKanbanHandleDrag = (id: string, entity: IEntityWithFields, overColumnId: string, columnsFieldId?: string) => {
    if (!selectedBusiness?.id || !entityType?.id) return;

    const updatedEntity = mapEntityFieldsToForm(entity, fields)
      .map((item) => {
        if (item && columnsFieldId && Object.keys(item).at(0) === columnsFieldId) {
          return {
            [columnsFieldId]: [overColumnId],
          };
        }
        return item;
      })
      .filter((item) => item);

    if (!updatedEntity) return;

    dispatch(
      editEntity({
        id: entity.id,
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEditedEntityFieldsToPayload(updatedEntity as IKeyValueEditedEntityCustomFields[], fields, entity),
      }),
    );
  };

  const entityTableColumns = useEntitiesTableColumns({
    columns: entityColumns,
    origin: personalizedViewOrigin,
    entityType,
    isDragging,
    editRowLayoutDrawer,
    fields,
    droppedActions,
    setDroppedActions,
    onEdit: () => setShowEntityDrawer(true),
    setIsDragging,
    onDelete: onEntityDelete,
    onTableRowAction: (entity) => dispatch(setSelectedEntity(entity)),
  });

  const views = useMemo(() => {
    return usePredefinedViews({
      selectedEntity: entityType,
      columns: entityTableColumns,
      data: entities.data,
      selectedView,
      origin: personalizedViewOrigin,
      entityType,
      onRowClick,
      onKanbanHandleDrag,
      setShowEntityDrawer,
    });
  }, [selectedView, selectedView?.style, entities, entityTableColumns]);

  useEffect(() => {
    dispatch(setCurrentPage(1));
  }, [selectedView?.view_display]);

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      dispatch(getEntityFields({ businessId: selectedBusiness?.id, entityTypeId: entityType?.id })).catch(() => {});
    }
  }, [selectedBusiness?.id, entityType?.id]);

  useEffect(() => {
    setPinnedFields([]);
    dispatch(setSelectedViewFilters({ id: undefined, filters: [] }));
    if (selectedBusiness?.id && selectedView?.id) {
      dispatch(getPersonalizedViewFilters({ businessId: selectedBusiness?.id, personalizedViewId: selectedView?.id }));
    }
  }, [selectedBusiness?.id, selectedView?.id]);

  useEffect(() => {
    if (selectedViewFilters?.id) {
      setPinnedFields(selectedViewFilters?.filters);

      const headerFormValues = form.getFieldsValue();
      if (headerFormValues?.custom_fields) {
        Object.entries(headerFormValues.custom_fields).forEach(([index, field]) => {
          const [[field_id, fieldData]] = Object.entries(field);
          const pinnedField = selectedViewFilters.filters.find((f) => f.field_id === field_id);

          if (pinnedField) {
            form.setFields([
              {
                name: ['custom_fields', index, field_id],
                value: fieldData,
              },
            ]);
          }
        });
      }
    }
  }, [selectedViewFilters?.id, form]);

  const handleFormValuesChange = () => {
    if (!selectedBusiness?.id || !entityType?.id) return;

    const formValues = form.getFieldsValue();
    const filters = createFiltersFromFormValues(formValues);

    if (filters?.length > 0) {
      debouncedFetchEntities(selectedBusiness.id, entityType.id, filters, false);
    }
  };

  const handleShowResults = () => {
    if (!selectedBusiness?.id || !entityType?.id) return;

    const formValues: IFormValues = form.getFieldsValue();
    const filters = createFiltersFromFormValues(formValues);

    if (filters?.length > 0) {
      debouncedFetchEntities(selectedBusiness.id, entityType.id, filters, true);

      const params: Record<string, string | number | boolean> = {
        page: entities.currentPage,
        size: entities.pageSize,
        paginate: true,
        show_results: true,
        status: getStatusForEntities(personalizedViewOrigin),
        view_id: selectedViewId,
        view_display: selectedView?.view_display,
        field_id: selectedView?.status_field,
        display_label_field_id: selectedView?.display_label_field_id,
        start_date: selectedView?.start_date,
        end_date: selectedView?.start_date,
        ...filters.reduce(
          (acc, filter, index) => {
            acc[`filters[${index}][field_id]`] = filter.field_id;
            acc[`filters[${index}][operator]`] = filter.operator;
            Object.entries(filter.value).forEach(([valueIndex, value]) => {
              acc[`filters[${index}][value][${valueIndex}]`] = value;
            });
            return acc;
          },
          {} as Record<string, string | number | boolean>,
        ),
      };

      dispatch(
        getEntities({
          businessId: selectedBusiness?.id,
          entityTypeId: entityType?.id,
          params,
        }),
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetEntities());
    };
  }, []);

  return (
    <DndContext
      sensors={sortableSensors}
      collisionDetection={closestCenter}
      onDragStart={() => dispatch(setHoveredPipelineFilterId(undefined))}
      onDragEnd={({ over, active }) => {
        if (editRowLayoutDrawer) onDragEnd(over, active);

        onEntityPipelineReorder(over, active);
      }}>
      <div className={`leads-wrapper ${editRowLayoutDrawer && 'locked-scroll'}`}>
        {editRowLayoutDrawer && <div className='overlay'></div>}

        {isMobile ? (
          <div className='w-full flex'>
            <PersonalizedViewTabs entityType={entityType} origin={personalizedViewOrigin} style={entityColumns} />
            <div>
              <Row className='mb-30' justify={{ sm: 'start', xs: 'start', md: 'start', lg: 'end' }}>
                <EntityHeaderActions
                  personalizedViewOrigin={personalizedViewOrigin}
                  key={selectedViewId}
                  entityType={entityType}
                  onEditRowLayout={() => setEditRowLayoutDrawer(true)}
                  onConfigureView={() => dispatch(setShowPersonalizedViewDrawer(true))}
                  onCreateEntity={() => {
                    dispatch(setSelectedEntity(undefined));
                    setShowEntityDrawer(true);
                  }}
                  onCreateForm={() => {
                    dispatch(setSelectedEntityForm(undefined));
                    setShowFormModal(true);
                  }}
                />
              </Row>
            </div>
          </div>
        ) : (
          <Row className='w-full mb-10' align='middle'>
            <Col span={12} xs={24} sm={24} md={12} lg={12} className='w-full'>
              <PersonalizedViewTabs entityType={entityType} origin={personalizedViewOrigin} style={entityColumns} />
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12}>
              <Row justify={{ sm: 'start', xs: 'start', md: 'start', lg: 'end' }}>
                <EntityHeaderActions
                  personalizedViewOrigin={personalizedViewOrigin}
                  pinnedFields={pinnedFields}
                  setPinnedFields={setPinnedFields}
                  key={selectedViewId}
                  entityType={entityType}
                  onEditRowLayout={() => setEditRowLayoutDrawer(true)}
                  onConfigureView={() => dispatch(setShowPersonalizedViewDrawer(true))}
                  onCreateEntity={() => {
                    dispatch(setSelectedEntity(undefined));
                    setShowEntityDrawer(true);
                  }}
                  onCreateForm={() => {
                    dispatch(setSelectedEntityForm(undefined));
                    setShowFormModal(true);
                  }}
                />
              </Row>
            </Col>
          </Row>
        )}
        {selectedView?.view_display && selectedView?.view_display !== EPersonalizedView.FORMS && (
          <Row>
            <Col span={24} className='pinned-filters-row'>
              <Form form={form} onValuesChange={handleFormValuesChange}>
                <Row gutter={10} wrap={true} align={'top'}>
                  {pinnedFields.map((filter, index) => {
                    const field = fields.find((f) => f.id === filter.field_id);
                    if (!field) return null;

                    return (
                      <Col key={filter.field_id} className='pinned-field'>
                        <Typography.Text style={{ marginLeft: '8px' }} type='secondary'>
                          {field.title}
                        </Typography.Text>
                        <FilterInputs field={field} form={form} key={index} index={index} defaultOperator={filter.operator} />
                      </Col>
                    );
                  })}
                  {pinnedFields.length > 0 && (
                    <Col style={{ marginLeft: 'auto' }}>
                      <Button
                        type='primary'
                        onClick={handleShowResults}
                        disabled={filterResultCount === 0 || !filterResultCount || loadingFilterResult}
                        style={{ marginTop: '24px' }}>
                        {loadingFilterResult ? 'Loading...' : `Show ${filterResultCount ?? ''} results`}
                      </Button>
                    </Col>
                  )}
                  {pinnedFields.length > 0 && (
                    <Button
                      type='text'
                      style={{ marginTop: '24px' }}
                      disabled={filterResultCount === 0 || !filterResultCount || loadingFilterResult}
                      onMouseEnter={(e) => (e.currentTarget.style.color = '#1890ff')}
                      onMouseLeave={(e) => (e.currentTarget.style.color = '')}
                      onClick={() => {
                        if (!selectedBusiness?.id || !entityType?.id) return;
                        form.resetFields();
                        setFilterResultCount(0);
                        dispatch(
                          getEntities({
                            businessId: selectedBusiness?.id,
                            entityTypeId: entityType?.id,
                            params: {
                              status: getStatusForEntities(personalizedViewOrigin),
                              view_id: selectedViewId,
                              page: 1,
                              size: 10,
                              paginate: true,
                              show_results: true,
                              filters: [],
                            },
                          }),
                        );
                      }}>
                      {translate('buttons.reset')}
                    </Button>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        )}

        {editRowLayoutDrawer && (
          <SortableContext items={entityColumns} strategy={verticalListSortingStrategy} {...sortableKeyboardCoordinates}>
            <div className='relative'>
              {(selectedView?.view_display === EPersonalizedView.TABLE ||
                selectedView?.view_display === EPersonalizedView.PIPELINE ||
                selectedView?.view_display === EPersonalizedView.ADDRESS_BOOK) && (
                <>
                  <DroppableRow id='droppable-row'>
                    <EditableTable
                      managementFields={fields}
                      viewColumns={entityColumns}
                      data={entities.data[0]}
                      updateColumns={handleUpdateColumns}
                      viewType={selectedView?.view_display}
                      removeColumn={removeColumn}
                    />
                  </DroppableRow>
                  <DroppableMenu id='droppable-menu'>
                    <>
                      {droppedActions.length === 0 ? (
                        <div className='text-center color-black'>
                          <p>{translate('action-buttons.dragActionsHere')}</p>
                        </div>
                      ) : (
                        droppedActions &&
                        droppedActions?.map((droppedAction) => (
                          <div key={droppedAction?.id}>
                            <div className='flex-space-between'>
                              <span className='my-5'>{droppedAction?.title}</span>
                              <DeleteOutlined
                                className='cursor-pointer color-red'
                                onClick={() => {
                                  setDroppedActions(droppedActions?.filter((action) => action?.id !== droppedAction?.id));
                                }}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  </DroppableMenu>
                </>
              )}
              {/* {selectedView?.view_display === EPersonalizedView.KANBAN && (
                <DroppableRow id='droppable-row' type='CARD'>
                  <EditableCard
                    managementFields={fields}
                    viewColumns={entityColumns}
                    data={entities?.data?.[0]}
                    updateColumns={handleUpdateColumns}
                    removeColumn={removeColumn}
                  />
                </DroppableRow>
              )} */}
              {/* {selectedView?.view_display === EPersonalizedView.MAP && (
                <DroppableRow id='droppable-row' type='CARD'>
                  <EditableCard
                    managementFields={fields}
                    viewColumns={entityColumns}
                    data={entities?.data?.[0]}
                    updateColumns={handleUpdateColumns}
                    removeColumn={removeColumn}
                  />
                </DroppableRow>
              )} */}
            </div>
          </SortableContext>
        )}
        {selectedView?.view_display === EPersonalizedView.ADDRESS_BOOK ? (
          <Row gutter={24} justify={'space-between'} style={{ marginTop: '5px' }}>
            <Col lg={12} span={12} xs={24} md={18} style={{ flexGrow: 1 }}>
              <Input.Search className='w-full' placeholder={translate('contacts.searchContacts')} onChange={(e) => setSearchKey(e.target.value)} />

              <Spin spinning={entities.loading}>
                <ContactsRows
                  contactColumns={entityColumns}
                  searchKey={debouncedSearchKey}
                  onClick={(contact) => dispatch(setSelectedEntity(contact))}
                  onEdit={(contact) => {
                    dispatch(setSelectedEntity(contact));
                    setShowEntityDrawer(true);
                  }}
                  onDelete={(contact) => {
                    dispatch(setSelectedEntity(contact));
                    onEntityDelete();
                  }}
                  data={entities.data}
                />
              </Spin>
            </Col>

            <Col style={{ position: 'sticky', height: 'fit-content', top: 0 }} lg={12} span={12} xs={24} md={12}>
              {entities.selectedEntity && <ContactDetails contact={entities.selectedEntity} />}
            </Col>
          </Row>
        ) : (
          <Spin className='w-full' spinning={entities.loading || entities.creating}>
            {views[selectedView?.view_display as string]}
          </Spin>
        )}
      </div>
      {editRowLayoutDrawer && (
        <EditRowLayoutDrawer
          origin={personalizedViewOrigin}
          columns={entityColumns}
          data={entities.data}
          managementFields={fields}
          droppedActions={droppedActions}
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          onClose={() => setEditRowLayoutDrawer(false)}
          onCancel={() => {
            setEditRowLayoutDrawer(false);
            if (!selectedView || !selectedView?.style) return setEntityColumns(initialColumns);
            setEntityColumns(JSON.parse(selectedView?.style));
          }}
        />
      )}
      {showFormModal && <AddFormModal entityType={entityType} formType={formType} onCancel={() => setShowFormModal(false)} />}
      {showEntityDrawer &&
        ([EPersonalizedViewOrigin.EVENTS, EPersonalizedViewOrigin.LEADS, EPersonalizedViewOrigin.BOOKINGS]?.includes(personalizedViewOrigin) ? (
          <UpsertJobDrawer
            entityType={entityType}
            fields={fields}
            formOnlyFields={formOnlyFields}
            open={showEntityDrawer}
            onClose={() => setShowEntityDrawer(false)}
            origin={personalizedViewOrigin}
          />
        ) : (
          <UpsertEntityDrawer
            entityType={entityType}
            fields={fields}
            formOnlyFields={formOnlyFields}
            open={showEntityDrawer}
            onClose={() => setShowEntityDrawer(false)}
            origin={personalizedViewOrigin}
          />
        ))}
    </DndContext>
  );
};
