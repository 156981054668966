import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, Node, NodeProps, Position, useReactFlow } from 'reactflow';
import { CloseOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { Button, Col, Dropdown, Row } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import '../edges/Edge.scss';

import { EAutomationNodeAction, ENodeType, INode } from '@aduvi/types/automation';

import { setDuplicateNodeId } from 'store/features/automation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getDefaultnodeIcon, getNodeIcon } from '../constants/helper';

import { AddNodeDrawer } from './AddNodeDrawer';

export interface CustomNodeData {
  label: string;
  nodeType: ENodeType;
  action: EAutomationNodeAction;
}

const CustomNode = ({
  data,
  id,
  xPos,
  yPos,
  onNodeClick,
}: NodeProps<CustomNodeData> & {
  onNodeClick?: (data?: Node) => void;
}) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const { getEdges, setNodes, setEdges, getNode, getNodes } = useReactFlow();

  const { duplicateNodeId, lastNodesId } = useAppSelector((state) => state.automation);

  const [showNodesDrawer, setShowNodesDrawer] = useState(false);

  const nodeColor: Record<ENodeType, string> = {
    ACTION: '#B37FEB',
    LOGIC: '#69C0FF',
    TRIGGER: '#5DCB9E',
    ACTION_INTERNAL: '#FFC069',
    BRANCH: '',
  };

  const nodeBackgroundColor: Record<ENodeType, string> = {
    ACTION: '#F4EBFD',
    LOGIC: '#EFF9FF',
    TRIGGER: '#52C41A14',
    ACTION_INTERNAL: '#FFF6E9',
    BRANCH: '',
  };

  const nodeTypeTitle = useMemo(
    () => (data.nodeType === ENodeType.ACTION_INTERNAL ? 'Internal' : data.nodeType.charAt(0) + data.nodeType.substring(1).toLowerCase()),
    [data.nodeType],
  );

  const onNodeDelete = () => {
    if (data.action === EAutomationNodeAction.IF_ELSE) {
      const branches = getEdges()
        .filter((item) => item.source === id)
        .map((item) => item.target);

      setEdges((prev) => prev.filter((item) => item.source !== id && item.target !== id));
      setNodes((prev) => prev.filter((item) => item.id !== id && !branches.includes(item.id)));

      return;
    }

    const newSource = getEdges().find((item) => item.target === id)?.source;
    const newTarget = getEdges().find((item) => item.source === id)?.target;

    setNodes((prev) => prev.filter((item) => item.id !== id));

    if (!newSource || !newTarget) {
      setEdges((prev) => prev.filter((item) => item.target !== id));
      return;
    }

    setEdges((prev) => [
      ...prev.filter((item) => item.source !== id && item.target !== id),
      {
        id: `${newSource}->${newTarget}`,
        source: newSource,
        target: newTarget,
        type: 'custom',
        style: { stroke: '#69C0FF', strokeWidth: 2 },
      },
    ]);
  };

  const onNodeDuplicateDrop = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const duplicateNode = getNodes()?.find((item) => item?.id === duplicateNodeId);

    const duplicatedNodeData = {
      ...duplicateNode,
      id: uuidv4(),
      position: {
        x: xPos,
        y: yPos + 100,
      },
      data: {
        ...duplicateNode?.data,
      },
    };

    setNodes((prev) => [...prev, duplicatedNodeData]);

    setEdges((prev) => [
      ...prev,
      {
        id: `${id}->${duplicatedNodeData.id}`,
        source: id,
        target: duplicatedNodeData.id,
        type: 'custom',
        style: { stroke: '#69C0FF', strokeWidth: 2 },
      },
    ]);

    dispatch(setDuplicateNodeId(undefined));
    const element = document.querySelector('.react-flow__pane') as HTMLElement;
    if (!element) return;
    element.click();
  };

  const disableDeleteOption = useMemo(() => {
    if (data.action === EAutomationNodeAction.IF_ELSE) {
      const ifElseBranches = getEdges()
        .filter((item) => item.source === id)
        .map((item) => item.target);

      return getEdges().some((node) => ifElseBranches.includes(node.source));
    }
    return false;
  }, [data, id, getEdges(), getNodes()]);

  const nodeDropdownItems: ItemType[] = [
    {
      key: '1',
      label: translate('buttons.duplicate'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        if (data.action === EAutomationNodeAction.IF_ELSE) return;
        dispatch(setDuplicateNodeId(id));
      },
      disabled: data.action === EAutomationNodeAction.IF_ELSE,
    },
    {
      key: '2',
      label: translate('buttons.delete'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        onNodeDelete();
      },
      disabled: disableDeleteOption,
    },
  ];

  const onNodeSelect = (data: INode) => {
    setShowNodesDrawer(false);

    const trueBranchID = uuidv4();
    const falseBranchID = uuidv4();

    setNodes((prev) => [
      ...prev,
      {
        id: data.id,
        type: 'custom',
        position: {
          x: xPos,
          y: yPos + 100,
        },
        data: {
          label: data.title,
          nodeType: data.node_type,
          triggerable_type: data.triggerable_type,
          triggerable_id: data.triggerable_id,
          action: data.action,
          payload:
            data.action === EAutomationNodeAction.IF_ELSE
              ? {
                  branches: [
                    {
                      id: trueBranchID,
                      sets: [
                        {
                          conditions: [],
                          condition_set_operator: 'all',
                        },
                      ],
                    },
                  ],
                }
              : data.payload,
        },
      },
      ...(data.action === EAutomationNodeAction.IF_ELSE
        ? [
            {
              id: trueBranchID,
              type: 'branch',
              position: {
                x: xPos - 100,
                y: yPos + 200,
              },
              data: {
                label: 'TRUE',
                nodeType: ENodeType.BRANCH,
                triggerable_type: undefined,
                triggerable_id: undefined,
                action: EAutomationNodeAction.IF_BRANCH,
                payload: undefined,
              },
            },
            {
              id: falseBranchID,
              type: 'branch',
              position: {
                x: xPos + 320,
                y: yPos + 200,
              },
              data: {
                label: 'FALSE',
                nodeType: ENodeType.BRANCH,
                triggerable_type: undefined,
                triggerable_id: undefined,
                action: EAutomationNodeAction.ELSE_BRANCH,
                payload: undefined,
              },
            },
          ]
        : []),
    ]);
    setEdges((prev) => [
      ...prev,
      {
        id: `${id}->${data.id}`,
        source: id,
        target: data.id,
        type: 'custom',
        style: { stroke: '#69C0FF', strokeWidth: 2 },
      },
      ...(data.action === EAutomationNodeAction.IF_ELSE
        ? [
            {
              id: `${data.id}->${trueBranchID}`,
              source: data.id,
              target: trueBranchID,
              type: 'default',
              style: { stroke: '#69C0FF', strokeWidth: 2 },
            },
            {
              id: `${data.id}->${falseBranchID}`,
              source: data.id,
              target: falseBranchID,
              type: 'default',
              style: { stroke: '#69C0FF', strokeWidth: 2 },
            },
          ]
        : []),
    ]);
  };

  return (
    <Col
      onClick={() => onNodeClick?.(getNode(id))}
      style={{
        padding: 10,
        boxShadow: '0px 2px 8px 0px #5194D826',
        minWidth: '260px',
        minHeight: '65px',
        background: 'white',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: duplicateNodeId === id ? '2px solid #1890FF' : '',
      }}>
      <Handle type='target' position={Position.Top} style={{ background: '#555' }} />
      <Row className='w-full'>
        <Col
          span={5}
          style={{
            height: '45px',
            width: '45px',
            borderRadius: '5px',
            background: nodeBackgroundColor[data.nodeType],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: nodeColor[data.nodeType],
          }}>
          {getNodeIcon[data.action] || getDefaultnodeIcon[data.nodeType]}
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column' }} className='ml-10' span={15}>
          <span style={{ fontSize: '9px', fontWeight: '700', color: nodeColor[data.nodeType] }}>{nodeTypeTitle}</span>
          <span className='mt-5'>{data.label}</span>
        </Col>
        {data.nodeType !== ENodeType.TRIGGER ? (
          <Col span={2} className='ml-5'>
            {duplicateNodeId === id ? (
              <Button
                icon={<CloseOutlined />}
                type='text'
                block
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  dispatch(setDuplicateNodeId(undefined));
                }}
              />
            ) : (
              <Dropdown
                menu={{
                  items: nodeDropdownItems,
                }}
                trigger={['click']}
                overlayClassName='user-management-dropdown'>
                <Button
                  type='text'
                  block
                  icon={<EllipsisOutlined style={{ color: 'gray' }} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </Dropdown>
            )}
          </Col>
        ) : null}
      </Row>
      {lastNodesId.includes(id) ? (
        <div className='nodrag nopan custom-edge'>
          <Col
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            style={{
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 2,
              height: 50,
              background: '#69C0FF',
            }}
          />
          {duplicateNodeId ? (
            <Row
              className='card example-5 cursor-pointer'
              style={{ position: 'absolute', width: '110px', height: '50px', marginLeft: '-175px', marginTop: '55px', zIndex: 99 }}
              justify={'center'}
              align={'middle'}
              onClick={onNodeDuplicateDrop}>
              <svg height='100%' width='100%' xmlns='http://www.w3.org/2000/svg'>
                <rect rx='8' ry='8' className='line' height='100%' width='100%' strokeLinejoin='round' />
              </svg>
              <div className='inner p-15'>{translate('automations.clickToDrop')}</div>
            </Row>
          ) : (
            <Button
              style={{
                position: 'absolute',
                top: 'calc(100% + 5px)',
                left: '50%',
                transform: 'translate(-50%, 100%)',
                zIndex: 1,
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '10px',
              }}
              type='primary'
              size='small'
              icon={<PlusOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowNodesDrawer(true);
              }}
            />
          )}
          <div
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            style={{
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%) translateY(100%)',
              width: 2,
              height: 50,
              background: '#C9C9C9',
            }}>
            <div
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              style={{
                width: '45px',
                background: '#D9D9D9',
                height: '17px',
                borderRadius: '10px',
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                color: '#858585',
                textAlign: 'center',
                fontSize: '10px',
                alignContent: 'center',
              }}>
              {translate('automations.end')}
            </div>
          </div>
        </div>
      ) : null}
      <Handle type='source' position={Position.Bottom} style={{ background: '#555' }} />
      <AddNodeDrawer
        open={showNodesDrawer}
        canBeLastNode
        nodesLength={getNodes().length}
        onClose={() => setShowNodesDrawer(false)}
        onNodeSelect={onNodeSelect}
      />
    </Col>
  );
};

export default CustomNode;
