import dayjs from 'dayjs';

import { Avatar } from 'antd';

import { DEFAULT_CELL_STYLE } from '@aduvi/constants';
import { EShiftFormat, IBusinessUser, ICustomFieldSpecificStyle, IPaginatedResponse, IProduct, IService } from '@aduvi/types';
import { IFieldData } from '@aduvi/types/entity';
import { IShiftSchedulerSettings } from '@aduvi/types/shift-scheduler';

interface IShiftReferenceContentProps {
  fieldData?: IFieldData[];
  users?: IPaginatedResponse<IBusinessUser>;
  services?: IPaginatedResponse<IService>;
  products?: IPaginatedResponse<IProduct>;
  settings?: IShiftSchedulerSettings;
  style?: ICustomFieldSpecificStyle;
}

const ShiftReferenceContent = ({ fieldData, users, services, products, settings, style }: IShiftReferenceContentProps) => {
  const format = style?.shiftFormat?.toString() ?? DEFAULT_CELL_STYLE.shiftFormat;

  const formatShiftTime = (time: string) => {
    return time.replace(':00am', 'am').replace(':00pm', 'pm');
  };

  if (format === EShiftFormat.TEXT) {
    return (
      <div className='flex'>
        {fieldData?.map((item, index) => {
          const user = users?.data?.find((u) => u?.id === item?.user_id);
          return user ? <span key={index}>{`${user?.first_name}${index < fieldData.length - 1 ? ', ' : ''}`}</span> : null;
        })}
      </div>
    );
  }

  if (format === EShiftFormat.CIRCLE_IMAGE) {
    return (
      <div className='flex'>
        {fieldData?.map((item, index) => {
          const user = users?.data?.find((u) => u?.id === item?.user_id);
          if (!user) return null;
          const { first_name, last_name, profile_picture } = user;
          return (
            <Avatar key={index} src={profile_picture} style={{ backgroundColor: !profile_picture ? '#f56a00' : undefined }}>
              {!profile_picture && `${first_name[0]}${last_name[0]}`}
            </Avatar>
          );
        })}
      </div>
    );
  }

  if (format === EShiftFormat.LIST) {
    return (
      <div className='flex flex-direction-column'>
        {fieldData?.map((item, index) => {
          const user = users?.data?.find((u) => u?.id === item?.user_id);
          if (!user) return null;

          const service = services?.data?.find((s) => s?.id === item?.assigned_id);
          const product = products?.data?.find((p) => p?.id === item?.assigned_id);
          const position = settings?.positions?.find((p) => p?.id === item?.assigned_id);

          const assignedTo = service?.name || product?.name || position?.position;
          const timeRange =
            item?.start_shift && item?.end_shift
              ? formatShiftTime(`${dayjs(item.start_shift).format('h:mma')} - ${dayjs(item.end_shift).format('h:mma')}`)
              : '';

          return (
            <div key={index} className='flex flex-direction-column'>
              <div>{user.first_name}</div>
              <div style={{ gap: '5px' }} className='flex justify-between'>
                <span className='color-gray-5'>{assignedTo}</span>
                <span className='color-gray-5'>{timeRange}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default ShiftReferenceContent;
