import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { AssetsType } from 'views/assets/Assets.hook';

import { Row, Table } from 'antd';
import './ServicesTable.scss';

import { Button } from '@aduvi/components/Button/Button';
import { PERMISSIONS } from '@aduvi/constants';
import { useActiveTheme, useBusiness, useLongPress } from '@aduvi/hooks';
import { IService } from '@aduvi/types/services';

import { orderPackages, orderServices, setSelectedPackage, setSelectedService } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { DraggableRow } from '../draggable-row/DraggableRow';

import { createObjectFromArray, StoreEmptyText, usePackagesTableColumns, useServicesTableColumns } from './helper';

interface IProps {
  currentTab?: AssetsType;
  setShowServicesDrawer: (prop: boolean) => void;
  setShowPackagesDrawer: (prop: boolean) => void;
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
}

export const ServicesTable = ({ currentTab, setShowServicesDrawer, setShowPackagesDrawer }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const activeTheme = useActiveTheme();
  const { t: translate } = useTranslation();

  const { handleMouseDown, handleMouseUp, checkLongPress } = useLongPress();

  const servicesColumns = useServicesTableColumns(setShowServicesDrawer);
  const packagesColumns = usePackagesTableColumns(setShowPackagesDrawer);

  const { services, loading } = useAppSelector((state) => state.services);

  const servicesData = useMemo(() => {
    return (
      services?.data?.map((item) => ({
        key: item?.id,
        name: item?.name,
        availability: item?.units?.filter((unit) => unit?.enabled)?.length + '  ' + translate('assets.services.available'),
        image: item?.image,
      })) ?? []
    );
  }, [services?.data]);

  const onSubDragEnd = ({ active, over }: DragEndEvent, parentKey: React.Key) => {
    if (active.id !== over?.id) {
      let updatedSubData = services?.data?.find((item) => item?.id === parentKey)?.packages;
      const activeIndex = updatedSubData?.findIndex((item) => item.id === active.id) as number;
      const overIndex = updatedSubData?.findIndex((item) => item.id === over?.id) as number;

      if (activeIndex < 0 || overIndex < 0) return;

      updatedSubData = arrayMove(updatedSubData as [], activeIndex, overIndex);

      if (!selectedBusiness?.id) return;
      dispatch(
        orderPackages({
          service_id: parentKey.toString(),
          business_id: selectedBusiness.id,
          body: {
            packages: updatedSubData.map((item, index) => ({
              ...item,
              order_number: index + 1,
            })),
          },
        }),
      );
    }
  };

  const onDataDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id === over?.id) return;
    const activeIndex = services?.data?.findIndex((item) => item.id === active.id) as number;
    const overIndex = services?.data?.findIndex((item) => item.id === over?.id) as number;

    if (activeIndex < 0 || overIndex < 0) return;

    const updatedData = arrayMove(services?.data as IService[], activeIndex, overIndex);
    if (!selectedBusiness?.id) return;

    dispatch(
      orderServices({
        business_id: selectedBusiness?.id,
        body: {
          services: updatedData.map((item, index) => ({
            ...item,
            order_number: index + 1,
          })),
        },
      }),
    );
  };

  const expandedPackagesTable = (record: IService) => {
    const subDataForRecord = createObjectFromArray(services?.data || [])[record.key];
    if (!subDataForRecord) return;

    return (
      <div className='mb-10'>
        <div style={{ backgroundColor: `${activeTheme?.bodyBg}` }}>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={(e) => onSubDragEnd(e, record.key)}>
            <SortableContext items={subDataForRecord?.map(({ key }) => key) as string[]} strategy={verticalListSortingStrategy}>
              {subDataForRecord?.length ? (
                <Table
                  rowKey='key'
                  rowClassName='cursor-pointer'
                  showHeader={false}
                  pagination={false}
                  columns={packagesColumns.filter((item) => !item.hidden)}
                  dataSource={subDataForRecord}
                  components={{
                    body: {
                      row: DraggableRow,
                    },
                  }}
                  onRow={(record) => ({
                    onMouseDown: handleMouseDown,
                    onMouseUp: handleMouseUp,
                    onClick: (e) => {
                      if (!checkLongPress()) {
                        e.stopPropagation();
                        e.preventDefault();
                        dispatch(setSelectedService({ id: record.business_service_id }));
                        dispatch(setSelectedPackage({ id: record.id, business_service_id: record.business_service_id }));
                        setShowPackagesDrawer(true);
                      }
                    },
                  })}
                />
              ) : (
                <></>
              )}
            </SortableContext>
          </DndContext>
          <Row justify='end'>
            <Button
              type='primary'
              requiredPermission={PERMISSIONS.SERVICES.CREATE}
              disabledButton
              className='mt-15 mr-10 mb-10'
              onClick={() => {
                dispatch(setSelectedService(services?.data.find((item) => item.id === record.key)));
                dispatch(setSelectedPackage(undefined));
                setShowPackagesDrawer(true);
              }}>
              + {translate('assets.services.addPackage')}
            </Button>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDataDragEnd}>
      <SortableContext items={servicesData?.map((item) => item.key)} strategy={verticalListSortingStrategy}>
        <Table
          rowClassName='service-table-row'
          className='services-table'
          loading={loading}
          columns={servicesColumns}
          dataSource={servicesData as IService[]}
          pagination={false}
          showHeader={false}
          components={{ body: { row: DraggableRow } }}
          expandable={{
            defaultExpandAllRows: true,
            expandedRowKeys: servicesData.map((service) => service.key),
            expandedRowRender: expandedPackagesTable,
          }}
          onRow={(record) => ({
            onMouseDown: handleMouseDown,
            onMouseUp: handleMouseUp,
            onClick: (e) => {
              if (!checkLongPress()) {
                e.stopPropagation();
                e.preventDefault();
                dispatch(setSelectedService(services?.data.find((item) => item.id === record.key)));
                setShowServicesDrawer(true);
              }
            },
          })}
          locale={{
            emptyText: <StoreEmptyText currentTab={currentTab} setShowDrawer={() => setShowServicesDrawer(true)} />,
          }}
        />
      </SortableContext>
    </DndContext>
  );
};
