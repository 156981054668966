import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IChatMessage, ICreateMessagePayload, ISupportChat, ISupportChatState } from '@aduvi/types';

import * as SupportChatService from 'store/services/support-chat.service';

const initialState: ISupportChatState = {
  supportChats: undefined,
  selectedSupportChat: undefined,
  loading: false,
  creating: false,
  users: [],
  liveChatAvailability: {
    data: undefined,
    loading: false,
  },
  messages: {
    data: [],
    creating: false,
    loading: false,
    removing: false,
  },
};

export const createSupportChat = createAsyncThunk(
  'support-chat/create-support-chat',
  async ({ payload }: { payload: ISupportChat }, { rejectWithValue }) => {
    try {
      return await SupportChatService.createSupportChat(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getSupportChats = createAsyncThunk('support-chat/get-support-chats', async (payload: { businessId: string }, { rejectWithValue }) => {
  try {
    const chats = await SupportChatService.getSupportChats(payload.businessId);

    return { data: chats?.data };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSupportChatInfo = createAsyncThunk(
  'support-chat/get-chat-info',
  async (payload: { businessId: string; supportChatId: string }, { rejectWithValue }) => {
    try {
      const chat = await SupportChatService.getSupportChatInfo(payload.businessId, payload.supportChatId);

      return { data: chat?.data };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createSupportChatMessage = createAsyncThunk(
  'support-chat/create-chat-message',
  async (
    { businessId, supportChatId, payload }: { businessId: string; supportChatId: string; payload: ICreateMessagePayload },
    { rejectWithValue },
  ) => {
    try {
      return await SupportChatService.createSupportChatMessage(businessId, supportChatId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getLiveChatAvailability = createAsyncThunk('admin-users/get', async (params: { businessId: string }, { rejectWithValue }) => {
  try {
    return await SupportChatService.getLiveChatAvailability(params.businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

const supportChatSlice = createSlice({
  name: 'support-chat',
  initialState,
  reducers: {
    setSelectedSupportChat(state, action: PayloadAction<ISupportChat | undefined>) {
      state.selectedSupportChat = action.payload;
    },
    appendMessage(state, { payload }: PayloadAction<IChatMessage>) {
      const existingMessageIndex = state.messages.data?.findIndex(
        (message: IChatMessage) => message.id?.includes('inserted-') && message.content === payload?.content,
      );

      if (existingMessageIndex > -1) {
        const data = state.messages?.data ?? [];
        state.messages.data = [...data.slice(0, existingMessageIndex), payload, ...data.slice(existingMessageIndex + 1)];
      } else {
        state.messages.data = state.messages.data ? [...state.messages.data, payload] : [payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSupportChat.pending, (state) => {
        state.creating = true;
      })
      .addCase(createSupportChat.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.selectedSupportChat = payload.data;
        state.supportChats = [payload.data, ...(state.supportChats || [])];
      })
      .addCase(createSupportChat.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getSupportChats.pending, (state) => {
        state.loading = true;
        state.messages.loading = true;
      })
      .addCase(getSupportChats.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.messages.loading = false;
        state.supportChats = payload.data;
        state.selectedSupportChat = payload.data[0];
      })
      .addCase(getSupportChats.rejected, (state) => {
        state.loading = false;
        state.messages.loading = false;
      })
      .addCase(getSupportChatInfo.pending, (state) => {
        state.messages.loading = true;
      })
      .addCase(getSupportChatInfo.fulfilled, (state, { payload }) => {
        state.messages.loading = false;
        state.messages.data = payload.data.business_support_chat_messages || [];
      })
      .addCase(getSupportChatInfo.rejected, (state) => {
        state.messages.loading = false;
      })
      .addCase(createSupportChatMessage.pending, (state, { meta }) => {
        state.messages.data = [
          ...state.messages.data,
          {
            id: `inserted-${meta.requestId}`,
            content: meta?.arg?.payload?.content,
            attachments: meta?.arg?.payload?.attachments || [],
            author_id: meta?.arg?.payload?.author_id,
            author_name: meta?.arg?.payload?.author_name,
            business_id: meta?.arg?.payload?.author_id,
          } as IChatMessage,
        ];
      })
      .addCase(getLiveChatAvailability.pending, (state) => {
        state.liveChatAvailability.loading = true;
      })
      .addCase(getLiveChatAvailability.fulfilled, (state, { payload }) => {
        state.liveChatAvailability.loading = false;
        state.liveChatAvailability.data = payload.data;
      })
      .addCase(getLiveChatAvailability.rejected, (state) => {
        state.liveChatAvailability.loading = false;
      });
  },
});

export const supportChatReducer = supportChatSlice.reducer;

export const { setSelectedSupportChat, appendMessage } = supportChatSlice.actions;
