import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';

import { Avatar, Button, Col, Empty, Flex, Form, Row, Spin, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './LiveChat.scss';

import { UserAvatar } from '@aduvi/components/UserStatusAvatar/UserStatusAvatar';
import { useBusinessId, useUser } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { IPartnerUser } from '@aduvi/types';

import { createSupportChatMessage, getLiveChatAvailability, getSupportChatInfo } from 'store/features/support-chat-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { CreateTicketModal } from '../contact/ticket/create-ticket-modal/CreateTicketModal';

import { ChatHistory } from './@components/chat-history/ChatHistory';

export const LiveChat = ({ setNotificationsNumber }: { setNotificationsNumber: React.Dispatch<React.SetStateAction<number>> }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const businessId = useBusinessId();
  const currentUser = useUser();
  const { messages: chat, selectedSupportChat, liveChatAvailability } = useAppSelector((state) => state.supportChat);

  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [chatUsers, setChatUsers] = useState<IPartnerUser[]>([]);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);

  const messageContainerRef = useRef<HTMLDivElement>(null);

  const handleSubmit = () => {
    const message = form.getFieldValue('message_input');
    if (!message?.trim()) return;

    dispatch(
      createSupportChatMessage({
        businessId,
        supportChatId: selectedSupportChat?.id!,
        payload: {
          attachments: [],
          content: message,
          author_id: currentUser?.id!,
          author_name: currentUser?.first_name,
          business_id: businessId,
        },
      }),
    );

    form.resetFields(['message_input']);
  };

  const handleEditorKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e?.key === 'Enter' && !e?.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const scrollToBottom = useCallback(() => {
    messageContainerRef.current?.scrollTo({
      top: messageContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
    setNewMessage(false);
  }, [messageContainerRef.current]);

  const onScroll = useCallback(
    debounce(() => {
      const container = messageContainerRef.current;
      if (container && container.scrollTop + container.clientHeight < container.scrollHeight) {
        setIsScrolledUp(true);
      } else {
        setIsScrolledUp(false);
      }
      setNotificationsNumber(0);
    }, 300),
    [messageContainerRef],
  );

  useEffect(() => {
    const container = messageContainerRef.current;
    if (container) {
      container.addEventListener('scroll', onScroll);
    }

    if (!isScrolledUp) {
      scrollToBottom();
    } else {
      setNewMessage(true);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', onScroll);
      }
    };
  }, [chat]);

  useEffect(() => {
    if (selectedSupportChat) {
      dispatch(getSupportChatInfo({ businessId, supportChatId: selectedSupportChat?.id! }));
    }
  }, [selectedSupportChat?.id]);

  useEffect(() => {
    if (selectedSupportChat) {
      const chatUsers = selectedSupportChat?.users?.filter((user) => user?.id !== currentUser?.id)?.map((user) => user?.partner_users?.[0]) || [];
      setChatUsers(chatUsers);
    }
  }, [selectedSupportChat?.id]);

  useEffect(() => {
    if (!businessId) return;
    dispatch(
      getLiveChatAvailability({
        businessId,
      }),
    );
  }, []);

  useEffect(() => {
    if (!selectedSupportChat || !chat?.data?.length) return;

    const lastMessage = chat.data[chat.data.length - 1];

    if (lastMessage.author_id !== currentUser?.id) {
      setNewMessage(true);
      setNotificationsNumber((prev) => prev + 1);
    }
  }, [chat]);

  return (
    <>
      {liveChatAvailability?.loading ? (
        <Row justify={'center'} className='mt-30'>
          <Spin spinning={liveChatAvailability?.loading} />
        </Row>
      ) : liveChatAvailability?.data ? (
        <Row gutter={10}>
          <Col span={8}>
            <ChatHistory />
          </Col>
          <Col span={16}>
            {selectedSupportChat ? (
              <Spin spinning={chat?.loading}>
                <Row className='chat-popover w-full' justify={'center'}>
                  <div className='chat-container'>
                    <Row className='header-container'>
                      {chatUsers?.map((user) => (
                        <Row key={user?.id}>
                          <UserAvatar user={user} />
                          <Typography.Text className='ml-5'>{`${user?.first_name}`}</Typography.Text>
                        </Row>
                      ))}
                    </Row>
                    <Row className='message-container' ref={messageContainerRef}>
                      {chat && chat?.data?.length ? (
                        <Col span={24}>
                          {chat.data.map((message, index) => (
                            <Row justify={message?.business_id?.length ? 'end' : 'start'} className='w-full' key={index}>
                              <Row className={`${message?.business_id?.length ? 'user-response' : 'system-response'} message-content`}>
                                <Flex vertical>
                                  <Typography.Text style={{ fontWeight: '900' }}>
                                    <Avatar size={'small'}>{message?.author_name?.charAt(0)}</Avatar> &nbsp; {message?.author_name}
                                  </Typography.Text>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: message?.content || '',
                                    }}
                                  />
                                </Flex>
                              </Row>
                            </Row>
                          ))}

                          {chat?.creating && (
                            <Row justify={'start'} className='w-full'>
                              <Row className='typing'>{translate('layout.chatBot.typing')}</Row>
                            </Row>
                          )}
                        </Col>
                      ) : (
                        <Empty />
                      )}
                      {newMessage && isScrolledUp && (
                        <Button className='new-message-indicator' onClick={scrollToBottom} icon={<MessageOutlined />}>
                          {translate('liveChat.unreadMessage')}
                        </Button>
                      )}
                    </Row>

                    <Form form={form} onFinish={handleSubmit}>
                      <Row className='w-full' align='middle'>
                        <Form.Item
                          name='message_input'
                          className='message-form-item w-full'
                          rules={[{ required: true, message: translate('layout.chatBot.inputValidation') }]}>
                          <Tiptap
                            content={null}
                            placeholder='Message...'
                            isConversationEditor
                            onKeyDown={handleEditorKeyDown}
                            onUpdate={(editor) => form.setFieldValue('message_input', editor.getHTML())}
                            onSendMessage={handleSubmit}
                          />
                        </Form.Item>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </Spin>
            ) : (
              <Empty className='mt-30' description={translate('liveChat.emptyChatMessage')} />
            )}
          </Col>
        </Row>
      ) : (
        <Flex vertical className='h-full w-full' align='center' justify='center' gap={'10px'} style={{ maxHeight: '450px', minHeight: '450px' }}>
          <Typography.Text type='secondary' className='fs-18'>
            Our Live Chat is currently unavailable, please send a Ticket instead
          </Typography.Text>
          <Button type='primary' onClick={() => setIsTicketModalOpen(true)}>
            Create Ticket
          </Button>
        </Flex>
      )}

      {isTicketModalOpen && <CreateTicketModal setIsTicketModalOpen={setIsTicketModalOpen} />}
    </>
  );
};
