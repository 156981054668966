import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Details } from 'views/assets/products/products-drawer/details/Details';
import { Pricing } from 'views/assets/products/products-drawer/pricing/Pricing';
import { QuantityStock } from 'views/assets/products/products-drawer/quantity-stock/QuantityStock';
import { Variations } from 'views/assets/products/products-drawer/variations/Varitations';

import { Form, FormInstance, Modal, Row, Space, Spin, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { LimitVisibility } from '@aduvi/components/LimitVisibility/LimitVisibility';
import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';
import { IProduct } from '@aduvi/types';
import { EDrawerTabs, ICreateProductPayload, IProductTaxRate } from '@aduvi/types/products';
import { getFormErrors } from '@aduvi/utils/helper';

import { createProduct, editProduct, getProductById, setSelectedProduct } from 'store/features/products-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  open: boolean;
  onClose: () => void;
  addProduct?: (item: IProduct) => void;
}

export const ProductsModal = ({ onClose, open, addProduct }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  const [tab, setTab] = useState<string>(EDrawerTabs.DETAILS);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [isCustom, setIsCustom] = useState<boolean>(false);

  const { selectedProduct, creating, updating, loadingProduct } = useAppSelector((state) => state.products);

  const onCreateProduct = () => {
    form
      .validateFields()
      .then((values: ICreateProductPayload) => {
        const [price_type, price_unit] = (values.price_unit || '').split('.');
        const [upsell_price_type, upsell_price_unit] = (values.upsell_price_unit || '').split('.');
        const limit_visibility = form.getFieldValue('limit_visibility');

        dispatch(
          createProduct({
            ...values,
            price_type: price_type || undefined,
            price_unit: price_unit || undefined,
            upsell_price_type: upsell_price_type || undefined,
            upsell_price_unit: upsell_price_unit || undefined,
            business_id: selectedBusiness?.id!,
            variations: values.variations ?? [],
            limit_visibility,
            tax_rate_ids: values.tax_rate_ids,
            custom: isCustom,
          }),
        )
          .unwrap()
          .then((response) => {
            addProduct?.(response.data);
            closeModal();
          })
          .catch((error) => {
            form.setFields(getFormErrors(error));
          });
      })
      .catch(() => {});
  };

  const onEditProduct = () => {
    if (!selectedBusiness?.id || !selectedProduct?.id) return;
    const { price_unit, upsell_price_unit, upsell, tax_rate_ids } = form.getFieldsValue();
    const [edited_price_type, edited_price_unit] = (price_unit || '').split('.');

    const body = {
      ...selectedProduct,
      ...form.getFieldsValue(),
      price_type: edited_price_type || selectedProduct?.price_type,
      price_unit: edited_price_unit || selectedProduct?.price_unit,
      upsell_price_type: upsell ? upsell_price_unit.split('.')[0] : selectedProduct?.upsell_price_type,
      upsell_price_unit: upsell ? upsell_price_unit.split('.')[1] : selectedProduct?.upsell_price_unit,
      tax_rate_ids,
      custom: isCustom,
    };
    delete body.tax_rates;

    dispatch(
      editProduct({
        business_id: selectedBusiness?.id,
        product_id: selectedProduct.id,
        body,
      }),
    )
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch((error) => form.setFields(getFormErrors(error)));
  };

  const handleCustomSelection = (custom: boolean) => {
    setIsCustom(custom);
    setIsCustomModalOpen(false);
    submitFormHandler();
  };

  const submitFormHandler = () => {
    selectedProduct?.id ? onEditProduct() : onCreateProduct();
  };

  const closeModal = () => {
    form.resetFields();
    setTab(EDrawerTabs.DETAILS);
    dispatch(setSelectedProduct(undefined));
    onClose();
  };

  useEffect(() => {
    if (!selectedProduct?.id || !selectedBusiness?.id) return;

    dispatch(getProductById({ product_id: selectedProduct?.id, business_id: selectedBusiness?.id }))
      .unwrap()
      .then(({ data }) => {
        const { price_type, price_unit, upsell_price_unit, upsell_price_type, sale_end_date, upsell, product_variations, tax_rates } = data;
        form.setFieldsValue({
          ...data,
          price_type,
          price_unit: price_unit ? `${price_type}.${price_unit}` : undefined,
          upsell_price_unit: upsell_price_unit ? `${upsell_price_type}.${upsell_price_unit}` : undefined,
          sale_end_date: sale_end_date ? dayjs(sale_end_date) : undefined,
          upsell: !!upsell,
          variations: product_variations,
          tax_rate_ids: tax_rates?.map((taxRate: IProductTaxRate) => taxRate.tax_rate_id),
        });
      })
      .catch(() => {});
  }, [selectedProduct?.id, selectedBusiness?.id]);

  return (
    <>
      <Modal
        title={translate('quotes.createProduct')}
        visible={open}
        width={720}
        onCancel={closeModal}
        footer={
          <Space>
            <Button disabled={creating || updating} onClick={closeModal}>
              {translate('buttons.cancel')}
            </Button>
            <Button
              requiredPermission={PERMISSIONS.PRODUCTS.EDIT}
              type='primary'
              onClick={() => setIsCustomModalOpen(true)}
              loading={creating || updating}
              disabled={loadingProduct || creating || updating}>
              {translate('buttons.save')}
            </Button>
          </Space>
        }>
        {loadingProduct ? (
          <Row className='h-full w-full' justify='center' align='middle'>
            <Spin spinning={true} tip={translate('assets.productsDrawer.fetchingProduct')} />
          </Row>
        ) : (
          <Form form={form} layout='vertical' requiredMark={false}>
            <Tabs defaultActiveKey={tab} activeKey={tab} items={items(translate, form)} onChange={setTab} />
          </Form>
        )}
      </Modal>

      <Modal
        title={translate('assets.productsDrawer.saveConfirmation')}
        visible={isCustomModalOpen}
        onCancel={() => setIsCustomModalOpen(false)}
        footer={[
          <Button key='cancel' onClick={() => setIsCustomModalOpen(false)}>
            {translate('buttons.cancel')}
          </Button>,
          <Button key='global' type='primary' onClick={() => handleCustomSelection(false)}>
            {translate('assets.productsDrawer.globally')}
          </Button>,
          <Button key='custom' type='primary' danger onClick={() => handleCustomSelection(true)}>
            {translate('assets.productsDrawer.custom')}
          </Button>,
        ]}>
        {translate('assets.productsDrawer.customProduct')}
      </Modal>
    </>
  );
};

const items = (translate: (key: string) => string, form: FormInstance): TabsProps['items'] => {
  return [
    {
      key: EDrawerTabs.DETAILS,
      label: translate('assets.productsDrawer.details'),
      children: <Details form={form} />,
    },
    {
      key: EDrawerTabs.QUANTITY_STOCK,
      label: translate('assets.productsDrawer.quantityStock'),
      children: <QuantityStock form={form} />,
    },
    {
      key: EDrawerTabs.PRICING,
      label: translate('assets.productsDrawer.pricing'),
      children: <Pricing form={form} />,
    },
    {
      key: EDrawerTabs.VARIATIONS,
      label: translate('assets.productsDrawer.variations'),
      children: <Variations form={form} />,
    },
    {
      key: EDrawerTabs.LIMIT_VISIBILITY,
      label: translate('assets.productsDrawer.limitVisibility'),
      children: <LimitVisibility form={form} />,
    },
  ];
};
