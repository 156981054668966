import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Button, Col, Form, Spin, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './FormsBuilder.scss';

import GenericHeader from '@aduvi/components/Header/GenericHeader';
import { useBusiness } from '@aduvi/hooks';

import { getFormById } from 'store/features/form-slice';
import { getWidget, updateWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Configure } from './tabs/configure/Configure';
import { Design } from './tabs/design/Design';
import { Embed } from './tabs/embed/Embed';

export const FormsBuilder = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();
  const { widgetId } = useParams();

  const { entityTypes } = useAppSelector((state) => state.entity);
  const { loading: loadingForm, loadingEntity } = useAppSelector((state) => state.forms);
  const { loading, loadingWidget, selectedWidget, updating } = useAppSelector((state) => state.widget);

  const [selectedTab, setSelectedTab] = useState('1');

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: translate('widgets.tabs.design'),
    },
    {
      key: '2',
      label: translate('widgets.tabs.configure'),
    },
    {
      key: '3',
      label: translate('widgets.tabs.embed'),
    },
  ];

  const onActiveTabChange = (tabKey: string) => {
    setSelectedTab(tabKey);
  };

  const onSave = () => {
    if (!selectedBusiness?.id || !widgetId) return;
    dispatch(
      updateWidget({
        apps: selectedWidget?.apps,
        business_id: selectedBusiness?.id,
        id: widgetId,
        form_id: selectedWidget?.form_id,
        ...form.getFieldsValue(),
      }),
    );
  };

  useEffect(() => {
    if (!widgetId || !selectedBusiness?.id) return;
    dispatch(getWidget({ businessId: selectedBusiness?.id, widgetId }))
      .unwrap()
      .then(({ data }) => {
        form.setFieldsValue({ ...data });
        if (!data?.form_id || !entityTypes?.data?.JOB?.id) return;
        dispatch(getFormById({ business_id: selectedBusiness?.id, form_id: data?.form_id, entity_type_id: entityTypes?.data?.JOB?.id }));
      })
      .catch(() => {});
  }, [selectedBusiness?.id, widgetId]);

  return (
    <Form form={form} layout='vertical' requiredMark={false}>
      <Col span={24} className='widget-forms-wrapper'>
        <GenericHeader
          breadcrumbs={
            <Breadcrumb
              className='ml-10 mb-10'
              items={[
                { title: translate('widgets.title'), onClick: () => navigate(`/widgets/forms`), className: 'cursor-pointer' },
                { title: translate('widgets.forms'), onClick: () => navigate(`/widgets/forms-configure`), className: 'cursor-pointer' },
              ]}
            />
          }
          tabs={<Tabs defaultActiveKey='1' activeKey={selectedTab} className='flex-center' items={tabs} onChange={onActiveTabChange} />}
          actions={
            <Button onClick={onSave} type='primary' loading={updating} disabled={updating}>
              {translate('buttons.save')}
            </Button>
          }
        />
      </Col>
      <Spin spinning={loading || loadingEntity || loadingForm || loadingWidget}>
        <Design form={form} selectedTab={selectedTab} />
        <Configure selectedTab={selectedTab} />
        <Col className='flex-center mt-30' style={{ display: selectedTab !== '3' ? 'none' : '' }}>
          <Embed form={form} />
        </Col>
      </Spin>
    </Form>
  );
};
