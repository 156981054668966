import { UIEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleFilled } from '@ant-design/icons';

import { Col, Row, Select, Typography } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useActiveTheme, useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { IPackage } from '@aduvi/types';

import { getServices, resetServicesState } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { PackagesModal } from '../../upsert-quote/@components/PackagesModal';

interface IProps {
  addPackage: (item: IPackage) => void;
  existingPackageIds?: string[];
}

export const PackagesList = ({ addPackage, existingPackageIds }: IProps) => {
  const activeTheme = useActiveTheme();
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { services, loading: loadingServices } = useAppSelector((state) => state.services);
  const hasPermissionToViewServices = useUserPermissionCheck(PERMISSIONS.SERVICES.VIEW);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const packages = useMemo(() => services?.data.map((item) => item.packages).flat(), [services?.data]);

  const handleScrollServices = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement;

      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        if (!services?.current_page || !services.next_page_url) return;
        onGetServices(services?.current_page + 1, 10);
      }
    },
    [services],
  );

  const onGetServices = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness?.id || !hasPermissionToViewServices) return;
      dispatch(getServices({ business_id: selectedBusiness?.id, params: { page, size } }));
    },
    [selectedBusiness?.id],
  );

  useEffect(() => {
    dispatch(resetServicesState());
    onGetServices(1, 50);
  }, [selectedBusiness?.id]);

  return (
    <>
      <Select
        bordered={false}
        value={translate('quotes.addPackage')}
        suffixIcon={null}
        className='w-full mt-10 mb-10'
        loading={loadingServices}
        onPopupScroll={handleScrollServices}>
        <Select.Option key='create-new-package' disabled style={{ cursor: 'default' }}>
          <Row
            style={{ padding: '8px 0', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setIsModalVisible(true);
            }}>
            <PlusCircleFilled style={{ color: `${activeTheme?.menuItemText}` }} className='fs-24 mr-10' />
            <Typography.Text style={{ color: `${activeTheme?.menuItemText}` }}>{translate('quotes.createPackage')}</Typography.Text>
          </Row>
        </Select.Option>
        {packages
          ?.filter((item) => !existingPackageIds || !existingPackageIds?.includes(item.id))
          .map((item, index) => (
            <Select.Option key={item?.id} style={{ borderBottom: index !== packages.length - 1 ? '1px dashed #5194D870' : '0px' }}>
              <Row justify={'space-between'} onClick={() => addPackage(item)}>
                <Col>{`${item.service_name} - ${item.name}`}</Col>
                <Col>${item.flat_price ?? item.base_price}</Col>
              </Row>
            </Select.Option>
          ))}
      </Select>

      {isModalVisible && <PackagesModal open={isModalVisible} onClose={() => setIsModalVisible(false)} addPackage={addPackage} />}
    </>
  );
};
