import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Constants } from '@aduvi/constants';
import { ThemeAdapter } from '@aduvi/patterns/adapter/ThemeAdapter';
import {
  EAuthorized,
  IAcceptInvitationPayload,
  IAuthState,
  IChangePasswordPayload,
  ICurrentUser,
  IForgotPasswordPayload,
  ILoginPayload,
  IRegisterPayload,
  IResetPasswordPayload,
  IUpdateUser2FaPayload,
  IUpdateUserDataPayload,
  IValidateTokenPayload,
  IVerifyEmailPayload,
} from '@aduvi/types/auth';
import { ELoadingState } from '@aduvi/types/common';
import { getBusiness } from '@aduvi/utils/helper';
import { Notification } from '@aduvi/utils/notification';

import * as AuthServices from 'store/services/auth.service';

import { setSelectedBusiness, setUserBusinesses } from './business-slice';
import { setActiveTheme } from './common-slice';

const initialState: IAuthState = {
  token: null,
  refreshToken: null,
  authActionState: ELoadingState.UNINITIALIZED,
  isAuthorized: EAuthorized.UNINITIALIZED,
  loginState: ELoadingState.UNINITIALIZED,
  registerState: ELoadingState.UNINITIALIZED,
  forgetPasswordState: ELoadingState.UNINITIALIZED,
  resetPasswordState: ELoadingState.UNINITIALIZED,
  validateTokenState: ELoadingState.UNINITIALIZED,
  resendVerificationEmailState: ELoadingState.UNINITIALIZED,
  resendVerificationCodeState: ELoadingState.UNINITIALIZED,
  acceptInvitationState: ELoadingState.UNINITIALIZED,
  user: {
    user: undefined,
    loading: false,
    updating: false,
    resettingPassword: false,
  },
};

export const login = createAsyncThunk('auth/login', async (params: ILoginPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.login(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const register = createAsyncThunk('auth/register', async (params: IRegisterPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.register(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const forgetPassword = createAsyncThunk('auth/forget-password', async (params: IForgotPasswordPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.forgetPassword(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const validateToken = createAsyncThunk('auth/validate-token', async (params: IValidateTokenPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.validateToken(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk('auth/reset-password', async (params: IResetPasswordPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.resetPassword(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

// inserted business id because from the onboarding we have to call this endpoint to apply the theme
export const getUserData = createAsyncThunk('user/get-user-data', async ({ businessId }: { businessId?: string }, { rejectWithValue, dispatch }) => {
  try {
    const user = await AuthServices.getUserData();
    if (user.data.businesses.length) {
      let business = user?.data?.businesses?.find((business) => business?.id === businessId);
      if (!businessId) {
        business = getBusiness(user.data);
      }

      dispatch(setUserBusinesses(user.data.businesses));
      dispatch(setSelectedBusiness(business));

      const theme = new ThemeAdapter(user.data, business);
      dispatch(setActiveTheme(theme.getActiveTheme()));
    }
    return user;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const verifyEmail = createAsyncThunk('auth/verify-email', async (params: IVerifyEmailPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.verifyEmail(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resendVerificationEmail = createAsyncThunk('auth/resend-verification-email', async (params: { email: string }, { rejectWithValue }) => {
  try {
    return await AuthServices.resendVerificationEmail(params.email);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateUserData = createAsyncThunk('user/update-user-data', async (params: IUpdateUserDataPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.updateUserData(params.id, params.body);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateUser2Fa = createAsyncThunk('user/update-user-2fa', async (params: IUpdateUser2FaPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.updateUser2Fa(params.id, params?.otp);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resendVerificationCode = createAsyncThunk('auth/send-otp', async (params: { email: string }, { rejectWithValue }) => {
  try {
    return await AuthServices.resendVerificationCode(params.email);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const changePassword = createAsyncThunk(
  'user/change-password',
  async (params: { id: string; body: IChangePasswordPayload }, { rejectWithValue }) => {
    try {
      return await AuthServices.changePassword(params.id, params.body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const acceptInvitation = createAsyncThunk('auth/accept-invitation', async (params: IAcceptInvitationPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.acceptInvitation(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    setAuthorization: (state, action) => {
      state.isAuthorized = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.isAuthorized = EAuthorized.UNAUTHORIZED;
      state.authActionState = ELoadingState.UNINITIALIZED;
    },
    setToken: (state, { payload }) => {
      state.isAuthorized = EAuthorized.AUTHORIZED;
      state.token = payload.data.access_token;
      localStorage.setItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN, payload.data.access_token);
    },
    setUserData: (state, { payload }) => {
      state.user.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginState = ELoadingState.LOADING;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loginState = ELoadingState.SUCCESS;
        if (payload?.data?.access_token && payload.data.email_verified_at) {
          state.token = payload.data.access_token;
          state.isAuthorized = EAuthorized.AUTHORIZED;
          localStorage.setItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN, payload.data.access_token);
        }
      })
      .addCase(login.rejected, (state) => {
        state.loginState = ELoadingState.FAILED;
      })
      .addCase(register.pending, (state) => {
        state.registerState = ELoadingState.LOADING;
      })
      .addCase(register.fulfilled, (state) => {
        state.registerState = ELoadingState.SUCCESS;
      })
      .addCase(register.rejected, (state) => {
        state.registerState = ELoadingState.FAILED;
      })
      .addCase(forgetPassword.pending, (state) => {
        state.forgetPasswordState = ELoadingState.LOADING;
      })
      .addCase(forgetPassword.fulfilled, (state) => {
        state.forgetPasswordState = ELoadingState.SUCCESS;
      })
      .addCase(forgetPassword.rejected, (state) => {
        state.forgetPasswordState = ELoadingState.FAILED;
      })
      .addCase(validateToken.pending, (state) => {
        state.validateTokenState = ELoadingState.LOADING;
      })
      .addCase(validateToken.fulfilled, (state) => {
        state.validateTokenState = ELoadingState.SUCCESS;
      })
      .addCase(validateToken.rejected, (state) => {
        state.validateTokenState = ELoadingState.FAILED;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordState = ELoadingState.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordState = ELoadingState.SUCCESS;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPasswordState = ELoadingState.FAILED;
      })
      .addCase(getUserData.pending, (state) => {
        state.user.loading = true;
      })
      .addCase(getUserData.fulfilled, (state, { payload }) => {
        state.user.loading = false;
        state.user.user = payload?.data;
      })
      .addCase(getUserData.rejected, (state) => {
        state.user.loading = false;
      })
      .addCase(resendVerificationEmail.pending, (state) => {
        state.resendVerificationEmailState = ELoadingState.LOADING;
      })
      .addCase(resendVerificationEmail.fulfilled, (state) => {
        state.resendVerificationEmailState = ELoadingState.SUCCESS;
      })
      .addCase(resendVerificationEmail.rejected, (state) => {
        state.resendVerificationEmailState = ELoadingState.FAILED;
      })
      .addCase(updateUserData.pending, (state) => {
        state.user.updating = true;
      })
      .addCase(updateUserData.fulfilled, (state, { meta }) => {
        state.user.updating = false;
        state.user.user = { ...state.user.user, ...meta.arg.body } as ICurrentUser;
        Notification.success({ title: 'Updated successfully', description: 'User updated successfully' });
      })
      .addCase(updateUserData.rejected, (state) => {
        state.user.updating = false;
      })
      .addCase(updateUser2Fa.pending, (state) => {
        state.user.updating = true;
      })
      .addCase(updateUser2Fa.fulfilled, (state, { payload }) => {
        state.user.updating = false;
        if (payload?.data) {
          state.user.user = payload?.data;
        }
      })
      .addCase(updateUser2Fa.rejected, (state) => {
        state.user.updating = false;
      })
      .addCase(resendVerificationCode.pending, (state) => {
        state.resendVerificationCodeState = ELoadingState.LOADING;
      })
      .addCase(resendVerificationCode.fulfilled, (state) => {
        state.resendVerificationCodeState = ELoadingState.SUCCESS;
      })
      .addCase(resendVerificationCode.rejected, (state) => {
        state.resendVerificationCodeState = ELoadingState.FAILED;
      })
      .addCase(changePassword.pending, (state) => {
        state.user.resettingPassword = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.user.resettingPassword = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.user.resettingPassword = false;
      })
      .addCase(acceptInvitation.pending, (state) => {
        state.acceptInvitationState = ELoadingState.LOADING;
      })
      .addCase(acceptInvitation.fulfilled, (state) => {
        state.acceptInvitationState = ELoadingState.SUCCESS;
      })
      .addCase(acceptInvitation.rejected, (state) => {
        state.acceptInvitationState = ELoadingState.FAILED;
      });
  },
});

export const authReducer = authSlice.reducer;
export const { resetAuthState, setAuthorization, logout, setToken, setUserData } = authSlice.actions;
