import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CalendarOutlined,
  FormOutlined,
  PlusOutlined,
  RightSquareOutlined,
  SolutionOutlined,
  TableOutlined,
  UngroupOutlined,
} from '@ant-design/icons';

import { Button, Col, Dropdown, MenuProps, Row, Spin } from 'antd';

import { useBusiness, useSelectedPlan } from '@aduvi/hooks';
import { EOrderType, EPersonalizedView, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

import { createPersonalizedView, setShowPersonalizedViewDrawer } from 'store/features/personalized-views-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { usePartner } from '../../../hooks/usePartner';
import { isAppIncludedInPlan } from '../../../utils/helper';

interface IProps {
  entityType?: IEntityType;
  setNewViewType: Dispatch<SetStateAction<string>>;
  origin: EPersonalizedViewOrigin;
}

export const TabsDropDown = ({ entityType, setNewViewType, origin }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const partner = usePartner();
  const { personalizedViews, creating, deleting } = useAppSelector((state) => state.personalizedViews);
  const { selectedPlan, planApps } = useSelectedPlan();
  const { t: translate } = useTranslation();

  const onCreateNewView = (view: EPersonalizedView) => {
    setNewViewType(view);
    if (view === EPersonalizedView.FORMS) {
      if (selectedBusiness?.id) {
        dispatch(
          createPersonalizedView({
            business_id: selectedBusiness.id,
            body: {
              name: 'Forms',
              view_display: EPersonalizedView.FORMS,
              origin,
              default_sort_key: 'date',
              default_sort_order: EOrderType.ASC,
              default_date_values: new Date().toISOString(),
              start_date: new Date().toISOString(),
              end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
              style: JSON.stringify([]),
            },
          }),
        );
      }
    } else {
      dispatch(setShowPersonalizedViewDrawer(true));
    }
  };

  const dropDownItems = useMemo(() => {
    const items: MenuProps['items'] = [
      {
        key: '1',
        type: 'group',
        label: translate('personalizedView.addNewView'),
        children: [
          {
            key: '1-0',
            onClick: () => onCreateNewView(EPersonalizedView.TABLE),
            label: (
              <Row style={{ gap: '5px' }}>
                <Col>
                  <TableOutlined />
                </Col>
                <Col>{translate('personalizedView.options.table')}</Col>
              </Row>
            ),
          },
          {
            key: '1-1',
            onClick: () => onCreateNewView(EPersonalizedView.PIPELINE),
            label: (
              <Row style={{ gap: '5px' }}>
                <Col>
                  <RightSquareOutlined />
                </Col>
                <Col>{translate('personalizedView.options.pipeline')}</Col>
              </Row>
            ),
          },
          // {
          //   onClick: () => onCreateNewView(EPersonalizedView.KANBAN),
          //   label: (
          //     <Row style={{ gap: '5px' }}>
          //       <Col>
          //         <ProjectOutlined />
          //       </Col>
          //       <Col>{translate('personalizedView.options.kanban')}</Col>
          //     </Row>
          //   ),
          //   key: '1-2',
          // },
          {
            onClick: () => onCreateNewView(EPersonalizedView.TIMELINE),
            label: (
              <Row style={{ gap: '5px' }}>
                <Col>
                  <UngroupOutlined />
                </Col>
                <Col> {translate('personalizedView.options.timeline')}</Col>
              </Row>
            ),
            key: '1-3',
          },
          {
            onClick: () => onCreateNewView(EPersonalizedView.CALENDAR),
            label: (
              <Row style={{ gap: '5px' }}>
                <Col>
                  <CalendarOutlined />
                </Col>
                <Col>{translate('personalizedView.options.calendar')}</Col>
              </Row>
            ),
            key: '1-4',
          },
          // {
          //   onClick: () => onCreateNewView(EPersonalizedView.MAP),
          //   label: (
          //     <Row style={{ gap: '5px' }}>
          //       <Col>
          //         <EnvironmentOutlined />
          //       </Col>
          //       <Col>{translate('personalizedView.options.map')}</Col>
          //     </Row>
          //   ),
          //   key: '1-6',
          // },
          {
            onClick: () => onCreateNewView(EPersonalizedView.FORMS),
            label: (
              <Row style={{ gap: '5px' }}>
                <Col>
                  <FormOutlined />
                </Col>
                <Col>{translate('personalizedView.options.forms')}</Col>
              </Row>
            ),
            key: '1-5',
            hide:
              !isAppIncludedInPlan(selectedPlan?.data, planApps, 'forms', partner) ||
              !entityType?.has_forms ||
              personalizedViews?.some((view) => view?.view_display === EPersonalizedView.FORMS),
          },
          {
            onClick: () => onCreateNewView(EPersonalizedView.ADDRESS_BOOK),
            label: (
              <Col>
                <SolutionOutlined /> {translate('personalizedView.options.address_book')}
              </Col>
            ),
            key: '1-6',
            hide: origin !== EPersonalizedViewOrigin.CONTACTS,
          },
        ]?.filter((i) => !i.hide),
      },
    ];

    return items;
  }, [origin, entityType, personalizedViews]);

  return (
    <Dropdown menu={{ items: dropDownItems }} trigger={['click']} overlayStyle={{ width: '150px' }}>
      <Spin size='small' spinning={creating || deleting}>
        <Button
          icon={<PlusOutlined style={{ color: 'gray' }} />}
          style={{
            border: '1px solid',
            borderColor: ' #d9d9d9',
            backgroundColor: '#ffffff',
            boxShadow: 'none',
          }}
          onClick={(e) => e.preventDefault()}
        />
      </Spin>
    </Dropdown>
  );
};
