import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { Title } from '@aduvi/components/Title';
import { ILineItem, IQuote } from '@aduvi/types';

export const ProductsTable = ({ selectedQuote }: { selectedQuote?: IQuote }) => {
  const productColumns = useProductsTableColumns();

  const products = useMemo(() => {
    return selectedQuote?.line_items?.filter((item) => item?.product_id) ?? [];
  }, [selectedQuote]);

  return products?.length ? (
    <Table className='mt-20' bordered={false} pagination={false} dataSource={products} columns={productColumns} rowKey='id' />
  ) : (
    <></>
  );
};

const useProductsTableColumns = () => {
  const { t: translate } = useTranslation();
  return [
    {
      title: translate('quotes.product'),
      dataIndex: ['product', 'name'],
      width: '40%',
      render: (text: string) => (
        <Title className='mb-0' level={5}>
          {text}
        </Title>
      ),
      editable: true,
    },
    {
      title: translate('quotes.qtyUppercase'),
      dataIndex: 'quantity',
      width: '10%',
      editable: true,
    },
    {
      title: translate('quotes.priceUppercase'),
      dataIndex: 'price',
      width: '12%',
      editable: true,
      render: (text: number) => <PriceDisplay price={text} />,
    },
    {
      title: translate('quotes.taxRateUppercase'),
      width: '16%',
      editable: true,
      render: (_: string, row: ILineItem) => {
        return row?.tax_rates?.map((rate) => rate?.tax_percentage?.toString()?.slice(0, 2) + '%')?.join(', ');
      },
    },
    {
      title: translate('quotes.totalUppercase'),
      dataIndex: 'sub_total',
      width: '22%',
      render: (text: number) => <PriceDisplay price={text} />,
      align: 'right',
    },
  ] as ColumnsType<ILineItem>;
};
