import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PERMISSIONS } from '@aduvi/constants';
import { useSelectedPlan, useUserPermissionCheck } from '@aduvi/hooks';
import { isAppIncludedInPlan } from '@aduvi/utils/helper';

import { setSelectedEvent } from 'store/features/events-slice';
import { setSelectedProduct } from 'store/features/products-slice';
import { setSelectedService } from 'store/features/services-slice';
import { useAppDispatch } from 'store/hooks';

import { usePartner } from '../../@aduvi/hooks/usePartner';

import { Events } from './events/Events';
import { Products } from './products/Products';
import { Services } from './services/Services';

interface IProps {
  currentTab?: AssetsType;
  searchValue: string;
  setShowDrawer: (value: SetStateAction<boolean>) => void;
  setCurrentTab: (value: SetStateAction<AssetsType | undefined>) => void;
}

export enum AssetsType {
  SERVICES = '/store/services',
  PRODUCTS = '/store/products',
  EVENTS = '/store/events',
}

const storeCreatePermissions = {
  [AssetsType.SERVICES]: PERMISSIONS.SERVICES.CREATE,
  [AssetsType.PRODUCTS]: PERMISSIONS.PRODUCTS.CREATE,
  [AssetsType.EVENTS]: PERMISSIONS.EVENTS.CREATE,
};

const storeSearchPlaceholder = {
  [AssetsType.SERVICES]: 'assets.services.searchServicesPlaceholder',
  [AssetsType.PRODUCTS]: 'assets.products.searchProductsPlaceholder',
  [AssetsType.EVENTS]: 'assets.events.searchEventsPlaceholder',
};

const storeAddBtn = {
  [AssetsType.SERVICES]: 'assets.services.addService',
  [AssetsType.PRODUCTS]: 'assets.services.addProduct',
  [AssetsType.EVENTS]: 'assets.events.addEvent',
};

export const useAssets = ({ searchValue, currentTab, setShowDrawer, setCurrentTab }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const partner = usePartner();

  const { t: translate } = useTranslation();
  const { selectedPlan, planApps } = useSelectedPlan();

  const tabPermissions = {
    [AssetsType.SERVICES]: useUserPermissionCheck(PERMISSIONS.SERVICES.VIEW) && isAppIncludedInPlan(selectedPlan.data, planApps, 'service', partner),
    [AssetsType.PRODUCTS]: useUserPermissionCheck(PERMISSIONS.PRODUCTS.VIEW) && isAppIncludedInPlan(selectedPlan.data, planApps, 'product', partner),
    [AssetsType.EVENTS]: useUserPermissionCheck(PERMISSIONS.EVENTS.VIEW) && isAppIncludedInPlan(selectedPlan.data, planApps, 'event', partner),
  };

  const AssetsComponents = {
    [AssetsType.SERVICES]: <Services searchKey={searchValue} currentTab={currentTab} />,
    [AssetsType.PRODUCTS]: <Products searchKey={searchValue} currentTab={currentTab} />,
    [AssetsType.EVENTS]: <Events searchKey={searchValue} currentTab={currentTab} />,
  };

  const onOpenCloseDrawer = (open = false) => {
    dispatch(setSelectedService(undefined));
    dispatch(setSelectedProduct(undefined));
    dispatch(setSelectedEvent(undefined));
    setShowDrawer(open);
  };

  const onSearchChange = (term: string) => {
    navigate({ search: term?.length ? `?search=${term}` : '' });
  };

  const setCurrentTabAndClearSearch = (value: string) => {
    setCurrentTab(value as AssetsType);
    navigate(value);
  };

  const tabItems = () => {
    const tabs = [];
    if (tabPermissions[AssetsType.SERVICES]) {
      tabs.push({
        label: translate('assets.services.services'),
        value: AssetsType.SERVICES,
      });
    }
    if (tabPermissions[AssetsType.PRODUCTS]) {
      tabs.push({
        label: translate('assets.products.products'),
        value: AssetsType.PRODUCTS,
      });
    }
    if (tabPermissions[AssetsType.EVENTS]) {
      tabs.push({
        label: translate('assets.events.events'),
        value: AssetsType.EVENTS,
      });
    }
    return tabs;
  };

  return {
    storeAddBtn,
    storeCreatePermissions,
    storeSearchPlaceholder,
    tabPermissions,
    AssetsComponents,
    onOpenCloseDrawer,
    onSearchChange,
    setCurrentTabAndClearSearch,
    tabItems,
  };
};
