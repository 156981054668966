import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { Button, Col, Form, FormInstance, Row, UploadFile } from 'antd';
import './Build.scss';

import { UpsertDrawer } from '@aduvi/components/ManagementFields/UpsertDrawer';
import { EShoppingBasketPosition } from '@aduvi/constants';
import { useBusiness, useTerminology } from '@aduvi/hooks';
import { EPersonalizedViewOrigin } from '@aduvi/types';
import { EFormLayout, EFormPurpose } from '@aduvi/types/form';
import { EWidgetApps, ICreateWidget } from '@aduvi/types/widget';
import { generateFormWidgetIFrame, replaceJobWithTerminology } from '@aduvi/utils/helper';

import { getEntityFields } from 'store/features/fields-slice';
import { setSelectedEntityForm, updateEntityForm } from 'store/features/form-slice';
import { createWidget, deleteWidget, updateWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { RightSidebar } from './components/RightSidebar';
import { ShoppingBasket } from './components/ShoppingBasket';
import { Widget } from './components/Widget';
import { BookingFormField } from './BookingFormField';
import { useBuild } from './Build.hook';
import { IBookingFormFieldProps } from './helper';

export const Build = ({
  form,
  entityTypeId,
  fields,
  fileList,
  setFileList,
  setFields,
  onAddField,
}: {
  form: FormInstance;
  entityTypeId?: string;
  fields: IBookingFormFieldProps[];
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
  onAddField: (element: IBookingFormFieldProps, index: number) => void;
}) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();
  const terminology = useTerminology();

  const { fields: entityFields, creating } = useAppSelector((state) => state.fields);
  const { selectedEntityForm, isHoveringWidgetArea } = useAppSelector((state) => state.forms);
  const { creating: creatingWiget } = useAppSelector((state) => state.widget);

  const [isDragging, setIsDragging] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [entityFieldDrawerStats, setEntityFieldDrawerStats] = useState({
    open: false,
    index: 0,
  });

  const { contentStyle, shoppingBasketPosition, layoutAlignment, onCreateEntityField, onCreateNewFieldDrawer, beforeUpload, onCreateAsset } =
    useBuild({
      entityFieldDrawerStats,
      entityTypeId,
      form,
      selectedEntityForm,
      onAddField,
      setEntityFieldDrawerStats,
    });

  const layout: EFormLayout = Form.useWatch(['form_style', 0, 'layout'], form);

  const isDraggingStyle = useMemo(() => (isDragging ? { rowGap: '20px', paddingBottom: '40px' } : {}), [isDragging]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id === over?.id) {
      if (isHoveringWidgetArea) {
        setFields((prev) =>
          prev
            .map((item) => {
              if (item?.field_id === active?.id) {
                return {
                  ...item,
                  field_instance: {
                    ...item?.field_instance,
                    display_settings: {
                      ...item?.field_instance?.display_settings,
                      is_widget_field: true,
                    },
                    weight: 0,
                  },
                };
              }
              return item;
            })
            .map((item, index) => {
              return {
                ...item,
                field_instance: {
                  ...item.field_instance,
                  weight: index,
                },
              };
            }),
        );
      }
      return;
    }

    const activeIndex = fields.findIndex((item) => item.field_id === active.id);
    const overIndex = fields?.findIndex((item) => item.field_id === over?.id);

    if (activeIndex < 0 || overIndex < 0) return;

    return setFields(
      arrayMove(fields, activeIndex, overIndex).map((item, index) => ({
        ...item,
        field_instance: {
          ...item.field_instance,
          display_settings: {
            ...item?.field_instance?.display_settings,
            is_widget_field:
              overIndex === index
                ? over?.data?.current?.sortable?.containerId === 'widget-container-id'
                : item?.field_instance?.display_settings?.is_widget_field,
          },
          weight: index,
        },
      })),
    );
  };

  const enableFormWidget = () => {
    if (!selectedBusiness?.id || !selectedEntityForm?.id) return;

    const createParams: ICreateWidget = {
      business_id: selectedBusiness?.id || '',
      apps: EWidgetApps.FORMS,
      title: selectedEntityForm?.name || '',
      description: '',
      widget_style: [],
      form_id: selectedEntityForm?.id,
      entity_type_id: selectedEntityForm?.entity_type_id,
    };

    dispatch(createWidget(createParams))
      .unwrap()
      .then((response) => {
        dispatch(
          updateWidget({
            ...response.data,
            business_id: selectedBusiness?.id,
            embed_code: generateFormWidgetIFrame(selectedBusiness?.id, response?.data?.id),
          }),
        )
          .unwrap()
          .then((response) => {
            dispatch(
              updateEntityForm({
                ...selectedEntityForm,
                widget: response.data,
                embed_widget_code: generateFormWidgetIFrame(selectedEntityForm.business_id, selectedEntityForm.form_url),
              }),
            )
              .unwrap()
              .then(({ data }) => {
                dispatch(setSelectedEntityForm(data));
              })
              .catch(() => {});
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  const disableFormWidget = () => {
    if (!selectedBusiness?.id || !selectedEntityForm?.widget?.id) return;
    dispatch(deleteWidget({ business_id: selectedBusiness?.id, widget_id: selectedEntityForm?.widget?.id }))
      .unwrap()
      .then(() => {
        dispatch(
          updateEntityForm({
            ...selectedEntityForm,
            widget: undefined,
            embed_widget_code: '',
          }),
        )
          .unwrap()
          .then(({ data }) => {
            dispatch(setSelectedEntityForm(data));
          })
          .catch(() => {});

        setFields((prev) =>
          prev.map((item) => ({
            ...item,
            field_instance: {
              ...item.field_instance,
              display_settings: {
                ...item.field_instance?.display_settings,
                is_widget_field: false,
              },
            },
          })),
        );
      })
      .catch(() => {});
  };

  useEffect(() => {
    form.setFieldValue(['form_style', 0, 'background_mode'], selectedEntityForm?.style?.form_style?.[0]?.background_mode);
    form.setFieldValue(['form_style', 0, 'background_image'], selectedEntityForm?.style?.form_style?.[0]?.background_image);
  }, [selectedEntityForm]);

  useEffect(() => {
    if (selectedBusiness?.id && entityTypeId) {
      dispatch(getEntityFields({ businessId: selectedBusiness.id, entityTypeId }));
    }
  }, [selectedBusiness?.id, entityTypeId]);

  return (
    <Col
      className='build-tab-wrapper'
      style={
        isMobileView
          ? {}
          : [
              EFormLayout.BACKGROUND_FULL_CONTENT_LEFT,
              EFormLayout.BACKGROUND_FULL_CONTENT_CENTER,
              EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT,
            ].includes(layout)
          ? contentStyle
          : {}
      }>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => {
          setIsDragging(false);
          onDragEnd(event);
        }}
        onDragStart={() => setIsDragging(true)}>
        {([EFormLayout.BACKGROUND_FULL_CONTENT_LEFT, EFormLayout.BACKGROUND_FULL_CONTENT_CENTER, EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT].includes(
          layout,
        ) ||
          isMobileView) && (
          <Row justify={'center'} style={{ position: 'relative' }}>
            {selectedEntityForm?.widget?.id && selectedEntityForm?.purpose === EFormPurpose.CREATE && !isMobileView ? (
              <Widget
                form={form}
                fields={fields}
                isDragging={isDragging}
                entityFields={entityFields}
                entityTypeId={entityTypeId}
                setFields={setFields}
                onAddField={onAddField}
                setIsDragging={setIsDragging}
                onCreateNewFieldDrawer={onCreateNewFieldDrawer}
                onCreateAsset={onCreateAsset}
                onCloseWidget={disableFormWidget}
              />
            ) : null}

            <Col className='form-wrapper' span={isMobileView ? 11 : 24} style={isMobileView ? contentStyle : {}}>
              {form.getFieldValue(['form_style', 0, 'shopping_basket']) && isMobileView && (
                <ShoppingBasket
                  amount={399}
                  style={{
                    borderRadius: '50px',
                    color: form.getFieldValue(['form_style', 0, 'primary_font_color']),
                    fontFamily: form.getFieldValue(['form_style', 0, 'font_family']),
                    ...shoppingBasketPosition[form.getFieldValue(['form_style', 0, 'shopping_basket_position']) as EShoppingBasketPosition],
                  }}
                  className='shopping-basket'
                />
              )}
              {!isMobileView && selectedEntityForm?.purpose === EFormPurpose.CREATE ? (
                <Row justify={'center'} className='mt-20'>
                  {!selectedEntityForm?.widget?.id ? (
                    <Button type='text' className='enable-widget' disabled={creatingWiget} loading={creatingWiget} onClick={enableFormWidget}>
                      {translate('forms.forms.enableWidget')}
                    </Button>
                  ) : null}
                </Row>
              ) : null}
              <Row
                className='mb-20'
                justify={
                  [
                    EFormLayout.BACKGROUND_FULL_CONTENT_LEFT,
                    EFormLayout.BACKGROUND_FULL_CONTENT_CENTER,
                    EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT,
                  ].includes(layout)
                    ? layoutAlignment[layout]
                    : 'center'
                }>
                <Col
                  className={`booking-form mt-30 ${isMobileView ? 'w-full mr-20 ml-20 ' : ''}`}
                  style={{ ...isDraggingStyle, background: form.getFieldValue(['form_style', 0, 'form_background']) }}>
                  <BookingFormField
                    form={form}
                    fields={fields}
                    isDragging={isDragging}
                    entityFields={entityFields}
                    fileList={fileList}
                    entityTypeId={entityTypeId}
                    setFields={setFields}
                    onAddField={onAddField}
                    setFileList={setFileList}
                    beforeUpload={beforeUpload}
                    setIsDragging={setIsDragging}
                    onCreateNewFieldDrawer={onCreateNewFieldDrawer}
                    onCreateAsset={onCreateAsset}
                  />
                </Col>
                <Col className='icons-container'>
                  <RightSidebar form={form} onMobileView={() => setIsMobileView((prevState) => !prevState)} isMobileView={isMobileView} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {[EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT, EFormLayout.CONTENT_FULL_NO_BACKGROUND, EFormLayout.CONTENT_RIGHT_BACKGROUND_LEFT].includes(
          layout,
        ) &&
          !isMobileView && (
            <Row
              className='form-wrapper'
              style={{
                minHeight: 'calc(100vh - 63px)',
                flexDirection: layout === EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT ? 'row-reverse' : 'row',
              }}>
              {layout !== EFormLayout.CONTENT_FULL_NO_BACKGROUND && selectedEntityForm?.purpose === EFormPurpose.CREATE && (
                <Col span={12} className='p-10' style={{ minHeight: 'calc(100vh - 63px)', ...contentStyle }}>
                  {!selectedEntityForm?.widget?.id ? (
                    <Row justify={'center'}>
                      <Button type='text' className='enable-widget' disabled={creatingWiget} loading={creatingWiget} onClick={enableFormWidget}>
                        {translate('forms.forms.enableWidget')}
                      </Button>
                    </Row>
                  ) : null}
                  {selectedEntityForm?.widget?.id && !isMobileView ? (
                    <Widget
                      form={form}
                      fields={fields}
                      isDragging={isDragging}
                      entityFields={entityFields}
                      entityTypeId={entityTypeId}
                      setFields={setFields}
                      onAddField={onAddField}
                      setIsDragging={setIsDragging}
                      onCreateNewFieldDrawer={onCreateNewFieldDrawer}
                      onCreateAsset={onCreateAsset}
                      onCloseWidget={disableFormWidget}
                    />
                  ) : null}
                </Col>
              )}

              <Col
                span={layout !== EFormLayout.CONTENT_FULL_NO_BACKGROUND ? 12 : 24}
                className={`booking-form ${isMobileView ? 'w-full mr-20 ml-20' : ''}`}
                style={{ ...isDraggingStyle, background: form.getFieldValue(['form_style', 0, 'form_background']), borderRadius: 0 }}>
                {layout === EFormLayout.CONTENT_FULL_NO_BACKGROUND && selectedEntityForm?.purpose === EFormPurpose.CREATE && (
                  <Row justify={'center'} className='p-10'>
                    {!selectedEntityForm?.widget?.id ? (
                      <Row justify={'center'}>
                        <Button type='text' className='enable-widget' disabled={creatingWiget} loading={creatingWiget} onClick={enableFormWidget}>
                          {translate('forms.forms.enableWidget')}
                        </Button>
                      </Row>
                    ) : null}
                    {selectedEntityForm?.widget?.id && !isMobileView ? (
                      <Widget
                        form={form}
                        fields={fields}
                        isDragging={isDragging}
                        entityFields={entityFields}
                        entityTypeId={entityTypeId}
                        setFields={setFields}
                        onAddField={onAddField}
                        setIsDragging={setIsDragging}
                        onCreateNewFieldDrawer={onCreateNewFieldDrawer}
                        onCreateAsset={onCreateAsset}
                        onCloseWidget={disableFormWidget}
                      />
                    ) : null}
                  </Row>
                )}
                <BookingFormField
                  form={form}
                  fields={fields}
                  isDragging={isDragging}
                  entityFields={entityFields}
                  fileList={fileList}
                  entityTypeId={entityTypeId}
                  setFields={setFields}
                  onAddField={onAddField}
                  setFileList={setFileList}
                  beforeUpload={beforeUpload}
                  setIsDragging={setIsDragging}
                  onCreateNewFieldDrawer={onCreateNewFieldDrawer}
                  onCreateAsset={onCreateAsset}
                />
              </Col>
              <Col span={1} className='icons-container' style={{ left: layout === EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT ? 0 : undefined }}>
                <RightSidebar form={form} onMobileView={() => setIsMobileView((prevState) => !prevState)} isMobileView={isMobileView} />
              </Col>
            </Row>
          )}
        {form.getFieldValue(['form_style', 0, 'shopping_basket']) && !isMobileView && (
          <ShoppingBasket
            amount={399}
            style={{
              borderRadius: '50px',
              color: form.getFieldValue(['form_style', 0, 'primary_font_color']),
              fontFamily: form.getFieldValue(['form_style', 0, 'font_family']),
              ...shoppingBasketPosition[form.getFieldValue(['form_style', 0, 'shopping_basket_position']) as EShoppingBasketPosition],
            }}
            className='shopping-basket'
          />
        )}
        <Form.Item name={['form_style']} hidden />

        {entityFieldDrawerStats.open && (
          <UpsertDrawer
            title={replaceJobWithTerminology(translate('settings.menu.createField'), terminology, true)}
            showDrawer={entityFieldDrawerStats.open}
            data={undefined}
            loadingAction={creating}
            permissionToEdit={undefined}
            showFieldVisibility={false}
            origin={EPersonalizedViewOrigin.FORMS}
            onCreate={onCreateEntityField}
            onUpdate={() => void 0}
            onCancel={() => {
              setEntityFieldDrawerStats({
                open: false,
                index: 0,
              });
            }}
            entityTypeId={entityTypeId}
          />
        )}
      </DndContext>
    </Col>
  );
};
