import { CSSProperties } from 'react';
import { useDroppable } from '@dnd-kit/core';

export const DroppableMenu = ({ id, children }: { id: string; children: JSX.Element }) => {
  const { setNodeRef, isOver } = useDroppable({ id });

  const dropBoxStyles: CSSProperties = {
    width: '165px',
    minHeight: '100px',
    maxHeight: '300px',
    padding: '10px',
    marginTop: '5px',
    backgroundColor: isOver ? 'rgb(245, 243, 243)' : '#ffffff',
    position: 'sticky',
    zIndex: 999,
    top: '20px',
    right: 0,
    float: 'right',
    border: isOver ? '1px dashed #70b6fc' : 'none',
    transition: 'all 0.3s ease',
    boxShadow: isOver ? '0 4px 12px rgba(0, 0, 0, 0.3)' : 'none',
    cursor: 'pointer',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  };

  return (
    <div ref={setNodeRef} style={dropBoxStyles}>
      {children}
    </div>
  );
};
