import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons';

import { Button, Checkbox, Col, Flex, Input, Popover, Row, Table, Typography } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { ColumnsType } from 'antd/es/table';
import Link from 'antd/lib/typography/Link';
import './UpsertQuote.scss';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { EPersonalizedViewOrigin, IPackage, IProduct, ITaxRate, IUpsertLineItem, QuoteTableDataType } from '@aduvi/types';
import { Notification } from '@aduvi/utils/notification';
import { mapLineItemsToPackages, mapLineItemsToProducts, mapPackagesToLineItems, mapProductsToLineItems } from '@aduvi/utils/quotes-helper';

import { createQuote, updateQuote } from 'store/features/quote-slice';
import { getTaxRates } from 'store/features/tax-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { PriceDisplay } from '../../PriceDisplay/PriceDisplay';
import { PackagesList } from '../@components/packages-list/PackagesList';
import { ProductsList } from '../@components/products-list/ProductsList';
import { RecalculateTravelFee } from '../@components/recalculate-travel-fee/RecalculateTravelFee';

import { TaxModal } from './@components/TaxModal';

interface IProps {
  entityId?: string;
  onCancel: () => void;
  onSave: () => void;
  isEditMode?: boolean;
  origin: EPersonalizedViewOrigin;
}

export const UpsertQuote = ({ entityId, onCancel, onSave, isEditMode, origin }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { creating, updating, selectedQuote, quotes } = useAppSelector((state) => state.quotes);
  const {
    rates: { taxRates },
  } = useAppSelector((state) => state.tax);
  const hasPermissionToViewTaxRates = useUserPermissionCheck(PERMISSIONS.TAX.VIEW);

  const isOrder = useMemo(() => origin === EPersonalizedViewOrigin.ORDERS, [origin]);

  const [tableProducts, setTableProducts] = useState<QuoteTableDataType[]>([]);
  const [tablePackages, setTablePackages] = useState<QuoteTableDataType[]>([]);
  const [quoteName, setQuoteName] = useState('');
  const [openTaxModal, setOpenTaxModal] = useState(false);

  const removeProduct = (index: number) => {
    setTableProducts((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const removePackage = (index: number) => {
    setTablePackages((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const onPackageTaxRateSelect = (checked: boolean, id: string, taxRateId: string) => {
    setTablePackages((prev) =>
      prev.map((item) =>
        item.id == id
          ? {
              ...item,
              tax_rate: checked ? [...item.tax_rate, taxRateId] : item.tax_rate.filter((rate) => rate !== taxRateId),
            }
          : item,
      ),
    );
  };

  const onProductTaxRateSelect = (checked: boolean, id: string, taxRateId: string) => {
    setTableProducts((prev) =>
      prev.map((item) =>
        item.id == id
          ? {
              ...item,
              tax_rate: checked ? [...item.tax_rate, taxRateId] : item.tax_rate.filter((rate) => rate !== taxRateId),
            }
          : item,
      ),
    );
  };

  const onProductsChange = (value: number, id: string, fieldKey: keyof QuoteTableDataType) => {
    setTableProducts((prev) =>
      prev.map((item) =>
        item.id == id
          ? {
              ...item,
              [fieldKey]: value,
            }
          : item,
      ),
    );
  };

  const onPackageChange = (value: number, id: string, fieldKey: keyof QuoteTableDataType) => {
    setTablePackages((prev) =>
      prev.map((item) =>
        item.id == id
          ? {
              ...item,
              [fieldKey]: value,
            }
          : item,
      ),
    );
  };

  const addProduct = (product: IProduct) => {
    setTableProducts((prev) => [
      ...prev,
      {
        id: product.id,
        name: product.name,
        quantity: 1,
        price: product.price ?? 0,
        tax_rate: taxRates?.length ? [taxRates[0].id] : [],
      },
    ]);
  };
  const addPackage = (selectedPackage: IPackage, data?: QuoteTableDataType) => {
    setTablePackages((prev) => [
      ...prev,
      {
        id: selectedPackage.id,
        name: `${selectedPackage.service_name} - ${selectedPackage.name}`,
        quantity: 1,
        price: Number(selectedPackage.base_price) || Number(selectedPackage.flat_price) || 0,
        tax_rate: taxRates?.length ? [taxRates[0].id] : [],
        total: data && data.quantity * (data?.price || 0),
      },
    ]);
  };

  const onSaveQuotes = () => {
    if (quoteName.trim() === '' && !isEditMode) {
      Notification.error({
        title: translate('quotes.error'),
        description: translate('quotes.nameValidation'),
      });
      return;
    }
    const lineItems = [...mapProductsToLineItems(tableProducts), ...(!isOrder ? mapPackagesToLineItems(tablePackages) : [])];
    isEditMode ? onUpdateQuote(lineItems) : onCreateQuote(lineItems);
  };

  const onCreateQuote = (lineItems: IUpsertLineItem[]) => {
    if (!selectedBusiness?.id || !entityId) return;
    dispatch(
      createQuote({
        business_id: selectedBusiness.id,
        entity_id: entityId,
        name: quoteName,
        line_items: lineItems,
      }),
    )
      .unwrap()
      .then(() => onSave())
      .catch(() => {});
  };

  const onUpdateQuote = (lineItems: IUpsertLineItem[]) => {
    if (!selectedBusiness?.id || !entityId || !selectedQuote) return;
    dispatch(
      updateQuote({
        business_id: selectedBusiness.id,
        entity_id: entityId,
        id: selectedQuote?.id,
        line_items: lineItems,
      }),
    )
      .unwrap()
      .then(() => onSave())
      .catch(() => {});
  };

  const packageColumns = useColumns(true, removePackage, onPackageTaxRateSelect, taxRates, setOpenTaxModal, onPackageChange);
  const productColumns = useColumns(false, removeProduct, onProductTaxRateSelect, taxRates, setOpenTaxModal, onProductsChange);

  useEffect(() => {
    if (isEditMode && selectedQuote && taxRates) {
      const products = mapLineItemsToProducts(selectedQuote?.line_items, taxRates);
      const packages = mapLineItemsToPackages(selectedQuote?.line_items, taxRates);

      setTableProducts(products);
      setTablePackages(packages);
    }
  }, [isEditMode, selectedQuote, taxRates]);

  useEffect(() => {
    if (!selectedBusiness?.id || !hasPermissionToViewTaxRates) return;

    dispatch(getTaxRates(selectedBusiness?.id));
  }, [selectedBusiness?.id]);

  useEffect(() => {
    if (!isEditMode) {
      setQuoteName(`Quote ${quotes.length + 1}`);
    }
  }, [isEditMode, quotes.length]);

  return (
    <Col className='create-quote-wrapper'>
      <Row justify={isEditMode ? 'end' : 'space-between'}>
        {!isEditMode ? (
          <Col span={12}>
            <Input placeholder={translate('settings.details.name')} value={quoteName} onChange={(e) => setQuoteName(e.target.value)} />
          </Col>
        ) : null}
        <Row justify='end'>
          <Button onClick={onCancel} disabled={creating || updating}>
            {translate('quotes.cancel')}
          </Button>
          <Button className='ml-10' type='primary' loading={creating || updating} disabled={creating || updating} onClick={onSaveQuotes}>
            {translate('quotes.saveChanges')}
          </Button>
        </Row>
      </Row>

      {!isOrder && (
        <>
          <Table
            className='custom-table mt-20'
            rowClassName={() => 'editable-row'}
            bordered={false}
            dataSource={tablePackages}
            columns={packageColumns as ItemType[]}
            pagination={false}
          />
          <PackagesList addPackage={addPackage} existingPackageIds={tablePackages.map((pack) => pack.id)} />
        </>
      )}

      <Table
        className='custom-table'
        rowClassName={() => 'editable-row'}
        bordered={false}
        dataSource={tableProducts}
        columns={productColumns as ItemType[]}
        pagination={false}
      />
      <ProductsList addProduct={addProduct} existingProductIds={tableProducts.map((product) => product.id)} />

      {!isOrder && <RecalculateTravelFee />}

      {openTaxModal && <TaxModal setOpenTaxModal={setOpenTaxModal} />}
    </Col>
  );
};

const useColumns = (
  isPackage: boolean,
  onRemove: (id: number) => void,
  onTaxRateSelect: (checked: boolean, id: string, taxRateId: string) => void,
  taxes?: ITaxRate[],
  setOpenTaxModal?: Dispatch<SetStateAction<boolean>>,
  onInputsChange?: (value: number, id: string, field: keyof QuoteTableDataType) => void,
) => {
  const { t: translate } = useTranslation();

  const taxRates = (record: QuoteTableDataType) => (
    <Flex vertical gap={'10px'}>
      <Link className='cursor-pointer text-center' onClick={() => setOpenTaxModal?.(true)}>
        {translate('quotes.addTaxRate')}
      </Link>

      {taxes?.map((item) => (
        <Checkbox
          key={item.id}
          value={item.id}
          checked={record.tax_rate.includes(item.id)}
          onChange={(e) => onTaxRateSelect(e.target.checked, record?.id?.toString(), e.target.value)}>
          {item.tax_name} ({item.tax_percentage.toString().slice(0, 2)}%)
        </Checkbox>
      ))}
    </Flex>
  );

  return [
    {
      title: isPackage ? translate('quotes.package') : translate('quotes.product'),
      dataIndex: 'name',
      render: (_: unknown, record: QuoteTableDataType, index) => {
        return (
          <Row>
            <Col>
              <Button type='text' style={{ color: '#00000073' }} icon={<DeleteOutlined />} onClick={() => onRemove(index)} />
              <Typography.Text className='fw-bold'>{record.name}</Typography.Text>
            </Col>
          </Row>
        );
      },
    },
    {
      title: translate('quotes.qtyUppercase'),
      dataIndex: 'quantity',
      render: (_: unknown, record: QuoteTableDataType) => {
        return (
          <Input
            value={record.quantity}
            size='small'
            style={{ width: '50px' }}
            onChange={(e) => onInputsChange?.(Number(e.target.value), record?.id?.toString(), 'quantity')}
          />
        );
      },
    },
    {
      title: translate('quotes.priceUppercase'),
      dataIndex: 'price',
      render: (_: unknown, record: QuoteTableDataType) => {
        return (
          <Input
            value={record.price}
            size='small'
            style={{ width: '50px' }}
            onChange={(e) => onInputsChange?.(Number(e.target.value), record?.id?.toString(), 'price')}
          />
        );
      },
    },
    {
      title: translate('quotes.taxRateUppercase'),
      render: (_: unknown, record: QuoteTableDataType) => {
        return (
          <Popover content={taxRates(record)} trigger='click' placement='bottom'>
            <Row justify={'space-around'}>
              <span>
                {taxes
                  ?.filter(({ id }) => record.tax_rate.includes(id))
                  .map((item) => Number(item.tax_percentage).toFixed(0) + '%')
                  ?.join(', ')}
              </span>
              <DownOutlined />
            </Row>
          </Popover>
        );
      },
    },
    {
      title: translate('quotes.totalUppercase'),
      render: (_: unknown, data: QuoteTableDataType) => {
        return <PriceDisplay price={data?.quantity * (data?.price || 0)} />;
      },
    },
  ] as ColumnsType;
};
