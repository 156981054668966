import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import './Reports.scss';

import { Title } from '@aduvi/components/Title';

import Grid from './@components/GridLayout';

export const Reports = () => {
  const { t: translate } = useTranslation();
  return (
    <div className='reports-wrapper'>
      <Row className='w-full mb-30 '>
        <Col span={12} xs={24} md={12}>
          <Row className='mobile-view-title'>
            <Title className='aduvi-title pl-10 pr-10'>{translate('reports.title')}</Title>
          </Row>
        </Col>
      </Row>
      <Grid />
    </div>
  );
};
