import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, FormInstance } from 'antd';

import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { useBusiness } from '@aduvi/hooks';
import { EPersonalizedViewOrigin, IEntityField } from '@aduvi/types';
import { normalToSnakeCase } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

import { SelectBrand } from '../../SelectBrand/SelectBrand';

interface IProps {
  form: FormInstance;
  fields: IEntityField[];
  formOnlyFields: IEntityField[];
  origin: EPersonalizedViewOrigin;
}

export const Details = ({ form, fields, formOnlyFields, origin }: IProps) => {
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();
  const { entities } = useAppSelector((state) => state.entity);

  const entityFormFields = useMemo(() => {
    if (!entities?.selectedEntity?.custom_fields) return [];

    return entities?.selectedEntity?.custom_fields?.map((item) => (item?.form_only ? item.id : null))?.filter((item) => item);
  }, [entities?.selectedEntity]);

  const entityFormManagementFields = useMemo(() => {
    if (!formOnlyFields) return [];

    return formOnlyFields?.filter((field) => entityFormFields?.includes(field?.id));
  }, [entityFormFields, formOnlyFields]);

  const filteredFields = [...fields, ...entityFormManagementFields];

  useEffect(() => {
    if (entities?.selectedEntity?.brand_id) {
      const brand = selectedBusiness?.brandings?.find((branding) => branding?.id === entities?.selectedEntity?.brand_id);
      if (brand) {
        form.setFieldValue('brand_id', brand?.id);
      } else {
        form.setFieldValue('brand_id', selectedBusiness?.brandings?.[0]?.id);
      }
    } else {
      form.setFieldValue('brand_id', selectedBusiness?.brandings?.[0]?.id);
    }
  }, [selectedBusiness?.brandings, entities?.selectedEntity]);

  return (
    <Col span={24} className='mt-15'>
      {[EPersonalizedViewOrigin.BOOKINGS, EPersonalizedViewOrigin.LEADS, EPersonalizedViewOrigin.ORDERS].includes(origin) && (
        <Form.Item
          name='brand_id'
          hidden={selectedBusiness?.brandings?.length === 1}
          label={translate('forms.forms.brand')}
          rules={[{ required: true }]}>
          <SelectBrand disabled={selectedBusiness?.brandings?.length === 1} />
        </Form.Item>
      )}
      <Form.Item name='custom_fields'>
        {filteredFields.map((item, index) =>
          [EPersonalizedViewOrigin.BOOKINGS, EPersonalizedViewOrigin.LEADS]?.includes(origin) &&
          ['event_name', 'event_date', 'status']?.includes(normalToSnakeCase(item?.title?.toLowerCase())) ? (
            <></>
          ) : (
            <CustomField field={item} form={form} key={item.id} index={index} disabledFields={[]} />
          ),
        )}
      </Form.Item>
    </Col>
  );
};
