import { JSX, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Form, Input, Select, Space, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { Paragraph } from '@aduvi/components/Paragraph';
import { DRAWER_BODY_STYLE, PERMISSIONS } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';
import { EPersonalizedView, EPersonalizedViewOrigin, ETableRowOptions, IPersonalizedViewsDrawer } from '@aduvi/types/personalized-views';
import { getFormErrors, toCapitalize } from '@aduvi/utils/helper';

import { getEntities } from 'store/features/entity-slice';
import { createPersonalizedView, editPersonalizedView, getPersonalizedViewById } from 'store/features/personalized-views-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IEntityType } from '../../../../types/entity';
import { getStatusForEntities } from '../../PredefinedViews/helper';

import AddressBook from './ViewOptions/AddressBook';
import { Calendar } from './ViewOptions/Calendar';
import { Pipeline } from './ViewOptions/Pipeline';
import Table from './ViewOptions/Table';
import { Timeline } from './ViewOptions/Timeline';

export const UpsertPersonalizedViewDrawer = ({ open, onClose, isNew, newViewType, origin, entityType, style }: IPersonalizedViewsDrawer) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { selectedViewId, creating, editing, loadingView } = useAppSelector((state) => state.personalizedViews);

  const displaySettings: Record<string, JSX.Element> = useDisplaySettings(form, origin, entityType);
  const [selectedViewDisplay, setSelectedViewDisplay] = useState<EPersonalizedView | undefined>(newViewType);

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onSubmit = () => {
    isNew ? onCreate() : onUpdate();
  };

  const onCreate = () => {
    if (selectedBusiness?.id)
      dispatch(
        createPersonalizedView({
          business_id: selectedBusiness.id,
          body: {
            ...form.getFieldsValue(),
            origin,
            default_sort_key: 'date',
            style: JSON.stringify(style),
          },
        }),
      )
        .unwrap()
        .then(() => {
          onCancel();
        })
        .catch((error) => {
          form.setFields(getFormErrors(error));
        });
  };

  const onUpdate = () => {
    if (selectedBusiness?.id && selectedViewId)
      dispatch(
        editPersonalizedView({
          business_id: selectedBusiness.id,
          personalizedViewId: selectedViewId,
          body: { ...form.getFieldsValue(), origin, column_summary: form.getFieldValue('column_summary') || null, default_sort_key: 'date' },
        }),
      )
        .unwrap()
        .then(({ data }) => {
          if (entityType?.id) {
            dispatch(
              getEntities({
                businessId: selectedBusiness?.id,
                entityTypeId: entityType?.id,
                params: {
                  status: getStatusForEntities(origin),
                  view_display: data.view_display,
                  page: 1,
                  size: 10,
                  paginate: true,
                  view_id: data.id,
                },
              }),
            );
          }
          onCancel();
        })
        .catch((error) => {
          form.setFields(getFormErrors(error));
        });
  };

  useEffect(() => {
    if (selectedViewId && selectedBusiness?.id && !isNew) {
      dispatch(getPersonalizedViewById({ businessId: selectedBusiness.id, personalizedView: selectedViewId }))
        .unwrap()
        .then(({ data }) => {
          form.setFieldsValue(data);
          setSelectedViewDisplay(form.getFieldValue('view_display'));
        })
        .catch(() => {});
    }
    form.resetFields();
  }, [selectedViewId, selectedBusiness?.id, isNew]);

  return (
    <Drawer
      title={isNew ? translate('components.upsertPersonalizedViewDrawer.createView') : form.getFieldValue('name')}
      className='leads-drawer-wrapper'
      width={680}
      onClose={onClose}
      open={open}
      bodyStyle={DRAWER_BODY_STYLE}
      extra={
        <Space>
          <Button disabled={creating || editing} onClick={onCancel}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            loading={creating || editing}
            disabled={creating || editing}
            disabledButton
            requiredPermission={isNew ? PERMISSIONS.PERSONALIZED_VIEWS.CREATE : PERMISSIONS.PERSONALIZED_VIEWS.EDIT}
            onClick={onSubmit}
            type='primary'>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Spin spinning={loadingView} tip={translate('components.upsertPersonalizedViewDrawer.loadingField')}>
        <Form form={form} className='mt-20' layout='vertical' requiredMark={false}>
          <Form.Item
            name='name'
            label={translate('components.upsertPersonalizedViewDrawer.name')}
            rules={[{ required: true }]}
            initialValue={toCapitalize(newViewType)}>
            <Input placeholder={translate('components.upsertPersonalizedViewDrawer.inputName')} />
          </Form.Item>
          <Form.Item name='view_display' label={'View Display'} rules={[{ required: true }]} initialValue={newViewType}>
            <Select
              options={Object.values(EPersonalizedView)?.map((value) => {
                return {
                  label: translate(`components.upsertPersonalizedViewDrawer.options.${value?.toLowerCase()}`),
                  value,
                };
              })}
              onSelect={(value) => setSelectedViewDisplay(value)}
              placeholder={translate('components.upsertPersonalizedViewDrawer.typePlaceholder')}
            />
          </Form.Item>
          <Divider />
          {selectedViewDisplay ? (
            <Paragraph className='color-gray-5 fs-12'>
              {translate(`components.upsertPersonalizedViewDrawer.options.${selectedViewDisplay?.toLowerCase()}`)}{' '}
              {translate('components.upsertPersonalizedViewDrawer.settings')}
            </Paragraph>
          ) : null}
          {displaySettings[selectedViewDisplay as string]}

          {[EPersonalizedView.TABLE, EPersonalizedView.PIPELINE].includes(selectedViewDisplay as EPersonalizedView) ? (
            <Form.Item name='row_height' label={'Row Height'}>
              <Select
                options={Object.values(ETableRowOptions)?.map((value) => {
                  return {
                    label: translate(`components.upsertPersonalizedViewDrawer.sizeOptions.${value?.toLowerCase()}`),
                    value,
                  };
                })}
                placeholder={translate('components.upsertPersonalizedViewDrawer.typePlaceholder')}
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </Spin>
    </Drawer>
  );
};

const useDisplaySettings = (form: FormInstance, origin: EPersonalizedViewOrigin, entityType?: IEntityType) => {
  return {
    TABLE: <Table origin={origin} form={form} entityType={entityType} />,
    PIPELINE: <Pipeline form={form} origin={origin} entityType={entityType} />,
    // KANBAN: <Kanban form={form} origin={origin} entityType={entityType} />,
    TIMELINE: <Timeline form={form} origin={origin} entityType={entityType} />,
    CALENDAR: <Calendar form={form} origin={origin} entityType={entityType} />,
    ADDRESS_BOOK: <AddressBook form={form} origin={origin} entityType={entityType} />,
    // MAP: <Map form={form} origin={origin} entityType={entityType} />,
    FORMS: <></>,
  };
};
