import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Button, Col, Divider, Dropdown, Form, FormInstance, Input, MenuProps, Row, Select, Table, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';

import { CustomFieldsDraggableRow } from '@aduvi/components/PersonalizedViewComponents/CustomFieldsDraggableRow/CustomFieldsDraggableRow';
import { FORM_AFTER_SUBMIT_OPTIONS } from '@aduvi/constants';
import { useBusinessId } from '@aduvi/hooks';
import { EAfterFormSubmitType, ERedirectTo, IAfterFormSubmitTableProps } from '@aduvi/types/form';

import { getClientPortals } from 'store/features/client-portal-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetContractsQuery } from 'store/rtk-slice/contract-slice';

export const AfterFormSubmit = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const { t: translate } = useTranslation();

  const { selectedEntityForm } = useAppSelector((state) => state.forms);
  const { data: contracts } = useGetContractsQuery(businessId, { skip: !businessId });

  const { fields } = useAppSelector((state) => state.fields);

  const [afterFormSubmitData, setAfterFormSubmitData] = useState<IAfterFormSubmitTableProps[]>([]);

  const isLeadForm = useMemo(() => selectedEntityForm?.form_type === 'LEADS_FORM', [selectedEntityForm?.form_type]);

  const selectOptionsByType: Record<EAfterFormSubmitType, DefaultOptionType[] | undefined> = useMemo(
    () => ({
      sign_contract: contracts?.data?.map((contract) => ({ label: contract.title, value: contract.id })),
      payment: [{ label: '', value: '' }],
      set_status_to: fields
        ?.find((field) => field?.core && field?.title === 'Status')
        ?.list_values?.map((value) => ({ label: value.value, value: value.id })),

      redirect_to: [
        { label: 'Thank you Page', value: String(ERedirectTo.THANK_YOU_PAGE) },
        ...(isLeadForm ? [] : [{ label: 'Client Portal', value: String(ERedirectTo.CLIENT_PORTAL) }]),
        { label: 'Appointment Booking', value: String(ERedirectTo.APPOINTMENT_BOOKING) },
        { label: 'External URL', value: String(ERedirectTo.EXTERNAL_URL) },
      ],
    }),
    [contracts?.data, fields],
  );

  const handleDelete = (type: EAfterFormSubmitType) => {
    setAfterFormSubmitData((prevData) => prevData.filter((item) => item.type !== type));
  };

  const handleAdd = (type: EAfterFormSubmitType) => {
    const itemToAdd = FORM_AFTER_SUBMIT_OPTIONS.find((item) => item.type === type);
    if (itemToAdd) {
      setAfterFormSubmitData((prevData) => [
        ...prevData,
        { ...itemToAdd, value: '', redirect_type: itemToAdd.type === EAfterFormSubmitType.REDIRECT ? ERedirectTo.THANK_YOU_PAGE : undefined },
      ]);
    }
  };

  const handleSelectChange = (type: EAfterFormSubmitType, value: string) => {
    setAfterFormSubmitData((prevData) => {
      return prevData.map((item) =>
        item.type === type
          ? {
              ...item,
              value,
              redirect_type: type === EAfterFormSubmitType.REDIRECT ? (value as ERedirectTo) : undefined,
              url:
                type === EAfterFormSubmitType.REDIRECT && value === ERedirectTo.CLIENT_PORTAL
                  ? `${window.location.origin}/portal/auth/login`
                  : undefined,
            }
          : item,
      );
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setAfterFormSubmitData((prevData) => {
        const oldIndex = prevData.findIndex((item) => item.type === active.id);
        const newIndex = prevData.findIndex((item) => item.type === over?.id);
        return arrayMove(prevData, oldIndex, newIndex);
      });
    }
  };

  const dropdownItems: MenuProps['items'] = useMemo(() => {
    const addedItems = afterFormSubmitData.map((item) => item.type);

    return FORM_AFTER_SUBMIT_OPTIONS?.filter(
      (item) =>
        !addedItems.includes(item.type) &&
        (!isLeadForm || (item.type !== EAfterFormSubmitType.SIGN_CONTRACT && item.type !== EAfterFormSubmitType.PAYMENT)),
    ).map((item, index) => ({
      label: translate(item.title),
      key: index.toString(),
      onClick: () => handleAdd(item.type),
    }));
  }, [afterFormSubmitData, isLeadForm]);

  const columns: ColumnsType<IAfterFormSubmitTableProps> = useMemo(
    () => [
      { key: 'sort' },
      {
        render: (_: unknown, record) => {
          return (
            <Row align={'middle'}>
              <Col>
                <Typography.Text>{translate(record?.title)}</Typography.Text>

                {record.type !== EAfterFormSubmitType.PAYMENT ? (
                  <>
                    <Select
                      className='ml-20'
                      style={{ minWidth: '200px' }}
                      options={selectOptionsByType[record.type]}
                      value={record.value}
                      onChange={(value) => {
                        handleSelectChange(record.type, value as string);
                      }}
                    />

                    {record?.redirect_type === ERedirectTo.EXTERNAL_URL && record.type === EAfterFormSubmitType.REDIRECT && (
                      <Form.Item className='mb-0 mt-10'>
                        <Input
                          value={record?.url}
                          placeholder='https://www.example.com'
                          onChange={({ target }) => {
                            setAfterFormSubmitData((prevData) => {
                              return prevData.map((item) =>
                                item.type === record.type
                                  ? {
                                      ...item,
                                      url: target?.value,
                                      redirect_type: ERedirectTo.EXTERNAL_URL,
                                    }
                                  : item,
                              );
                            });
                          }}
                        />
                      </Form.Item>
                    )}
                  </>
                ) : null}
              </Col>
            </Row>
          );
        },
      },
      {
        render: (_: unknown, record) => (
          <Row justify={'end'}>
            <Button type='text' icon={<DeleteOutlined />} onClick={() => handleDelete(record.type)} />
          </Row>
        ),
      },
    ],
    [selectOptionsByType, handleDelete, form.getFieldValue('form_submit_automation')],
  );

  useEffect(() => {
    if (selectedEntityForm?.form_submit_automation) {
      setAfterFormSubmitData(selectedEntityForm?.form_submit_automation);
    }
  }, [selectedEntityForm?.form_submit_automation]);

  useEffect(() => {
    if (businessId) dispatch(getClientPortals(businessId));
  }, [businessId]);

  useEffect(() => {
    form.setFieldValue('form_submit_automation', afterFormSubmitData);
  }, [afterFormSubmitData]);

  return (
    <>
      <Typography.Text type='secondary'>{translate('forms.forms.afterFormSubmit')}</Typography.Text>
      <DndContext modifiers={[restrictToVerticalAxis]} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={afterFormSubmitData.map((item) => item.type)} strategy={verticalListSortingStrategy}>
          <Table
            rowKey='type'
            loading={false}
            columns={columns}
            style={{ marginTop: '15px' }}
            rowClassName='cursor-pointer'
            dataSource={afterFormSubmitData}
            pagination={false}
            showHeader={false}
            components={{ body: { row: CustomFieldsDraggableRow } }}
          />

          {dropdownItems?.length ? (
            <Row justify={'end'} className='mt-15'>
              <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
                <Button type='primary' ghost>
                  {translate('forms.forms.addAction')}
                </Button>
              </Dropdown>
            </Row>
          ) : (
            <></>
          )}
        </SortableContext>
      </DndContext>
      <Divider />

      <Form.Item name='form_submit_automation' hidden />
    </>
  );
};
