import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import { DatePicker } from '@aduvi/components/DatePicker/DatePicker';
import { InputPrice } from '@aduvi/components/InputPrice/InputPrice';
import { SelectTax } from '@aduvi/components/SelectTax/SelectTax';

export const Pricing = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  const [priceUnit, setPriceUnit] = useState<string>('FLAT_RATE.FLAT_RATE');

  const [pricingSettings, setPricingSetting] = useState({
    upsell: false,
    is_on_sale: false,
  });

  useEffect(() => {
    setPricingSetting({
      upsell: !!form.getFieldValue('upsell'),
      is_on_sale: !!form.getFieldValue('is_on_sale'),
    });
  }, []);

  return (
    <Col span={24} className='mt-15 w-full'>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='price' label={translate('assets.productsPricing.price')}>
            <InputPrice className='w-full' placeholder={translate('assets.productsPricing.pricePlaceholder')} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name='price_unit' label=' ' initialValue='FLAT_RATE.FLAT_RATE'>
            <Select
              className='w-full'
              onChange={(value: string) => {
                setPriceUnit(value);
                form.setFieldsValue({ upsell_price_unit: value });
              }}
              options={[
                {
                  label: translate('assets.productsPricing.flatPriceType'),
                  options: [{ label: translate('assets.productsPricing.flatPrice'), value: 'FLAT_RATE.FLAT_RATE' }],
                },
                {
                  label: translate('assets.productsPricing.jobDurationType'),
                  options: [
                    { label: translate('assets.productsPricing.perMinute'), value: 'BY_JOB_DURATION.PER_MINUTE' },
                    { label: translate('assets.productsPricing.perHour'), value: 'BY_JOB_DURATION.PER_HOUR' },
                    { label: translate('assets.productsPricing.perDay'), value: 'BY_JOB_DURATION.PER_DAY' },
                    { label: translate('assets.productsPricing.perWeek'), value: 'BY_JOB_DURATION.PER_WEEK' },
                  ],
                },
                {
                  label: translate('assets.productsPricing.subscriptionType'),
                  options: [
                    { label: translate('assets.productsPricing.perMonth'), value: 'SUBSCRIPTION.PER_MONTH', disabled: true },
                    { label: translate('assets.productsPricing.perYear'), value: 'SUBSCRIPTION.PER_YEAR', disabled: true },
                  ],
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name='tax_rate_ids' label={translate('settings.tax.taxRates')}>
        <SelectTax mode='multiple' className='w-full' />
      </Form.Item>
      <Form.Item className='mb-5' name='upsell' valuePropName='checked'>
        <Checkbox className='mb-15' onChange={(e) => setPricingSetting((prev) => ({ ...prev, [e.target.id!]: e.target.checked }))}>
          {translate('assets.productsPricing.upsellProductInClientPortal')}
        </Checkbox>
      </Form.Item>
      {pricingSettings.upsell && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name='upsell_price' label={translate('assets.productsPricing.upsellPrice')}>
                <InputPrice className='w-full' placeholder={translate('assets.productsPricing.upsellpricePlaceholder')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name='upsell_price_unit' label=' ' initialValue={priceUnit}>
                <Select
                  disabled={true}
                  className='w-full'
                  options={[
                    {
                      label: translate('assets.productsPricing.flatPriceType'),
                      options: [{ label: translate('assets.productsPricing.flatPrice'), value: 'FLAT_RATE.FLAT_RATE' }],
                    },
                    {
                      label: translate('assets.productsPricing.jobDurationType'),
                      options: [
                        { label: translate('assets.productsPricing.perMinute'), value: 'BY_JOB_DURATION.PER_MINUTE' },
                        { label: translate('assets.productsPricing.perHour'), value: 'BY_JOB_DURATION.PER_HOUR' },
                        { label: translate('assets.productsPricing.perDay'), value: 'BY_JOB_DURATION.PER_DAY' },
                        { label: translate('assets.productsPricing.perWeek'), value: 'BY_JOB_DURATION.PER_WEEK' },
                      ],
                    },
                    {
                      label: translate('assets.productsPricing.subscriptionType'),
                      options: [
                        { label: translate('assets.productsPricing.perMonth'), value: 'SUBSCRIPTION.PER_MONTH' },
                        { label: translate('assets.productsPricing.perYear'), value: 'SUBSCRIPTION.PER_YEAR' },
                      ],
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Form.Item className='mb-5' name='is_on_sale' valuePropName='checked' initialValue={form.getFieldValue('is_on_sale')}>
          <Checkbox
            className='mb-15'
            onChange={(e) => {
              setPricingSetting((prev) => ({ ...prev, [e.target.id!]: e.target.checked }));
            }}>
            {translate('assets.productsQuantityStock.productIsOnSale')}
          </Checkbox>
        </Form.Item>
      </Row>

      {pricingSettings.is_on_sale && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name='sale_price' label={translate('assets.productsPricing.salePrice')}>
                <InputPrice className='w-full' placeholder={translate('assets.productsPricing.salePricePlaceholder')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name='sale_end_date' label={translate('assets.productsPricing.saleEndDate')}>
                <DatePicker className='w-full' />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Col>
  );
};
