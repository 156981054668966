import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CopyOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  ExportOutlined,
  ImportOutlined,
  LayoutOutlined,
  SettingOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';

import { Dropdown, MenuProps } from 'antd';

import { Button } from '@aduvi/components/Button/Button';
import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useBusinessId, useUserPermissionCheck } from '@aduvi/hooks';
import { EPersonalizedView, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';
import { downloadCSVFile } from '@aduvi/utils/helper';

import { exportEntities } from 'store/features/entity-slice';
import { deletePersonalizedView, duplicatePersonalizedView } from 'store/features/personalized-views-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  personalizedViewOrigin: EPersonalizedViewOrigin;
  entityType?: IEntityType;
  onConfigureView: () => void;
  onEditRowLayout: () => void;
}

export const EntityHeaderActionsDropdown = ({ entityType, onConfigureView, onEditRowLayout }: IProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const selectedBusiness = useBusiness();

  const { selectedViewId, selectedView } = useAppSelector((state) => state.personalizedViews);
  const hasPermissionToDeletePersonalizedView = useUserPermissionCheck(PERMISSIONS.PERSONALIZED_VIEWS.DELETE);

  const onDeletePersonalizedView = () => {
    if (!businessId || !selectedViewId || !selectedView) return;
    dispatch(deletePersonalizedView({ businessId, personalizedViewId: selectedViewId, name: selectedView?.name }));
  };

  const onDuplicatePersonalizedView = () => {
    if (!businessId || !selectedViewId) return;
    dispatch(duplicatePersonalizedView({ businessId, personalizedViewId: selectedViewId }));
  };

  const onExportBusinessUsers = () => {
    if (!selectedBusiness?.id || !entityType?.id) return;

    dispatch(exportEntities({ businessId: selectedBusiness?.id, entityTypeId: entityType?.id }))
      .unwrap()
      .then((data) => {
        const fileName = `${selectedBusiness?.name}-entities-${format(new Date(), 'dd-MM-yyyy')}.csv`;
        downloadCSVFile(data, fileName);
      })
      .catch(() => {});
  };

  const dropDownItems: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: '0',
        type: 'group',
        label: translate('leads.leadsHeaderAction.pipeline'),
        children: [
          ...(selectedView?.view_display !== EPersonalizedView.CALENDAR && selectedView?.view_display !== EPersonalizedView.FORMS
            ? [
                {
                  key: '0-0',
                  onClick: onEditRowLayout,
                  label: (
                    <div>
                      <LayoutOutlined /> {translate('leads.leadsHeaderAction.editRowLayout')}
                    </div>
                  ),
                },
              ]
            : []),
          {
            key: '0-1',
            onClick: onConfigureView,
            label: (
              <div>
                <SettingOutlined /> {translate('leads.leadsHeaderAction.configureView')}
              </div>
            ),
          },
          ...(selectedView?.view_display !== EPersonalizedView.FORMS
            ? [
                {
                  key: '0-2',
                  onClick: onDuplicatePersonalizedView,
                  label: (
                    <div>
                      <CopyOutlined /> {translate('leads.duplicate')}
                    </div>
                  ),
                },
              ]
            : []),
          {
            key: '0-3',
            onClick: onDeletePersonalizedView,
            label: (
              <div>
                <DeleteOutlined /> {translate('leads.delete')}
              </div>
            ),
            disabled: !hasPermissionToDeletePersonalizedView,
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        key: '1',
        type: 'group',
        label: 'Leads',
        children: [
          {
            key: '1-0',
            // onClick: () => setShowImportUsers(true),
            label: (
              <div>
                <ImportOutlined /> {translate(`entity.leadsHeaderActions.import.${selectedView?.origin}`)}
              </div>
            ),
          },
          {
            key: '1-1',
            onClick: onExportBusinessUsers,
            label: (
              <div>
                <ExportOutlined /> {translate(`entity.leadsHeaderActions.export.${selectedView?.origin}`)}
              </div>
            ),
          },
          {
            label: (
              <div>
                <ThunderboltOutlined /> {translate('leads.leadsHeaderAction.leadAutomations')}
              </div>
            ),
            key: '1-2',
          },
        ],
      },
    ];
  }, []);
  return (
    <Dropdown menu={{ items: dropDownItems }} trigger={['click']} overlayClassName='user-management-dropdown'>
      <Button icon={<EllipsisOutlined style={{ color: 'gray' }} />} type='link' onClick={(e) => e.preventDefault()} />
    </Dropdown>
  );
};
