import { IManualPaymentOption } from '@aduvi/types/manual-payment';

import { EEntityType, IDestinationAddressField } from './entity';
import { IProduct } from './products';
import { IPackage } from './services';
import { ITaxRate } from './tax';
export enum EQuoteEntityType {
  PRODUCT = 'PRODUCT',
  PACKAGE = 'PACKAGE',
}

export interface IQuoteState {
  quotes: IQuote[];
  selectedQuote?: IQuote;
  selectedQuotePayments: {
    payments?: IPayment[];
    loading: boolean;
    deleting: boolean;
  };
  loadingQuote: boolean;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  recalculating: boolean;
  checkout: {
    loading: boolean;
    quote?: IQuote;
    amount: number;
  };
}

interface IQuoteTaxRate extends ITaxRate {
  tax: {
    line_item_id: string;
    tax_id: string;
    tax_total: string;
    quote_total_tax_included: string;
    created_at: string;
    updated_at: string;
  };
}

export interface ICreateQuote {
  business_id: string;
  entity_id: string;
  name?: string;
  line_items: {
    entity_id: string;
    entity_type: EQuoteEntityType;
    quantity: number;
    price?: number;
    name: string;
    tax_rates: string[];
  }[];
}

export interface IUpdateQuote extends ICreateQuote {
  id: string;
}
export interface IDeleteQuotePayload {
  business_id: string;
  quote_id: string;
  quote_name?: string;
}

export interface ICloneQuotePayload extends IDeleteQuotePayload {}

export interface IRenameQuotePayload {
  business_id: string;
  quote_id: string;
  name: string;
}

export interface IRecalculateTravelFeePayload {
  business_id: string;
  quote_id?: string;
  entity_id?: string;
  business_location_id: string | null;
  field_id?: string | null;
  destination_address?: IDestinationAddressField;
}

export interface IUpsertQuoteReponse {
  checks: {
    success: boolean;
    reasons: [];
  };
  quote: IQuote;
}

export interface IQuote {
  business_id: string;
  entity_type: EEntityType;
  travel_fee: number;
  entity_id?: string;
  job_id: string;
  name?: string;
  id: string;
  updated_at: string;
  created_at: string;
  sub_total: string;
  total: string;
  total_paid: string;
  line_items: ILineItem[];
  tax_rates: IQuoteTaxRate[];
}

export interface ILineItem {
  entity_id: string;
  entity_type: EQuoteEntityType;
  quantity: string;
  price: string;
  sub_total: string;
  product_id: string | null;
  package_id: string | null;
  quote_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  product: IProduct | null;
  package: IPackage | null;
  tax_rates: IQuoteTaxRate[];
}

export interface IUpsertLineItem {
  entity_id: string;
  entity_type: EQuoteEntityType;
  name: string;
  quantity: number;
  tax_rates: string[];
  price: number;
}
export interface QuoteTableDataType {
  id: string;
  name: string;
  quantity: number;
  price: number;
  tax_rate: string[];
}

export interface IGetPaymentsPayload {
  businessId: string;
  quoteId: string;
}
export enum EQuotePaymentMethod {
  MANUAL_PAYMENT = 'MANUAL_PAYMENT',
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
}

export interface IPayment {
  id: string;
  status: string;
  amount: string;
  payment_id: string;
  user_id: string;
  business_id: string;
  quote_id: string;
  created_at: string;
  updated_at: string;
  payment_method: EQuotePaymentMethod;
  manual_payment_option_id: string;
  payment_date: string;
  paid_by: string;
  manual_payment_option: IManualPaymentOption;
}

export interface IPaymentState {
  loading: boolean;
  deleting: boolean;
  payments?: IPayment[];
  selectedPayment?: IPayment;
}

export interface IDeletePaymentPayload {
  businessId: string;
  quoteId: string;
  transactionId: string;
}
