import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Row, Typography } from 'antd';

import { SelectBrand } from '@aduvi/components/Entity/components/SelectBrand/SelectBrand';
import { EFormPurpose } from '@aduvi/types/form';

import { useAppSelector } from 'store/hooks';

export const FormSettings = () => {
  const { t: translate } = useTranslation();

  const { selectedEntityForm } = useAppSelector((state) => state.forms);

  const hideFormSettings = useMemo(
    () =>
      !(selectedEntityForm?.purpose === EFormPurpose.CREATE && selectedEntityForm?.form_type) && selectedEntityForm?.purpose !== EFormPurpose.CREATE,
    [selectedEntityForm],
  );

  if (hideFormSettings) return <></>;

  return (
    <>
      <Row className='mb-15'>
        <Typography.Text type='secondary'>{translate('forms.forms.formSettings')}</Typography.Text>
      </Row>
      {/* Hide Save Incomplete option temporarily */}
      {/* {selectedEntityForm?.purpose === EFormPurpose.CREATE && selectedEntityForm?.form_type === EFormType.BOOKINGS_FORM ? (
        <Row justify={'space-between'} className='mt-15'>
          <Typography.Text className='mt-5'>{translate('forms.forms.saveIncomplete')}</Typography.Text>
          <Form.Item name='form_data_lead' valuePropName='checked'>
            <Switch onChange={(checked) => setLeadStatusVisible(checked)} />
          </Form.Item>
        </Row>
      ) : null}
      {isLeadStatusVisible && (
        <Row justify='end' className='mt-0'>
          <Col>
            <Form.Item name='lead_status' label={translate('forms.forms.setLeadStatusTo')}>
              <Select options={LEAD_STATUS_OPTIONS} style={{ width: '200px' }} />
            </Form.Item>
          </Col>
        </Row>
      )} */}
      {selectedEntityForm?.purpose === EFormPurpose.CREATE ? (
        <Form.Item name='brand_id' label={translate('forms.forms.brand')} rules={[{ required: true }]}>
          <SelectBrand className='w-full' />
        </Form.Item>
      ) : null}
    </>
  );
};
