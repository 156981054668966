import { useTranslation } from 'react-i18next';

import { Checkbox, Form, FormInstance, InputNumber } from 'antd';

import { DateSettings } from './date-settings/DateSettings';
import { FileSettings } from './default-forms/FileSettings';
import { ImageSettings } from './default-forms/ImageSettings';
import { OptionList } from './option-list/OptionList';

const allowMultiple = (placeholder: string) => (
  <Form.Item name={'multiple'} valuePropName='checked' initialValue={false}>
    <Checkbox>{placeholder}</Checkbox>
  </Form.Item>
);

export const useDefaultFields = (form: FormInstance) => {
  const { t: translate } = useTranslation();

  return {
    field_data_emails: allowMultiple(translate('components.customFieldsDrawer.allowMultiple')),
    field_data_phones: allowMultiple(translate('components.customFieldsDrawer.allowMultiple')),
    field_data_urls: allowMultiple(translate('components.customFieldsDrawer.allowMultiple')),
    field_data_numbers: allowMultiple(translate('components.customFieldsDrawer.allowMultiple')),
    field_data_texts: allowMultiple(translate('components.customFieldsDrawer.allowMultiple')),
    field_data_long_texts: allowMultiple(translate('components.customFieldsDrawer.allowMultiple')),
    field_data_files: <FileSettings />,
    field_data_images: <ImageSettings form={form} />,
    field_data_sequential_ids: (
      <Form.Item name={['settings', 'next_number']} label={translate('components.customFieldsDrawer.nextIdNumber')}>
        <InputNumber className='w-full' placeholder={translate('components.customFieldsDrawer.nextEventIdNumber')} />
      </Form.Item>
    ),
    field_data_date_times: <DateSettings form={form} />,
    field_data_star_ratings: <></>,
    field_data_songs: allowMultiple(translate('components.customFieldsDrawer.allowMultiple')),
    field_data_signatures: <></>,
    field_data_lists: <OptionList form={form} />,
    field_data_addresses: <></>,
    field_data_references: <>{allowMultiple(translate('components.customFieldsDrawer.allowMultiple'))}</>,
  } as Record<string, JSX.Element>;
};
