import dayjs from 'dayjs';

import { IContractStyle } from './contract';
import { ICustomFieldViewStyle } from './custom-fields';
import { ITicket } from './event';
import { EFieldDataValueType, EFieldsVisibility, EFileExtensions, EReferenceEntityType, IEntityField } from './fields';
import { IEntityForm } from './form';
import { EPersonalizedViewOrigin } from './personalized-views';
import { EAvailabilityType } from './services';

export enum EEntityType {
  JOB = 'JOB',
  EVENT = 'EVENT',
  VENUE = 'VENUE',
  EXHIBITOR = 'EXHIBITOR',
  CONTACT = 'CONTACT',
  ORDER = 'ORDER',
  TEAM_USER = 'TEAM_USER',
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE',
  TEAM = 'TEAM',
  TASK = 'TASK',
  PROJECT = 'PROJECT',
}

export enum EntityDrawerTabs {
  DETAILS = 'details',
  QUOTES = 'quotes',
  CONTACTS = 'contacts',
  FILES = 'files',
  ACTIVITY = 'activity',
  TASKS = 'tasks',
  SHIFTS = 'shifts',
  FORMS = 'forms',
}

export enum ITarget {
  CURRENT_TAB = 'CURRENT_TAB',
  NEW_TAB = 'NEW_TAB',
}

export interface IEntityTypeInitialState {
  entityTypes: {
    data: Record<EEntityType, IEntityType | undefined>;
    selectedEntityType?: IEntityType;
    entityTypes: IEntityType[];
    action_buttons: IActionButton[];
    loadingEntityType: boolean;
    loading: boolean;
    creating: boolean;
    updating: boolean;
  };
  entities: {
    data: IEntityWithFields[];
    forms: {
      data: IEntityForm[];
      selectedFormData?: IEntityWithFields;
      loading: boolean;
    };
    selectedEntity?: IEntityWithFields;
    selectedTicket?: ITicket;
    loadingTicket: boolean;
    loadingEntity: boolean;
    loading: boolean;
    creating: boolean;
    updating: boolean;
    currentPage: number;
    pageSize: number;
    total: number;
    to: number;
    from: number;
    last_page: number;
    total_list_values_count: { [key: string]: number };
    files: IEntityFiles;
  };
  sendingEmail: boolean;
}
export interface IEntityType {
  app_id: string;
  base_entity_type_id: string;
  business_id: string;
  can_be_referenced: boolean;
  core: boolean;
  created_at: string;
  deleted_at: string;
  description: string;
  has_forms: boolean;
  has_own_view: boolean;
  has_quotes: boolean;
  id: string;
  name: EEntityType;
  splittable: boolean;
  updated_at: string;
}

export interface IActionButton {
  id: string;
  title: string;
  description: string;
  url: string;
  target: ITarget;
  business_id: string;
  business_entity_type_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface IEditActionButtonPayload {
  title: string;
  url: string;
  target: string;
  description: string;
  business_id: string;
  entity_type_id: string;
  action_button_id: string;
}

export interface IEntityTypeSettings {
  id: string;
  entity_type_id: string;
  business_id: string;
  minimum_time_between_jobs?: string;
  completed_task_status?: string;
  auto_archive_task?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface IDateTime {
  value: string;
  format: string;
}

export interface IUpsertEntity {
  id?: string;
  business_id: string;
  entity_type_id: string;
  quote_id?: string;
  brand_id?: string;
  custom_fields: {
    field_data_id?: string | null;
    field_id: string;
    value?: string | number;
    format?: string;
    address?: IAddressField;
    date_time?: IDateTime;
    time?: IDateTime;
    list_values?: string[];
    entity_references?: string[];
  }[];
  skip_inserting_on_state?: boolean;
  rep_link_id?: string;
}

export interface IDateTimeField {
  value?: dayjs.Dayjs;
  end_date_time?: dayjs.Dayjs;
}

export interface IAddressField {
  city: string;
  country: string;
  province: string;
  street_one: string;
  street_two: string;
  zip_code: string;
}

export interface IDestinationAddressField {
  address: string;
  post_code?: string;
  state?: string;
  street_one?: string;
  street_two?: string;
  zip_code?: string;
  city?: string;
}

export interface IFieldData extends IAddressField {
  created_at: string;
  deleted_at: string;
  delta: number;
  entity_id?: string;
  field_id: string;
  id: string;
  ip?: string;
  title?: string;
  content?: string;
  summary?: string;
  contract_style: IContractStyle[];
  signature?: string;
  signer_id?: string;
  signer_type: string;
  updated_at: string;
  value: string | number;
  end_date_time?: string;
  format?: string;
  color?: string;
  value_type: string;
  is_archived?: boolean;
  is_default?: boolean;
  weight: number;
  availability_type?: EAvailabilityType;
  description?: string;
  image?: string;
  profile_picture?: string;
  first_name: string;
  last_name: string;
  min_user_required: number;
  name: string;
  order_number: number;
  business_team_id: string;
  custom_fields: ICustomField[];
  entity_type_id: string;
  start_shift: string;
  end_shift: string;
  user_id: string;
  status: string;
  notes: string;
  wage_amount: string;
  wage_type: string;
  travel_amount: string;
  travel_type: string;
  assigned_id: string;
  url: string;
  artist: string;
  track: string;
  extension: EFileExtensions;
  size: string;
  additional_data?: {
    created_at: string;
    custom_value: string;
    entity_id: string;
    field_id: string;
    list_value_id: string;
    updated_at: string;
  };
}

export interface ICustomField {
  business_id: string;
  core: boolean;
  created_at: string;
  default_value: string;
  entity_type_id: string;
  form_only: boolean;
  id: string;
  multiple: boolean;
  place_holder: string;
  required: boolean;
  settings: {
    allowed_extensions: EFileExtensions[];
    maximum_upload_size: string;
    format: string;
    controller_date_file_id?: string;
  };
  title: string;
  updated_at: string;
  value_type: EFieldDataValueType;
  reference_entity_type_id: string;
  reference_entity_type: EReferenceEntityType;
  weight: number;
  visibility: EFieldsVisibility;
  field_data: IFieldData[];
}

export interface IEntityWithFields {
  brand_id: string;
  business_id: string;
  created_at: string;
  deleted_at: string;
  entity_type_id: string;
  is_confirmed?: boolean;
  entity_type: {
    id: string;
    name: EEntityType;
  };
  id: string;
  updated_at: string;
  custom_fields: ICustomField[];
  tickets: ITicket[];
  files?: IEntityFiles;
}

export interface IInvoice {
  id: string;
  entity_id: string;
  quote_id: string;
  invoice_status: string;
  business_id: string;
  created_at: string;
  updated_at: string;
  invoice_type: string;
  invoice_number: string;
  amount: number;
}

export interface IContractTemplate {
  id: string;
  business_id: string;
  title: string;
  content: string;
  summary: string;
  contract_style: Array<{
    primary_signature: string;
  }>;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

export interface IContract {
  id: string;
  entity_id: string;
  contract_template_id: string;
  entity_type_id: string;
  reference_entity_type_id: string;
  field_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  latest_status: string;
  contract_template: IContractTemplate;
}

export interface IEntityFiles {
  invoices: IInvoice[];
  contracts: IContract[];
}

export interface IEntities {
  entity_type: IEntityType;
  entities: IEntityWithFields[];
}

export interface IBulkSendEmail {
  entity_ids: string[];
  subject: string;
  content: string;
}

export interface IUpdateEntityTypeSettings {
  entity_type_id: string;
  business_id: string;
  minimum_time_between_jobs?: string;
  completed_task_status?: 'IN_PROGRESS' | 'DONE';
  auto_archive_task?: boolean;
}

export interface IUseEntity {
  entityType?: IEntityType;
  isConfirmed?: boolean;
  origin: EPersonalizedViewOrigin;
}

export interface IUseTableColumns {
  columns: ICustomFieldViewStyle[];
  entityType?: IEntityType;
  isDragging?: boolean;
  editRowLayoutDrawer?: boolean;
  origin: EPersonalizedViewOrigin;
  fields: IEntityField[];
  droppedActions: IActionButton[];
  setColumns?: (columns: ICustomFieldViewStyle[]) => void;
  setIsDragging?: (isDragging: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
  setDroppedActions: (action: IActionButton[]) => void;
  onTableRowAction: (data: IEntityWithFields) => void;
}

export interface IGetPaginatedEntities extends IEntities {
  current_page: number;
  from: number;
  last_page: number;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
  total_list_values_count: { [key: string]: number };
}
