import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ICloneQuotePayload,
  ICreateQuote,
  IDeletePaymentPayload,
  IDeleteQuotePayload,
  IPayment,
  IQuote,
  IQuoteState,
  IRecalculateTravelFeePayload,
  IRenameQuotePayload,
  IUpdateQuote,
} from '@aduvi/types';

import * as Quote from 'store/services/quote.service';

const initialState: IQuoteState = {
  quotes: [],
  selectedQuote: undefined,
  selectedQuotePayments: {
    payments: undefined,
    loading: false,
    deleting: false,
  },
  loading: false,
  creating: false,
  updating: false,
  recalculating: false,
  loadingQuote: false,
  checkout: {
    loading: false,
    quote: undefined,
    amount: 0,
  },
};

export const getQuotes = createAsyncThunk('quote/get-quotes', async (params: { businessId: string; entityId: string }, { rejectWithValue }) => {
  try {
    return await Quote.getQuotes(params.businessId, params.entityId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createQuote = createAsyncThunk('quote/create-quote', async (params: ICreateQuote, { rejectWithValue }) => {
  try {
    return await Quote.createQuote(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getQuote = createAsyncThunk(
  'quote/get-quote',
  async (params: { businessId: string; entityId: string; quoteId: string; entityType: string }, { rejectWithValue }) => {
    try {
      return await Quote.getQuote(params.businessId, params.entityId, params.quoteId, params.entityType);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateQuote = createAsyncThunk('quote/update-quote', async (params: IUpdateQuote, { rejectWithValue }) => {
  try {
    return await Quote.updateQuote(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteQuote = createAsyncThunk('quote/delete-quote', async (params: IDeleteQuotePayload, { rejectWithValue }) => {
  try {
    return await Quote.deleteQuote(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const cloneQuote = createAsyncThunk('quote/clone-quote', async (params: ICloneQuotePayload, { rejectWithValue }) => {
  try {
    return await Quote.cloneQuote(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const renameQuote = createAsyncThunk('quote/rename-quote', async (params: IRenameQuotePayload, { rejectWithValue }) => {
  try {
    return await Quote.renameQuote(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const recalculateTravelFee = createAsyncThunk(
  'quote/recalculate-travel-fee',
  async (params: IRecalculateTravelFeePayload, { rejectWithValue }) => {
    try {
      return await Quote.recalculateTravelFee(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const checkoutQuote = createAsyncThunk(
  'quote/checkout-payment-quote',
  async (params: { businessId?: string; quoteId?: string; entityId?: string }, { rejectWithValue }) => {
    try {
      return await Quote.checkoutQuote(params.businessId, params.quoteId, params.entityId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPayments = createAsyncThunk('payment/get-payments', async (params: { businessId: string; quoteId: string }, { rejectWithValue }) => {
  try {
    return await Quote.getPayments({ businessId: params.businessId, quoteId: params.quoteId });
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deletePayment = createAsyncThunk('payment/delete-payment', async (params: IDeletePaymentPayload, { rejectWithValue }) => {
  try {
    return await Quote.deletePayment(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setSelectedQuote: (state, action: PayloadAction<IQuote | undefined>) => {
      state.selectedQuote = action.payload;
    },
    setCheckoutAmount: (state, action: PayloadAction<number>) => {
      state.checkout.amount = action.payload;
    },
    addPayment: (state, { payload }: PayloadAction<IPayment>) => {
      state.selectedQuotePayments.payments = [...(state.selectedQuotePayments.payments || []), payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuotes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.quotes = payload.data;
        state.selectedQuote = payload.data[0];
      })
      .addCase(getQuotes.rejected, (state) => {
        state.loading = false;
        state.quotes = [];
      })
      .addCase(createQuote.pending, (state) => {
        state.creating = true;
      })
      .addCase(createQuote.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.quotes = [...state.quotes, payload.data?.quote];
        state.selectedQuote = payload.data?.quote;
      })
      .addCase(createQuote.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getQuote.pending, (state) => {
        state.loadingQuote = true;
      })
      .addCase(getQuote.fulfilled, (state, { payload }) => {
        state.loadingQuote = false;
        state.selectedQuote = payload.data;
      })
      .addCase(getQuote.rejected, (state) => {
        state.loadingQuote = false;
      })
      .addCase(updateQuote.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateQuote.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.quotes) {
          state.quotes = state.quotes?.map((item) => (item.id === payload.data.quote.id ? payload.data.quote : item));
        }
        state.selectedQuote = payload.data.quote;
      })
      .addCase(updateQuote.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteQuote.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.quotes) {
          state.quotes = state.quotes.filter((quote) => quote.id !== meta.arg.quote_id);
          state.selectedQuote = state.quotes?.[state?.quotes?.length - 1] || undefined;
        }
      })
      .addCase(deleteQuote.rejected, (state) => {
        state.loading = false;
      })
      .addCase(cloneQuote.pending, (state) => {
        state.creating = true;
      })
      .addCase(cloneQuote.fulfilled, (state, { payload, meta }) => {
        state.creating = false;
        if (state.quotes) {
          const clonedQuote = state.quotes.find((quote) => quote.id === meta.arg.quote_id);

          if (!clonedQuote) return;
          state.quotes = [...state.quotes, { ...clonedQuote, ...payload.data }];
        }
      })
      .addCase(cloneQuote.rejected, (state) => {
        state.creating = false;
      })
      .addCase(renameQuote.pending, (state) => {
        state.updating = true;
      })
      .addCase(renameQuote.fulfilled, (state, { payload, meta }) => {
        state.updating = false;
        if (state.quotes) {
          state.quotes = state.quotes.map((quote) => (quote.id === meta.arg.quote_id ? payload.data : quote));
        }
      })
      .addCase(renameQuote.rejected, (state) => {
        state.updating = false;
      })
      .addCase(checkoutQuote.pending, (state) => {
        state.checkout.loading = true;
      })
      .addCase(checkoutQuote.fulfilled, (state, { payload }) => {
        state.checkout.loading = false;
        state.checkout.quote = payload.data;
      })
      .addCase(checkoutQuote.rejected, (state) => {
        state.checkout.loading = false;
      })
      .addCase(recalculateTravelFee.pending, (state) => {
        state.recalculating = true;
      })
      .addCase(recalculateTravelFee.fulfilled, (state, { payload }) => {
        if (state.selectedQuote) state.selectedQuote.travel_fee = payload.data;
        state.recalculating = false;
      })
      .addCase(recalculateTravelFee.rejected, (state) => {
        state.recalculating = false;
      })
      .addCase(getPayments.pending, (state) => {
        state.selectedQuotePayments.loading = true;
      })
      .addCase(getPayments.fulfilled, (state, { payload }) => {
        state.selectedQuotePayments.payments = payload.data;
        state.selectedQuotePayments.loading = false;
      })
      .addCase(getPayments.rejected, (state) => {
        state.selectedQuotePayments.loading = false;
      })
      .addCase(deletePayment.pending, (state) => {
        state.selectedQuotePayments.deleting = true;
      })
      .addCase(deletePayment.fulfilled, (state, { meta }) => {
        state.selectedQuotePayments.deleting = false;
        state.selectedQuotePayments.payments = state.selectedQuotePayments.payments?.filter((payment) => payment.id !== meta.arg.transactionId);
      })
      .addCase(deletePayment.rejected, (state) => {
        state.selectedQuotePayments.deleting = false;
      });
  },
});

export const quoteReducer = quoteSlice.reducer;
export const { setSelectedQuote, setCheckoutAmount, addPayment } = quoteSlice.actions;
