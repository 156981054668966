import { useEffect, useMemo, useState } from 'react';
import { Chart as ChartComponent } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Row, Select, Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, EGranularity, IEntityField, ILeadsByFieldData } from '@aduvi/types';
import { toHumanReadable } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';
import { getEntityFields } from 'store/services/fields.service';
import { getLeadsByFieldReport } from 'store/services/report.service';

import 'chart.js/auto';

import { ILayout } from '../GridLayout';

dayjs.extend(customParseFormat);
interface ILeadsByFieldChartProps {
  granularity: EGranularity;
  dateRange: [Dayjs | null, Dayjs | null] | null;
  fieldId: string;
  dateField: string;
  allowEntityChange?: boolean;
  chartId: string;
  onFieldChange?: (fieldId: string) => void;
  onDateFieldChange?: (dateField: string) => void;
}
function LeadsByFieldChart({
  granularity,
  dateRange,
  fieldId,
  dateField,
  allowEntityChange = false,
  chartId,
  onFieldChange,
  onDateFieldChange,
}: ILeadsByFieldChartProps) {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ILeadsByFieldData[]>([]);
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedDateField, setSelectedDateField] = useState<string>(dateField);
  const { entityTypes } = useAppSelector((state) => state.entity);
  const [fields, setFields] = useState<IEntityField[]>([]);
  const fieldOptions = useMemo(() => {
    return fields
      .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_LISTS)
      .map((item) => ({
        label: toHumanReadable(item.title),
        value: item.id,
      }));
  }, [fields]);

  const dateFieldOptions = useMemo(() => {
    return [
      { label: translate('reports.created_at'), value: 'created_at' },
      ...fields
        .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES)
        .map((item) => ({
          label: toHumanReadable(item.title),
          value: item.id,
        })),
    ];
  }, [fields, translate]);

  const chartsData = useMemo(() => {
    const labels = Array.from(new Set(data.flatMap((item) => item.values.map((lead) => lead.list_value)).filter(Boolean)));

    const datasets = [
      {
        label: translate('reports.leads_by_field.title'),
        data: labels.map((label) =>
          data.reduce((sum, item) => {
            const product = item.values.find((p) => p.list_value === label);
            return product ? sum + product.total_leads : sum;
          }, 0),
        ),
      },
    ];
    return {
      labels,
      datasets,
    };
  }, [data, translate]);

  const handleFieldChange = (value: string) => {
    setSelectedField(value);
    onFieldChange?.(value);
  };

  const handleDateFieldChange = (value: string) => {
    setSelectedDateField(value);
    onDateFieldChange?.(value);
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !entityTypes.data.JOB?.id) return;
    setIsLoading(true);
    getEntityFields(selectedBusiness?.id, entityTypes.data.JOB?.id).then((res) => {
      setFields(res.data);

      const listFields = res.data
        .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_LISTS)
        .map((item) => ({
          label: toHumanReadable(item.title),
          value: item.id,
        }));

      if (chartId) {
        const savedLayouts = localStorage.getItem('grid-layout');
        if (savedLayouts) {
          try {
            const layouts = JSON.parse(savedLayouts);
            const savedLayout = layouts.lg.find((item: ILayout) => item.i === chartId);
            if (savedLayout?.fieldId && listFields.some((field) => field.value === savedLayout.fieldId)) {
              setSelectedField(savedLayout.fieldId);
              setIsLoading(false);
              return;
            }
          } catch (error) {
            () => setIsLoading(false);
          }
        }
      }

      if (fieldId && listFields.some((field) => field.value === fieldId)) {
        setSelectedField(fieldId);
      } else if (listFields.length > 0) {
        setSelectedField(listFields[0].value);
      }
    });
  }, [selectedBusiness?.id, entityTypes.data.JOB?.id, chartId, fieldId]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityTypes.data.JOB?.id || !dateRange || !selectedField) return;
    setIsLoading(true);
    getLeadsByFieldReport({
      business_id: selectedBusiness?.id,
      start_date: dateRange?.[0]?.format('YYYY-MM-DD') || '',
      end_date: dateRange?.[1]?.format('YYYY-MM-DD') || '',
      date_argument: selectedDateField,
      granularity,
      field_id: selectedField,
    }).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [selectedBusiness?.id, granularity, dateRange, selectedField, selectedDateField]);

  return (
    <>
      <div className='chart-container'>
        {allowEntityChange && (
          <Select
            disabled={isLoading}
            size={'small'}
            style={{ width: 150 }}
            value={selectedField}
            onChange={handleFieldChange}
            options={fieldOptions}
          />
        )}
        <Select
          disabled={isLoading}
          size={'small'}
          style={{ width: 150 }}
          value={selectedDateField}
          onChange={handleDateFieldChange}
          options={dateFieldOptions}
        />
        <div className='relative h-full'>
          <Spin spinning={isLoading} className='spin-container' />
          {!isLoading && chartsData.labels && chartsData.labels.length > 0 && (
            <>
              <div className='chart'>
                <ChartComponent
                  type={'pie'}
                  data={chartsData}
                  options={{
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          stepSize: 1,
                        },
                      },
                    },
                    plugins: {
                      title: {
                        display: true,
                        text: `${translate('reports.leads_by_field.title', {
                          field: toHumanReadable(fields.find((item) => item.id === selectedField)?.title || ''),
                        })}`,
                      },
                    },
                  }}
                />
              </div>
            </>
          )}
          {!isLoading && (!chartsData.labels || chartsData?.labels?.length === 0) && (
            <Row justify='center' align={'middle'} className='h-full'>
              <p>{translate('reports.leads_by_field.no_data')}</p>
            </Row>
          )}
        </div>
      </div>
    </>
  );
}
export default LeadsByFieldChart;
