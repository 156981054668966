import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EllipsisOutlined, EyeOutlined, RightOutlined } from '@ant-design/icons';
import { removeWidget, useWidgetTitle } from 'views/settings/client-portal/helper';

import { Button, Card, Col, Divider, Dropdown, Form, FormInstance, Input, Row, Select, Switch, Typography } from 'antd';
import './Upsells.scss';

import { UPSELL_PRODUCTS, VISIBILITY_OPTIONS } from '@aduvi/constants/client-portal-constants';
import { EClientPortalComponentType } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const Upsells = ({ readonly = true, form }: { readonly?: boolean; form?: FormInstance }) => {
  const { t: translate } = useTranslation();

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const title = useWidgetTitle('clientPortal.upsells.title', 'upsells', selectedClientPortal?.client_portal_settings?.upsells?.title, form);

  return (
    <Card
      bordered={false}
      title={
        <Row justify={'space-between'}>
          <Typography.Paragraph className='mb-0'>{title}</Typography.Paragraph>
          {!readonly && (
            <Dropdown
              open={dropdownVisible}
              onOpenChange={(isOpen) => setDropdownVisible(isOpen)}
              destroyPopupOnHide
              className='edit-button'
              overlayClassName='editable-cell-drowpdown-options-wrapper'
              dropdownRender={() => (
                <Card className='style-modal'>
                  <div>
                    <Typography.Paragraph className='mb-10' type='secondary'>
                      {translate('clientPortal.options.blockSettings')}
                    </Typography.Paragraph>
                  </div>
                  <Form.Item
                    initialValue={'Special offers just for John Doe'}
                    labelCol={{ span: 24 }}
                    name={['client_portal_settings', 'upsells', 'title']}>
                    <Input />
                  </Form.Item>
                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item initialValue={true} className='mb-0' name={['client_portal_settings', 'upsells', 'half_width']}>
                      <Switch
                        size='small'
                        onChange={() => {}}
                        defaultValue={selectedClientPortal?.client_portal_settings?.upsells?.half_width}
                        style={{ margin: 6 }}
                      />
                    </Form.Item>
                    <Typography.Text>{translate('clientPortal.options.halfWidth')}</Typography.Text>
                  </Row>

                  <Divider />

                  <Typography.Paragraph className='mb-10' type='secondary'>
                    {translate('clientPortal.options.options')}
                  </Typography.Paragraph>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal' style={{ width: 250 }}>
                        <Typography.Paragraph className='mb-10' type='secondary'>
                          {translate('clientPortal.options.dateLogic')}
                        </Typography.Paragraph>
                        <Form.Item initialValue={'always'} name={['client_portal_settings', 'upsells', 'visibility']}>
                          <Select>
                            {VISIBILITY_OPTIONS.map((option) => (
                              <Select.Option value={option.id} key={option.id}>
                                {option.value ? translate(option.text, { value: option.value }) : translate(option.text)}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <EyeOutlined /> {translate('clientPortal.options.blockSettings')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>

                  <Row>
                    <Button
                      type='text'
                      danger
                      className='w-full flex align-center'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        removeWidget(EClientPortalComponentType.UPSELLS, form);
                        setDropdownVisible(false);
                      }}>
                      {translate('clientPortal.options.remove')}
                    </Button>
                  </Row>
                </Card>
              )}
              trigger={['click']}>
              <Typography.Text>
                <EllipsisOutlined />
              </Typography.Text>
            </Dropdown>
          )}
        </Row>
      }
      className='client-portal-upsells-wrapper b-radius-10'>
      <Row gutter={16}>
        {UPSELL_PRODUCTS.map((product) => (
          <Col span={8} key={product.id}>
            <Card
              bordered={false}
              className='product-card'
              cover={
                readonly ? (
                  <img src='https://www.pragimtech.com/wp-content/uploads/2019/03/java-script.jpg' alt={product.name} />
                ) : (
                  <div style={{ backgroundColor: 'grey', height: '200px', opacity: '50%' }}></div>
                )
              }>
              <span className='sale-tag d-inline-block bg-color-red color-white'>
                {translate('clientPortal.upsells.save')} ${product.price - product.salePrice}
              </span>
              <div className='text-center'>
                <Typography.Paragraph>{product.name}</Typography.Paragraph>
                <Typography.Paragraph className='fs-10 mb-0'>
                  {translate('clientPortal.upsells.oldPrice', { oldPrice: `$${product.price}` })}
                </Typography.Paragraph>
                <Typography.Paragraph className='fs-10 mb-0 color-red'>{translate('clientPortal.upsells.nowOnly')}</Typography.Paragraph>
                <Typography.Text className='fs-24 color-red'>${product.salePrice.toFixed(2)}</Typography.Text>
                <Button className='w-full' type='primary'>
                  {translate('clientPortal.upsells.claim')}
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
};
