import axios from '@aduvi/config/axios';
import {
  IEntitiesReportData,
  IEntitiesReportPayload,
  IFinancialOverviewData,
  IFinancialReportData,
  IFinancialReportPayload,
  ILeadsByFieldData,
  ILeadsByFieldPayload,
  IPopularProductsData,
  IPopularServicesData,
  IStaffShiftsData,
  ITransactionReportData,
  ITransactionReportPayload,
} from '@aduvi/types';

export const getEntitiesReport = async (payload: IEntitiesReportPayload): Promise<IEntitiesReportData[]> => {
  return await axios.get(`/businesses/${payload.business_id}/reports/entities`, { params: payload });
};

export const getFinancialReport = async (payload: IFinancialReportPayload): Promise<IFinancialReportData[]> => {
  return await axios.get(`/businesses/${payload.business_id}/reports/financial`, { params: payload });
};

export const getFinancialOverviewReport = async (payload: IFinancialReportPayload): Promise<IFinancialOverviewData[]> => {
  return await axios.get(`/businesses/${payload.business_id}/reports/financial-overview`, { params: payload });
};

export const getTransactionReport = async (payload: ITransactionReportPayload): Promise<ITransactionReportData[]> => {
  return await axios.get(`/businesses/${payload.business_id}/reports/transactions`, { params: payload });
};

export const getPopularProductsReport = async (payload: IEntitiesReportPayload): Promise<IPopularProductsData[]> => {
  return await axios.get(`/businesses/${payload.business_id}/reports/products`, { params: payload });
};

export const getPopularServicesReport = async (payload: IEntitiesReportPayload): Promise<IPopularServicesData[]> => {
  return await axios.get(`/businesses/${payload.business_id}/reports/services`, { params: payload });
};

export const getLeadsByFieldReport = async (payload: ILeadsByFieldPayload): Promise<ILeadsByFieldData[]> => {
  return await axios.get(`/businesses/${payload.business_id}/reports/field`, { params: payload });
};

export const getStaffShiftsReport = async (payload: IEntitiesReportPayload): Promise<IStaffShiftsData[]> => {
  return await axios.get(`/businesses/${payload.business_id}/reports/shifts`, { params: payload });
};
