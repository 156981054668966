import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CodeOutlined,
  CopyOutlined,
  DownOutlined,
  EyeOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  MailOutlined,
  QrcodeOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { isEqual, sortBy } from 'lodash';
import { convertRuleSetsToEngineRules } from 'views/automations/nodes/json-rules-engine-helper';

import { Breadcrumb, Button, Col, Dropdown, Form, MenuProps, message, Modal, notification, QRCode, Row, Spin, Tabs, UploadFile } from 'antd';
import './FormBuilder.scss';

import GenericHeader from '@aduvi/components/Header/GenericHeader';
import { useBusiness } from '@aduvi/hooks';
import { useGetContactManagementFields } from '@aduvi/hooks/useGetContactManagementFields';
import { EFieldDataValueType, EReferenceEntityType } from '@aduvi/types';
import { IFieldInstance } from '@aduvi/types/form';
import { generateFormIFrame, hasConsecutivePageBreaks } from '@aduvi/utils/helper';

import { getFormById, setSelectedEntityForm, updateEntityForm, updateEntityFormCustomFields } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Build } from './tabs/build/Build';
import { IBookingFormFieldProps } from './tabs/build/helper';
import { Configure } from './tabs/configure/Configure';
import { Share } from './tabs/share/Share';
import { useBookingForm } from './FormBuilder.hooks';

export const FormBuilder = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();
  const { entityTypeId, formId } = useParams();
  const navigate = useNavigate();

  const { selectedEntityForm, updating, loading } = useAppSelector((state) => state.forms);
  const { uploading } = useAppSelector((state) => state.common);

  const { form, selectedTab, setSelectedTab, tabs } = useBookingForm(selectedEntityForm?.purpose);

  Form.useWatch(['form_style', 'form_url'], form);

  const { contactCustomFields } = useGetContactManagementFields();

  const [fields, setFields] = useState<IBookingFormFieldProps[]>([]);
  const [initialFieldsState, setInitialFieldsState] = useState<IBookingFormFieldProps[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [openQRCode, setOpenQRCode] = useState(false);

  const downloadQRCode = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadCanvasQRCode = () => {
    const canvas = document.getElementById('formQRcode')?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      downloadQRCode(url, 'QRCode.png');
    }
  };

  const getFormFields = () => {
    const filteredFields = fields?.filter((item) => !item?.isPageBreak && !item?.isTravelFee);

    // Adding contract field instance on update. Because it is hidden it is not part of the fields
    if (selectedEntityForm?.contract_template?.id) {
      const contractField = selectedEntityForm?.field_instances?.find((item) => item?.field?.reference_entity_type === EReferenceEntityType.CONTRACT);
      if (contractField?.field?.id) {
        filteredFields.push({
          field: contractField?.field,
          isPageBreak: false,
          isTravelFee: false,
          field_id: contractField?.field?.id,
          field_instance: contractField as IFieldInstance,
        });
      }
    }

    return filteredFields;
  };

  const handleSaveForm = () => {
    if (!selectedBusiness?.id || !formId || !entityTypeId) return;

    if (fields?.[0]?.isPageBreak || fields?.[fields?.length - 1]?.isPageBreak) {
      return notification.warning({
        message: translate('forms.forms.warning'),
        description: translate('forms.forms.pageBreakWarning'),
      });
    }

    if (hasConsecutivePageBreaks(fields)) {
      return notification.warning({
        message: translate('forms.forms.warning'),
        description: translate('forms.forms.backToBackPageBreakWarning'),
      });
    }

    const pageBreaks = fields?.filter((item) => item?.isPageBreak);
    const travelFees = fields?.filter((item) => item?.isTravelFee);

    dispatch(
      updateEntityForm({
        ...selectedEntityForm,
        ...form.getFieldsValue(),
        limit_visibility: convertRuleSetsToEngineRules(form.getFieldValue('branches') ?? [], true),
        style: {
          form_style: [{ ...selectedEntityForm?.style?.form_style?.[0], ...form.getFieldValue(['form_style', 0]) }],
          page_breaks: pageBreaks,
          travel_fees: travelFees,
        },
      }),
    );

    if (!isEqual(fields, initialFieldsState))
      dispatch(
        updateEntityFormCustomFields({
          business_id: selectedBusiness?.id,
          form_id: formId,
          entity_type_id: entityTypeId,
          custom_fields: getFormFields(),
        }),
      );
  };

  const onActiveTabChange = (tabKey: string) => {
    dispatch(
      setSelectedEntityForm({
        ...selectedEntityForm,
        ...form.getFieldsValue(),
        style: {
          form_style: [{ ...selectedEntityForm?.style?.form_style?.[0], ...form.getFieldValue(['form_style', 0]) }],
        },
      }),
    );
    setSelectedTab(tabKey);
  };

  const onAddField = (element: IBookingFormFieldProps, index: number, isUpdate?: boolean) => {
    if (element?.field?.hidden) return;
    const isContactField =
      element.field?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE && element.field?.reference_entity_type === EReferenceEntityType.CONTACT;
    if (isUpdate) {
      setInitialFieldsState((prev) => sortBy([...prev, element], (field) => field.field_instance.weight));
      return setFields((prev) => [...prev, element]);
    }

    const updatedArray = [...fields?.slice(0, index), element, ...fields?.slice(index)].map((item, index) => ({
      ...item,
      field_instance: {
        ...item?.field_instance,
        display_settings: {
          ...item?.field_instance?.display_settings,
          custom_fields:
            isContactField &&
            item.field?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE &&
            item?.field?.reference_entity_type === EReferenceEntityType.CONTACT
              ? contactCustomFields?.data.filter((item) => item.core)
              : item?.field_instance?.display_settings?.custom_fields,
        },
        weight: index,
      },
    }));
    setFields(updatedArray);
  };

  const redirectUrl = useMemo(() => {
    const brand = selectedBusiness?.brandings?.[0];
    return brand?.vanity_domain || brand?.system_domain || window.location.origin;
  }, [selectedBusiness]);

  const formUrl = Form.useWatch('form_url');
  const formLink = `${redirectUrl}/forms/${selectedEntityForm?.form_url}`;

  const onCopyUrl = () => {
    if (!selectedBusiness?.id) return;
    navigator.clipboard.writeText(`${redirectUrl}/forms/${selectedEntityForm?.form_url}`);
  };

  const formViewUrl = useMemo(() => {
    if (selectedEntityForm?.form_type === 'BOOKINGS_FORM') {
      return `/bookings?viewId=form`;
    } else {
      return `/leads?viewId=form`;
    }
  }, [selectedEntityForm?.form_type]);

  useEffect(() => {
    if (formUrl && redirectUrl) {
      form.setFieldValue('embed_form_code', generateFormIFrame(formUrl, redirectUrl));
    }
  }, [formUrl, redirectUrl]);

  const handleEmailModal = () => {
    Modal.info({
      title: 'Share via Email',
      content: (
        <div>
          <p>Send the form link via email:</p>
          <p>
            <strong>{selectedEntityForm?.name}</strong>
          </p>
          <p>
            <a href={formLink} target='_blank' rel='noopener noreferrer'>
              {redirectUrl}/forms/{selectedEntityForm?.form_url}
            </a>
          </p>
        </div>
      ),
      okText: 'Close',
    });
  };

  const formLinkDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div>
          <CodeOutlined /> {translate('forms.forms.embedForm')}
        </div>
      ),
      onClick: () => {
        navigator.clipboard.writeText(form.getFieldValue('embed_form_code'));
        message.success(translate('widgets.copiedSuccessfully'));
      },
    },
    {
      key: '2',
      label: (
        <div>
          <QrcodeOutlined /> {translate('forms.forms.qrCode')}
        </div>
      ),
      onClick: () => setOpenQRCode(true),
    },
    {
      key: '3',
      label: (
        <div>
          <EyeOutlined /> {translate('forms.forms.previewForm')}
        </div>
      ),
      onClick: () => window.open(formLink, '_blank'),
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      type: 'group',
      label: translate('forms.forms.shareVia'),
      children: [
        {
          key: '4-1',
          label: (
            <div onClick={() => window.open(`https://twitter.com/intent/tweet?text=${selectedEntityForm?.name}&url=${formLink}`, '_blank')}>
              <TwitterOutlined /> Twitter (X)
            </div>
          ),
        },
        {
          key: '4-2',
          label: (
            <div onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${formLink}`, '_blank')}>
              <FacebookOutlined /> Facebook
            </div>
          ),
        },
        {
          key: '4-3',
          label: (
            <div
              onClick={() =>
                window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${formLink}&title=${selectedEntityForm?.name}`, '_blank')
              }>
              <LinkedinOutlined /> LinkedIn
            </div>
          ),
        },
        {
          key: '4-4',
          label: (
            <div onClick={() => window.open(`https://api.whatsapp.com/send?text=${selectedEntityForm?.name}%20${formLink}`, '_blank')}>
              <WhatsAppOutlined /> WhatsApp
            </div>
          ),
        },
        {
          key: '4-5',
          label: (
            <div onClick={handleEmailModal}>
              <MailOutlined /> Email
            </div>
          ),
        },
      ],
    },
  ];

  const menuProps = {
    items: formLinkDropdownItems,
    onClick: () => void 0,
  };

  const activeTab: Record<string, ReactNode> = {
    '1': (
      <Build
        entityTypeId={entityTypeId}
        form={form}
        fields={fields}
        fileList={fileList}
        setFileList={setFileList}
        setFields={setFields}
        onAddField={onAddField}
      />
    ),
    '2': (
      <Col className='flex-center mt-30'>
        <Configure form={form} />
      </Col>
    ),
    '3': (
      <Col className='flex-center mt-30'>
        <Share form={form} />
      </Col>
    ),
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !entityTypeId || !formId) return;
    dispatch(getFormById({ business_id: selectedBusiness?.id, entity_type_id: entityTypeId, form_id: formId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedEntityForm(data));
        form.setFieldsValue(data);
        form.setFieldValue('form_style', data?.style?.form_style);

        setFileList(
          data?.style?.form_style?.[0]?.logo
            ? [
                {
                  uid: '-1',
                  name: data?.style?.form_style?.[0]?.logo,
                  status: 'done',
                  url: data?.style?.form_style?.[0]?.logo,
                },
              ]
            : [],
        );

        data?.style?.page_breaks?.map(({ field_id, field_instance, isPageBreak, field }, index) =>
          onAddField({ field_id, field_instance, field, isPageBreak }, index, true),
        );

        data?.style?.travel_fees?.map(({ field_id, field_instance, isTravelFee, field, isPageBreak }, index) =>
          onAddField({ field_id, field_instance, isTravelFee, field, isPageBreak }, index, true),
        );

        data?.field_instances?.map((item, index) =>
          onAddField(
            {
              field_id: item?.field.id,
              field: item.field,
              field_instance: {
                description: item?.description,
                default_value: item?.default_value,
                display_settings: { ...item?.display_settings, is_widget_field: data?.widget?.id ? item.display_settings?.is_widget_field : false },
                half_width: item?.half_width,
                hidden: item?.hidden,
                multiple: item?.multiple,
                place_holder: item?.place_holder,
                required: item?.required,
                title: item?.title,
                weight: item?.weight,
                id: item?.id,
              },
              isPageBreak: false,
            },
            index,
            true,
          ),
        );

        return setFields((prev) => sortBy(prev, (field) => field?.field_instance?.weight));
      })
      .catch(() => {});
  }, [selectedBusiness?.id, entityTypeId, formId]);

  return (
    <>
      <Form form={form} initialValues={selectedEntityForm} layout='vertical' requiredMark={false}>
        <Col span={24} className='booking-form-wrapper'>
          <GenericHeader
            breadcrumbs={
              <Breadcrumb
                items={[
                  {
                    title:
                      selectedEntityForm?.form_type === 'BOOKINGS_FORM' ? translate('forms.forms.bookingForms') : translate('forms.forms.leadForms'),
                    onClick: () => navigate(formViewUrl),
                    className: 'cursor-pointer',
                  },
                  {
                    title: selectedEntityForm?.name,
                  },
                ]}
              />
            }
            tabs={<Tabs defaultActiveKey='1' activeKey={selectedTab} className='flex-center' items={tabs} onChange={onActiveTabChange} />}
            actions={
              <Row justify={'space-around'}>
                <Col className='mr-15'>
                  <Dropdown.Button menu={menuProps} onClick={onCopyUrl} icon={<DownOutlined />}>
                    <Row justify={'space-between'} align={'middle'}>
                      <CopyOutlined className='mr-10' /> {translate('forms.forms.copyFromLink')}
                    </Row>
                  </Dropdown.Button>
                </Col>

                <Button onClick={handleSaveForm} disabled={updating || uploading} loading={updating || uploading} type='primary'>
                  {translate('buttons.save')}
                </Button>
              </Row>
            }
          />

          <Spin spinning={loading}>
            <Col span={24} style={{ overflow: 'hidden' }}>
              {activeTab[selectedTab]}
            </Col>
          </Spin>
        </Col>
      </Form>
      <Modal
        title={translate('forms.forms.qrCode')}
        open={openQRCode}
        footer={() => (
          <Button type='primary' onClick={downloadCanvasQRCode}>
            {translate('file.download')}
          </Button>
        )}
        onCancel={() => setOpenQRCode(false)}>
        <div className='flex-center w-full mb-10' style={{ backgroundColor: '#fff' }} id='formQRcode'>
          <QRCode value={formLink} />
        </div>
      </Modal>
    </>
  );
};
