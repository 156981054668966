import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';

import { useBusiness } from '@aduvi/hooks';

import { getBuilderTemplates } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const CreateProposal = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const {
    templates: { templates, loading },
  } = useAppSelector((state) => state.proposals);

  useEffect(() => {
    if (!selectedBusiness?.id) return;

    dispatch(getBuilderTemplates(selectedBusiness?.id));
  }, [selectedBusiness?.id]);

  return (
    <>
      <Form.Item name={'builder_template_id'} label={translate('automations.proposalTheme')}>
        <Select
          placeholder={translate('automations.basicQuote')}
          loading={loading}
          disabled={loading}
          options={templates.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Form.Item>
      <Form.Item hidden name={'accepted'} initialValue={false} />
      <Form.Item hidden name={'accepted_date'} initialValue={null} />
    </>
  );
};
