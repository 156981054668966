import { useEffect, useState } from 'react';

import { Col, Form, Input, InputNumber, Select } from 'antd';

import { DatePicker } from '@aduvi/components/DatePicker/DatePicker';
import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, IEntityField } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';

import { getEntities } from 'store/services/entity.service';

import { EOperator } from './helper';

interface IConditionInputProps {
  field?: IEntityField;
  namePrefix: (string | number)[];
  disabled?: boolean;
  operator?: EOperator;
}

export const ConditionInput = ({ field, namePrefix, disabled, operator }: IConditionInputProps) => {
  const selectedBusiness = useBusiness();
  const [references, setReferences] = useState<IEntityWithFields[]>([]);

  useEffect(() => {
    if (!field) return;
    if (field.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE) {
      if (selectedBusiness && field.reference_entity_type_id) {
        getEntities(selectedBusiness.id, field.reference_entity_type_id).then((entities) => {
          setReferences(entities.data.entities);
        });
      }
    }
  }, [field, selectedBusiness, field?.reference_entity_type_id]);

  const referenceOptions = references.map((reference) => ({
    label:
      reference.custom_fields.find((field) => field.title === 'Name')?.field_data?.[0]?.value ||
      reference.custom_fields.find((field) => field.title === 'Title')?.field_data?.[0]?.value,
    value:
      reference.custom_fields.find((field) => field.title === 'Name')?.field_data?.[0]?.value ||
      reference.custom_fields.find((field) => field.title === 'Title')?.field_data?.[0]?.value,
  }));
  if (!field) return null;

  if (operator === EOperator.IS_EMPTY || operator === EOperator.IS_NOT_EMPTY) {
    return null;
  }

  switch (field.value_type) {
    case EFieldDataValueType.FIELD_DATA_DATE_TIMES:
      if (operator === EOperator.BETWEEN) {
        return (
          <>
            <Col span={5}>
              <Form.Item name={[...namePrefix, 'start_date']} noStyle>
                <DatePicker className='w-full' disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name={[...namePrefix, 'end_date']} noStyle>
                <DatePicker className='w-full' disabled={disabled} />
              </Form.Item>
            </Col>
          </>
        );
      }
      return (
        <Col span={11}>
          <Form.Item name={[...namePrefix, 'date']} noStyle>
            <DatePicker className='w-full' disabled={disabled} />
          </Form.Item>
        </Col>
      );

    case EFieldDataValueType.FIELD_DATA_NUMBERS:
    case EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS:
    case EFieldDataValueType.FIELD_DATA_STAR_RATINGS:
      if (operator === EOperator.BETWEEN) {
        return (
          <>
            <Col span={5}>
              <Form.Item name={[...namePrefix, 'start_value']} noStyle>
                <InputNumber className='w-full' disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name={[...namePrefix, 'end_value']} noStyle>
                <InputNumber className='w-full' disabled={disabled} />
              </Form.Item>
            </Col>
          </>
        );
      }
      return (
        <Col span={11}>
          <Form.Item name={[...namePrefix, 'value']} noStyle>
            <InputNumber className='w-full' disabled={disabled} />
          </Form.Item>
        </Col>
      );

    case EFieldDataValueType.FIELD_DATA_LISTS:
      return (
        <Col span={11}>
          <Form.Item name={[...namePrefix, 'value']} noStyle>
            <Select
              mode='multiple'
              className='w-full'
              disabled={disabled}
              options={field.list_values?.map((item) => ({
                label: item.value,
                value: item.value,
              }))}
            />
          </Form.Item>
        </Col>
      );

    case EFieldDataValueType.FIELD_DATA_REFERENCE:
      return (
        <Col span={11}>
          <Form.Item name={[...namePrefix, 'value']} noStyle>
            <Select mode='multiple' className='w-full' disabled={disabled} options={referenceOptions} />
          </Form.Item>
        </Col>
      );

    default:
      return (
        <Col span={11}>
          <Form.Item name={[...namePrefix, 'value']} noStyle>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      );
  }
};
