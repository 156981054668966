import dayjs from 'dayjs';

import { EGranularity } from '@aduvi/types';

export const getDateFormat = (date: string, granularity: EGranularity) => {
  if (granularity === EGranularity.DAILY) return dayjs(date).format('YYYY-MM-DD');
  if (granularity === EGranularity.WEEKLY)
    return (
      dayjs(date.split('-')[0].trim(), 'DD.MM.YYYY').format('YYYY-MMM-DD') +
      ' - ' +
      dayjs(date.split('-')[1].trim(), 'DD.MM.YYYY').format('YYYY-MMM-DD')
    );
  if (granularity === EGranularity.MONTHLY) return dayjs(date).format('YYYY-MMM');
  if (granularity === EGranularity.YEARLY) return date;
  return dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
};
