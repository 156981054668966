import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  DeleteFilled,
  PlusOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

import { Button, Col, message, Popconfirm, Row, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { Title } from '@aduvi/components/Title';
import { EQuotePaymentMethod, IPayment } from '@aduvi/types/quote';
import { toHumanReadable } from '@aduvi/utils/helper';

import { deletePayment, getPayments } from 'store/features/quote-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const PaymentsTable = ({ setIsManualModalPaymentOpen }: { setIsManualModalPaymentOpen: (value: boolean) => void }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { selectedQuote, selectedQuotePayments } = useAppSelector((state) => state.quotes);
  const { creating } = useAppSelector((state) => state.manualPaymentOption);
  const [copyButtonText, setCopyButtonText] = useState(translate('quotes.payments.copyLink.copy'));
  const [copyButtonIcon, setCopyButtonIcon] = useState(<ShareAltOutlined />);

  const handleCopyCheckoutLink = () => {
    if (!selectedQuote?.id || !selectedQuote?.entity_id) return;
    const checkoutLink = `${window.location.origin}/checkout/${selectedQuote.id}/${selectedQuote.entity_id}`;
    navigator.clipboard.writeText(checkoutLink);
    message.success(translate('quotes.payments.copyLink.success'), 3);
    setCopyButtonText(translate('quotes.payments.copyLink.success'));
    setCopyButtonIcon(<CheckCircleOutlined style={{ color: 'green' }} />);
    setTimeout(() => {
      setCopyButtonText(translate('quotes.payments.copyLink.copy'));
      setCopyButtonIcon(<ShareAltOutlined />);
    }, 3000);
  };
  const handleDeletePayment = (transactionId: string) => {
    if (!selectedQuote?.business_id || !selectedQuote?.id || !transactionId) return;
    dispatch(deletePayment({ businessId: selectedQuote.business_id, quoteId: selectedQuote.id, transactionId }));
  };
  const paymentsColumns = usePaymentsTableColumns(handleDeletePayment);

  const totalPayments = useMemo(() => {
    return selectedQuotePayments?.payments?.reduce((sum, payment) => sum + +payment?.amount, 0);
  }, [selectedQuotePayments?.payments]);

  useEffect(() => {
    if (selectedQuote?.id && selectedQuote?.business_id) {
      dispatch(getPayments({ businessId: selectedQuote.business_id, quoteId: selectedQuote.id }));
    }
  }, [selectedQuote]);

  const totalBalance = useMemo(() => {
    return (Number(selectedQuote?.total) - Number(totalPayments))?.toFixed(2);
  }, [selectedQuote, totalPayments]);

  return (
    <div>
      <Table
        loading={selectedQuotePayments?.loading || selectedQuotePayments?.deleting || creating}
        bordered={false}
        pagination={false}
        dataSource={selectedQuotePayments?.payments}
        columns={paymentsColumns}
        rowKey='id'
        footer={() => (
          <Row justify='space-between' align='middle'>
            <Col>
              <Space>
                <Button onClick={() => setIsManualModalPaymentOpen(true)} icon={<PlusOutlined />}>
                  {translate('quotes.payments.addPayment')}
                </Button>

                <Button disabled={!selectedQuote?.id || !selectedQuote?.entity_id} onClick={handleCopyCheckoutLink} icon={copyButtonIcon}>
                  {copyButtonText}
                </Button>
              </Space>
            </Col>
            <Col>
              <Space size='large'>
                <Typography.Text strong style={{ color: Number(totalBalance) === 0 ? 'green' : 'red' }}>
                  {translate('quotes.payments.balance')}
                </Typography.Text>
                <Typography.Text strong style={{ color: Number(totalBalance) === 0 ? 'green' : 'red' }}>
                  <PriceDisplay price={Number(totalBalance)} />
                </Typography.Text>
              </Space>
            </Col>
          </Row>
        )}
      />
    </div>
  );
};

const usePaymentsTableColumns = (handleDeletePayment: (transactionId: string) => void) => {
  const { t: translate } = useTranslation();
  return [
    {
      title: translate('quotes.payments.title'),

      width: '45%',
      render: (payment: IPayment) => {
        return (
          <Title level={5} style={{ marginBottom: '0px' }}>
            {payment?.manual_payment_option
              ? `${payment?.manual_payment_option?.title} (${translate('quotes.payments.addedBy', {
                  name: payment?.paid_by,
                })})`
              : `${toHumanReadable(payment?.payment_method)} (${
                  payment.paid_by
                    ? translate('quotes.payments.addedBy', {
                        name: payment?.paid_by,
                      })
                    : ''
                })`}
          </Title>
        );
      },
    },
    {
      title: translate('quotes.payments.actions'),
      width: '15%',
      editable: true,
      render: (payment: IPayment) => {
        return (
          <Row>
            <Col>
              <Tooltip title={translate('quotes.payments.refund.notAvailable')}>
                <Button disabled={true} type='text' icon={<ReloadOutlined />} />
              </Tooltip>
            </Col>
            <Col>
              <Popconfirm
                title={translate('quotes.payments.delete.title')}
                disabled={payment?.payment_method !== EQuotePaymentMethod.MANUAL_PAYMENT}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onCancel={(e) => e?.stopPropagation()}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  return handleDeletePayment(payment?.id);
                }}>
                <Button type='text' danger icon={<DeleteFilled />} />
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
    {
      title: translate('quotes.payments.date'),

      width: '20%',
      render: (payment: IPayment) => (
        <Typography.Text>
          <CalendarOutlined /> {dayjs(payment?.payment_date).format('MM/DD/YYYY')}
        </Typography.Text>
      ),
    },
    {
      title: translate('quotes.payments.total'),
      width: '20%',
      render: (payment: IPayment) => <PriceDisplay price={payment?.amount} />,
    },
  ] as ColumnsType<IPayment>;
};
