import { IPaginatedResponse, IPaginationParams } from './common';
import { EEntityType } from './entity';
import { EFieldDataValueType } from './fields';
import { IEntityField as IEntityFieldT } from './fields';

export enum EAutomationNodeAction {
  // TRIGGER ENTITY ACTIONS
  // LEAD
  LEAD_CREATE = 'CREATE',
  LEAD_UPDATE = 'LEAD_UPDATE',
  LEAD_DELETE = 'LEAD_DELETE',
  LEAD_STATUS_CHANGED = 'LEAD_STATUS_CHANGED',
  LEAD_CONVERTED = 'LEAD_CONVERTED',
  LEAD_CONVERSION_FAILED = 'LEAD_CONVERSION_FAILED',

  // BOOKING
  BOOKING_CREATE = 'BOOKING_CREATE',
  BOOKING_UPDATE = 'BOOKING_UPDATE',
  BOOKING_DELETE = 'BOOKING_DELETE',
  BOOKING_STATUS_CHANGED = 'BOOKING_STATUS_CHANGED',
  BOOKING_STARTED = 'BOOKING_STARTED',
  BOOKING_ENDED = 'BOOKING_ENDED',

  // ARTWORK
  ARTWORK_CREATE = 'ARTWORK_CREATE',
  ARTWORK_UPDATE = 'ARTWORK_UPDATE',
  ARTWORK_COMMENT = 'ARTWORK_COMMENT',
  ARTWORK_ACCEPTED = 'ARTWORK_ACCEPTED',

  // PROPOSAL
  PROPOSAL_VIEWED = 'PROPOSAL_VIEWED',
  PROPOSAL_CREATE = 'PROPOSAL_CREATE',
  PROPOSAL_ACCEPTED = 'PROPOSAL_ACCEPTED',
  PROPOSAL_ACCEPTANCE_FAILED = 'PROPOSAL_ACCEPTANCE_FAILED',
  PROPOSAL_SENT = 'PROPOSAL_SENT',

  // TASK
  TASK_CREATE = 'TASK_CREATE',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_DELETE = 'TASK_DELETE',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_DEADLINE_DUE = 'TASK_DEADLINE_DUE',

  // PAYMENT
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_MANUAL_CREATE = 'PAYMENT_MANUAL_CREATE',
  PAYMENT_DELETE = 'PAYMENT_DELETE',
  PAYMENT_REFUNDED = 'PAYMENT_REFUNDED',

  // INVOICE
  INVOICE_DUE = 'INVOICE_DUE',

  // USER
  USER_CREATE = 'USER_CREATE',
  USER_UPDATE = 'USER_UPDATE',
  USER_DELETE = 'USER_DELETE',
  USER_SHIFT_ASSIGNED = 'USER_SHIFT_ASSIGNED',
  USER_SHIFT_REMOVED = 'USER_SHIFT_REMOVED',
  USER_SHIFT_ACCEPTED = 'USER_SHIFT_ACCEPTED',
  USER_SHIFT_DECLINED = 'USER_SHIFT_DECLINED',
  USER_SHIFT_STARTED = 'USER_SHIFT_STARTED',
  USER_SHIFT_ENDED = 'USER_SHIFT_ENDED',

  // REP
  REP_WITHDRAWAL_REQUEST = 'REP_WITHDRAWAL_REQUEST',
  REP_WITHDRAWAL_PAID = 'REP_WITHDRAWAL_PAID',

  // CONTACT
  CONTACT_CREATE = 'CONTACT_CREATE',
  CONTACT_UPDATE = 'CONTACT_UPDATE',
  CONTACT_DELETE = 'CONTACT_DELETE',

  // CONTRACT
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CONTRACT_DELETE = 'CONTRACT_DELETE',

  // FORM
  FORM_SUBMITTED = 'FORM_SUBMITTED',
  FORM_ABANDONED = 'FORM_ABANDONED',
  FORM_SUBMISSION_UPDATED = 'FORM_SUBMISSION_UPDATED',
  FORM_WIDGET_SUBMITTED = 'FORM_WIDGET_SUBMITTED',

  // VENUE
  VENUE_CREATE = 'VENUE_CREATE',
  VENUE_UPDATE = 'VENUE_UPDATE',
  VENUE_DELETE = 'VENUE_DELETE',

  // PROJECT
  PROJECT_CREATE = 'PROJECT_CREATE',
  PROJECT_UPDATE = 'PROJECT_UPDATE',
  PROJECT_DELETE = 'PROJECT_DELETE',

  // EVENT
  EVENT_STARTED = 'EVENT_STARTED',
  EVENT_ENDED = 'EVENT_ENDED',

  // TEMPLATE
  TEMPLATE_CHOSEN = 'TEMPLATE_CHOSEN',
  TEMPLATE_CHOICE_DELETED = 'TEMPLATE_CHOICE_DELETED',

  // BACKDROP
  BACKDROP_CHOSEN = 'BACKDROP_CHOSEN',
  BACKDROP_CHOICE_DELETED = 'BACKDROP_CHOICE_DELETED',

  // STARTSCREEN
  STARTSCREEN_CHOSEN = 'STARTSCREEN_CHOSEN',
  STARTSCREEN_CHOICE_DELETED = 'STARTSCREEN_CHOICE_DELETED',

  // ORDER
  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_UPDATE = 'ORDER_UPDATE',
  ORDER_DELETE = 'ORDER_DELETE',
  ORDER_STATUS_CHANGED = 'ORDER_STATUS_CHANGED',

  // MESSAGE
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',

  // TIME BASED
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',

  // INVOICE
  INVOICE_CREATE = 'INVOICE_CREATE',
  INVOICE_UPDATE = 'INVOICE_UPDATE',
  INVOICE_DELETE = 'INVOICE_DELETE',

  // CONTRACT
  CONTRACT_CREATE = 'CONTRACT_CREATE',
  CONTRACT_UPDATE = 'CONTRACT_UPDATE',

  // PAYMENT
  PAYMENT_CREATE = 'PAYMENT_CREATE',
  PAYMENT_UPDATE = 'PAYMENT_UPDATE',

  // TRIGGER BOOKING ACTIONS
  LEAD_CONVERTS_TO_BOOKING = 'LEAD_CONVERTS_TO_BOOKING',

  BOOKING_FORM_IS_SUBMITTED = 'BOOKING_FORM_IS_SUBMITTED',
  BOOKING_FORM_IS_ABANDONED = 'BOOKING_FORM_IS_ABANDONED',
  BOOKING_STATUS_IS_CHANGED = 'BOOKING_STATUS_IS_CHANGED',
  BEFORE_BOOKING_EVENT_DATE_STARTS = 'BEFORE_BOOKING_EVENT_DATE_STARTS',
  BOOKING_DATE_STARTS = 'BOOKING_DATE_STARTS',
  BOOKING_DATE_ENDS = 'BOOKING_DATE_ENDS',

  // TRIGGER PAYMENT ACTIONS
  PAYMENT_FAIL = 'PAYMENT_FAIL',
  GRATUITY_IS_ADDED = 'GRATUITY_IS_ADDED',

  // TRIGGER INVOICE ACTIONS
  INVOICE_IS_PAID = 'INVOICE_IS_PAID',
  INVOICE_IS_DUE = 'INVOICE_IS_DUE',
  INVOICE_IS_OVERDUE = 'INVOICE_IS_OVERDUE',

  // TRIGGER TASK ACTIONS
  TASK_IS_COMPLETED = 'TASK_IS_COMPLETED',
  TASK_IS_DUE = 'TASK_IS_DUE',
  TASK_IS_OVERDUE = 'TASK_IS_OVERDUE',

  // TRIGGER CONTRACT ACTIONS
  CONTRACT_IS_VIEWED = 'CONTRACT_IS_VIEWED',
  CONTRACT_IS_SIGNED = 'CONTRACT_IS_SIGNED',
  CONTRACT_IS_DELETED = 'CONTRACT_IS_DELETED',

  // TRIGGER LEAD(JOB) ACTIONS
  LEAD_FORM_IS_SUBMITTED = 'LEAD_FORM_IS_SUBMITTED',
  LEAD_FORM_IS_ABANDONED = 'LEAD_FORM_IS_ABANDONED',
  LEAD_STATUS_IS_CHANGED = 'LEAD_STATUS_IS_CHANGED',
  BEFORE_LEAD_EVENT_DATE_STARTS = 'BEFORE_LEAD_EVENT_DATE_STARTS',
  LEAD_EVENT_DATE_STARTS = 'LEAD_EVENT_DATE_STARTS',
  LEAD_EVENT_DATE_ENDS = 'LEAD_EVENT_DATE_ENDS',
  LEAD_DATE_FIELD_DUE = 'LEAD_DATE_FIELD_DUE',

  // TRIGGER UPPCELL ACTIONS
  UPSELL_IS_CLAIMED = 'UPSELL_IS_CLAIMED',

  // TRIGGER CONTACT ACTIOMS
  CONTACT_LOGGED_IN = 'CONTACT_LOGGED_IN',
  CONTACT_REGISTERED = 'CONTACT_REGISTERED',

  // TRIGGER USER SHIFT ACTIONS
  USER_REQUESTS_SHIFT = 'USER_REQUESTS_SHIFT',
  USER_DECLINES_SHIFT = 'USER_DECLINES_SHIFT',
  USER_ACCEPTS_SHIFT = 'USER_ACCEPTS_SHIFT',
  USER_ASSIGNED_TO_SHIFT = 'USER_ASSIGNED_TO_SHIFT',
  USER_IS_REMOVED_FROM_SHIFT = 'USER_IS_REMOVED_FROM_SHIFT',
  USER_SHIFT_CHANGES = 'USER_SHIFT_CHANGES',

  // TRIGGER FIELD BASE ACTIONS
  ENTITY_FIELD_UPDATED = 'ENTITY_FIELD_UPDATED',

  // LOGIC ACTIONS
  IF_ELSE = 'IF_ELSE',
  IF_BRANCH = 'IF_BRANCH',
  ELSE_IF_BRANCH = 'ELSE_IF_BRANCH',
  ELSE_BRANCH = 'ELSE_BRANCH',
  SPLIT = 'SPLIT',
  GO_TO = 'GO_TO',
  FORMAT_NUMBER = 'FORMAT_NUMBER',
  FORMAT_DATE = 'FORMAT_DATE',
  CHAT_GPT_ACTION = 'CHAT_GPT_ACTION',
  WAIT = 'WAIT',

  // ACTIONS: ACTIONS
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_SMS = 'SEND_SMS',
  SEND_WHATSAPP_MESSAGE = 'SEND_WHATSAPP_MESSAGE',
  WEBHOOK = 'WEBHOOK',
  PUSHOVER_NOTIFICATION = 'PUSHOVER_NOTIFICATION',
  MAILCHIMP = 'MAILCHIMP',
  ACTIVE_CAMPAIGN = 'ACTIVE_CAMPAIGN',
  MAILER_LITE = 'MAILER_LITE',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
  PLANT_TREE = 'PLANT_TREE',
  PHONE_CALL = 'PHONE_CALL',
  VOICE_MAIL = 'VOICE_MAIL',

  // ACTIONS: INTERNAL
  CHANGE_FIELD_DATA = 'CHANGE_FIELD_DATA',
  SEND_CONVERSATION_MESSAGE = 'SEND_CONVERSATION_MESSAGE',
  CREATE_TASK = 'CREATE_TASK',
  CREATE_PROPOSAL = 'CREATE_PROPOSAL',
}

export enum ENodeType {
  TRIGGER = 'TRIGGER',
  LOGIC = 'LOGIC',
  ACTION = 'ACTION',
  ACTION_INTERNAL = 'ACTION_INTERNAL',
  BRANCH = 'BRANCH',
}

export enum EDistributionType {
  Random = 'Random',
  Distributed = 'Distributed',
}

export enum EConversationSendType {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum EMethods {
  GET = 'GET',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum EMailChimpAction {
  Subscribe = 'Subscribe',
  Unsubscribe = 'Unsubscribe',
}

export enum ENumberFormat {
  DECIMAL_PLACES = 'DECIMAL_PLACES',
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  SCIENTIFIC_NOTATION = 'SCIENTIFIC_NOTATION',
  ORDINAL = 'ORDINAL',
  ROMAN_NUMERALS = 'ROMAN_NUMERALS',
  ROUNDED_INTEGER = 'ROUNDED_INTEGER',
  FLOOR = 'FLOOR',
  CEIL = 'CEIL',
  ABSOLUTE = 'ABSOLUTE',
  THOUSANDS_SEPARATOR = 'THOUSANDS_SEPARATOR',
  EXPONENTIAL = 'EXPONENTIAL',
  FRACTION = 'FRACTION',
  COMPACT = 'COMPACT',
}

export enum EDateFormat {
  YEAR = 'YEAR',
  YEAR_MONTH = 'YEAR_MONTH',
  YEAR_MONTH_DATE = 'YEAR_MONTH_DATE',
  ISO8601 = 'ISO8601',
  RFC2822 = 'RFC2822',
  SHORT_DATE_US = 'SHORT_DATE_US',
  SHORT_DATE_EU = 'SHORT_DATE_EU',
  LONG_DATE = 'LONG_DATE',
  TIME_24_HOUR = 'TIME_24_HOUR',
  TIME_12_HOUR = 'TIME_12_HOUR',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  WEEK_NUMBER = 'WEEK_NUMBER',
  QUARTER = 'QUARTER',
  DAY_OF_YEAR = 'DAY_OF_YEAR',
  DAYS_IN_MONTH = 'DAYS_IN_MONTH',
  START_OF_MONTH = 'START_OF_MONTH',
  END_OF_MONTH = 'END_OF_MONTH',
  START_OF_QUARTER = 'START_OF_QUARTER',
  END_OF_QUARTER = 'END_OF_QUARTER',
  START_OF_WEEK = 'START_OF_WEEK',
  END_OF_WEEK = 'END_OF_WEEK',
  START_OF_YEAR = 'START_OF_YEAR',
  END_OF_YEAR = 'END_OF_YEAR',
  START_OF_DAY = 'START_OF_DAY',
  END_OF_DAY = 'END_OF_DAY',
  DIFF_FOR_HUMANS = 'DIFF_FOR_HUMANS',
  CUSTOM_FORMAT = 'CUSTOM_FORMAT',
}

export enum EProvider {
  TWILIO = 'TWILIO',
  TEXT_LOCAL = 'TEXT_LOCAL',
  MAILCHIMP = 'MAILCHIMP',
}

export enum ECurrencySign {
  DOLLAR = '$',
  POUND = '£',
  EURO = '€',
}

export enum ETimeUnits {
  Minutes = 'Minutes',
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
}

export enum EAutomationStatus {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE',
}

export enum ENodeStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface IAutomationState {
  automations?: IPaginatedResponse<IAutomation>;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  loadingAutomation: boolean;
  loadingAutomationHistory: boolean;
  loadingAutomationQueue: boolean;
  selectedAutomation?: IAutomation;
  selectedAutomationHistory?: IPaginatedResponse<IAutomationHistory>;
  selectedAutomationQueue?: IPaginatedResponse<IAutomationHistory>;
  duplicateNodeId?: string;
  lastNodesId: string[];
  mentions: IAutomationMention[];
  taskFields: IEntityFieldT[];
  connections: {
    loading: boolean;
    data: IConnection[];
  };
}

export interface IUpsertAutomation {
  businessId: string;
  automationId?: string;
  body: {
    id?: string;
    title: string;
    status: string;
    work_flow_data: [];
  };
}

export interface IAutomation {
  business_id: string;
  title: string;
  status: string;
  nodes: IAutomationNode[];
  id: string;
  updated_at: string;
  created_at: string;
}

export interface IMetaDataNodePayload {
  name: string;
  api_url: string;
  api_key: string;
  api_secret: string;
  list: string;
  action: EMailChimpAction;
  source_data: string[];
  prompt: string;
  proposal_theme: unknown;
  title: string;
  description: string;
  users: string; // potentially array of strings
  priority: unknown;
  status: unknown;
  to: string;
  subject: string;
  message: string;
  attachments: string; // potentially array of strings
  new_format: unknown;
  event_name: string;
  go_to: unknown;
  number_of_trees_to_plant: number;
  user_api_key: string;
  notification_title: string;
  notification_message: string;
  distribution: EDistributionType;
  webhook_url: string;
  method: EMethods;
  email: string;
  sending_number: string;
  connection_id: string;
  paths: {
    title: string;
    percentage: number;
  }[];
  event_parameters: {
    key: string;
    value: string;
  }[];
  query_parameters: {
    key: string;
    value: string;
  }[];
  more_settings?: {
    cc: string;
    bcc: string;
    reply_to: string;
    device: string;
    url: string;
    url_text: string;
    authentication: string;
    headers: {
      key: string;
      value: string;
    }[];
  };
  deadline: {
    deadline: unknown;
    time: number;
    range: unknown;
  };
  halt_workout: {
    value: boolean;
    action: unknown;
  };
  duration: {
    time: number;
    range: unknown;
  };
}

export interface IAutomationNode {
  id: string;
  node_type: ENodeType;
  logic: null; // TO BE DETERMINED YET
  meta_data: {
    x: number;
    y: number;
    type?: string;
  };
  payload?: IMetaDataNodePayload;
  rules: [];
  triggerable_type: EEntityType | string;
  previous_node_id?: string;
  business_id: string;
  automation_id: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  triggerable_id: string;
  action?: EAutomationNodeAction;
  title: string;
}

export interface IUpsertAutomationNodesPayload {
  businessId: string;
  automationId: string;
  body: {
    automation_nodes: IAutomationNode[];
  };
}

export interface INode {
  id: string;
  triggerable_type: EEntityType | string | undefined;
  title: string;
  triggerable_id: string | undefined;
  action: EAutomationNodeAction;
  node_type: ENodeType;
  payload?: IMetaDataNodePayload;
}

export interface IConnection {
  id: string;
  name: string;
  provider: EProvider;
  type: EAutomationNodeAction;
  credentials: {
    api_key: string;
    auth_token: string;
  };
  meta_data: {
    description: string;
    twilio_phone_number?: string;
    twilio_whatsapp_number?: string;
  };
  business_id: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IAutomationMention {
  id: string;
  name: string;
  node_id: string;
  field_type?: EFieldDataValueType;
  triggerId?: string;
}

export interface IGetAutomationHistoryPayload {
  businessId: string;
  automationId: string;
  params?: IPaginationParams;
}

export interface IAutomationHistory {
  id: string;
  entity_id: string;
  automation_id: string;
  current_execution_node_id: string;
  created_at: string;
  updated_at: string;
  entity: IEntity;
  node_histories: INodeHistory[];
}

export interface IEntity {
  id: string;
  business_id: string;
  entity_type_id: string;
  brand_id: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  form_id: string | null;
  created_by: string;
  is_confirmed: boolean;
  fields?: { [key: string]: IEntityField };
}

export interface IEntityField {
  title: string;
  value: string;
}

export interface INodeHistory {
  id: string;
  status: string;
  node_id: string;
  previous_node_history_id: string | null;
  entity_automation_history_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  response: INodeHistoryResponse;
}

export interface INodeHistoryResponse {
  node_id: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  response: any;
}
