import { useEffect, useMemo, useState } from 'react';
import { Chart as ChartComponent } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EGranularity, IFinancialReportData } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';
import { getFinancialReport } from 'store/services/report.service';

import 'chart.js/auto';

import { getDateFormat } from './helper';
dayjs.extend(customParseFormat);
interface IFinancialChartProps {
  granularity: EGranularity;
  dateRange: [Dayjs | null, Dayjs | null] | null;
}
function FinancialChart({ granularity, dateRange }: IFinancialChartProps) {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const [isLoading, setIsLoading] = useState(false);
  const { entityTypes } = useAppSelector((state) => state.entity);
  const [data, setData] = useState<IFinancialReportData[]>([]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityTypes.data.JOB?.id || !dateRange) return;
    setIsLoading(true);
    getFinancialReport({
      business_id: selectedBusiness?.id,
      start_date: dateRange?.[0]?.format('YYYY-MM-DD') || '',
      end_date: dateRange?.[1]?.format('YYYY-MM-DD') || '',
      granularity,
    }).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [selectedBusiness?.id, granularity, dateRange]);

  const chartsData = useMemo(() => {
    const labels = data.map((item) => getDateFormat(item.period, granularity));
    const datasets = [
      {
        label: translate('reports.financial_overview.received'),
        data: data.map((item) => item.payment_received),
      },
      {
        label: translate('reports.financial_overview.remaining'),
        data: data.map((item) => item.remaining_balances),
      },
      {
        label: translate('reports.financial_overview.total'),
        data: data.map((item) => item.total_revenue),
      },
    ];
    return {
      labels,
      datasets,
    };
  }, [data, getDateFormat, granularity, dateRange]);

  return (
    <>
      <Spin spinning={isLoading}> </Spin>

      {data.length > 0 && !isLoading && (
        <div className='chart-container'>
          <div className='chart'>
            <ChartComponent
              type={'bar'}
              data={chartsData}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: `${translate('reports.financial_overview.title')}`,
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default FinancialChart;
