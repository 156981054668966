import { useEffect, useMemo, useState } from 'react';
import { Chart as ChartComponent } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Select, Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, EGranularity, IEntitiesReportData, IEntityField } from '@aduvi/types';
import { toHumanReadable } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';
import { getEntityFields } from 'store/services/fields.service';
import { getEntitiesReport } from 'store/services/report.service';

import 'chart.js/auto';

import { ILayout } from '../GridLayout';

import { getDateFormat } from './helper';

dayjs.extend(customParseFormat);

interface IChartProps {
  granularity: EGranularity;
  dateRange: [Dayjs | null, Dayjs | null] | null;
  chartEntity: string;
  allowEntityChange?: boolean;
  onEntityTypeChange?: (entityType: string) => void;
  onJobTypeChange?: (jobType: 'leads' | 'bookings') => void;
  onDateFieldChange?: (dateField: string) => void;
  chartId?: string;
  jobType?: 'leads' | 'bookings';
  dateField?: string;
}
function Chart({
  granularity,
  dateRange,
  chartEntity,
  allowEntityChange = false,
  onEntityTypeChange,
  onJobTypeChange,
  onDateFieldChange,
  chartId,
  jobType = 'leads',
  dateField = 'created_at',
}: IChartProps) {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();
  const { entityTypes } = useAppSelector((state) => state.entity);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IEntitiesReportData[]>([]);
  const [entityType, setEntityType] = useState<string>(chartEntity);
  const [localJobType, setLocalJobType] = useState<'leads' | 'bookings'>(jobType);
  const [selectedDateField, setSelectedDateField] = useState<string>(dateField);
  const [fields, setFields] = useState<IEntityField[]>([]);

  const dateFieldOptions = useMemo(() => {
    return [
      { label: 'Created At', value: 'created_at' },
      ...fields
        .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES)
        .map((item) => ({
          label: toHumanReadable(item.title),
          value: item.id,
        })),
    ];
  }, [fields, selectedDateField]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityType) return;
    getEntityFields(selectedBusiness?.id, entityType).then((res) => {
      setFields(res.data);
    });
  }, [selectedBusiness?.id, entityType]);

  useEffect(() => {
    if (!selectedBusiness?.id || !chartEntity || !dateRange || !selectedDateField) return;
    setIsLoading(true);
    getEntitiesReport({
      business_id: selectedBusiness?.id,
      start_date: dateRange?.[0]?.format('YYYY-MM-DD') || '',
      end_date: dateRange?.[1]?.format('YYYY-MM-DD') || '',
      entity_type: entityType,
      date_argument: selectedDateField,
      granularity,
    }).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [selectedBusiness?.id, chartEntity, granularity, dateRange, entityType, selectedDateField]);

  const chartsData = useMemo(() => {
    return {
      labels: data.map((item) => getDateFormat(item.period, granularity)),
      datasets: [
        {
          label:
            chartEntity === entityTypes.data.JOB?.id
              ? jobType === 'leads'
                ? translate('reports.chart.leads')
                : translate('reports.chart.bookings')
              : toHumanReadable(entityTypes.entityTypes.find((item) => item.id === chartEntity)?.name || ''),
          data: data.map(
            (item) => item[chartEntity === entityTypes.data.JOB?.id ? (localJobType === 'leads' ? 'leads' : 'bookings') : 'total_entities'],
          ),
        },
      ],
    };
  }, [data, getDateFormat, granularity, dateRange, chartEntity, localJobType]);

  const handleEntityTypeChange = (value: string) => {
    setEntityType(value);
    onEntityTypeChange?.(value);

    if (chartId) {
      const savedLayouts = localStorage.getItem('grid-layout');
      if (savedLayouts) {
        try {
          const layouts = JSON.parse(savedLayouts);
          const updatedLayouts = {
            ...layouts,
            lg: layouts.lg.map((item: ILayout) => {
              if (item.i === chartId) {
                return {
                  ...item,
                  entityType: value,
                };
              }
              return item;
            }),
          };

          localStorage.setItem('grid-layout', JSON.stringify(updatedLayouts));
        } catch (error) {
          () => {};
        }
      }
    }
  };

  const handleJobTypeChange = (value: 'leads' | 'bookings') => {
    setLocalJobType(value);
    onJobTypeChange?.(value);
  };

  const handleDateFieldChange = (value: string) => {
    setSelectedDateField(value);
    onDateFieldChange?.(value);
  };

  return (
    <>
      <Spin spinning={isLoading}> </Spin>

      {data.length > 0 && !isLoading && (
        <div className='chart-container'>
          {allowEntityChange && (
            <Select size={'small'} style={{ width: 150 }} value={entityType} onChange={(value) => handleEntityTypeChange(value)}>
              {entityTypes.entityTypes.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {toHumanReadable(item.name)}
                </Select.Option>
              ))}
            </Select>
          )}
          {entityType === entityTypes.data.JOB?.id && allowEntityChange && (
            <Select size={'small'} style={{ width: 150 }} value={localJobType} onChange={(value: 'leads' | 'bookings') => handleJobTypeChange(value)}>
              <Select.Option value='leads'>{translate('reports.chart.leads')}</Select.Option>
              <Select.Option value='bookings'>{translate('reports.chart.bookings')}</Select.Option>
            </Select>
          )}
          <Select size={'small'} style={{ width: 150 }} value={selectedDateField} onChange={handleDateFieldChange} options={dateFieldOptions} />
          <div className='chart'>
            <ChartComponent
              type={'bar'}
              data={chartsData}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: `Total ${
                      entityType === entityTypes.data.JOB?.id
                        ? localJobType === 'leads'
                          ? translate('reports.chart.leads')
                          : translate('reports.chart.bookings')
                        : toHumanReadable(entityTypes.entityTypes.find((item) => item.id === entityType)?.name || '')
                    } by month created- Total ${
                      entityType === entityTypes.data.JOB?.id
                        ? localJobType === 'leads'
                          ? translate('reports.chart.leads')
                          : translate('reports.chart.bookings')
                        : toHumanReadable(entityTypes.entityTypes.find((item) => item.id === entityType)?.name || '')
                    }:${data.reduce(
                      (acc, item) =>
                        acc + item[entityType === entityTypes.data.JOB?.id ? (localJobType === 'leads' ? 'leads' : 'bookings') : 'total_entities'],
                      0,
                    )}`,
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Chart;
