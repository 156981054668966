import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'antd';
import './SelectNode.scss';

import DisableWrapper from '@aduvi/components/PersonalizedViewComponents/DraggableFields/draggable-column/DisableWrapper';
import { EAutomationNodeAction, INode } from '@aduvi/types/automation';

import { NodeCard } from './NodeCard';

interface IProps {
  data: INode[][];
  canBeLastNode: boolean;
  onNodeSelect: (data: INode) => void;
  disabledTriggers?: string[];
}

export const SelectNode = ({ data, canBeLastNode, onNodeSelect, disabledTriggers = [] }: IProps) => {
  const { t: translate } = useTranslation();
  const [search, setSearch] = useState('');

  return (
    <div className='select-node-wrapper'>
      <Input.Search placeholder={translate('automations.searchTriggers')} onChange={(e) => setSearch(e.target.value)} />
      {data.map((group) => {
        return group
          .filter((node) => node.title.toLowerCase().includes(search.toLowerCase()))
          .filter((node) => (!canBeLastNode && node.action !== EAutomationNodeAction.IF_ELSE) || canBeLastNode)
          .filter((node) => (!canBeLastNode && node.action !== EAutomationNodeAction.SPLIT) || canBeLastNode)
          .map((node, index) => {
            const isDisabled = disabledTriggers.includes(node.id);
            return (
              <DisableWrapper key={node.id} disabled={isDisabled}>
                <NodeCard
                  key={node.id}
                  id={node.id}
                  triggerable_type={node.triggerable_type}
                  title={node.title}
                  triggerable_id={node.triggerable_id}
                  action={node.action}
                  node_type={node.node_type}
                  index={index}
                  onClick={() => !isDisabled && onNodeSelect(node)}
                />
              </DisableWrapper>
            );
          });
      })}
    </div>
  );
};
