import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Empty, Row } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType } from '@aduvi/types';

import { getEntityFiles } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { FileCard } from './card/FileCard';

const extractFileNameFromUrl = (url: string) => {
  const parts = url?.split('/uploads/');
  if (parts?.length === 2) {
    return parts[1];
  }
  return undefined;
};

export const Files = ({ fullscreen = false }: { fullscreen?: boolean }) => {
  const {
    entities: {
      selectedEntity,
      files: { invoices, contracts },
    },
  } = useAppSelector((state) => state.entity);

  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const fileFields = selectedEntity?.custom_fields?.filter((item) => item?.value_type === EFieldDataValueType.FIELD_DATA_FILES);

  useEffect(() => {
    if (!selectedBusiness?.id || !selectedEntity?.id || !selectedEntity?.entity_type_id) return;

    dispatch(
      getEntityFiles({
        businessId: selectedBusiness?.id,
        entityId: selectedEntity?.id,
        entityTypeId: selectedEntity?.entity_type_id,
      }),
    );
  }, [selectedBusiness?.id, selectedEntity?.id, selectedEntity?.entity_type_id]);

  return (
    <>
      {contracts?.length || invoices?.length || fileFields?.filter((item) => item.field_data?.[0]?.url).length ? (
        <Row>
          {contracts?.map((contract) => (
            <Col span={fullscreen ? 6 : 12} key={contract.id}>
              <FileCard title={contract.contract_template?.title} tag={contract.latest_status} color='#FFF1CF' />
            </Col>
          ))}

          {invoices?.map((invoice) => (
            <Col span={fullscreen ? 6 : 12} key={invoice.id}>
              <FileCard
                title={invoice.invoice_number}
                tag={translate(`billing.invoices.statusType.${invoice.invoice_status}`)}
                color={invoice.invoice_status === 'PAID_FULLY' ? '#4CAF50' : '#FFF1CF'}
              />
            </Col>
          ))}

          {fileFields
            ?.filter((item) => item.field_data?.[0]?.url)
            .map((item) => (
              <Col span={fullscreen ? 6 : 12} key={item.id}>
                <FileCard title={extractFileNameFromUrl(item.field_data?.[0]?.url)} tag={item.weight.toString()} color='#FFF1CF' />
              </Col>
            ))}
        </Row>
      ) : (
        <Col>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      )}
    </>
  );
};
