import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Avatar, Button, Col, Divider, Row } from 'antd';
import './EditRowLayoutDrawer.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { DroppableArea } from '@aduvi/components/PersonalizedViewComponents/DraggableFields/droppable-area/DroppableArea';
import { DEFAULT_CELL_STYLE } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';
import {
  EActionButtonTitle,
  EPersonalizedViewOrigin,
  EReferenceEntityType,
  EShiftFormat,
  ICustomFieldSpecificStyle,
  ICustomFieldViewStyle,
  IEntityField,
  IQuote,
} from '@aduvi/types';
import { IActionButton, ICustomField, IEntityWithFields } from '@aduvi/types/entity';
import { toCapitalize, toHumanReadable } from '@aduvi/utils/helper';

import { editPersonalizedView } from 'store/features/personalized-views-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetEntityFieldsByMultipleIdsQuery } from 'store/rtk-slice/fields-slice';
import { getQuotes } from 'store/services/quote.service';

import { DraggableColumn } from '../DraggableFields/draggable-column/DraggableColumn';
import { draggableQuoteFields } from '../QuotesTable/helper';
import { useQuoteFieldValue } from '../QuotesTable/hooks/useQuoteFieldValue';

interface IEditRowLayout {
  origin: EPersonalizedViewOrigin;
  columns?: ICustomFieldViewStyle[];
  data?: IEntityWithFields[];
  droppedActions: IActionButton[];
  isDragging?: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  managementFields?: IEntityField[];
  onCancel: () => void;
  onClose: () => void;
}

export const EditRowLayoutDrawer = ({
  onClose,
  columns,
  onCancel,
  data,
  isDragging,
  setIsDragging,
  droppedActions,
  managementFields,
  origin,
}: IEditRowLayout) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { selectedViewId, selectedView, editing } = useAppSelector((state) => state.personalizedViews);
  const { users } = useAppSelector((state) => state.user);
  const { services } = useAppSelector((state) => state.services);
  const { products } = useAppSelector((state) => state.products);
  const { settings } = useAppSelector((state) => state.shiftScheduler);
  const { action_buttons } = useAppSelector((state) => state.entity.entityTypes);

  const baseDisabledButtons = [
    'View Invoice',
    'Custom Action',
    'Disable / Enable Notifications',
    'Send Client Portal Link',
    'Recalculate Route',
    'View Route',
    'View Contract',
    'Enable / Disable Payments',
  ];

  const disabledButtons = [
    ...baseDisabledButtons,
    ...(origin === EPersonalizedViewOrigin.BOOKINGS || origin === EPersonalizedViewOrigin.ORDERS ? [] : [EActionButtonTitle.ADD_PAYMENT]),
    ...(origin === EPersonalizedViewOrigin.BOOKINGS || origin === EPersonalizedViewOrigin.ORDERS ? [] : [EActionButtonTitle.VIEW_CLIENT_PORTAL]),
  ];

  const [quotes, setQuotes] = useState<IQuote[]>([]);

  const referenceEntityIds = managementFields
    ?.filter((field) => field?.reference_entity_type)
    ?.map((field) => ({ entity_type_id: field?.reference_entity_type_id, entity_name: field?.place_holder || field?.reference_entity_type }));

  const { data: referenceEntities, isLoading } = useGetEntityFieldsByMultipleIdsQuery(
    {
      businessId: selectedBusiness?.id!,
      entityTypeIds: referenceEntityIds!,
    },
    { skip: !selectedBusiness || !referenceEntityIds },
  );

  const onUpdateColumns = () => {
    if (selectedBusiness?.id && selectedViewId && selectedView)
      dispatch(
        editPersonalizedView({
          business_id: selectedBusiness?.id,
          personalizedViewId: selectedViewId,
          body: { ...selectedView, style: JSON.stringify(columns), menu_items: JSON.stringify(droppedActions) },
        }),
      )
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch(() => {});
  };

  useEffect(() => {
    if (!data?.[0]?.business_id || !data?.[0]?.id) return;

    getQuotes(data?.[0]?.business_id, data?.[0]?.id).then((res) => {
      setQuotes(res.data);
    });
  }, [data?.[0]?.business_id, data?.[0]?.id]);

  return (
    <div className='edit-row-layout-drawer'>
      <DroppableArea id='droppableContainer'>
        <>
          <Row align={'middle'} className='header' justify={'space-between'}>
            <Row align='middle'>
              <CloseOutlined className='close-button' style={{ width: '16px', height: '16px', cursor: 'pointer' }} onClick={onCancel} />
              <Paragraph className='title mb-0'>{translate('contacts.editRowLayoutDrawer.addField')}</Paragraph>
              <Paragraph className='description mb-0'>{translate('contacts.editRowLayoutDrawer.dragTheFields')}</Paragraph>
            </Row>

            <Row>
              <Button className='mr-10' onClick={onCancel}>
                {translate('contacts.editRowLayoutDrawer.close')}
              </Button>
              <Button disabled={editing} type='primary' onClick={onUpdateColumns}>
                {translate('contacts.editRowLayoutDrawer.ok')}
              </Button>
            </Row>
          </Row>
          <Divider />
          <div
            style={{ overflowY: isDragging ? 'visible' : 'auto' }}
            className='fields_wrapper'
            onMouseDown={() => setIsDragging(true)}
            onMouseUp={() => setIsDragging(false)}>
            <Col className='content mb-20'>
              <Paragraph className='details color-blue-6'>
                {translate('contacts.editRowLayoutDrawer.details', {
                  origin: origin.toLowerCase() === 'bookings' ? 'Booking' : toCapitalize(origin),
                })}
              </Paragraph>

              <Row gutter={[16, 16]}>
                {managementFields &&
                  managementFields
                    ?.filter((field) => !field?.reference_entity_type && !field?.hidden_in_management_fields)
                    ?.map((fieldValue) => {
                      const field = data?.[0]?.custom_fields?.find((item) => item?.id === fieldValue?.id);
                      return (
                        <DraggableColumn
                          key={fieldValue?.id}
                          id={fieldValue?.id}
                          title={fieldValue?.title}
                          field={field || (fieldValue as unknown as ICustomField)}
                          record={data?.[0]}
                        />
                      );
                    })}
              </Row>
            </Col>
            {!isLoading &&
              referenceEntities?.data?.map((entity) => (
                <Col className='content mb-20' key={entity?.entity_id}>
                  <Paragraph className='details color-blue-6'>
                    {translate('contacts.editRowLayoutDrawer.details', { origin: toCapitalize(entity?.entity_name) })}
                  </Paragraph>
                  <Row gutter={[16, 16]}>
                    {entity?.custom_fields?.length === 0 &&
                      data?.[0]?.custom_fields
                        ?.filter(
                          (el) => el?.reference_entity_type === EReferenceEntityType.SHIFT && el?.reference_entity_type_id === entity?.entity_id,
                        )
                        ?.map((shiftField) => {
                          const column = columns?.find((col) => col.field_id?.includes(shiftField.id));
                          const format =
                            (column as unknown as ICustomFieldSpecificStyle)?.[shiftField.id]?.style?.shiftFormat?.toString() ??
                            DEFAULT_CELL_STYLE.shiftFormat;

                          const formatShiftTime = (time: string) => {
                            return time.replace(':00am', 'am').replace(':00pm', 'pm');
                          };

                          let shiftsContent;

                          if (format === EShiftFormat.TEXT) {
                            shiftsContent = (
                              <div className='flex'>
                                {shiftField?.field_data?.map((shift, index) => {
                                  const user = users?.data?.find((u) => u?.id === shift?.user_id);
                                  return user ? (
                                    <span key={index}>{`${user?.first_name}${index < shiftField?.field_data?.length - 1 ? ', ' : ''}`}</span>
                                  ) : null;
                                })}
                              </div>
                            );
                          } else if (format === EShiftFormat.CIRCLE_IMAGE) {
                            shiftsContent = (
                              <div className='flex'>
                                {shiftField?.field_data?.map((shift, index) => {
                                  const user = users?.data?.find((u) => u?.id === shift?.user_id);
                                  if (!user) return null;
                                  const { first_name, last_name, profile_picture } = user;
                                  return (
                                    <Avatar key={index} src={profile_picture} style={{ backgroundColor: !profile_picture ? '#f56a00' : undefined }}>
                                      {!profile_picture && `${first_name[0]}${last_name[0]}`}
                                    </Avatar>
                                  );
                                })}
                              </div>
                            );
                          } else if (format === EShiftFormat.LIST) {
                            shiftsContent = (
                              <div className='flex flex-direction-column'>
                                {shiftField?.field_data?.map((shift, index) => {
                                  const user = users?.data?.find((u) => u?.id === shift?.user_id);
                                  if (!user) return null;

                                  const service = services?.data?.find((s) => s?.id === shift?.assigned_id);
                                  const product = products?.data?.find((p) => p?.id === shift?.assigned_id);
                                  const position = settings?.positions?.find((p) => p?.id === shift?.assigned_id);

                                  const assignedTo = service?.name || product?.name || position?.position;
                                  const timeRange =
                                    shift?.start_shift && shift?.end_shift
                                      ? formatShiftTime(`${dayjs(shift.start_shift).format('h:mma')} - ${dayjs(shift.end_shift).format('h:mma')}`)
                                      : '';

                                  return (
                                    <div key={index} className='flex flex-direction-column'>
                                      <div className='color-black'>{user?.first_name}</div>
                                      <div style={{ gap: '5px' }} className='flex justify-between'>
                                        <span className='color-gray-5'>{assignedTo}</span>
                                        <span className='color-gray-5'>{timeRange}</span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }

                          return (
                            <DraggableColumn
                              key={shiftField.id}
                              id={shiftField.id}
                              title={
                                (
                                  <div>
                                    <span>{translate('settings.shiftScheduler.shifts')}</span>
                                    <div className='mt-5'>{shiftsContent}</div>
                                  </div>
                                ) as unknown as string
                              }
                              field={undefined}
                              record={undefined}
                            />
                          );
                        })}
                    {entity?.custom_fields?.map((fieldValue) => {
                      return (
                        <DraggableColumn
                          key={fieldValue?.id}
                          id={fieldValue?.id}
                          title={`${toHumanReadable(entity?.entity_name)} ${fieldValue?.title}`}
                          field={
                            data?.[0]?.custom_fields
                              ?.filter((field) => field?.reference_entity_type_id === entity?.entity_id)?.[0]
                              ?.field_data?.[0]?.custom_fields?.find((customField) => customField?.id === fieldValue?.id) ||
                            (fieldValue as unknown as ICustomField)
                          }
                          record={
                            data?.[0]?.custom_fields?.filter((field) => field?.reference_entity_type_id === entity?.entity_id)?.[0]
                              ?.field_data?.[0] as unknown as IEntityWithFields
                          }
                        />
                      );
                    })}
                  </Row>
                </Col>
              ))}

            <Col className='content'>
              <Paragraph className='details color-blue-6 mt-10'> {translate('action-buttons.actions')}</Paragraph>
              <Row gutter={[16, 16]}>
                {action_buttons &&
                  action_buttons
                    ?.filter((button) => {
                      if (button.title === EActionButtonTitle.ADD_PAYMENT) {
                        return origin === EPersonalizedViewOrigin.BOOKINGS || origin === EPersonalizedViewOrigin.ORDERS;
                      }
                      return true;
                    })
                    .map((action_button) => {
                      const isDisabled = !disabledButtons.includes(action_button?.title);
                      const isAddPayment = action_button?.title === EActionButtonTitle.ADD_PAYMENT;

                      return (
                        <DraggableColumn
                          key={action_button?.id}
                          id={action_button?.id}
                          title={
                            (
                              <div className='flex flex-direction-column' style={{ gap: '10px' }}>
                                {(!isAddPayment || (isAddPayment && (origin.toLowerCase() === 'bookings' || origin.toLowerCase() === 'orders'))) && (
                                  <span>{isDisabled ? action_button?.description : action_button.description + ' - Coming soon'}</span>
                                )}
                                <Button size='small' type={isDisabled ? 'primary' : 'default'} className='text-left' disabled={!isDisabled}>
                                  {action_button?.title}
                                </Button>
                              </div>
                            ) as unknown as string
                          }
                          field={undefined}
                          record={undefined}
                          disabled={isDisabled}
                        />
                      );
                    })}
              </Row>
            </Col>
            <Col className='content'>
              <Paragraph className='details color-blue-6 mt-10'>{translate('formatFields.quote')}</Paragraph>
              <Row gutter={[16, 16]}>
                {draggableQuoteFields.map((field) => {
                  const quoteValue = useQuoteFieldValue(field?.id, quotes);
                  const displayQuoteValue = quoteValue || field?.value;

                  return (
                    <DraggableColumn
                      key={field.id}
                      id={field?.id}
                      title={
                        (
                          <div className='flex flex-direction-column'>
                            <span>{field?.title}</span>
                            <span className='text-left fs-14 color-black mt-5'>{displayQuoteValue}</span>
                          </div>
                        ) as unknown as string
                      }
                      field={undefined}
                      record={undefined}
                    />
                  );
                })}
              </Row>
            </Col>
          </div>
        </>
      </DroppableArea>
    </div>
  );
};
