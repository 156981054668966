import React from 'react';
import { EditOutlined, EllipsisOutlined, EyeOutlined } from '@ant-design/icons';

import { Card, CardProps, Col, Row, Typography } from 'antd';
import './FileCard.scss';

interface IProps extends CardProps {
  title?: string;
  tag?: string;
  color?: string;
}

export const FileCard = ({ title, tag, color, ...props }: IProps) => {
  return (
    <Card
      {...props}
      bordered={false}
      className='edit-job-file-card'
      actions={[<EyeOutlined key='view' />, <EditOutlined key='edit' />, <EllipsisOutlined key='ellipsis' />]}>
      <Row wrap={false}>
        <Col style={{ flexGrow: 1 }}>
          <Typography.Paragraph className='title'>{title}</Typography.Paragraph>
          <Typography.Paragraph className='description' style={{ backgroundColor: color }}>
            {tag}
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
