import axios from '@aduvi/config/axios';
import { IPayment, IResponse } from '@aduvi/types';
import { ICreateManualPaymentPayload, IManualPaymentOption } from '@aduvi/types/manual-payment';

export const createManualPaymentOption = async (payload: { business_id: string; title: string }): Promise<IResponse<IManualPaymentOption>> => {
  return await axios.post(`/businesses/${payload.business_id}/manual-payment-options`, { title: payload.title });
};

export const updateManualPaymentOption = async (payload: {
  business_id: string;
  id: string;
  title: string;
}): Promise<IResponse<IManualPaymentOption>> => {
  return await axios.put(`/businesses/${payload.business_id}/manual-payment-options/${payload.id}`, { title: payload.title });
};

export const getManualPaymentOptionById = async (payload: { business_id: string; id: string }): Promise<IResponse<IManualPaymentOption>> => {
  return await axios.get(`/businesses/${payload.business_id}/manual-payment-options/${payload.id}`);
};

export const getAllManualPaymentOptions = async (business_id: string): Promise<IResponse<IManualPaymentOption[]>> => {
  return await axios.get(`/businesses/${business_id}/manual-payment-options`);
};

export const deleteManualPaymentOption = async (payload: { business_id: string; id: string }): Promise<IResponse<{ success: boolean }>> => {
  return await axios.delete(`/businesses/${payload.business_id}/manual-payment-options/${payload.id}`);
};

export const createManualPayment = async (payload: {
  business_id: string;
  quoteId: string;
  body: ICreateManualPaymentPayload;
}): Promise<IResponse<IPayment>> => {
  return await axios.post(`/businesses/${payload.business_id}/payments/transactions/${payload.quoteId}?payment_method=MANUAL_PAYMENT`, payload.body);
};
