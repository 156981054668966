import { IBusiness } from './business';
import { ELoadingState } from './common';
import { IUser } from './user';

export enum EAuthorized {
  UNINITIALIZED = 0,
  AUTHORIZED = 1,
  UNAUTHORIZED = 2,
}

export interface IAuthState {
  authActionState: ELoadingState;
  token: string | null;
  refreshToken: string | null;
  isAuthorized: EAuthorized;
  loginState: ELoadingState;
  resetPasswordState: ELoadingState;
  forgetPasswordState: ELoadingState;
  validateTokenState: ELoadingState;
  registerState: ELoadingState;
  resendVerificationEmailState: ELoadingState;
  resendVerificationCodeState: ELoadingState;
  acceptInvitationState: ELoadingState;

  user: {
    user?: ICurrentUser;
    loading: boolean;
    updating: boolean;
    resettingPassword: boolean;
  };
}

export interface ILoginPayload {
  email?: string;
  password?: string;
  otp?: string;

  auth_type: 'GOOGLE' | 'MICROSOFT' | undefined;
  access_token?: string;
  token_type?: string;
}

export interface ILoginResponse {
  access_token: string;
  refresh_token: string;
  email_verified_at: Date | string;
}

export interface IRegisterPayload {
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  password: string;
  confirm_password: string;
}

export interface IRegisterResponse {
  access_token: string;
}

export interface IForgotPasswordPayload {
  email: string;
}

export interface IForgotPasswordResponse {
  status: string;
}

export interface IValidateTokenPayload {
  email: string;
  token: string;
}

export interface IValidateAndResetPasswordResponse {
  message: string;
}

export interface IResetPasswordPayload {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export interface ICurrentUser {
  created_at: Date | string;
  deleted_at: Date | string;
  updated_at: Date | string;
  email_verified_at: Date | string;
  email: string;
  id: string;
  name: string;
  first_name?: string;
  last_name?: string;
  phone_number: string;
  profile_picture: string;
  role: number;
  is_2fa_enabled?: boolean;
  partner_id: string;
  businesses: IBusiness[];
}

export interface IVerifyEmailPayload {
  email: string;
  email_verification_token: string;
}

export interface IUpdateUserDataPayload {
  id: string;
  body: IUser;
}

export interface IUpdateUser2FaPayload {
  id: string;
  otp?: string;
}

export interface IChangePasswordPayload {
  old_password: string;
  password: string;
  password_confirmation: string;
}

export interface IAcceptInvitationPayload {
  email: string;
  invitation_code: string;
  password?: string;
  password_confirmation?: string;
}

export interface IAcceptInvitationResponse {
  is_new_user: boolean;
  user?: IUser;
  access_token?: string;
}
