import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { Card, Col, Row, Select, Spin, Typography } from 'antd';
import './Statistics.scss';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';

import { PERIOD_OPTIONS, useStatistics } from './useGetStatistics';

export const Statistics = () => {
  const { t: translate } = useTranslation();
  const { stats, isLoading, period, setPeriod } = useStatistics();

  return (
    <div className='statistics-widget'>
      <Select
        value={period}
        onChange={setPeriod}
        rootClassName='period-select'
        className='mb-15 period-select'
        style={{ width: 200, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        {PERIOD_OPTIONS.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {translate(option.label)}
          </Select.Option>
        ))}
      </Select>

      <Row
        wrap={false}
        className='block-container'
        gutter={[16, 16]}
        onWheel={(e) => {
          const container = e?.currentTarget;
          container.scrollLeft += e?.deltaY;
        }}>
        <Col span={3}>
          <StatisticsBlock
            loading={isLoading}
            title={translate('dashboard.widgets.statistics.leads')}
            value={stats?.number_of_leads?.current_period_count?.toString() || '0'}
            change={Number(stats?.number_of_leads?.growth_percentage?.toFixed(1)) || 0}
            previousPeriod={stats?.number_of_leads?.previous_period_count || 0}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            loading={isLoading}
            title={translate('dashboard.widgets.statistics.conversion')}
            value={`${stats?.conversion_rate?.current_period_percentage?.toFixed(2)}%` || '0%'}
            change={Number(stats?.conversion_rate?.growth_percentage?.toFixed(1)) || 0}
            previousPeriod={Number(stats?.conversion_rate?.previous_period_percentage?.toFixed(2)) || 0}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            loading={isLoading}
            title={translate('dashboard.widgets.statistics.customers')}
            value={stats?.number_of_customers?.current_period_count?.toString() || '0'}
            change={Number(stats?.number_of_customers?.growth_percentage?.toFixed(1)) || 0}
            previousPeriod={stats?.number_of_customers?.previous_period_count || 0}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.transactions')}
            value={stats?.average_transactions_per_customer?.current_period_avg?.toString() || '0'}
            change={Number(stats?.average_transactions_per_customer?.growth_percentage?.toFixed(1)) || 0}
            previousPeriod={stats?.average_transactions_per_customer?.previous_period_avg || 0}
            loading={isLoading}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.saleValue')}
            value={stats?.average_sale_value?.current_period?.toString() || '0'}
            change={Number(stats?.average_sale_value?.growth_percentage?.toFixed(1)) || 0}
            previousPeriod={Number(stats?.average_sale_value?.previous_period?.toFixed(2)) || 0}
            loading={isLoading}
            isPrice={true}
          />
        </Col>

        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.revenue')}
            value={stats?.total_revenue?.current_period?.toString() || '0'}
            change={Number(stats?.total_revenue?.growth_percentage?.toFixed(1)) || 0}
            previousPeriod={Number(stats?.total_revenue?.previous_period) || 0}
            loading={isLoading}
            isPrice={true}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.margin')}
            value='1000'
            change={10}
            previousPeriod={300}
            loading={isLoading}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.profit')}
            value={stats?.profit?.current_period_profit?.toString() || '0'}
            change={Number(stats?.profit?.growth_percentage?.toFixed(1)) || 0}
            previousPeriod={Number(stats?.profit?.previous_period_profit) || 0}
            isPrice={true}
            loading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

const StatisticsBlock = ({
  title,
  value,
  change,
  previousPeriod,
  loading,
  isPrice,
}: {
  title: string;
  value: string;
  change: number;
  previousPeriod: number;
  loading: boolean;
  isPrice?: boolean;
}) => {
  const formatNumber = (value: string) => {
    const numValue = parseFloat(value);
    if (isPrice && numValue >= 1000) {
      return (numValue / 1000).toFixed(1);
    }
    return value;
  };

  return (
    <Spin spinning={loading} wrapperClassName='statistics-spinner'>
      <Card style={{ height: '100%', borderRadius: 20, backgroundColor: change === 0 ? undefined : change > 0 ? '#F0FDF4' : '#FEF3F2' }}>
        <Typography.Paragraph className='mb-0' type='secondary'>
          {title}
        </Typography.Paragraph>
        <Typography.Paragraph className='mb-0 mt-0 aduvi-title'>
          {isPrice ? (
            <>
              <PriceDisplay price={formatNumber(value)} />
              {parseFloat(value) >= 1000 && 'K'}
            </>
          ) : (
            value
          )}
        </Typography.Paragraph>
        {change === 0 ? (
          <StatisticsCardInfo previousPeriod={previousPeriod} change={change} isPrice={isPrice} />
        ) : change > 0 ? (
          <StatisticsCardInfo previousPeriod={previousPeriod} change={change} type='success' isPrice={isPrice} />
        ) : (
          <StatisticsCardInfo previousPeriod={previousPeriod} change={change} type='danger' isPrice={isPrice} />
        )}
      </Card>
    </Spin>
  );
};

const StatisticsCardInfo = ({
  previousPeriod,
  change,
  type,
  isPrice,
}: {
  previousPeriod: number;
  change: number;
  type?: 'success' | 'danger';
  isPrice?: boolean;
}) => {
  const formatNumber = (value: number) => {
    if (isPrice && value >= 1000) {
      return value / 1000;
    }
    return value;
  };

  return (
    <Row>
      <Typography.Paragraph type={type ? type : 'secondary'} className='mb-0 flex items-center flex-wrap fs-12 '>
        {isPrice ? (
          <>
            <PriceDisplay price={formatNumber(previousPeriod)} />
            {previousPeriod >= 1000 && 'K'}
          </>
        ) : (
          previousPeriod
        )}
        <Typography.Text type={type} className='ml-10 fs-12'>
          <Row wrap={false} className='fs-12'>
            {type ? type === 'success' ? <CaretUpOutlined className='fs-13' /> : <CaretDownOutlined className='fs-12' /> : null} {change}%
          </Row>
        </Typography.Text>
      </Typography.Paragraph>
    </Row>
  );
};
