import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, FormInstance, Input, InputNumber, Row, Select, Switch } from 'antd';
import './ActionFormItems.scss';

import DisableWrapper from '@aduvi/components/PersonalizedViewComponents/DraggableFields/draggable-column/DisableWrapper';
import { SelectUser } from '@aduvi/components/SelectUser/SelectUser';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { EFieldDataValueType, EReferenceEntityType } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const CreateTask = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  const { taskFields } = useAppSelector((state) => state.automation);

  const titleField = useMemo(() => taskFields?.find((item) => item.core && item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS), [taskFields]);
  const descriptionField = useMemo(
    () => taskFields?.find((item) => item.core && item.value_type === EFieldDataValueType.FIELD_DATA_LONG_TEXTS),
    [taskFields],
  );
  const assignToField = useMemo(
    () =>
      taskFields?.find(
        (item) =>
          item.core && item.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE && item.reference_entity_type === EReferenceEntityType.TEAM_USER,
      ),
    [taskFields],
  );

  const priorityField = useMemo(
    () => taskFields?.find((item) => item.core && item.value_type === EFieldDataValueType.FIELD_DATA_LISTS && item.title === 'Priority'),
    [taskFields],
  );

  const statusField = useMemo(
    () => taskFields?.find((item) => item.core && item.value_type === EFieldDataValueType.FIELD_DATA_LISTS && item.title === 'Status'),
    [taskFields],
  );

  return (
    <>
      <Form.Item name={['original_custom_fields', 0, String(titleField?.id)]} label={translate('automations.title')}>
        <Input />
      </Form.Item>
      <Form.Item name={['original_custom_fields', 1, String(descriptionField?.id)]} label={translate('automations.description')}>
        <Tiptap
          content={form.getFieldValue(['original_custom_fields', 1, String(descriptionField?.id)])}
          onUpdate={(content) => form.setFieldValue(['original_custom_fields', 1, String(descriptionField?.id)], content.getHTML())}
        />
      </Form.Item>
      <Form.Item name={['original_custom_fields', 2, String(assignToField?.id)]} label={translate('automations.assignTo')}>
        <SelectUser placeholder={translate('automations.typeOrInsert')} />
      </Form.Item>
      <Row justify={'space-between'} gutter={18}>
        <Col span={12}>
          <Form.Item
            name={['original_custom_fields', 3, String(priorityField?.id)]}
            label={translate('automations.priority')}
            getValueFromEvent={(value) => {
              return [value];
            }}>
            <Select
              placeholder={translate('automations.normal')}
              options={priorityField?.list_values.map((item) => ({
                label: item.value,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['original_custom_fields', 4, String(statusField?.id)]}
            label={translate('automations.status')}
            getValueFromEvent={(value) => {
              return [value];
            }}>
            <Select
              placeholder={translate('automations.none')}
              options={statusField?.list_values.map((item) => ({
                label: item.value,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <DisableWrapper disabled={true}>
        <div>{translate('automations.deadline')}</div>
        <Row justify={'space-between'} gutter={18} className='mt-10'>
          <Col span={12}>
            <Form.Item name={['deadline', 'deadline']}>
              <Select placeholder={translate('automations.beforeEventDate')} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={['deadline', 'time']}>
              <InputNumber className='w-full' placeholder='3' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={['deadline', 'range']}>
              <Select placeholder={translate('automations.days')} />
            </Form.Item>
          </Col>
        </Row>
        <span className='action-form-items'>
          <Row justify={'space-between'} gutter={18} className='mt-15' align={'middle'}>
            <Col span={16} className='flex flex-direction-row'>
              <Form.Item name={['halt_workout', 'value']}>
                <Switch />
              </Form.Item>
              <span className='mt-5 ml-15'>{translate('automations.haltTheWorkFlow')}</span>
            </Col>
            <Col span={8}>
              <Form.Item name={['halt_workout', 'action']}>
                <Select className='w-full' placeholder={translate('automations.completed')} />
              </Form.Item>
            </Col>
          </Row>
        </span>
      </DisableWrapper>
    </>
  );
};
