import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import { Button, Col, Divider, Dropdown, FormInstance, Row } from 'antd';
import './Widget.scss';

import { IEntityField } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';

import { bookingFormDropdownItems, IBookingFormFieldProps } from '../helper';

import { WidgetFields } from './WidgetFields';

interface IProps {
  fields: IBookingFormFieldProps[];
  entityFields: IEntityField[];
  form: FormInstance;
  isDragging: boolean;
  entityTypeId?: string;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
  onAddField: (element: IBookingFormFieldProps, index: number) => void;
  onCreateNewFieldDrawer: (index: number) => void;
  onCreateAsset: (assetType: IEntityType, index: number) => void;
  onCloseWidget: () => void;
}

export const Widget = ({
  entityFields,
  fields,
  form,
  isDragging,
  entityTypeId,
  onAddField,
  onCloseWidget,
  onCreateAsset,
  onCreateNewFieldDrawer,
  setFields,
  setIsDragging,
}: IProps) => {
  const { t: translate } = useTranslation();

  const { entityTypes } = useAppSelector((state) => state.entity);

  const entityTypeHasQuotes = Boolean(Object.values(entityTypes.data).find((item) => item?.id === entityTypeId)?.has_quotes);

  return (
    <Col className='w-full widget-wrapper pt-30 pb-30'>
      <Col style={{ width: '45%', marginLeft: '28%' }}>
        <Row className='add-field-wrapper flex-center'>
          <Divider dashed orientation='center' className={fields.length === 0 ? '' : 'add-field-divider'}>
            <Dropdown
              overlayClassName='booking-form-dropdown'
              menu={{
                items: bookingFormDropdownItems(
                  0,
                  fields,
                  entityFields,
                  form,
                  entityTypeHasQuotes,
                  entityTypes.entityTypes,
                  true,
                  onAddField,
                  onCreateNewFieldDrawer,
                  onCreateAsset,
                ),
              }}
              trigger={['click']}
              placement='bottomCenter'>
              <Button icon={<PlusOutlined />} type='primary' style={{ borderRadius: '5px' }} size='small' />
            </Dropdown>
          </Divider>
        </Row>
        <Row>
          <WidgetFields
            form={form}
            isDragging={isDragging}
            fields={fields}
            entityTypeHasQuotes={entityTypeHasQuotes}
            setFields={setFields}
            setIsDragging={setIsDragging}
            onAddField={onAddField}
            onCreateNewFieldDrawer={onCreateNewFieldDrawer}
            onCreateAsset={onCreateAsset}
          />
        </Row>
      </Col>
      <Row className='w-full mb-20'>
        <Col span={8} />
        <Col span={8} className='flex-center'>
          <Button type='primary'>{translate('forms.forms.checkAvailability')}</Button>
        </Col>
      </Row>
      <Row justify={'end'}>
        <Button type='text' className='enable-widget mr-20' onClick={onCloseWidget}>
          {translate('forms.forms.disableWidget')}
        </Button>
      </Row>
    </Col>
  );
};
