import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Col, Row } from 'antd';

import { getFieldInlineStyles, getLabelAlignmentStyles } from '@aduvi/components/CustomField/CustomFieldValue/helper';
import { applyNumberFormat } from '@aduvi/components/CustomField/helper';
import { DEFAULT_CELL_STYLE } from '@aduvi/constants';
import { useBusinessCurrency } from '@aduvi/hooks';
import { EFieldLabelAligment, EFieldSettings, ICustomFieldSpecificStyle, ICustomFieldViewStyle, IQuote } from '@aduvi/types';
import { ETextAlignment } from '@aduvi/types';

import { formatQuoteItem } from '../../CustomField/helper';

export const QuotesTable = ({
  fieldId,
  style,
  column,
  quotes,
}: {
  fieldId?: string;
  style?: ICustomFieldSpecificStyle;
  column?: ICustomFieldViewStyle;
  quotes?: IQuote[];
}) => {
  const { t: translate } = useTranslation();
  const businessCurrency = useBusinessCurrency();

  const fieldLabel = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabel;
  const fieldSetting = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style.fieldSetting;

  const isLabelHidden = fieldSetting === EFieldSettings.HIDDEN;
  const isLabelOnTop = fieldSetting === EFieldSettings.ON_TOP;
  const isLabelInline = fieldSetting === EFieldSettings.INLINE;
  const fieldLabelAlignment = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabelAlignment;

  const renderQuotes = (callback: (quote: IQuote) => React.ReactNode) =>
    quotes?.map((quote) => (
      <div style={isLabelOnTop ? { display: 'block' } : (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style} key={quote.id}>
        {isLabelOnTop && !isLabelHidden && <span className='mb-5'>{fieldLabel}</span>}
        {callback(quote)}
      </div>
    ));

  const containerContent = (content: React.ReactNode) => (
    <div style={getFieldInlineStyles(isLabelInline)}>
      {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment || EFieldLabelAligment.LEFT)}>{fieldLabel}</span>}
      <span
        style={{
          order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
        }}>
        {content}
      </span>
    </div>
  );

  const renderQuoteFields = () => {
    switch (fieldId) {
      case 'total_paid':
        return renderQuotes((quote: IQuote) => {
          const parsedValue = parseFloat(quote?.total) || 0;
          const format = style?.quoteNumberFormat?.toString() || DEFAULT_CELL_STYLE.quoteNumberFormat;
          const alignment = style?.alignment ?? DEFAULT_CELL_STYLE.alignment;

          const formattedValue = applyNumberFormat(parsedValue, format, alignment as ETextAlignment, {
            code: businessCurrency?.code as string,
            format: businessCurrency?.format as string,
          });

          return containerContent(<span>{formattedValue}</span>);
        });

      case 'quote_table': {
        const hasFieldData = quotes && quotes?.length > 0;

        if (!hasFieldData) {
          return <></>;
        }

        const tableContent = (
          <div style={{ width: '100%' }}>
            {quotes?.map((quote) => (
              <div key={quote?.id}>
                <span style={{ borderBottom: '1px solid gray', opacity: 0.8 }}>{quote?.name}</span>
                {quote?.line_items?.map((el) => (
                  <Row key={el?.product_id || el.package_id}>
                    <Col>
                      {el.quantity} x {el.package?.service_name} - {el.product?.name || el.package?.name}
                    </Col>
                  </Row>
                ))}
              </div>
            ))}
          </div>
        );

        return (
          <div
            style={{
              display: isLabelOnTop ? 'block' : 'flex',
              flexDirection: isLabelOnTop ? 'column' : 'row',
              alignItems: isLabelOnTop ? 'flex-start' : 'center',
              gap: isLabelOnTop ? undefined : '8px',
            }}
            className='editable-container'>
            {isLabelOnTop && !isLabelHidden && (
              <span
                style={{
                  marginBottom: '5px',
                }}>
                {fieldLabel}
              </span>
            )}
            <div style={getFieldInlineStyles(isLabelInline)}>
              {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
              <span
                style={{
                  order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
                }}>
                {tableContent}
              </span>
            </div>
          </div>
        );
      }

      case 'unpaid_paid_stamp': {
        const hasFieldData = quotes && quotes?.length > 0;

        if (!hasFieldData) {
          return <></>;
        }

        const renderUnpaidPaidContent = (quote: IQuote) => {
          const totalPaid = Number(quote?.total_paid);
          const totalAmount = Number(quote?.total);

          const statusLabel = totalPaid >= totalAmount ? translate('formatFields.paid') : translate('formatFields.unpaid');
          const statusStyle =
            totalPaid >= totalAmount
              ? { borderBottom: '1px solid green', borderTop: '1px solid green', color: 'green' }
              : { borderBottom: '1px solid red', borderTop: '1px solid red', color: 'red' };

          const content = <span style={statusStyle}>{statusLabel}</span>;

          return (
            <div style={getFieldInlineStyles(isLabelInline)}>
              {!isLabelHidden && isLabelInline && (
                <span style={getLabelAlignmentStyles(fieldLabelAlignment || EFieldLabelAligment.LEFT)}>{fieldLabel}</span>
              )}
              <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>{content}</span>
            </div>
          );
        };

        return (
          <div style={isLabelOnTop ? { display: 'block' } : getFieldInlineStyles(isLabelInline)} className='editable-container'>
            {renderQuotes(renderUnpaidPaidContent)}
          </div>
        );
      }

      case 'remaining_balance': {
        const hasFieldData = quotes && quotes?.length > 0;

        if (!hasFieldData) {
          return <></>;
        }

        return renderQuotes((quote: IQuote) => {
          const remainingBalance = Number(quote?.total) - Number(quote?.sub_total);
          const format = style?.quoteNumberFormat?.toString() || DEFAULT_CELL_STYLE.quoteNumberFormat;
          const alignment = style?.alignment ?? DEFAULT_CELL_STYLE.alignment;

          const formattedValue = applyNumberFormat(remainingBalance, format, alignment as ETextAlignment, {
            code: businessCurrency?.code as string,
            format: businessCurrency?.format as string,
          });

          return containerContent(<span>{formattedValue}</span>);
        });
      }

      case 'tax': {
        const hasFieldData = quotes && quotes?.length > 0;

        if (!hasFieldData) {
          return <></>;
        }

        const renderTaxContent = (quote: IQuote) => {
          const taxPercentages = quote?.tax_rates?.map((tax) => tax?.tax_percentage).join(', ');
          const content = <span>{taxPercentages}</span>;

          return (
            <div style={getFieldInlineStyles(isLabelInline)}>
              {!isLabelHidden && isLabelInline && (
                <span style={getLabelAlignmentStyles(fieldLabelAlignment || EFieldLabelAligment.LEFT)}>{fieldLabel}</span>
              )}
              <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>{content}</span>
            </div>
          );
        };

        return (
          <div style={isLabelOnTop ? { display: 'block' } : getFieldInlineStyles(isLabelInline)} className='editable-container'>
            {renderQuotes(renderTaxContent)}
          </div>
        );
      }

      case 'subtotal': {
        const hasFieldData = quotes && quotes?.length > 0;

        if (!hasFieldData) {
          return <></>;
        }

        const renderSubtotalContent = (quote: IQuote) => {
          const subtotalValue = quote?.sub_total;
          const content = <span>{subtotalValue}</span>;

          return (
            <div style={getFieldInlineStyles(isLabelInline)}>
              {!isLabelHidden && isLabelInline && (
                <span style={getLabelAlignmentStyles(fieldLabelAlignment || EFieldLabelAligment.LEFT)}>{fieldLabel}</span>
              )}
              <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>{content}</span>
            </div>
          );
        };

        return (
          <div style={isLabelOnTop ? { display: 'block' } : getFieldInlineStyles(isLabelInline)} className='editable-container'>
            {renderQuotes(renderSubtotalContent)}
          </div>
        );
      }

      case 'packages': {
        const hasFieldData = quotes && quotes.some((quote) => quote?.line_items?.some((item) => item?.package));

        if (!hasFieldData) {
          return <></>;
        }

        const format = style?.quoteFormat?.toString() || DEFAULT_CELL_STYLE.quoteFormat;

        const renderPackageContent = (quote: IQuote) => {
          if (!quote?.line_items) return;

          const formattedPackages = quote?.line_items
            .filter((item) => item?.package)
            .map((item, index) => {
              const shouldIncludeService = format !== 'circle_image';
              const value = shouldIncludeService ? `${item?.package?.service_name} - ${item?.package?.name}` : String(item?.package?.name);
              const formattedValue = formatQuoteItem(value, index, format);
              return {
                formattedValue,
                image: item?.package?.image,
              };
            });

          if (formattedPackages.length === 0) return;

          let content;

          switch (format) {
            case 'circle_image':
              content = (
                <div className='flex'>
                  {formattedPackages.map((item, index) => (
                    <div key={index} className='flex align-center'>
                      {item?.image ? <Avatar size='default' src={item?.image} alt={item?.formattedValue} /> : <span>{item?.formattedValue}</span>}
                    </div>
                  ))}
                </div>
              );
              break;

            case 'bullet_list':
              content = (
                <div className='flex flex-direction-column'>
                  {formattedPackages.map((item, index) => (
                    <span key={index}>{item?.formattedValue}</span>
                  ))}
                </div>
              );
              break;

            case 'comma_separated':
              content = <span>{formattedPackages.map((item) => item?.formattedValue).join(', ')}</span>;
              break;

            case 'numbered':
              content = (
                <div className='flex flex-direction-column'>
                  {formattedPackages.map((item, index) => (
                    <span key={index}>{item?.formattedValue}</span>
                  ))}
                </div>
              );
              break;

            default:
              content = (
                <div className='flex'>
                  {formattedPackages.map((item, index) => (
                    <span key={index}>{item?.formattedValue}</span>
                  ))}
                </div>
              );
              break;
          }

          return (
            <div style={getFieldInlineStyles(isLabelInline)}>
              {!isLabelHidden && isLabelInline && (
                <span style={getLabelAlignmentStyles(fieldLabelAlignment || EFieldLabelAligment.LEFT)}>{fieldLabel}</span>
              )}
              <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>{content}</span>
            </div>
          );
        };

        return (
          <div style={isLabelOnTop ? { display: 'block' } : getFieldInlineStyles(isLabelInline)} className='editable-container'>
            {renderQuotes(renderPackageContent)}
          </div>
        );
      }

      case 'products': {
        const hasFieldData = quotes && quotes.some((quote) => quote?.line_items?.some((item) => item?.product));

        if (!hasFieldData) {
          return <></>;
        }

        const format = style?.quoteFormat?.toString() || DEFAULT_CELL_STYLE.quoteFormat;

        const renderProductContent = (quote: IQuote) => {
          if (!quote?.line_items) return;

          const formattedProducts = quote.line_items
            .filter((item) => item?.product)
            .map((item, index) => {
              const value = String(item?.product?.name);
              const formattedValue = formatQuoteItem(value, index, format);
              return {
                formattedValue,
                image: item?.product?.image,
              };
            });

          if (formattedProducts?.length === 0) return;

          let content;

          switch (format) {
            case 'circle_image':
              content = (
                <div className='flex'>
                  {formattedProducts?.map((item, index) => (
                    <div className='flex align-center' key={index}>
                      {item?.image ? <Avatar size='default' src={item?.image} alt={item?.formattedValue} /> : <span>{item?.formattedValue}</span>}
                    </div>
                  ))}
                </div>
              );
              break;

            case 'bullet_list':
              content = (
                <div className='flex flex-direction-column'>
                  {formattedProducts?.map((item, index) => <span key={index}>{item?.formattedValue}</span>)}
                </div>
              );
              break;

            case 'comma_separated':
              content = <span>{formattedProducts.map((item) => item?.formattedValue).join(', ')}</span>;
              break;

            case 'numbered':
              content = (
                <div className='flex flex-direction-column'>
                  {formattedProducts.map((item, index) => (
                    <span key={index}>{item?.formattedValue}</span>
                  ))}
                </div>
              );
              break;

            default:
              content = (
                <div className='flex' style={{ gap: '10px' }}>
                  {formattedProducts.map((item, index) => (
                    <span key={index}>{item?.formattedValue}</span>
                  ))}
                </div>
              );
              break;
          }

          return (
            <div style={getFieldInlineStyles(isLabelInline)}>
              {!isLabelHidden && isLabelInline && (
                <span style={getLabelAlignmentStyles(fieldLabelAlignment || EFieldLabelAligment.LEFT)}>{fieldLabel}</span>
              )}
              <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>{content}</span>
            </div>
          );
        };

        return (
          <div style={isLabelOnTop ? { display: 'block' } : getFieldInlineStyles(isLabelInline)} className='editable-container'>
            {renderQuotes(renderProductContent)}
          </div>
        );
      }

      default:
        return [];
    }
  };

  return <div style={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style}>{renderQuoteFields()}</div>;
};
