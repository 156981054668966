import { Navigate, Route, Routes } from 'react-router-dom';
import ClientPortalAuthWrapper from 'views/client-portal/authentication/ClientPortalAuthWrapper';

import { EClientPortalAuthComponent } from '@aduvi/types/client-portal';

export const NonAuthorizedClientPortalRoutes = () => {
  return (
    <Routes>
      <Route path='/portal'>
        <Route path='auth'>
          <Route path='magic-link'>
            <Route path='login' element={<ClientPortalAuthWrapper componentToRender={EClientPortalAuthComponent.MAGIC_LINK_LOGIN} />} />
          </Route>
          <Route path='login' element={<ClientPortalAuthWrapper componentToRender={EClientPortalAuthComponent.LOGIN} />} />
          <Route path='forgot-password' element={<ClientPortalAuthWrapper componentToRender={EClientPortalAuthComponent.FORGOT_PASSWORD} />} />
          <Route path='reset-password' element={<ClientPortalAuthWrapper componentToRender={EClientPortalAuthComponent.RESET_PASSWORD} />} />
        </Route>
        <Route index path='*' element={<Navigate to={`/portal/auth/login`} />} />
        <Route path='*' element={<Navigate to={`/portal/auth/login`} />} />
      </Route>
    </Routes>
  );
};
