import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { Title } from '@aduvi/components/Title';
import { ILineItem } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const PackagesTable = () => {
  const packageColumns = usePackagesTableColumns();
  const { selectedQuote } = useAppSelector((state) => state.quotes);

  const packages = useMemo(() => {
    return selectedQuote?.line_items?.filter((item) => item.package_id) ?? [];
  }, [selectedQuote]);

  return packages?.length ? <Table bordered={false} pagination={false} dataSource={packages} columns={packageColumns} rowKey='id' /> : <></>;
};

const usePackagesTableColumns = () => {
  const { t: translate } = useTranslation();

  return [
    {
      title: translate('quotes.package'),
      dataIndex: ['package'],
      width: '40%',
      render: (item: ILineItem['package']) => {
        return (
          <Title level={5} style={{ marginBottom: '0px' }}>
            {`${item?.service_name} - ${item?.name}`}
          </Title>
        );
      },
      editable: true,
    },
    {
      title: translate('quotes.qtyUppercase'),
      dataIndex: 'quantity',
      width: '10%',
      editable: true,
    },
    {
      title: translate('quotes.priceUppercase'),
      dataIndex: 'price',
      width: '12%',
      editable: true,
      render: (text: number) => <PriceDisplay price={text} />,
    },
    {
      title: translate('quotes.taxRateUppercase'),
      width: '16%',
      editable: true,
      render: (_: string, row: ILineItem) => {
        return row?.tax_rates?.map((rate) => rate?.tax_percentage?.toString()?.slice(0, 2) + '%')?.join(', ');
      },
    },
    {
      title: translate('quotes.totalUppercase'),
      dataIndex: 'sub_total',
      width: '22%',
      render: (text: number) => <PriceDisplay price={text} />,
      align: 'right',
    },
  ] as ColumnsType<ILineItem>;
};
