import { IChatMessage } from './common';
import { ISinglePermission } from './permissions';
import { IPartnerUser, IUser } from './user';

export interface ISupportChat {
  id?: string;
  business_id: string;
  name: string;
}

export interface ISupportChatMessage extends ISupportChat {
  business_support_chat_messages?: IChatMessage[];
  users?: {
    id: string;
    email: string;
    partner_users: IPartnerUser[];
  }[];
}

export interface IAdminUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  all_partners: boolean;
  permissions: ISinglePermission[];
  is_active: boolean;
  partners: {
    partner_id: string;
    partner_name: string;
  }[];
}

export interface ISupportChatState {
  supportChats?: ISupportChat[];
  selectedSupportChat?: ISupportChatMessage;
  loading: boolean;
  creating: boolean;
  users: IUser[];
  liveChatAvailability: {
    data?: boolean;
    loading: boolean;
  };
  messages: {
    data: IChatMessage[];
    creating: boolean;
    loading: boolean;
    removing: boolean;
  };
}

export interface ICannedResponse {
  id: string;
  title: string;
  response: string;
  partner_id: string;
}

export enum ESupportChatPusherEvents {
  MESSAGE_SENT = 'support-message-sent',
}
