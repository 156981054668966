import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandOutlined } from '@ant-design/icons';
import { CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { isSameDay } from 'date-fns';
import dayjs from 'dayjs';

import { Col, Divider, Form, Input, Modal, notification, Row, Select, Space, Spin, Tabs, Typography } from 'antd';
import './UpsertJobDrawer.scss';

import { Button } from '@aduvi/components/Button/Button';
import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { createEntityPermission, editEntityPermission } from '@aduvi/constants/entity-type';
import { useActiveTheme, useIsMobile } from '@aduvi/hooks';
import { EFieldsVisibility, EPersonalizedViewOrigin, IEntityField } from '@aduvi/types';
import { EntityDrawerTabs, IEntityType } from '@aduvi/types/entity';
import { toHumanReadable } from '@aduvi/utils/helper';

import { setSelectedQuote } from 'store/features/client-auth-slice';
import { checkEntityAvailability, convertToBooking } from 'store/features/common-slice';
import { createEntity, editEntity, removeEntity, setSelectedEntity } from 'store/features/entity-slice';
import { getQuotes } from 'store/features/quote-slice';
import { useAppSelector } from 'store/hooks';

import { mapEditedEntityFieldsToPayload, mapEntityFieldsToPayload } from '../helper';

import { Contacts } from './drawer-tabs/contacts/Contacts';
import { useUpsertEntityDrawer } from './UpsertEntityDrawer.hooks';

interface IProps {
  open: boolean;
  entityType?: IEntityType;
  origin: EPersonalizedViewOrigin;
  fields: IEntityField[];
  formOnlyFields: IEntityField[];
  onClose: () => void;
}

export const UpsertJobDrawer = ({ open, entityType, origin, fields, formOnlyFields, onClose }: IProps) => {
  const { t: translate } = useTranslation();
  const isMobile = useIsMobile();
  const activeTheme = useActiveTheme();

  const { dispatch, tabItems, selectedEntity, creating, updating, loadingEntity, form, selectedBusiness } = useUpsertEntityDrawer({
    origin,
    entityType,
    fields,
    formOnlyFields,
  });
  const { entities } = useAppSelector((state) => state.entity);
  const { quotes } = useAppSelector((state) => state.quotes);
  const {
    availabilityCheck: { loading },
  } = useAppSelector((state) => state.common);
  const entityFormFields = useMemo(() => {
    if (!entities?.selectedEntity?.custom_fields) return [];

    return entities?.selectedEntity?.custom_fields?.map((item) => (item?.form_only ? item.id : null))?.filter((item) => item);
  }, [entities?.selectedEntity]);

  const entityManagementFields = useMemo(() => {
    if (!formOnlyFields) return [];

    return formOnlyFields?.filter((field) => entityFormFields?.includes(field?.id));
  }, [entityFormFields, formOnlyFields]);

  const formFields = useMemo(() => [...fields, ...entityManagementFields], [fields, entityManagementFields]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [isEditDate, setIsEditDate] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedQuoteId, setSelectedQuoteId] = useState<string | null>(null);

  const headerFields = useMemo(
    () => ({
      nameField: formFields.find((field) => field?.title === 'Event Name'),
      nameFieldIndex: formFields.findIndex((field) => field?.title === 'Event Name'),
      statusField: formFields.find((field) => field?.title === 'Status'),
      statusFieldIndex: formFields.findIndex((field) => field?.title === 'Status'),
      eventDate: formFields.find((field) => field?.title === 'Event Date'),
      eventDateIndex: formFields.findIndex((field) => field?.title === 'Event Date'),
    }),
    [selectedEntity],
  );

  const unconfirmedStatuses = headerFields.statusField?.list_values?.filter((status) => status.value_type === EFieldsVisibility.UNCONFIRMED) || [];
  const confirmedStatuses = headerFields.statusField?.list_values?.filter((status) => status.value_type === EFieldsVisibility.CONFIRMED) || [];

  const isStatusOfType = (statusId: string, statusType: EFieldsVisibility.UNCONFIRMED | EFieldsVisibility.CONFIRMED) => {
    const relevantStatuses = statusType === EFieldsVisibility.UNCONFIRMED ? unconfirmedStatuses : confirmedStatuses;
    return relevantStatuses.some((status) => status.id === statusId);
  };

  const getCurrentSelectedStatus = () => {
    const currentStatus = form.getFieldValue(['custom_fields', headerFields.statusFieldIndex!, headerFields.statusField?.id!]);
    const currentStatusId = Array.isArray(currentStatus) ? currentStatus[0] : undefined;
    return confirmedStatuses?.find((status) => status.id === currentStatusId)?.value;
  };

  const [eventDateValue, setEventDateValue] = useState<{
    value?: string;
    end_date_time?: string;
  } | null>(null);

  const closeDrawer = () => {
    form.resetFields();
    dispatch(setSelectedEntity(undefined));
    onClose();
  };

  const createNewEntity = () => {
    if (!selectedBusiness?.id || !entityType?.id) return;

    dispatch(
      createEntity({
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        brand_id: form.getFieldValue('brand_id'),
        custom_fields: mapEntityFieldsToPayload(form.getFieldValue('custom_fields'), [...fields, ...formOnlyFields]),
      }),
    )
      .unwrap()
      .then(({ data }) => {
        const newStatusArr = form.getFieldValue(['custom_fields', headerFields.statusFieldIndex!, headerFields.statusField?.id!]);
        const newStatusId = Array.isArray(newStatusArr) ? newStatusArr[0] : undefined;
        if (origin === EPersonalizedViewOrigin.BOOKINGS && isStatusOfType(newStatusId!, EFieldsVisibility.UNCONFIRMED))
          dispatch(removeEntity(data?.id));
        onClose();
      })
      .catch(() => {});
  };

  const updateEntity = () => {
    if (!selectedBusiness?.id || !entityType?.id || !selectedEntity?.id) return;

    let finalQuoteId = selectedQuoteId;
    if (quotes?.length === 1) {
      finalQuoteId = quotes[0].id;
    }

    dispatch(
      editEntity({
        id: selectedEntity.id,
        business_id: selectedBusiness.id,
        entity_type_id: entityType?.id,
        brand_id: form.getFieldValue('brand_id'),
        quote_id: finalQuoteId!,
        custom_fields: mapEditedEntityFieldsToPayload(form.getFieldValue('custom_fields'), [...fields, ...formOnlyFields], selectedEntity),
      }),
    )
      .unwrap()
      .then(() => {
        const newStatusArr = form.getFieldValue(['custom_fields', headerFields.statusFieldIndex!, headerFields.statusField?.id!]);
        const newStatusId = Array.isArray(newStatusArr) ? newStatusArr[0] : undefined;
        if (isStatusOfType(currentStatusId!, EFieldsVisibility.CONFIRMED) && isStatusOfType(newStatusId!, EFieldsVisibility.UNCONFIRMED))
          dispatch(removeEntity(selectedEntity?.id));
        onClose();
      })
      .catch(() => {});
  };

  const currentStatusId = useMemo(() => {
    return selectedEntity?.custom_fields?.find((field) => field?.title === 'Status')?.field_data?.[0]?.id;
  }, [selectedEntity?.custom_fields?.find((field) => field.title === 'Status')]);

  const submitFormHandler = () => {
    const newStatusArr = form.getFieldValue(['custom_fields', headerFields.statusFieldIndex!, headerFields.statusField?.id!]);
    const newStatusId = Array.isArray(newStatusArr) ? newStatusArr[0] : undefined;

    if (isStatusOfType(currentStatusId!, EFieldsVisibility.UNCONFIRMED) && isStatusOfType(newStatusId!, EFieldsVisibility.CONFIRMED)) {
      // New status is any CONFIRMED status - show conversion modal
      setIsModalVisible(true);
    } else if (isStatusOfType(currentStatusId!, EFieldsVisibility.CONFIRMED) && isStatusOfType(newStatusId!, EFieldsVisibility.UNCONFIRMED)) {
      // Changing from any CONFIRMED → any UNCONFIRMED status
      selectedEntity?.id ? updateEntity() : createNewEntity();
    } else {
      // All other cases
      selectedEntity?.id ? updateEntity() : createNewEntity();
    }
  };

  const handleEditDateClick = () => {
    setIsEditDate(true);
  };

  const handleQuoteSelect = (quoteId: string) => {
    setSelectedQuoteId(quoteId);
    const selected = quotes.find((quote) => quote.id === quoteId);
    if (selected) {
      dispatch(setSelectedQuote(selected));
    }
  };

  const handleConvertToBooking = async () => {
    if (isStatusOfType(currentStatusId!, EFieldsVisibility.UNCONFIRMED)) {
      const defaultConfirmedStatus = confirmedStatuses.find((status) => status.is_default) || confirmedStatuses[0];
      form.setFieldsValue({
        custom_fields: {
          [headerFields.statusFieldIndex!]: {
            [headerFields.statusField?.id!]: [defaultConfirmedStatus.id],
          },
        },
      });

      form.validateFields([['custom_fields', headerFields.statusFieldIndex!, headerFields.statusField?.id!]]);
    }

    let finalQuoteId = selectedQuoteId;
    if (quotes?.length === 1) {
      finalQuoteId = quotes[0].id;
    }
    if (!finalQuoteId) {
      Modal.error({
        title: translate('leads.layoutDrawer.missingQuote'),
        content: translate('leads.layoutDrawer.noQuotesMessage'),
      });
      return;
    }

    try {
      const availabilityResp = await dispatch(
        checkEntityAvailability({
          businessId: selectedBusiness?.id!,
          entityTypeId: entityType?.id!,
          entityId: selectedEntity?.id!,
          quoteId: finalQuoteId,
        }),
      ).unwrap();

      if (!availabilityResp?.data?.available) {
        Modal.error({
          title: translate('leads.layoutDrawer.notAvailable'),
          content: availabilityResp.data.message,
        });
        return;
      }

      await dispatch(
        convertToBooking({
          businessId: selectedBusiness?.id!,
          entityTypeId: entityType?.id!,
          entityId: selectedEntity?.id!,
          quoteId: finalQuoteId,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(removeEntity(selectedEntity?.id));
          setIsModalVisible(false);
          onClose();
        })
        .catch(() => {});

      setIsModalVisible(false);
      notification.success({
        message: translate('leads.layoutDrawer.confirmedJobNotificationMessage', { unconfirmed_job: 'Lead', confirmed_job: 'Booking' }),
      });
    } catch (err) {
      () => {};
    }
  };

  useEffect(() => {
    if (selectedEntity?.id && selectedBusiness?.id) {
      dispatch(
        getQuotes({
          businessId: selectedBusiness.id,
          entityId: selectedEntity.id,
        }),
      );
    }
  }, [selectedBusiness?.id, selectedEntity?.id]);

  useEffect(() => {
    if (quotes?.length > 0) {
      const newDefaultId = quotes[0].id;

      if (!selectedQuoteId || selectedQuoteId !== newDefaultId) {
        setSelectedQuoteId(newDefaultId);
        dispatch(setSelectedQuote(quotes[0]));
      }
    }
  }, [quotes]);

  useEffect(() => {
    setTimeout(() => {
      const customFields = form.getFieldValue('custom_fields');

      const eventDate = customFields?.[headerFields?.eventDateIndex]?.[headerFields?.eventDate?.id!];
      if (eventDate) {
        setEventDateValue(eventDate);
      }
    }, 100);
  }, [form, headerFields, formFields, fields, entityManagementFields]);

  useEffect(() => {
    const customFields = form.getFieldValue('custom_fields');
    const eventDate = customFields?.[headerFields?.eventDateIndex]?.[headerFields?.eventDate?.id!];

    if (eventDate) {
      setEventDateValue(eventDate);
    }
  }, [isEditDate]);

  return (
    <Drawer
      className='edit-job-drawer-wrapper'
      title={
        selectedEntity?.id
          ? `Edit ${entityType?.name && toHumanReadable(entityType?.name)}`
          : `Create ${entityType?.name && toHumanReadable(entityType?.name)}`
      }
      open={open}
      width={isExpanded ? '95%' : 720}
      onClose={closeDrawer}
      bodyStyle={{ ...DRAWER_BODY_STYLE, width: '100%' }}
      extra={
        <Space>
          <Button disabled={creating || updating} onClick={closeDrawer}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={creating || updating}
            loading={creating || updating}
            type='primary'
            requiredPermission={
              selectedEntity ? entityType && editEntityPermission[entityType?.name] : entityType && createEntityPermission[entityType?.name]
            }
            disabledButton
            onClick={submitFormHandler}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Spin spinning={loadingEntity}>
        <Form form={form} layout='vertical' requiredMark={false}>
          <Row
            gutter={16}
            style={{
              backgroundColor: activeTheme?.buttonBg,
              color: activeTheme?.buttonText,
            }}
            className='selected-event-details'
            align='middle'>
            <Col style={{ flexGrow: 1 }}>
              {isEditMode ? (
                <Form.Item name={['custom_fields', headerFields.nameFieldIndex!, headerFields.nameField?.id!]}>
                  <Input
                    className='job-name-input'
                    placeholder={translate('leads.layoutDrawer.jobName')}
                    style={{ color: activeTheme?.buttonText }}
                  />
                </Form.Item>
              ) : (
                <Typography.Title level={3} className='event-title' onClick={() => setIsEditMode(true)}>
                  <CustomField disabledFields={[]} field={headerFields?.nameField!} form={form} index={headerFields.nameFieldIndex} />
                </Typography.Title>
              )}
              {!isEditDate ? (
                <Space size='small' onClick={handleEditDateClick} style={{ cursor: 'pointer' }}>
                  <CalendarOutlined style={{ fontSize: '16px' }} />{' '}
                  {eventDateValue ? (
                    isSameDay(new Date(eventDateValue?.value || ''), new Date(eventDateValue?.end_date_time || '')) ? (
                      <>
                        <span>{dayjs(eventDateValue?.value).format('dddd, MMMM D YYYY')}</span>
                        <span>{dayjs(eventDateValue?.value).format('hA')}</span>-<span>{dayjs(eventDateValue?.end_date_time).format('hA')}</span>
                      </>
                    ) : (
                      <>
                        <span>{dayjs(eventDateValue?.value).format('dddd, MMMM D YYYY hA')}</span> -{' '}
                        <span>{dayjs(eventDateValue?.end_date_time).format('dddd, MMMM D YYYY hA')}</span>
                      </>
                    )
                  ) : (
                    <span>{translate('leads.layoutDrawer.selectDate')}</span>
                  )}
                </Space>
              ) : (
                <Space direction='horizontal' align='center'>
                  <Form.Item
                    name={['custom_fields', headerFields?.eventDateIndex!, headerFields?.eventDate?.id!]}
                    getValueFromEvent={(value) => [value]}
                    style={{ marginBottom: 0, marginTop: '10px' }}>
                    <CustomField disabledFields={[]} field={headerFields?.eventDate!} form={form} index={headerFields?.eventDateIndex} />
                  </Form.Item>
                  <Button onClick={() => setIsEditDate(false)}>Save</Button>
                </Space>
              )}
              <Row className='mt-10'>
                <div className='status-select'>
                  <Form.Item
                    name={['custom_fields', headerFields.statusFieldIndex!, headerFields.statusField?.id!]}
                    getValueFromEvent={(value) => [value]}>
                    <CustomField
                      disabledFields={[]}
                      field={headerFields?.statusField!}
                      form={form}
                      index={headerFields.statusFieldIndex}
                      size='small'
                    />
                  </Form.Item>
                </div>
                {origin === EPersonalizedViewOrigin.LEADS ? (
                  <Button className='ml-5' size='small' onClick={() => setIsModalVisible(true)}>
                    {translate('leads.layoutDrawer.convertToBooking')}
                  </Button>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
            <Col style={{ alignSelf: 'self-start', marginLeft: 'auto' }}>
              {!isMobile && <ExpandOutlined onClick={() => setIsExpanded((prev) => !prev)} />}
            </Col>
          </Row>

          <Row gutter={30}>
            <Col span={isExpanded ? 15 : 24} className='job-tabs'>
              <Tabs defaultActiveKey={EntityDrawerTabs.DETAILS} items={tabItems} />
            </Col>
            {isExpanded && (
              <Col span={6} className='contact-card'>
                <Contacts />
              </Col>
            )}
          </Row>
        </Form>
      </Spin>
      {isModalVisible && (
        <Modal
          title={translate('leads.layoutDrawer.convertJob', { unconfirmed_job: 'Lead', confirmed_job: 'Booking' })}
          visible={isModalVisible}
          onOk={handleConvertToBooking}
          onCancel={() => setIsModalVisible(false)}
          okText={translate('leads.layoutDrawer.convert')}
          footer={[
            <Divider key='divider-footer' />,
            <Button key='convert' type='primary' onClick={handleConvertToBooking} loading={loading}>
              {translate('leads.layoutDrawer.convert')}
            </Button>,
          ]}>
          <Divider />
          <Row align='middle' style={{ display: 'flex', alignItems: 'center' }}>
            <Col style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
              <ExclamationCircleOutlined style={{ fontSize: '25px', marginRight: '10px' }} />
              <Typography.Text>
                {translate('leads.layoutDrawer.confirmMessage', {
                  status: getCurrentSelectedStatus() || (confirmedStatuses.find((status) => status.is_default) || confirmedStatuses[0])?.value,
                  unconfirmed_job: 'Lead',
                  confirmed_job: 'Booking',
                })}
              </Typography.Text>
            </Col>
          </Row>

          {quotes?.length > 1 && (
            <div>
              <Typography.Text>{translate('leads.layoutDrawer.multipleQuotesMessage', { unconfirmed_job: 'Lead' })}</Typography.Text>
              <Select
                value={selectedQuoteId}
                onChange={handleQuoteSelect}
                placeholder={translate('leads.layoutDrawer.selectQuote')}
                style={{ width: '100%' }}>
                {quotes.map((quote) => (
                  <Select.Option key={quote.id} value={quote.id}>
                    {`${quote.name ?? 'Quote'} : $${quote?.total}`}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        </Modal>
      )}
    </Drawer>
  );
};
