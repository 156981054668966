import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib';

import { CustomField } from '@aduvi/components/CustomField/CustomField';

import { useAppSelector } from 'store/hooks';

export const ChangeFieldData = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  const { fields } = useAppSelector((state) => state.fields);

  const selectedFieldId = Form.useWatch('field_id', form);

  const selectedField = fields.find((item) => item.id === selectedFieldId);

  return (
    <>
      <Form.Item
        name={'field_id'}
        label={translate('formatFields.field')}
        getValueFromEvent={(value) => {
          form.setFieldValue('custom_fields', []);
          return value;
        }}>
        <Select options={fields.map((item) => ({ label: item.title, value: item.id }))} placeholder='Surname' />
      </Form.Item>
      {selectedField?.id ? <CustomField form={form} field={selectedField} disabledFields={[]} index={0} /> : <></>}
    </>
  );
};
