import dayjs from 'dayjs';

import { Avatar, Card, Col, Row, Typography } from 'antd';
import { Image } from 'antd';

import { IChat, ITicket } from '@aduvi/types/ticket';

import { useAppSelector } from 'store/hooks';

export const ChatCard = ({ message, selectedTicket }: { message?: IChat; selectedTicket?: ITicket }) => {
  const { layout } = useAppSelector((state) => state.common);

  const findMessageUser = message?.user?.partner_users?.find((user) => user?.partner_id === layout?.id);
  const findSelectedTicketUser = selectedTicket?.user?.partner_users?.find((user) => user?.partner_id === layout?.id);

  return (
    <Row
      justify={message?.user?.partner_users[0]?.role === 'USER' || selectedTicket?.user?.partner_users?.[0]?.role === 'USER' ? 'end' : 'start'}
      className='mb-20'>
      <Card style={{ width: '600px', borderRadius: 10 }} bodyStyle={{ padding: 0 }}>
        <Row>
          <Row className='w-full pl-10 pr-10' justify={'space-between'} align={'middle'} style={{ backgroundColor: '#C0C0C026', height: '40px' }}>
            <Col className='flex'>
              <Avatar size={'small'}>
                {message
                  ? !message?.user?.admin_users
                    ? findMessageUser?.first_name?.charAt(0)
                    : message?.user?.admin_users?.length === 0
                    ? findMessageUser?.first_name?.charAt(0)
                    : message?.user?.admin_users?.[0]?.first_name?.charAt(0)
                  : findSelectedTicketUser?.first_name?.charAt(0)}
              </Avatar>

              {message ? (
                !message?.user?.admin_users ? (
                  <Typography.Text className='pl-10'>
                    {findMessageUser?.first_name} {findMessageUser?.last_name}
                  </Typography.Text>
                ) : message?.user?.admin_users?.length === 0 ? (
                  <Typography.Text className='pl-10'>
                    {findMessageUser?.first_name} {findMessageUser?.last_name}
                  </Typography.Text>
                ) : (
                  <Typography.Text className='pl-10'>
                    {message?.user?.admin_users?.[0]?.first_name} ({layout?.legal_name} Support)
                  </Typography.Text>
                )
              ) : (
                <Typography.Text className='pl-10'>
                  {findSelectedTicketUser?.first_name} {findSelectedTicketUser?.last_name}
                </Typography.Text>
              )}
            </Col>
            <Col>
              <Typography.Text type='secondary'>{dayjs(selectedTicket?.created_at).format('DD/MM/YYYY hh:mm A')}</Typography.Text>
            </Col>
          </Row>
          <Typography.Text className='p-10'>
            <div dangerouslySetInnerHTML={(message && { __html: message?.message }) || (selectedTicket && { __html: selectedTicket?.description })} />
            {message?.attachments ? (
              message?.attachments?.map((file, index) => <Image key={index} src={file?.url} width={200}></Image>)
            ) : selectedTicket?.attachments ? (
              selectedTicket?.attachments.map((file, index) => <Image key={index} src={file?.url} width={200}></Image>)
            ) : (
              <></>
            )}
          </Typography.Text>
        </Row>
      </Card>
    </Row>
  );
};
