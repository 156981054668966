/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOutlined, PushpinFilled, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';

import { Card, Col, Collapse, CollapseProps, Dropdown, Form, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './EntityHeaderActions.scss';

import { Button } from '@aduvi/components/Button/Button';
import { getStatusForEntities } from '@aduvi/components/PersonalizedViewComponents/PredefinedViews/helper';
import { SearchInput } from '@aduvi/components/SearchInput/SearchInput';
import { createEntityPermission } from '@aduvi/constants/entity-type';
import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, EPersonalizedView, EPersonalizedViewOrigin, IEntityField } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

import { getEntities } from 'store/features/entity-slice';
import { getEntityFields } from 'store/features/fields-slice';
import { createPersonalizedViewFilters, editPersonalizedViewFilters } from 'store/features/personalized-views-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getEntitiesCountWithFilters } from 'store/services/entity.service';

import { EntityHeaderActionsDropdown } from './EntityHeaderActionsDropDown';
import { FilterInputs } from './FilterInputs';

const hiddenFilterFields = [
  EFieldDataValueType.FIELD_DATA_REFERENCE,
  EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS,
  EFieldDataValueType.FIELD_DATA_FILES,
  EFieldDataValueType.FIELD_DATA_IMAGES,
  EFieldDataValueType.FIELD_DATA_SIGNATURES,
  EFieldDataValueType.FIELD_DATA_SONGS,
];
interface IProps {
  entityType?: IEntityType;
  personalizedViewOrigin: EPersonalizedViewOrigin;
  pinnedFields?: IFilter[];
  onCreateForm: () => void;
  onCreateEntity: () => void;
  onConfigureView: () => void;
  onEditRowLayout: () => void;
  setPinnedFields?: (pinnedFields: IFilter[]) => void;
}

interface IFilter {
  field_id: string;
  operator: string;
  value?: string | number | Array<string | number>;
  end_value?: string | number;
}

interface IFilterObj {
  field_id: string;
  operator: string;
  value: Record<string, string | number>;
}

interface IFieldData {
  operator?: string;
  value?: string | number;
  values?: Array<string | number>;
  startValue?: string | number;
  endValue?: string | number;
}

export const createFiltersFromFormValues = (formValues: { custom_fields?: Record<string, Record<string, IFieldData>> }) => {
  return Object.entries(formValues.custom_fields || {})
    .reduce((acc: any[], [_, field]) => {
      if (!field) return acc;

      const [[field_id, fieldData]] = Object.entries(field);

      if (!fieldData) return acc;

      if (fieldData.operator === 'Is between') {
        if (fieldData.startValue && fieldData.endValue) {
          return [
            ...acc,
            {
              field_id,
              operator: fieldData.operator,
              value: {
                0: dayjs(fieldData.startValue).format('YYYY-MM-DD'),
                1: dayjs(fieldData.endValue).format('YYYY-MM-DD'),
              },
            },
          ];
        }
      } else {
        const value = fieldData.value || fieldData.values;
        if (value) {
          if (Array.isArray(value)) {
            const filterObj: IFilterObj = {
              field_id,
              operator: fieldData.operator || 'Contains',
              value: {},
            };

            // Add each value with an index starting from 0
            value.forEach((val, index) => {
              filterObj.value[index] = val;
            });

            return [...acc, filterObj];
          }
          return [
            ...acc,
            {
              field_id,
              operator: fieldData.operator || 'Contains',
              value: { 0: value },
            },
          ];
        }
      }

      return acc;
    }, [])
    .filter(Boolean);
};

const getDefaultOperatorForField = (field: IEntityField) => {
  switch (field.value_type) {
    case EFieldDataValueType.FIELD_DATA_DATE_TIMES:
      return 'Is equal to';
    default:
      return 'Contains';
  }
};

export const EntityHeaderActions = ({
  entityType,
  personalizedViewOrigin,
  onCreateEntity,
  onConfigureView,
  onEditRowLayout,
  onCreateForm,
  pinnedFields,
  setPinnedFields,
}: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [form] = useForm();
  const { t: translate } = useTranslation();
  const { entities } = useAppSelector((state) => state.entity);
  const { fields } = useAppSelector((state) => state.fields);
  const { selectedViewFilters } = useAppSelector((state) => state.personalizedViews);
  const [filterResultCount, setFilterResultCount] = useState(0);
  const [loadingFilterResult, setLoadingFilterResult] = useState(false);
  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      dispatch(getEntityFields({ businessId: selectedBusiness?.id, entityTypeId: entityType?.id })).catch(() => {});
    }
  }, [selectedBusiness?.id, entityType?.id]);

  const { selectedView, selectedViewId } = useAppSelector((state) => state.personalizedViews);
  const [searchFieldVisible, setSearchFieldVisible] = useState(false);

  const filterItems: CollapseProps['items'] = fields
    .filter((field) => !hiddenFilterFields.includes(field.value_type))
    .map((field, index) => ({
      key: field.id,
      label: (
        <Row align='middle' justify='space-between'>
          <span>{field.title}</span>
          <PushpinFilled
            className='icon'
            style={{ color: pinnedFields?.some((filter) => filter.field_id === field.id) ? '#165dff' : undefined }}
            onClick={(e) => {
              e.stopPropagation();
              if (selectedBusiness?.id && selectedView?.id && setPinnedFields && pinnedFields) {
                const formValues = form.getFieldValue(['custom_fields', index, field.id]);
                const currentOperator = formValues?.operator || getDefaultOperatorForField(field);

                const filteredPinnedFiels = pinnedFields?.some((filter) => filter.field_id === field.id)
                  ? pinnedFields.filter((filter) => filter.field_id !== field.id)
                  : [...pinnedFields, { field_id: field.id, operator: currentOperator }];

                if (selectedViewFilters.id) {
                  dispatch(
                    editPersonalizedViewFilters({
                      businessId: selectedBusiness?.id,
                      personalizedViewId: selectedView?.id,
                      filters: [
                        ...filteredPinnedFiels.map((filter) => ({
                          field_id: filter.field_id,
                          operator: filter.operator,
                        })),
                      ],
                      filtersId: selectedViewFilters.id,
                    }),
                  );
                } else {
                  dispatch(
                    createPersonalizedViewFilters({
                      businessId: selectedBusiness?.id,
                      personalizedViewId: selectedView?.id,
                      filters: [
                        ...filteredPinnedFiels.map((filter) => ({
                          field_id: filter.field_id,
                          operator: filter.operator,
                        })),
                      ],
                    }),
                  );
                }
                setPinnedFields(filteredPinnedFiels);
              }
            }}
          />
        </Row>
      ),
      children: <FilterInputs field={field} form={form} index={index} isCard={true} />,
    }));

  const debouncedFetchEntities = useMemo(
    () =>
      debounce(async (businessId: string, entityTypeId: string, filters: any[], showResults: boolean = false) => {
        try {
          setLoadingFilterResult(true);

          const response = await getEntitiesCountWithFilters(businessId, entityTypeId, {
            paginate: false,
            show_results: showResults,
            status: getStatusForEntities(personalizedViewOrigin),
            view_id: selectedViewId,
            view_display: selectedView?.view_display,
            field_id: selectedView?.status_field,
            display_label_field_id: selectedView?.display_label_field_id,
            start_date: selectedView?.start_date,
            end_date: selectedView?.start_date,
            filters,
          });
          setFilterResultCount(response.data.filtered_entities_count);
        } catch (error) {
          () => {};
        } finally {
          setLoadingFilterResult(false);
        }
      }, 500),
    [],
  );

  const handleFormValuesChange = (_: unknown, allValues: { custom_fields?: Record<string, Record<string, IFieldData>> }) => {
    if (selectedBusiness?.id && entityType?.id) {
      const filters = createFiltersFromFormValues(allValues);

      if (filters?.length > 0) {
        debouncedFetchEntities(selectedBusiness.id, entityType.id, filters, false);
      }
    }
  };

  return (
    <Row justify={'end'} gutter={10} align='middle' className='header-actions-wrapper'>
      <Col>
        {selectedView?.view_display && selectedView?.view_display !== EPersonalizedView.FORMS && (
          <Dropdown
            overlayClassName='view-filter-card'
            trigger={['click']}
            dropdownRender={() => (
              <Card style={{ width: '300px', borderRadius: '10px' }}>
                <Row justify='space-between' align='middle' className='mb-15'>
                  <Col>
                    <Typography.Title level={5}>Filters</Typography.Title>
                  </Col>
                  <Col>
                    <Button
                      type='primary'
                      disabled={filterResultCount === 0 || !filterResultCount || loadingFilterResult}
                      onClick={() => {
                        if (selectedBusiness?.id && entityType?.id) {
                          const formValues = form.getFieldsValue();
                          const filters = createFiltersFromFormValues(formValues);

                          if (filters?.length > 0) {
                            debouncedFetchEntities(selectedBusiness.id, entityType.id, filters, true);
                            dispatch(
                              getEntities({
                                businessId: selectedBusiness?.id,
                                entityTypeId: entityType?.id,
                                params: {
                                  status: getStatusForEntities(personalizedViewOrigin),
                                  view_id: selectedViewId,
                                  page: entities.currentPage,
                                  size: entities.pageSize,
                                  paginate: true,
                                  show_results: true,
                                  view_display: selectedView?.view_display,
                                  field_id: selectedView?.status_field,
                                  display_label_field_id: selectedView?.display_label_field_id,
                                  start_date: selectedView?.start_date,
                                  end_date: selectedView?.start_date,
                                  filters,
                                },
                              }),
                            );
                          }
                        }
                      }}>
                      {loadingFilterResult ? ` Loading...` : `Show ${filterResultCount ?? ''} results`}
                    </Button>
                  </Col>
                </Row>
                <Form form={form} onValuesChange={handleFormValuesChange}>
                  <Collapse items={filterItems} className='filters-container' bordered={false} />
                </Form>
                <div className='text-right'>
                  <Button
                    type='primary'
                    style={{ marginTop: '24px', marginLeft: 'auto' }}
                    disabled={filterResultCount === 0 || !filterResultCount || loadingFilterResult}
                    onClick={() => {
                      if (!selectedBusiness?.id || !entityType?.id) return;

                      form.resetFields();
                      setFilterResultCount(0);
                      dispatch(
                        getEntities({
                          businessId: selectedBusiness?.id,
                          entityTypeId: entityType?.id,
                          params: {
                            status: getStatusForEntities(personalizedViewOrigin),
                            view_id: selectedViewId,
                            page: entities.currentPage,
                            size: entities.pageSize,
                            paginate: true,
                            show_results: true,
                            filters: [],
                          },
                        }),
                      );
                    }}>
                    {translate('buttons.reset')}
                  </Button>
                </div>
              </Card>
            )}>
            <FilterOutlined className='icon' />
          </Dropdown>
        )}
      </Col>
      <Col>
        {selectedView?.view_display !== EPersonalizedView.ADDRESS_BOOK && (
          <>
            {!searchFieldVisible && (
              <Col>
                <SearchOutlined className='icon' onClick={() => setSearchFieldVisible(!searchFieldVisible)} />
              </Col>
            )}
            {searchFieldVisible && (
              <Col onBlur={() => setSearchFieldVisible(false)}>
                <SearchInput
                  placeholder='Search'
                  autoFocus={searchFieldVisible}
                  onFocus={() => setSearchFieldVisible(true)}
                  onBlur={() => setSearchFieldVisible(false)}
                />
              </Col>
            )}
          </>
        )}
      </Col>

      <Col>
        {selectedView?.view_display === EPersonalizedView.FORMS ? (
          <Button disabledButton className='mr-10' type='primary' onClick={onCreateForm}>
            {translate('leads.leadsHeaderAction.addForm')}
          </Button>
        ) : (
          <Button
            disabledButton
            requiredPermission={entityType && createEntityPermission[entityType?.name]}
            className='mr-10'
            type='primary'
            onClick={onCreateEntity}>
            {translate(`entity.leadsHeaderActions.addButton.${personalizedViewOrigin}`)}
          </Button>
        )}

        <EntityHeaderActionsDropdown
          entityType={entityType}
          onConfigureView={onConfigureView}
          onEditRowLayout={onEditRowLayout}
          personalizedViewOrigin={personalizedViewOrigin}
        />
      </Col>
    </Row>
  );
};
