/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { EdgeProps, useEdgesState, useNodesState } from 'reactflow';
import { DownOutlined, ExportOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { Breadcrumb, Button, Col, Dropdown, Form, MenuProps, Row, Spin, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';

import GenericHeader from '@aduvi/components/Header/GenericHeader';
import axios from '@aduvi/config/axios';
import { useBusiness } from '@aduvi/hooks';
import { EAutomationNodeAction, ENodeType } from '@aduvi/types/automation';
import { reorderNodes } from '@aduvi/utils/helper';

import { createAutomationNodes, getAutomation, setAutomationMentions, setTaskFields } from 'store/features/automation-slice';
import { getEntityFields } from 'store/features/fields-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { History } from './automation-tabs/history/History';
import { Queue } from './automation-tabs/queue/Queue';
import { initialEdges } from './edges/Edges';
import { prepareAutomationsPayload } from './nodes/node-settings/helper';
import { initialNodes } from './nodes/Nodes';
import AutomationBuilder from './AutomationBuilder';

export const FullScreenAutomationsModal = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const { automationId } = useParams();
  const selectedBusiness = useBusiness();
  const navigate = useNavigate();

  const { loadingAutomation, selectedAutomation, creating, updating, taskFields } = useAppSelector((state) => state.automation);
  const { fields } = useAppSelector((state) => state.fields);
  const { entityTypes } = useAppSelector((state) => state.entity);

  const [selectedTab, setSelectedTab] = useState('workflow');
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [selectedEdge, setSelectedEdge] = useState<EdgeProps | undefined>();

  const activeTab: Record<string, ReactNode> = {
    workflow: (
      <AutomationBuilder
        nodes={nodes}
        edges={edges}
        selectedEdge={selectedEdge}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
        setEdges={setEdges}
        setNodes={setNodes}
        setSelectedEdge={setSelectedEdge}
      />
    ),
    configure: (
      <Col className='flex-center mt-30'>
        <div>Configuree</div>
      </Col>
    ),
    queue: (
      <Col className='mt-30'>
        <Queue />
      </Col>
    ),
    history: (
      <Col className='mt-30'>
        <History />
      </Col>
    ),
  };

  const menuProps: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div>
          <ExportOutlined /> {translate('automations.export')}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div>
          <PlayCircleOutlined /> {translate('automations.testWorkflow')}
        </div>
      ),
    },
  ];

  const onAutomationsSubmitForm = () => {
    if (!nodes.length || !selectedBusiness?.id || !selectedAutomation?.id) return;

    const reorderedNodes = reorderNodes(nodes, edges);

    dispatch(
      createAutomationNodes({
        automationId: selectedAutomation?.id,
        businessId: selectedBusiness?.id,
        body: {
          automation_nodes: reorderedNodes.map((item) => {
            const defaultNodeData = {
              id: item.id,
              node_type: item.data.nodeType,
              logic: null,
              meta_data: {
                x: item.position.x,
                y: item.position.y,
                type: item.type,
              },
              triggerable_id: item.data.triggerable_id,
              triggerable_type: item.data.triggerable_type,
              title: item.data.label,
              business_id: selectedBusiness?.id,
              automation_id: selectedAutomation?.id,
              previous_node_id: edges.find((edge) => item.id === edge.target)?.source,
              action: item?.data?.action,
              payload: prepareAutomationsPayload(item, fields, taskFields),
              rules: [],
            };

            if (item?.data?.action === EAutomationNodeAction.IF_ELSE) {
              const branches = edges.filter((edge) => item.id === edge.source).map((edge) => edge.target);

              const branchesNextOutputId = branches.map((item) => edges.find((edge) => edge.source === item)?.target);

              return item?.data?.rules?.length
                ? {
                    id: item.id,
                    node_type: item.data.nodeType,
                    logic: null,
                    meta_data: {
                      x: item.position.x,
                      y: item.position.y,
                      type: item.type,
                    },
                    triggerable_id: item.data.triggerable_id,
                    triggerable_type: item.data.triggerable_type,
                    title: item.data.label,
                    business_id: selectedBusiness?.id,
                    automation_id: selectedAutomation?.id,
                    previous_node_id: edges.find((edge) => item.id === edge.target)?.source,
                    action: item?.data?.action,
                    payload: item?.data?.payload,
                    rules: item.data.rules.map((rule: any, index: number) => ({
                      ...rule,
                      event: {
                        ...rule.event,
                        params: {
                          message: 'conditions have been met',
                          next_output_id: branchesNextOutputId[index],
                        },
                      },
                    })),
                  }
                : defaultNodeData;
            }
            return defaultNodeData;
          }),
        },
      }),
    );
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !automationId) return;
    dispatch(setAutomationMentions([]));
    dispatch(getAutomation({ automationId, businessId: selectedBusiness?.id }))
      .unwrap()
      .then(({ data }) => {
        setNodes(
          data.nodes.map((item) => ({
            id: item.id,
            type: item.meta_data.type || 'custom',
            position: {
              x: item.meta_data.x,
              y: item.meta_data.y,
            },
            data: {
              label: item.title,
              nodeType: item.node_type,
              triggerable_type: item.triggerable_type,
              triggerable_id: item.triggerable_id,
              action: item.action,
              payload: item.payload,
              rules: item.rules,
            },
          })),
        );

        setEdges(
          data.nodes
            .filter((item) => item.previous_node_id)
            .map((item) => ({
              id: `${item.previous_node_id}->${item.id}`,
              source: String(item?.previous_node_id),
              target: item.id,
              type: item.meta_data.type === 'branch' ? 'default' : 'custom',
              style: { stroke: '#69C0FF', strokeWidth: 2 },
            })),
        );

        const trigger = data.nodes.find((item) => item.node_type === ENodeType.TRIGGER);

        if (!trigger?.triggerable_id) return;

        dispatch(getEntityFields({ businessId: selectedBusiness?.id, entityTypeId: trigger?.triggerable_id }))
          .unwrap()
          .then(({ data }) => {
            dispatch(
              setAutomationMentions(
                data.map((item) => ({
                  id: item.id,
                  node_id: '',
                  field_type: item?.value_type,
                  name: item?.title,
                  triggerId: trigger?.id,
                })),
              ),
            );
          })
          .catch(() => {});
      })
      .catch(() => {
        navigate(-1);
      });
  }, [selectedBusiness?.id, automationId]);

  useEffect(() => {
    if (
      nodes.some((item) => item.data?.action === EAutomationNodeAction.CREATE_TASK) &&
      !taskFields.length &&
      selectedBusiness?.id &&
      entityTypes?.data?.TASK?.id
    ) {
      try {
        axios
          .get(`/businesses/${selectedBusiness?.id}/entity-types/${entityTypes?.data?.TASK?.id}/fields`)
          .then((response) => dispatch(setTaskFields(response.data)))
          .catch(() => {});
      } catch (err) {
        return;
      }
    }
  }, [selectedBusiness?.id, nodes, taskFields]);

  return (
    <Form form={form} layout='vertical' requiredMark={false}>
      <Col span={24} className='booking-form-wrapper'>
        <GenericHeader
          breadcrumbs={
            <Breadcrumb
              items={[
                { title: translate('automations.automations'), onClick: () => navigate(`/automations`), className: 'cursor-pointer' },
                {
                  title: selectedAutomation?.title,
                },
              ]}
            />
          }
          tabs={
            <Tabs
              activeKey={selectedTab}
              items={[
                { key: 'workflow', label: translate('automations.workflow') },
                { key: 'configure', label: translate('automations.configure') },
                { key: 'queue', label: translate('automations.queue') },
                { key: 'history', label: translate('automations.history') },
              ]}
              onChange={setSelectedTab}
            />
          }
          actions={
            <Row justify={'space-around'}>
              <Col className='mr-15'>
                <Dropdown.Button
                  menu={{
                    items: menuProps,
                  }}
                  onClick={void 0}
                  icon={<DownOutlined />}>
                  <ExportOutlined style={{ verticalAlign: 'middle' }} /> {translate('automations.export')}
                </Dropdown.Button>
              </Col>
              <Button onClick={onAutomationsSubmitForm} disabled={creating || updating} loading={creating || updating} type='primary'>
                {translate('buttons.save')}
              </Button>
            </Row>
          }
        />

        <Spin spinning={loadingAutomation}>
          <Col span={24}>{activeTab[selectedTab]}</Col>
        </Spin>
      </Col>
    </Form>
  );
};
