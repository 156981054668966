import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled } from '@ant-design/icons';

import { Col, Row } from 'antd';
import './Message.scss';

import { EFieldDataValueType, EReferenceEntityType } from '@aduvi/types';

interface IMessageProps {
  field: string;
  referenceType?: string;
}

export const Message = ({ field, referenceType }: IMessageProps) => {
  const { t: translate } = useTranslation();

  const getFieldName = () => {
    if (field === EFieldDataValueType.FIELD_DATA_REFERENCE) {
      if (referenceType === EReferenceEntityType.SHIFT) {
        return translate('components.customFieldsDrawer.shiftSettingsMessage');
      }
      return translate('components.customFieldsDrawer.globalFieldMessage', {
        field: translate(`components.customFieldsDrawer.type.${referenceType?.toUpperCase()}`),
      });
    }
    return translate('components.customFieldsDrawer.globalFieldMessage', {
      field: translate(`components.customFieldsDrawer.type.${field.toUpperCase()}`),
    });
  };

  return (
    <Row className='message-wrapper'>
      <Col span={1}>
        <CheckCircleFilled className='check-icon' />
      </Col>
      <Col span={23} className='fs-14'>
        {getFieldName()}
      </Col>
    </Row>
  );
};
