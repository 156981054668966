import { ReactNode } from 'react';

import { Col, Row } from 'antd';

interface IProps {
  breadcrumbs?: ReactNode;
  tabs?: ReactNode;
  actions?: ReactNode;
}
import './GenericHeader.scss';

import { useActiveTheme } from '@aduvi/hooks/usePlatformPalette';
const GenericHeader = ({ breadcrumbs, tabs, actions }: IProps) => {
  const activeTheme = useActiveTheme();
  return (
    <Col span={24} className='header' style={{ backgroundColor: activeTheme?.bodyBg }}>
      <Row justify={'space-between'} align={'middle'} className='px-15 py-5  header-menu desktop-header'>
        {breadcrumbs && <Col className='header-breadcrumbs'>{breadcrumbs}</Col>}
        {tabs && <Col className='header-tabs'>{tabs}</Col>}
        {actions && <Col className='header-actions'>{actions}</Col>}
      </Row>
      <Row justify={'space-between'} align={'middle'} className='px-15 pt-15 header-menu tablet-header'>
        <Col span={24}>
          <Row className='actions-row' justify={'space-between'} align={'middle'} wrap={true}>
            {breadcrumbs && <Col className='header-breadcrumbs'>{breadcrumbs}</Col>}
            {actions && <Col className='header-actions'>{actions}</Col>}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={'center'}> {tabs && <Col className='header-tabs'>{tabs}</Col>}</Row>
        </Col>
      </Row>
    </Col>
  );
};

export default GenericHeader;
