import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { EFormPurpose } from '@aduvi/types/form';

export const useBookingForm = (formPurpose: EFormPurpose | undefined) => {
  const [form] = useForm();
  const { t: translate } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('1');

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: translate('forms.forms.build'),
    },
    {
      key: '2',
      label: translate('forms.forms.configure'),
    },
    {
      key: '3',
      label: formPurpose === EFormPurpose.CAPTURE ? translate('forms.forms.visibility') : translate('forms.forms.share'),
    },
  ];

  return { form, selectedTab, tabs, setSelectedTab };
};
