import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Quote } from 'views/projects/project-tabs/quote/Quote';
import { ManualPaymentModal } from 'views/settings/payments/payment-gateway-settings/components/ManualPaymentModal';

import { Col, Dropdown, Empty, Input, Row, Spin, Tabs, Tooltip } from 'antd';
import './Quotes.scss';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { EPersonalizedViewOrigin } from '@aduvi/types';

import { renameQuote, setSelectedQuote } from 'store/features/quote-slice';
import { useAppSelector } from 'store/hooks';

import { Button } from '../Button/Button';

import { QuoteSummary } from './@components/order-summary/QuoteSummary';
import { PackagesTable } from './@components/packages-table/PackagesTable';
import { PaymentsTable } from './@components/payments-table/PaymentsTable';
import { ProductsTable } from './@components/products-table/ProductsTable';
import { UpsertQuote } from './upsert-quote/UpsertQuote';
import { useQuotes } from './Quotes.hooks';

export const Quotes = ({ entityId, origin }: { entityId?: string; origin: EPersonalizedViewOrigin }) => {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const { dispatch, dropdownItems, editingQuoteId, setEditingQuoteId } = useQuotes({ entityId });
  const { loading, updating, quotes, selectedQuote } = useAppSelector((state) => state.quotes);
  const hasPermissionToEditQuote = useUserPermissionCheck(PERMISSIONS.QUOTES.EDIT);

  const [isNewQuote, setIsNewQuote] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isManualModalPaymentOpen, setIsManualModalPaymentOpen] = useState(false);

  const isLead = useMemo(() => origin === EPersonalizedViewOrigin.LEADS, [origin]);

  if ((isNewQuote || isEditMode) && origin !== EPersonalizedViewOrigin.PROJECTS)
    return (
      <UpsertQuote
        onCancel={() => {
          setIsNewQuote(false);
          setIsEditMode(false);
        }}
        onSave={() => {
          setIsNewQuote(false);
          setIsEditMode(false);
        }}
        entityId={entityId}
        isEditMode={isEditMode}
        origin={origin}
      />
    );

  return (
    <div className='quote-wrapper'>
      <Spin spinning={loading || updating}>
        {origin !== EPersonalizedViewOrigin.PROJECTS ? (
          <>
            <Row className='w-full'>
              <Col span={20}>
                {quotes.length ? (
                  <Tabs
                    activeKey={selectedQuote?.id || quotes?.[0].id}
                    defaultValue={selectedQuote?.id}
                    tabBarExtraContent={
                      isLead && (
                        <Tooltip title={translate('quotes.create')}>
                          <Button icon={<PlusOutlined />} type='link' onClick={() => setIsNewQuote(true)} />
                        </Tooltip>
                      )
                    }
                    items={
                      isLead
                        ? quotes.map((item, index) => ({
                            label:
                              editingQuoteId === item.id ? (
                                <Input
                                  disabled={!hasPermissionToEditQuote}
                                  defaultValue={item.name ? item.name : `Quote ${++index}`}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  onBlur={(e) => {
                                    const newName = e.target.value;
                                    if (!selectedBusiness?.id || !editingQuoteId) return;
                                    dispatch(renameQuote({ business_id: selectedBusiness.id, quote_id: editingQuoteId, name: newName }))
                                      .unwrap()
                                      .then(() => setEditingQuoteId(''));
                                  }}
                                  autoFocus
                                />
                              ) : (
                                <Row align='middle'>
                                  {item.name ? item.name : `Quote ${++index}`}
                                  {selectedQuote?.id === item.id && (
                                    <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
                                      <Button icon={<SettingOutlined />} type='link' onClick={(e) => e.preventDefault()} />
                                    </Dropdown>
                                  )}
                                </Row>
                              ),
                            key: item.id,
                          }))
                        : quotes
                            .filter((item) => item.id === selectedQuote?.id)
                            .map((item) => ({
                              label: item.name,
                              key: item.id,
                            }))
                    }
                    onChange={(value) => dispatch(setSelectedQuote(quotes.find((item) => item.id === value)))}
                  />
                ) : null}
              </Col>
              <Col span={4}>
                {quotes.length ? (
                  <Button
                    icon={<EditOutlined />}
                    type='default'
                    className='mt-15'
                    disabledButton
                    requiredPermission={PERMISSIONS.QUOTES.EDIT}
                    onClick={() => setIsEditMode(true)}>
                    {translate('quotes.edit')}
                  </Button>
                ) : (
                  <Button
                    type='default'
                    className='mt-15'
                    disabledButton
                    requiredPermission={PERMISSIONS.QUOTES.CREATE}
                    onClick={() => setIsNewQuote(true)}>
                    {translate('quotes.create')}
                  </Button>
                )}
              </Col>
            </Row>

            {quotes.length ? (
              <>
                <PackagesTable />
                <ProductsTable selectedQuote={selectedQuote} />
                <QuoteSummary selectedQuote={selectedQuote} />

                {!isLead && (
                  <>
                    <PaymentsTable setIsManualModalPaymentOpen={setIsManualModalPaymentOpen} />
                  </>
                )}
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={translate('quotes.noQuotes')} />
            )}
          </>
        ) : (
          <Quote />
        )}
      </Spin>
      <ManualPaymentModal quoteId={selectedQuote?.id!} isOpen={isManualModalPaymentOpen} onClose={() => setIsManualModalPaymentOpen(false)} />
    </div>
  );
};
