import { useEffect, useState } from 'react';

import { Select, SelectProps } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';

import { getLocations } from 'store/features/business-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const SelectLocation = (props: SelectProps & { defaultLocation?: string }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { defaultLocation } = props;

  const { locations, loading } = useAppSelector((state) => state.business);

  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);

  const hasPermissionToViewLocations = useUserPermissionCheck(PERMISSIONS.LOCATIONS.VIEW);

  useEffect(() => {
    if (!selectedBusiness?.id || !hasPermissionToViewLocations) return;
    dispatch(getLocations(selectedBusiness?.id));
  }, [selectedBusiness?.id, hasPermissionToViewLocations]);

  return (
    <Select
      {...props}
      defaultValue={defaultLocation}
      showSearch
      filterOption={false}
      loading={loading || props.loading}
      onSearch={(term) => setSearchKey(term)}
      options={locations?.data
        ?.map((item) => ({ label: item.name, value: item.id }))
        .filter((item) => item.label.toLowerCase().includes(searchKey?.toLowerCase() || ''))}
    />
  );
};
