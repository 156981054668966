import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Button, Col, Form, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './EventTicketingBuilder.scss';

import GenericHeader from '@aduvi/components/Header/GenericHeader';
import { useBusiness } from '@aduvi/hooks';
import { EWidgetApps, ICreateWidget, IUpdateWidget } from '@aduvi/types/widget';

import { createWidget, getWidget, setSelectedWidget, updateWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Configure } from './tabs/events/configure/Configure';
import { Design } from './tabs/events/design/Design';
import { Embed } from './tabs/events/embed/Embed';

export const EventTicketingBuilder = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { widgetId } = useParams();
  const selectedBusiness = useBusiness();

  const { selectedWidget } = useAppSelector((state) => state.widget);

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('1');

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: translate('widgets.tabs.design'),
    },
    {
      key: '2',
      label: translate('widgets.tabs.configure'),
    },
    {
      key: '3',
      label: translate('widgets.tabs.embed'),
    },
  ];

  const onActiveTabChange = (tabKey: string) => {
    dispatch(
      setSelectedWidget({
        ...selectedWidget,
        ...form.getFieldsValue(),
        widget_style: { ...selectedWidget?.widget_style?.[0], ...form.getFieldValue(['widget_style']) },
      }),
    );
    setSelectedTab(tabKey);
  };

  const activeTab: Record<string, ReactNode> = {
    '1': <Design form={form} />,
    '2': (
      <Col className='flex-center mt-30'>
        <Configure form={form} />
      </Col>
    ),
    '3': (
      <Col className='flex-center mt-30'>
        <Embed form={form} />
      </Col>
    ),
  };

  const onSave = () => {
    const formValues = form.getFieldsValue();

    if (!widgetId) {
      const createParams: ICreateWidget = {
        business_id: selectedBusiness?.id || '',
        apps: EWidgetApps.EVENT_TICKETING,
        ...formValues,
        widget_style: formValues.widget_style,
      };
      dispatch(createWidget(createParams))
        .unwrap()
        .then(() => {
          navigate('/widgets/event-ticketing');
        })
        .catch(() => {});
    } else {
      const updateParams: IUpdateWidget = {
        id: widgetId,
        business_id: selectedBusiness?.id || '',
        apps: EWidgetApps.EVENT_TICKETING,
        ...selectedWidget,
        ...formValues,
        widget_style: formValues.widget_style ?? selectedWidget?.widget_style,
      };

      dispatch(updateWidget(updateParams))
        .unwrap()
        .then(() => {
          navigate('/widgets/event-ticketing');
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !widgetId || !selectedWidget) return;
    dispatch(getWidget({ businessId: selectedBusiness.id, widgetId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedWidget(data));
        form.setFieldsValue({ ...data });
      });
  }, [selectedBusiness?.id, widgetId]);

  return (
    <Form form={form} layout='vertical' requiredMark={false}>
      <Col span={24} className='widget-event-ticketing-wrapper'>
        <GenericHeader
          breadcrumbs={
            <Breadcrumb
              items={[
                { title: translate('widgets.title'), onClick: () => navigate(`/widgets/event-ticketing`), className: 'cursor-pointer' },
                { title: translate('widgets.events'), onClick: () => navigate(`/widgets/events-configure`), className: 'cursor-pointer' },
              ]}
            />
          }
          tabs={<Tabs defaultActiveKey='1' activeKey={selectedTab} items={tabs} onChange={onActiveTabChange} />}
          actions={
            <Button onClick={onSave} type='primary'>
              {translate('buttons.save')}
            </Button>
          }
        />

        <Col span={24}>{activeTab[selectedTab]}</Col>
      </Col>
    </Form>
  );
};
