import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import { EFieldDataValueType, IEntityField } from '@aduvi/types';

interface IFilterInputProps {
  field: IEntityField;
  index: number;
  form: FormInstance;
  isCard?: boolean;
  defaultOperator?: string;
}

export const FilterInputs = ({ field, index, form, isCard, defaultOperator }: IFilterInputProps) => {
  const { t: translate } = useTranslation();

  const operatorOptions = useMemo(() => {
    const dateOperators = [
      { label: translate('filters.operators.equals'), value: 'Is equal to' },
      { label: translate('filters.operators.greaterThan'), value: 'Is greater than' },
      { label: translate('filters.operators.lessThan'), value: 'Is less than' },
      { label: translate('filters.operators.between'), value: 'Is between' },
    ];

    return dateOperators;
  }, [translate]);

  // Set operator from defaultOperator prop if provided
  useEffect(() => {
    if (defaultOperator) {
      form.setFieldValue(['custom_fields', index, field.id, 'operator'], defaultOperator);
    } else if (field.value_type !== EFieldDataValueType.FIELD_DATA_DATE_TIMES) {
      form.setFieldValue(['custom_fields', index, field.id, 'operator'], 'Contains');
    }
  }, [field.id, field.value_type, form, index, defaultOperator]);

  const renderInputByOperator = () => {
    const operator = Form.useWatch(['custom_fields', index, field.id, 'operator'], form);

    const renderDateInput = () => {
      if (operator === 'Is between') {
        return (
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name={['custom_fields', index, field.id, 'startValue']} trigger='onChange'>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={() => {
                    const currentValues = form.getFieldsValue();
                    form.setFieldsValue(currentValues);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['custom_fields', index, field.id, 'endValue']} trigger='onChange'>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={() => {
                    const currentValues = form.getFieldsValue();
                    form.setFieldsValue(currentValues);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        );
      }
      return (
        <Form.Item name={['custom_fields', index, field.id, 'value']}>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      );
    };

    const renderNumberInput = () => {
      if (operator === 'Between') {
        return (
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name={['custom_fields', index, field.id, 'startValue']}>
                <InputNumber className='w-full' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['custom_fields', index, field.id, 'endValue']}>
                <InputNumber className='w-full' />
              </Form.Item>
            </Col>
          </Row>
        );
      }
      return (
        <Form.Item name={['custom_fields', index, field.id, 'value']}>
          <InputNumber className='w-full' />
        </Form.Item>
      );
    };

    switch (field.value_type) {
      case EFieldDataValueType.FIELD_DATA_DATE_TIMES:
        return renderDateInput();
      case EFieldDataValueType.FIELD_DATA_NUMBERS:
      case EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS:
        return renderNumberInput();
      case EFieldDataValueType.FIELD_DATA_LISTS:
        return (
          <Form.Item name={['custom_fields', index, field.id, 'value']} getValueFromEvent={(selected) => selected}>
            <Select
              style={{ minWidth: '150px' }}
              mode='multiple'
              options={field.list_values?.map((item) => ({
                label: item.value,
                value: item.value,
              }))}
            />
          </Form.Item>
        );
      default:
        return (
          <Form.Item name={['custom_fields', index, field.id, 'value']}>
            <Input />
          </Form.Item>
        );
    }
  };

  return (
    <Row gutter={[8, 8]}>
      <Col style={{ width: isCard ? '100%' : 'auto' }}>
        {field.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES && (
          <Form.Item name={['custom_fields', index, field.id, 'operator']}>
            <Select style={{ minWidth: 150 }} defaultValue={operatorOptions[0].value} options={operatorOptions} />
          </Form.Item>
        )}
      </Col>
      <Col style={{ width: isCard ? '100%' : 'auto' }}>{renderInputByOperator()}</Col>
    </Row>
  );
};
