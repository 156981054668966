import dayjs, { isDayjs } from 'dayjs';
import { isArray, isString } from 'lodash';

import {
  EFieldDataValueType,
  EReferenceEntityType,
  ICustomFieldValue,
  IEntityField,
  IFileField,
  IKeyValue,
  IKeyValueEditedEntityCustomFields,
  IUploadFile,
} from '@aduvi/types';
import { IAddressField, IDateTimeField, IEntityWithFields, IFieldData } from '@aduvi/types/entity';

import store from 'store/store';

const getDateTimeFormat = (field: IEntityField) => {
  if (field?.settings?.format?.toLowerCase()?.includes('hh')) {
    return `YYYY/MM/DD ${field?.settings?.format?.split(' ')?.[1]}`;
  }

  return `YYYY/MM/DD`;
};

const getFormFieldValue = (key: string, value: ICustomFieldValue, fields?: IEntityField[], contactEntityFields?: IEntityField[]): {} => {
  const field = fields?.find((item) => item.id === key);

  if (!value) return '';

  if (field?.value_type === EFieldDataValueType.FIELD_DATA_TIMES && isDayjs(value)) {
    return {
      time: {
        value: dayjs(value).format('HH:mm:ss'),
        format: 'HH:mm:ss',
      },
    };
  }
  if (field?.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES) {
    const format = getDateTimeFormat(field);

    if (field?.settings?.capture_end_date) {
      const { value: startDateTime, end_date_time } = value as IDateTimeField;

      return {
        date_time: {
          value: isDayjs(startDateTime) ? dayjs(startDateTime).format(format) : null,
          format: field?.settings?.format,
          end_date_time: end_date_time ? (isDayjs(end_date_time) ? dayjs(end_date_time).format(format) : null) : null,
        },
      };
    } else {
      return {
        date_time: {
          value: isDayjs(value) ? dayjs(value).format(format) : null,
          format: field?.settings?.format,
          end_date_time: null,
        },
      };
    }
  }
  if (field?.value_type === EFieldDataValueType.FIELD_DATA_ADDRESSES) {
    return { address: value as IAddressField };
  }
  if (field?.value_type === EFieldDataValueType.FIELD_DATA_LISTS && isArray(value)) {
    return {
      list_values: value,
    };
  }
  if (
    field?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE &&
    field?.reference_entity_type === EReferenceEntityType.CONTACT &&
    Array.isArray(value) &&
    !value.every((item) => typeof item === 'string')
  ) {
    return {
      custom_fields: (value as [])
        .filter((item) => item)
        .flatMap(
          (obj) =>
            Object?.entries(obj)?.map(([key, value]) => {
              return {
                field_id: key,
                ...getFormFieldValue(key, value as ICustomFieldValue, contactEntityFields),
              };
            }),
        ),
    };
  }
  if (field?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE) {
    return {
      entity_references: (isArray(value) ? value : Array(value)) as string[],
    };
  }

  if ([EFieldDataValueType.FIELD_DATA_FILES, EFieldDataValueType.FIELD_DATA_IMAGES]?.includes(field?.value_type as EFieldDataValueType)) {
    if (isString((value as IFileField)?.file?.url)) {
      return value;
    }
    return { file: value as IUploadFile };
  }

  if (field?.value_type === EFieldDataValueType.FIELD_DATA_SONGS) {
    return {
      values: value,
    };
  }

  return { value: value as string | number };
};

const transformFieldDataToForm = (value: IFieldData[], field: IEntityField) => {
  if (field.value_type === EFieldDataValueType.FIELD_DATA_ADDRESSES) {
    return {
      city: value?.[0]?.city,
      country: value?.[0]?.country,
      province: value?.[0]?.province,
      street_two: value?.[0]?.street_two,
      street_one: value?.[0]?.street_one,
      zip_code: value?.[0]?.zip_code,
    };
  }
  if (field.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES) {
    if (!value?.[0]?.value) return '';
    const format = getDateTimeFormat(field);
    if (field?.settings?.capture_end_date) {
      return {
        value: dayjs(value?.[0]?.value, format),
        end_date_time: value?.[0]?.end_date_time ? dayjs(value?.[0]?.end_date_time, format) : null,
      };
    } else {
      return dayjs(value?.[0]?.value, format);
    }
  }

  if (field.value_type === EFieldDataValueType.FIELD_DATA_TIMES) {
    if (!value?.[0]?.value) return '';
    return dayjs(value?.[0]?.value, 'HH:mm:ss');
  }
  if (field.value_type === EFieldDataValueType.FIELD_DATA_LISTS) {
    return value?.map((item) => item?.additional_data?.list_value_id);
  }
  if (field.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE) {
    return value.map((item) => item.id);
  }
  if ([EFieldDataValueType.FIELD_DATA_FILES, EFieldDataValueType.FIELD_DATA_IMAGES]?.includes(field.value_type)) {
    return {
      file: {
        url: value?.[0]?.url || '',
        size: value?.[0]?.size || '',
        extension: value?.[0]?.extension || '',
      },
    };
  }
  if (field.value_type === EFieldDataValueType.FIELD_DATA_SONGS) {
    return value?.map((songItem) => ({ song: songItem }));
  }
  return value?.[0]?.value;
};

// This is for creating entity through drawer
export const mapEntityFieldsToPayload = (data: IKeyValue[], fields: IEntityField[], contactEntityFields?: IEntityField[]) => {
  return data
    ?.filter((item) => item)
    .flatMap((obj) =>
      Object.entries(obj).map(([key, value]) => {
        return {
          field_id: key,
          ...getFormFieldValue(key, value, fields, contactEntityFields),
        };
      }),
    );
};

export const mapEntityFieldsToForm = (data: IEntityWithFields | IFieldData, fields: IEntityField[]) => {
  return fields
    ?.map((item) => {
      const custom_field = data?.custom_fields?.find((field) => field?.id === item?.id);
      if (custom_field) {
        return { [item?.id]: transformFieldDataToForm(custom_field?.field_data, item) };
      }
      return undefined;
    })
    .filter((item) => item);
};

// This is when edit the entities
export const mapEditedEntityFieldsToPayload = (
  data: IKeyValueEditedEntityCustomFields[],
  fields: IEntityField[],
  editedEntity?: IEntityWithFields | IFieldData,
) => {
  return data?.flatMap((obj) =>
    Object.entries(obj).map(([key, value]) => {
      const editedField = editedEntity?.custom_fields?.find((item) => item.id === key);
      const field = fields?.find((f) => f.id === key);

      if (field?.reference_entity_type === EReferenceEntityType.SHIFT) {
        return {
          field_id: key,
          field_data_id: editedField?.field_data?.[0]?.id,
          field_data: editedField?.field_data,
        };
      }

      return {
        field_data_id:
          editedField?.value_type === EFieldDataValueType.FIELD_DATA_LISTS || editedField?.value_type === EFieldDataValueType.FIELD_DATA_SONGS
            ? null
            : editedField?.field_data?.[0]?.id,
        field_id: key,
        ...getFormFieldValue(key, value, fields),
      };
    }),
  );
};

export const resolveUrl = (url: string): string => {
  const state = store.getState();
  const baseDomain = state.common?.layout?.platform_domain;
  const dynamicParams: { [key: string]: string | undefined } = {}; // handle dynamic id's

  let resolvedUrl = url.replace('{url}', baseDomain || '');

  if (baseDomain && resolvedUrl.startsWith(baseDomain)) {
    resolvedUrl = resolvedUrl.replace(baseDomain, '');
  }

  resolvedUrl = resolvedUrl.replace(/{(\w+)}/g, (_, param) => {
    return dynamicParams?.[param] || `{${param}}`;
  });

  return resolvedUrl;
};
