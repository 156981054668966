import { useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import { useTranslation } from 'react-i18next';
import { DesktopOutlined, FontSizeOutlined, LayoutOutlined, MobileOutlined, PictureOutlined } from '@ant-design/icons';
import { LayoutIconFive } from 'views/forms/assets/LayoutIconFive';
import { LayoutIconFour } from 'views/forms/assets/LayoutIconFour';
import { LayoutIconOne } from 'views/forms/assets/LayoutIconOne';
import { LayoutIconSix } from 'views/forms/assets/LayoutIconSix';
import { LayoutIconThree } from 'views/forms/assets/LayoutIconThree';
import { LayoutIconTwo } from 'views/forms/assets/LayoutIconTwo';
import { FormatIconOne } from 'views/forms/format/FormatIconOne';
import { FormatIconThree } from 'views/forms/format/FormatIconThree';
import { FormatIconTwo } from 'views/forms/format/FormatIconTwo';

import { Button, Col, Divider, Flex, Form, FormInstance, Popover, Radio, Row, Switch, Typography } from 'antd';
import './RightSidebar.scss';

import { ColorPicker as AntColorPicker } from '@aduvi/components/ColorPicker/ColorPicker';
import { GradientColorPicker } from '@aduvi/components/ColorPicker/GradientColorPicker';
import { Title } from '@aduvi/components/Title';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { EShoppingBasketPosition } from '@aduvi/constants';
import { FONT_SELECTION } from '@aduvi/constants/client-portal-constants';
import { useActiveTheme } from '@aduvi/hooks';
import { EFormFormat, EFormLayout } from '@aduvi/types/form';
import { determinePickerColor, isValidUrl } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

interface IProps {
  form: FormInstance;
  onMobileView: () => void;
  isMobileView: boolean;
}

export const RightSidebar = ({ form, onMobileView, isMobileView }: IProps) => {
  const activeTheme = useActiveTheme();
  const { t: translate } = useTranslation();

  const { selectedEntityForm } = useAppSelector((state) => state.forms);
  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);

  const [backgroundMode, setBackgroundMode] = useState('colour');

  Form.useWatch('form_style', form);

  return (
    <Form.Item className='right-sidebar-wrapper'>
      <Flex vertical>
        <Popover
          trigger={'click'}
          content={
            <>
              <Form.Item name={['form_style', 0, 'background_mode']} initialValue={selectedEntityForm?.style?.form_style?.[0]?.background_mode}>
                <Radio.Group
                  onChange={(event) => {
                    setBackgroundMode(event.target.value);
                    form.setFieldValue(['form_style', 0, 'background_mode'], event.target.value);
                  }}
                  className='flex-center mb-10'
                  value={backgroundMode}
                  defaultValue={form.getFieldValue(['form_style', 0, 'background_mode'])}>
                  <Radio.Button style={{ width: '100px', textAlign: 'center' }} value='colour'>
                    {translate('forms.forms.colour')}
                  </Radio.Button>
                  <Radio.Button style={{ width: '100px', textAlign: 'center' }} value='image'>
                    {translate('forms.forms.image')}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              {backgroundMode === 'colour' ? (
                <Form.Item name={['form_style', 0, 'background_color']} className='flex-center' getValueFromEvent={determinePickerColor}>
                  <ColorPicker onChange={() => {}} width={230} height={150} />
                </Form.Item>
              ) : (
                <>
                  <Form.Item name={['form_style', 0, 'background_image']}>
                    <UploadImage
                      name={'image'}
                      onUploadChange={(value) => form.setFieldValue(['form_style', 0, 'background_image'], value?.url)}
                      text={translate('forms.forms.uploadYourOwn')}
                      onPreview={(value) => form.setFieldValue(['form_style', 0, 'background_image'], value.thumbUrl)}
                      fileList={
                        isValidUrl(form.getFieldValue(['form_style', 0, 'background_image']))
                          ? [
                              {
                                name: form.getFieldValue(['form_style', 0, 'background_image']).split('/uploads/')[1],
                                url: form.getFieldValue(['form_style', 0, 'background_image']),
                                uid: '-1',
                                status: 'done',
                              },
                            ]
                          : []
                      }
                    />
                    <Typography.Text type='secondary'>{translate('forms.forms.selectFromLibrary')}</Typography.Text>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <Col
                        style={{
                          border:
                            form.getFieldValue(['form_style', 0, 'background_image']) === `assets/images/theme-${index + 1}.png`
                              ? `2px dashed ${selectedClientPortal?.brand?.brand_color || '#7cc0ff'} `
                              : '',
                        }}
                        key={index}
                        className='mt-10 cursor-pointer'
                        onClick={() => form.setFieldValue(['form_style', 0, 'background_image'], `assets/images/theme-${index + 1}.png`)}>
                        <img src={require(`assets/images/theme-${index + 1}.png`)} />
                      </Col>
                    ))}
                  </Form.Item>
                </>
              )}
            </>
          }
          placement='leftTop'>
          <PictureOutlined className='icon' />
        </Popover>

        <Popover
          trigger={'click'}
          content={
            <div className='form-layout-popover'>
              <Typography.Text type='secondary'>{translate('forms.forms.format')}</Typography.Text>
              <Form.Item name={['form_style', 0, 'format']}>
                <Radio.Group className='w-full'>
                  <Row justify={'space-between'} className='mb-10 mt-5'>
                    <Radio.Button value={EFormFormat.SINGLE_PAGE} className='layout-button'>
                      <FormatIconOne active={form.getFieldValue(['form_style', 0, 'format']) === EFormFormat.SINGLE_PAGE} />
                    </Radio.Button>
                    <Radio.Button value={EFormFormat.MULTI_PAGE_OPTIONAL} className='layout-button'>
                      <FormatIconTwo active={form.getFieldValue(['form_style', 0, 'format']) === EFormFormat.MULTI_PAGE_OPTIONAL} />
                    </Radio.Button>
                    <Radio.Button value={EFormFormat.MULTI_PAGE_INDIVIDUAL} className='layout-button'>
                      <FormatIconThree active={form.getFieldValue(['form_style', 0, 'format']) === EFormFormat.MULTI_PAGE_INDIVIDUAL} />
                    </Radio.Button>
                  </Row>
                </Radio.Group>
              </Form.Item>

              {form.getFieldValue(['form_style', 0, 'format']) !== EFormFormat.SINGLE_PAGE && (
                <Row>
                  <Row align={'middle'}>
                    <Form.Item name={['form_style', 0, 'progress_bar']} className='mb-0'>
                      <Switch className='mb-0' />
                    </Form.Item>
                    <Typography.Paragraph className='mb-0 ml-10'>{translate('forms.forms.showProgressBar')}</Typography.Paragraph>
                  </Row>
                </Row>
              )}

              <Divider style={{ margin: '12px 0' }} />
              <Typography.Text type='secondary'>{translate('forms.forms.layout')}</Typography.Text>
              <Form.Item name={['form_style', 0, 'layout']}>
                <Radio.Group className='w-full'>
                  <Row>
                    <Radio.Button value={EFormLayout.BACKGROUND_FULL_CONTENT_LEFT} className='layout-button'>
                      <LayoutIconOne active={form.getFieldValue(['form_style', 0, 'layout']) === EFormLayout.BACKGROUND_FULL_CONTENT_LEFT} />
                    </Radio.Button>
                    <Radio.Button value={EFormLayout.BACKGROUND_FULL_CONTENT_CENTER} className='layout-button'>
                      <LayoutIconTwo active={form.getFieldValue(['form_style', 0, 'layout']) === EFormLayout.BACKGROUND_FULL_CONTENT_CENTER} />
                    </Radio.Button>
                    <Radio.Button value={EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT} className='layout-button'>
                      <LayoutIconThree active={form.getFieldValue(['form_style', 0, 'layout']) === EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT} />
                    </Radio.Button>
                  </Row>
                  <Row>
                    <Radio.Button value={EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT} className='layout-button'>
                      <LayoutIconFour active={form.getFieldValue(['form_style', 0, 'layout']) === EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT} />
                    </Radio.Button>
                    <Radio.Button value={EFormLayout.CONTENT_FULL_NO_BACKGROUND} className='layout-button'>
                      <LayoutIconFive active={form.getFieldValue(['form_style', 0, 'layout']) === EFormLayout.CONTENT_FULL_NO_BACKGROUND} />
                    </Radio.Button>
                    <Radio.Button value={EFormLayout.CONTENT_RIGHT_BACKGROUND_LEFT} className='layout-button'>
                      <LayoutIconSix active={form.getFieldValue(['form_style', 0, 'layout']) === EFormLayout.CONTENT_RIGHT_BACKGROUND_LEFT} />
                    </Radio.Button>
                  </Row>
                </Radio.Group>
              </Form.Item>
              <Row align={'middle'}>
                <Form.Item name={['form_style', 0, 'shopping_basket']} className='mb-0'>
                  <Switch className='mb-0' />
                </Form.Item>
                <Typography.Paragraph className='mb-0 ml-10'>{translate('forms.forms.showShoppingBasket')}</Typography.Paragraph>
              </Row>
              {form.getFieldValue(['form_style', 0, 'shopping_basket']) && (
                <Form.Item name={['form_style', 0, 'shopping_basket_position']}>
                  <Radio.Group className='mt-10 w-full'>
                    <Row className='w-full'>
                      <Col span={12}>
                        <Radio.Button value={EShoppingBasketPosition.TOP_LEFT} className='w-full fs-11'>
                          {translate('forms.forms.topLeft')}
                        </Radio.Button>
                      </Col>
                      <Col span={12}>
                        <Radio.Button value={EShoppingBasketPosition.TOP_RIGHT} className='w-full fs-11'>
                          {translate('forms.forms.topRight')}
                        </Radio.Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Radio.Button value={EShoppingBasketPosition.BOTTOM_LEFT} className='w-full fs-11'>
                          {translate('forms.forms.bottomLeft')}
                        </Radio.Button>
                      </Col>
                      <Col span={12}>
                        <Radio.Button value={EShoppingBasketPosition.BOTTOM_RIGHT} className='w-full fs-11'>
                          {translate('forms.forms.bottomRight')}
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              )}
            </div>
          }
          placement='leftTop'>
          <LayoutOutlined className='icon' />
        </Popover>

        <Popover
          trigger={'click'}
          overlayClassName='colour-popover'
          content={
            <Col style={{ width: 200 }}>
              <Title className='popover-font-title pb-5'>{translate('settings.contracts.editContract.colours')}</Title>

              <Row gutter={8} align={'middle'} className='mb-10'>
                <Col>
                  <Form.Item
                    name={['form_style', 0, 'primary_font_color']}
                    initialValue={'#000000'}
                    getValueFromEvent={determinePickerColor}
                    className='mb-0'>
                    <AntColorPicker />
                  </Form.Item>
                </Col>
                <Col>
                  <Typography.Text>{translate('settings.contracts.editContract.primaryFont')}</Typography.Text>
                </Col>
              </Row>

              <Row gutter={8} className='mb-10' align={'middle'}>
                <Col>
                  <Form.Item
                    name={['form_style', 0, 'secondary_font_color']}
                    initialValue={'#000000'}
                    getValueFromEvent={determinePickerColor}
                    className='mb-0'>
                    <AntColorPicker />
                  </Form.Item>
                </Col>
                <Col>
                  <Typography.Text>{translate('settings.contracts.editContract.secondaryFont')}</Typography.Text>
                </Col>
              </Row>

              <Row gutter={8} className='mb-10' align={'middle'}>
                <Col>
                  <Form.Item
                    name={['form_style', 0, 'form_background']}
                    initialValue={'#ffffff'}
                    getValueFromEvent={determinePickerColor}
                    className='mb-0'>
                    <GradientColorPicker />
                  </Form.Item>
                </Col>
                <Col>
                  <Typography.Text>{translate('settings.contracts.editContract.formBackground')}</Typography.Text>
                </Col>
              </Row>

              <Divider className='w-full' />

              <Title className='popover-font-title pb-5 pl-5'>{translate('settings.contracts.editContract.font')}</Title>

              {FONT_SELECTION.map((font) => (
                <Row key={font} gutter={8} className='mb-5' align={'middle'}>
                  <Col>
                    <Form.Item name={['form_style', 0, 'font_family']} initialValue={'Roboto'} className='mb-0'>
                      <Button
                        className='pl-5'
                        type='link'
                        style={{
                          fontFamily: font,
                          color: font === form.getFieldValue(['form_style', 0, 'font_family']) ? activeTheme?.menuActiveText : activeTheme?.menuText,
                        }}
                        onClick={() => {
                          form.setFieldValue(['form_style', 0, 'font_family'], font);
                        }}>
                        {font}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </Col>
          }
          placement='leftTop'>
          <FontSizeOutlined className='icon' />
        </Popover>

        {isMobileView ? <DesktopOutlined className='icon' onClick={onMobileView} /> : <MobileOutlined className='icon' onClick={onMobileView} />}
      </Flex>
    </Form.Item>
  );
};
