import { useEffect, useMemo, useState } from 'react';
import { Chart as ChartComponent } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EGranularity, ITransactionReportData } from '@aduvi/types';

import { getTransactionReport } from 'store/services/report.service';

import 'chart.js/auto';

import { getDateFormat } from './helper';
dayjs.extend(customParseFormat);
interface IFinancialChartProps {
  granularity: EGranularity;
  dateRange: [Dayjs | null, Dayjs | null] | null;
}
function TransactionsChart({ granularity, dateRange }: IFinancialChartProps) {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ITransactionReportData[]>([]);

  const chartsData = useMemo(() => {
    const labels = data.map((item) => getDateFormat(item.period, granularity));
    const datasets = [
      {
        label: translate('reports.transactions.completed'),
        data: data.map((item) => item.transactions.COMPLETED),
      },
      {
        label: translate('reports.transactions.failed'),
        data: data.map((item) => item.transactions.FAILED),
      },
      {
        label: translate('reports.transactions.pending'),
        data: data.map((item) => item.transactions.PENDING),
      },
      {
        label: translate('reports.transactions.canceled'),
        data: data.map((item) => item.transactions.CANCELED),
      },
      {
        label: translate('reports.transactions.unknown'),
        data: data.map((item) => item.transactions.UNKNOWN),
      },
    ];
    return {
      labels,
      datasets,
    };
  }, [data, getDateFormat, granularity, dateRange]);

  useEffect(() => {
    if (!selectedBusiness?.id || !dateRange) return;
    setIsLoading(true);
    getTransactionReport({
      business_id: selectedBusiness?.id,
      start_date: dateRange?.[0]?.format('YYYY-MM-DD') || '',
      end_date: dateRange?.[1]?.format('YYYY-MM-DD') || '',
      granularity,
    }).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [selectedBusiness?.id, granularity, dateRange]);

  return (
    <>
      <Spin spinning={isLoading}> </Spin>

      {data.length > 0 && !isLoading && (
        <div className='chart-container'>
          <div className='chart'>
            <ChartComponent
              type={'bar'}
              data={chartsData}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: `${translate('reports.transactions.title')}`,
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default TransactionsChart;
