import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Button, Col, Form, Input, Popover, Row, Select, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import '../../ShiftScheduler.scss';

import { useBusiness } from '@aduvi/hooks';
import { ETravelType, EWageStatus, EWageTypeShift } from '@aduvi/types/shift-scheduler';

import { calculateWage, deleteShift } from 'store/features/shift-scheduler-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface ShiftDetailsModalProps {
  isVisible: boolean;
  form: FormInstance;
  index: number;
  remove: (index: number | number[]) => void;
  onClose: () => void;
  entityId: string;
}

export const WagesPopover = ({ isVisible, onClose, form, index, remove, entityId }: ShiftDetailsModalProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const [shiftWageTotal, setShiftWageTotal] = useState<number | undefined>();
  const [durationValue, setDurationValue] = useState<number | undefined>();

  const { settings } = useAppSelector((state) => state.shiftScheduler);

  const isAutoCalculateEnabled = settings?.auto_calculate_wages;

  Form.useWatch(['shifts', index, 'user_id'], form);
  Form.useWatch(['shifts', index, 'assigned_id'], form);
  Form.useWatch(['shifts', index, 'start_shift'], form);
  Form.useWatch(['shifts', index, 'end_shift'], form);

  useEffect(() => {
    if (isAutoCalculateEnabled && selectedBusiness?.id) {
      const userId = form.getFieldValue(['shifts', index, 'user_id']);
      const assignedId = form.getFieldValue(['shifts', index, 'assigned_id']);
      const startShift = form.getFieldValue(['shifts', index, 'start_shift']);
      const endShift = form.getFieldValue(['shifts', index, 'end_shift']);

      if (startShift && endShift) {
        const startDate = dayjs().startOf('day').add(startShift, 'minutes');
        const endDate = dayjs().startOf('day').add(endShift, 'minutes');

        const formattedStartShift = startDate.format('YYYY-MM-DD HH:mm:ss');
        const formattedEndShift = endDate.format('YYYY-MM-DD HH:mm:ss');

        dispatch(
          calculateWage({
            businessId: selectedBusiness?.id,
            startShift: formattedStartShift,
            endShift: formattedEndShift,
            ...(userId && { userId }),
            ...(assignedId && { position: assignedId }),
          }),
        )
          .unwrap()
          .then((response) => {
            if (response) {
              const { wage_total, duration } = response;

              setDurationValue(duration);
              setShiftWageTotal(wage_total);

              form.setFieldsValue({
                shifts: {
                  [index]: {
                    wage_amount: wage_total,
                    wage_type: duration ? EWageTypeShift.PER_HOUR : EWageTypeShift.PER_SHIFT,
                    duration,
                    status: EWageStatus.CONFIRMED,
                  },
                },
              });
            }
          })
          .catch(() => {});
      }
    }
  }, [
    isAutoCalculateEnabled,
    form.getFieldValue(['shifts', index, 'user_id']),
    form.getFieldValue(['shifts', index, 'assigned_id']),
    form.getFieldValue(['shifts', index, 'start_shift']),
    form.getFieldValue(['shifts', index, 'end_shift']),
  ]);

  useEffect(() => {
    const shifts = form.getFieldValue('shifts') || [];
    if (!shifts[index]) {
      form.setFieldValue(['shifts', index], {
        wage_amount: 0,
        wage_type: 'PER_SHIFT',
        duration: 0,
        travel_amount: 0,
        travel_type: ETravelType.PER_HOUR,
      });
    }
  }, []);

  const { TextArea } = Input;

  Form.useWatch('shifts', form);

  const popoverContent = (
    <div style={{ width: 200 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <div>
          <Typography.Text>{translate('settings.shiftScheduler.status')}</Typography.Text>
          <Form.Item name={['shifts', index, 'status']} className='mb-0'>
            <Select
              placeholder='Status'
              className='w-full'
              size='small'
              options={[
                { label: translate('settings.shiftScheduler.confirmed'), value: EWageStatus.CONFIRMED },
                { label: translate('settings.shiftScheduler.declined'), value: EWageStatus.DECLINED },
              ]}
              defaultValue={form.getFieldValue(['shifts', index, 'status'])}
              onChange={(value) => {
                form.setFieldValue(['shifts', index, 'status'], value);
              }}
            />
          </Form.Item>
        </div>

        <Typography.Text>{translate('settings.shiftScheduler.wages.wage')}</Typography.Text>
        <Row gutter={8}>
          <Col span={12}>
            {isAutoCalculateEnabled ? (
              <Input
                addonBefore='$'
                size='small'
                placeholder='Amount'
                min={0}
                value={shiftWageTotal}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setShiftWageTotal(value);
                  form.setFieldValue(['shifts', index, 'wage_amount'], value);
                }}
              />
            ) : (
              <Form.Item className='m-0' name={['shifts', index, 'wage_amount']}>
                <Input addonBefore='$' size='small' placeholder='Amount' min={0} />
              </Form.Item>
            )}
          </Col>

          <Col span={12}>
            {isAutoCalculateEnabled ? (
              <Input size='small' placeholder='Duration' min={0} value={durationValue} disabled addonAfter='hrs' />
            ) : (
              <Form.Item className='m-0' name={['shifts', index, 'wage_type']}>
                <Select
                  placeholder='Wage Type'
                  className='w-full'
                  size='small'
                  options={[
                    { label: translate('settings.shiftScheduler.wages.perHour'), value: EWageTypeShift.PER_HOUR },
                    { label: translate('settings.shiftScheduler.wages.perShift'), value: EWageTypeShift.PER_SHIFT },
                  ]}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Typography.Text>{translate('settings.shiftScheduler.wages.travel')}</Typography.Text>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item className='m-0' name={['shifts', index, 'travel_amount']}>
              <Input
                onChange={(e) => {
                  form.setFieldValue(['shifts', index, 'travel_amount'], e.target.value);
                }}
                addonBefore='$'
                size='small'
                placeholder='Amount'
                min={0}
                defaultValue={form.getFieldValue(['shifts', index, 'travel_amount'])}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className='m-0' name={['shifts', index, 'travel_type']}>
              <Select
                placeholder='Travel Type'
                className='w-full'
                size='small'
                options={[
                  { label: translate('settings.shiftScheduler.wages.perHour'), value: ETravelType.PER_HOUR },
                  { label: translate('settings.shiftScheduler.wages.perMile'), value: ETravelType.PER_MILE },
                ]}
                onChange={(value) => {
                  form.setFieldValue(['shifts', index, 'travel_type'], value);
                }}
                defaultValue={form.getFieldValue(['shifts', index, 'travel_type'])}
              />
            </Form.Item>
          </Col>
        </Row>

        <Typography.Text>{translate('settings.shiftScheduler.notes')}</Typography.Text>
        <Form.Item name={['shifts', index, 'notes']} className='mb-0'>
          <TextArea
            placeholder='Notes'
            defaultValue={form.getFieldValue(['shifts', index, 'notes'])}
            onChange={(e) => {
              form.setFieldValue(['shifts', index, 'notes'], e.target.value);
            }}
          />
        </Form.Item>

        <Button
          type='link'
          icon={<DeleteOutlined />}
          style={{ color: '#000', padding: 0, textAlign: 'left', marginTop: '-4px' }}
          onClick={() => {
            const shift = form.getFieldValue(['shifts', index]);

            if (shift?.id && selectedBusiness?.id) {
              try {
                dispatch(
                  deleteShift({
                    businessId: selectedBusiness?.id,
                    entityId,
                    shiftId: shift?.id,
                  }),
                ).unwrap();
              } catch (error) {
                return;
              }
            }

            remove(index);
            onClose();
          }}>
          {translate('settings.shiftScheduler.delete')}
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName='custom-popover box-shadow'
      content={popoverContent}
      trigger='click'
      open={isVisible}
      onOpenChange={(visible) => !visible && onClose()}
      placement='bottom'
    />
  );
};
