import { Modal, Row } from 'antd';
import './SupportModal.scss';

import { SupportModalTabs } from './SupportModalTabs/SupportModalTabs';

interface IProps {
  open: boolean;
  setSupportModalVisible: (value: React.SetStateAction<boolean>) => void;
  setNotificationsNumber: (value: React.SetStateAction<number>) => void;
}

export const SupportModal = ({ open, setSupportModalVisible, setNotificationsNumber }: IProps) => {
  return (
    <Modal
      width={1018}
      open={open}
      centered
      rootClassName='support-modal'
      styles={{ content: { borderRadius: 8 } }}
      footer={null}
      destroyOnClose
      onCancel={() => setSupportModalVisible(false)}
      title={<SupportModalTabs setSupportModalVisible={setSupportModalVisible} setNotificationsNumber={setNotificationsNumber} />}>
      <Row className='support-modal-content w-full ' />
    </Modal>
  );
};
