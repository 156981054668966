import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import {
  EPersonalizedViewOrigin,
  ICreatePersonalizedViewPayload,
  IFilter,
  IPersonalizedView,
  IReorderPersonalizedViewPayload,
  IUpdatePersonalizedViewPayload,
} from '@aduvi/types/personalized-views';

export const getPersonalizedView = async (business_id: string, origin: EPersonalizedViewOrigin): Promise<IResponse<IPersonalizedView[]>> => {
  return await axios.get(`/businesses/${business_id}/personalized-views`, { params: { origin } });
};

export const createPersonalizedView = async (params: ICreatePersonalizedViewPayload): Promise<IResponse<IPersonalizedView>> => {
  return await axios.post(`/businesses/${params.business_id}/personalized-views`, params.body);
};

export const getPersonalizedViewById = async (business_id: string, personalizedViewId: string): Promise<IResponse<IPersonalizedView>> => {
  return await axios.get(`/businesses/${business_id}/personalized-views/${personalizedViewId}`);
};

export const editPersonalizedView = async (params: IUpdatePersonalizedViewPayload): Promise<IResponse<IPersonalizedView>> => {
  return await axios.put(`/businesses/${params.business_id}/personalized-views/${params.personalizedViewId}`, params.body);
};
export const reorderPersonsalizedView = async (params: IReorderPersonalizedViewPayload): Promise<IResponse<IPersonalizedView[]>> => {
  return await axios.put(`/businesses/${params.business_id}/personalized-views/re-order?Origin=${params.origin}`, {
    personalized_views: params.personalized_views,
  });
};

export const deletePersonalizedView = async (
  business_id: string,
  personalizedViewId: string,
  name: string,
): Promise<IResponse<IPersonalizedView>> => {
  return await axios.delete(`/businesses/${business_id}/personalized-views/${personalizedViewId}?title=${name}`);
};

export const duplicatePersonalizedView = async (business_id: string, personalizedViewId: string): Promise<IResponse<IPersonalizedView>> => {
  return await axios.post(`/businesses/${business_id}/personalized-views/${personalizedViewId}/duplicate`);
};

export const getPersonalizedViewFilters = async (
  business_id: string,
  personalizedViewId: string,
): Promise<IResponse<{ id: string; filters: IFilter[] }>> => {
  return await axios.get(`/businesses/${business_id}/filters/${personalizedViewId}`);
};

export const createPersonalizedViewFilters = async (
  business_id: string,
  personalizedViewId: string,
  filters: IFilter[],
): Promise<IResponse<{ id: string; filters: IFilter[] }>> => {
  return await axios.post(`/businesses/${business_id}/filters`, { view_id: personalizedViewId, filters });
};

export const editPersonalizedViewFilters = async (
  business_id: string,
  personalizedViewId: string,
  filters: IFilter[],
  filters_id: string,
): Promise<IResponse<{ id: string; filters: IFilter[] }>> => {
  return await axios.put(`/businesses/${business_id}/filters/${filters_id}`, { view_id: personalizedViewId, filters });
};
