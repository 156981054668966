import { useTranslation } from 'react-i18next';

import { Avatar, Col, Popover, Row, Typography } from 'antd';
import '../../ShiftScheduler.scss';

import { ISelectedItem } from '@aduvi/types/shift-scheduler';

import { useAppSelector } from 'store/hooks';

interface PositionsServicesPopoverProps {
  isVisible: boolean;
  onClose: () => void;
  onSelect: (item: ISelectedItem) => void;
}

export const PositionsPopover = ({ isVisible, onClose, onSelect }: PositionsServicesPopoverProps) => {
  const { t: translate } = useTranslation();

  const { settings } = useAppSelector((state) => state.shiftScheduler);
  const { services } = useAppSelector((state) => state.services);
  const { products } = useAppSelector((state) => state.products);

  const popoverContent = (
    <div style={{ maxWidth: 250 }}>
      {settings?.assign_user_to_positions && settings?.positions && (
        <>
          <Typography.Text className='fs-12 color-gray-5 mb-10'>{translate('settings.shiftScheduler.wages.positions')}</Typography.Text>
          {settings?.positions.map((position) => (
            <Row
              key={position.id}
              align='middle'
              className='mt-10 mb-10 cursor-pointer'
              onClick={() => {
                onClose();
                onSelect(position);
              }}>
              <Col span={4}>
                <Avatar size='small'>
                  <span>{position?.position?.slice(0, 2)}</span>
                </Avatar>
              </Col>
              <Col span={20}>
                <Typography.Text className='ml-10'>{position?.position}</Typography.Text>
              </Col>
            </Row>
          ))}
        </>
      )}

      {settings?.assign_user_to_services && Array.isArray(services?.data) && services?.data && services?.data?.length > 0 && (
        <>
          <Typography.Text className='fs-12 color-gray-5 mb-10'>{translate('settings.shiftScheduler.wages.services')}</Typography.Text>
          {services?.data?.map((service) => (
            <Row
              key={service.id}
              align='middle'
              className='mt-10 mb-10 cursor-pointer'
              onClick={() => {
                onClose();
                onSelect(service);
              }}>
              <Col span={4}>
                {service.image ? (
                  <Avatar size='small' src={service?.image} />
                ) : (
                  <Avatar size='small'>
                    <span>{service?.name?.slice(0, 2)}</span>
                  </Avatar>
                )}
              </Col>
              <Col span={20}>
                <Typography.Text className='ml-10'>{service?.name}</Typography.Text>
              </Col>
            </Row>
          ))}
        </>
      )}

      {settings?.assign_user_to_products && Array.isArray(products?.data) && products?.data && products?.data?.length > 0 && (
        <>
          <Typography.Text className='fs-12 color-gray-5 mb-10'>{translate('settings.shiftScheduler.wages.products')}</Typography.Text>
          {products?.data?.map((product) => (
            <Row
              key={product.id}
              align='middle'
              className='mt-10 mb-10 cursor-pointer'
              onClick={() => {
                onClose();
                onSelect(product);
              }}>
              <Col span={4}>
                {product?.image ? (
                  <Avatar src={product?.image} size='small' />
                ) : (
                  <Avatar size='small'>
                    <span>{product?.name?.slice(0, 2)}</span>
                  </Avatar>
                )}
              </Col>
              <Col span={20}>
                <Typography.Text className='ml-10'>{product?.name}</Typography.Text>
              </Col>
            </Row>
          ))}
        </>
      )}
    </div>
  );

  return (
    <Popover
      overlayClassName='custom-popover box-shadow'
      content={popoverContent}
      trigger='click'
      open={isVisible}
      onOpenChange={(visible) => {
        if (!visible) {
          onClose();
        }
      }}
      placement='bottom'
    />
  );
};
