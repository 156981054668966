import { CreditCardOutlined, DashboardOutlined, DeploymentUnitOutlined, FileTextOutlined, MessageOutlined } from '@ant-design/icons';

const getActionIconMap = (): Record<string, JSX.Element> => ({
  'Start Conversation': <MessageOutlined className='color-black cursor-pointer fs-12' />,
  'View Conversation': <MessageOutlined className='color-black cursor-pointer fs-12' />,
  'Add Payment': <CreditCardOutlined className='color-black cursor-pointer fs-12' />,
  'Edit Proposal': <FileTextOutlined className='color-black cursor-pointer fs-12' />,
  'Create Proposal': <FileTextOutlined className='color-black cursor-pointer fs-12' />,
  'View Automations': <DeploymentUnitOutlined className='color-black cursor-pointer fs-12' />,
  'View Client Portal': <DashboardOutlined className='color-black cursor-pointer fs-12' />,
});
export const actionIconMap = getActionIconMap();
