import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import { IPaymentGatewaySetting, IPaymentGatewaySettingResponse, IUpdatePaymentGatewaySettings } from '@aduvi/types/payments-method';

export const getAllPaymentGatewaySettings = async (businessId: string): Promise<IResponse<IPaymentGatewaySetting[]>> => {
  return await axios.get(`businesses/${businessId}/payment-gateway-settings`);
};

export const connectPaymentGatewaySettings = async (payload: IPaymentGatewaySetting): Promise<IResponse<IPaymentGatewaySettingResponse>> => {
  return await axios.post(`/businesses/${payload.business_id}/payment-gateway-settings/connect`, payload);
};

export const disconnectPaymentGatewaySettings = async (businessId: string, id: string): Promise<IResponse<IPaymentGatewaySetting>> => {
  return await axios.delete(`/businesses/${businessId}/payment-gateway-settings/${id}/disconnect`);
};

export const updatePaymentGatewaySettings = async (payload: {
  businessId: string;
  data: IUpdatePaymentGatewaySettings;
}): Promise<IResponse<IPaymentGatewaySetting>> => {
  return await axios.put(`/businesses/${payload.businessId}/payment-gateway-settings/${payload.data.id}`, JSON.stringify(payload?.data?.options));
};

export const stripeAuthorizationCodeCallback = async (business_id: string, code: string): Promise<IResponse<IPaymentGatewaySetting>> => {
  return await axios.post(`/businesses/${business_id}/payment-gateway-settings/stripe/callback`, { business_id, code });
};

export const squareAuthorizationCodeCallback = async (business_id: string, code: string) => {
  return await axios.post(`/businesses/${business_id}/payment-gateway-settings/square/callback`, { business_id, code });
};

export const squareCreatePayment = async (business_id: string, amount: number, source_id: string) => {
  return await axios.post(`/businesses/${business_id}/payment-gateway-settings/square/payment`, { business_id, amount, source_id });
};

export const stripePaymentIntent = async (business_id: string, amount: number): Promise<IResponse<{ client_secret: string }>> => {
  return await axios.post(`/businesses/${business_id}/payment-gateway-settings/stripe/payment-intent`, { amount });
};

export const paypalCallback = async (business_id: string): Promise<IResponse<IPaymentGatewaySetting>> => {
  return await axios.get(`/businesses/${business_id}/payment-gateway-settings/paypal/callback`);
};

export const paypalCreateOrder = async (business_id: string, amount: number): Promise<IResponse<{ order_id: string }>> => {
  return await axios.post(`/businesses/${business_id}/payment-gateway-settings/paypal/create-order`, { amount });
};

export const paypalCompleteOrder = async (business_id: string, orderId: string) => {
  return await axios.post(`/businesses/${business_id}/payment-gateway-settings/paypal/complete-order/${orderId}`);
};

export const makeDefaultPayment = async (business_id: string, payment_getaway_setting_id: string) => {
  return await axios.put(`/businesses/${business_id}/payment-gateway-settings/${payment_getaway_setting_id}/default`, {});
};

export const paymentTransaction = async (payload: {
  business_id: string;
  quote_id: string;
  payment_id: string;
}): Promise<IResponse<IPaymentGatewaySetting>> => {
  return await axios.post(`/businesses/${payload.business_id}/payments/transactions/${payload.quote_id}`, { payment_id: payload.payment_id });
};
