import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DatePicker, Form, Input, Modal, Select } from 'antd';

import { useBusinessCurrency, useBusinessId } from '@aduvi/hooks';

import { createManualPayment, getAllManualPaymentOptions } from 'store/features/manual-payment-option-slice';
import { addPayment } from 'store/features/quote-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const { Option } = Select;

interface ManualPaymentModalProps {
  isOpen: boolean;
  quoteId: string;
  onClose: () => void;
}

export const ManualPaymentModal = ({ isOpen, onClose, quoteId }: ManualPaymentModalProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusinessId = useBusinessId();
  const businessCurrency = useBusinessCurrency();
  const [form] = Form.useForm();

  const { manualPaymentOptions, loading } = useAppSelector((state) => state.manualPaymentOption);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(createManualPayment({ body: values, business_id: selectedBusinessId, quoteId }))
          .unwrap()
          .then(({ data }) => {
            dispatch(addPayment(data));
          });
        form.resetFields();
        onClose();
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (isOpen && selectedBusinessId) {
      dispatch(getAllManualPaymentOptions(selectedBusinessId));
    }
  }, [isOpen, selectedBusinessId]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        title={translate('payments.manualPaymentModal.title')}
        open={isOpen}
        onOk={handleOk}
        onCancel={onClose}
        okText={translate('payments.manualPaymentModal.save')}
        cancelText={translate('payments.manualPaymentModal.cancel')}>
        <Form
          form={form}
          layout='vertical'
          initialValues={{
            payment_date: dayjs(),
          }}>
          <Form.Item
            name='manual_payment_option_id'
            label={translate('payments.manualPaymentModal.paymentMethod')}
            rules={[{ required: true, message: translate('payments.manualPaymentModal.paymentMethodRequired') }]}>
            <Select placeholder={translate('payments.manualPaymentModal.selectPlaceholder')} loading={loading} disabled={loading}>
              {manualPaymentOptions?.map((opt) => (
                <Option key={opt.id} value={opt.id}>
                  {opt.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='amount'
            label={translate('payments.manualPaymentModal.amount')}
            rules={[{ required: true, message: translate('payments.manualPaymentModal.amountRequired') }]}>
            <Input type='number' addonBefore={businessCurrency?.code || '$'} placeholder={translate('payments.manualPaymentModal.enterAmount')} />
          </Form.Item>

          <Form.Item name='payment_date' label={translate('payments.manualPaymentModal.paymentDate')}>
            <DatePicker showTime format='YYYY-MM-DD HH:mm' placeholder={translate('payments.manualPaymentModal.selectDate')} className='w-full' />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
