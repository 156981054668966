import { useEffect, useState } from 'react';

import { Select, SelectProps } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { IEntities, IEntityWithFields } from '@aduvi/types/entity';
import { EFieldDataValueType } from '@aduvi/types/fields';

import { useAppSelector } from 'store/hooks';
import { useGetEntitiesQuery, useGetPublicEntitiesQuery } from 'store/rtk-slice/entity-slice';

import { CreateInlineEntity } from '../InlineEntity/CreateInlineEntity';

export const SelectEntityByReference = ({
  referenceEntityTypeId,
  businessId,
  onAutoSelect,
  ...props
}: SelectProps & { referenceEntityTypeId?: string; businessId?: string; onAutoSelect?: (entity: IEntityWithFields) => void }) => {
  const selectedBusiness = useBusiness();
  const [open, setOpen] = useState(false);

  const { creating } = useAppSelector((state) => state.entity.entities);
  const [entities, setEntities] = useState<IEntities | undefined>();

  const { data: publicEntitiesData, isFetching: isPublicEntitiesFetching } = useGetPublicEntitiesQuery(
    { businessId, entityTypeId: referenceEntityTypeId },
    { skip: !businessId || !referenceEntityTypeId },
  );

  const { data: entitiesData, isFetching: isEntitiesFetching } = useGetEntitiesQuery(
    {
      businessId: selectedBusiness?.id,
      entityTypeId: referenceEntityTypeId,
    },
    { skip: !selectedBusiness?.id || !referenceEntityTypeId },
  );

  useEffect(() => {
    setEntities(publicEntitiesData?.data ?? entitiesData?.data);
  }, [publicEntitiesData, entitiesData]);

  return (
    <Select
      {...props}
      open={open}
      onDropdownVisibleChange={setOpen}
      disabled={isPublicEntitiesFetching || isEntitiesFetching}
      loading={isPublicEntitiesFetching || isEntitiesFetching || creating}
      optionLabelProp={props?.optionLabelProp || 'label'}
      dropdownRender={(menu) => (
        <>
          {selectedBusiness?.id ? (
            <div className='mynameselect' onClick={() => setOpen(false)}>
              <CreateInlineEntity
                entityTypeId={referenceEntityTypeId as string}
                entityTitle={entities?.entity_type?.name}
                onAutoSelect={(data) => {
                  onAutoSelect?.(data);
                  setEntities((prevState) => {
                    // Pushing to the state the newly created entity
                    if (!prevState) return undefined;

                    return { ...prevState, entities: [...(prevState?.entities || []), data] };
                  });
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {menu}
        </>
      )}
      options={entities?.entities?.map((entity) => ({
        value: entity.id,
        label:
          entity?.custom_fields?.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]?.value ??
          entity?.custom_fields?.find((field) => field?.field_data?.[0]?.value)?.field_data?.[0]?.value ??
          entities.entity_type.name,
      }))}
    />
  );
};
