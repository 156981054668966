import { useEffect, useMemo, useState } from 'react';
import { Chart as ChartComponent } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Row, Select, Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, EGranularity, IEntityField, IPopularProductsData } from '@aduvi/types';
import { toHumanReadable } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';
import { getEntityFields } from 'store/services/fields.service';
import { getPopularProductsReport } from 'store/services/report.service';

import 'chart.js/auto';

import { ILayout } from '../GridLayout';

import { getDateFormat } from './helper';
dayjs.extend(customParseFormat);
interface IPopularProductsChartProps {
  granularity: EGranularity;
  dateRange: [Dayjs | null, Dayjs | null] | null;
  chartEntity: string;
  allowEntityChange: boolean;
  onEntityTypeChange: (entityType: string) => void;
  onJobTypeChange: (jobType: 'leads' | 'bookings') => void;
  onDateFieldChange: (dateField: string) => void;
  chartId: string;
  jobType: 'leads' | 'bookings';
  dateField: string;
}
function PopularProductsChart({
  granularity,
  dateRange,
  chartEntity,
  allowEntityChange = false,
  onEntityTypeChange,
  onDateFieldChange,
  chartId,
  dateField,
}: IPopularProductsChartProps) {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();
  const { entityTypes } = useAppSelector((state) => state.entity);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IPopularProductsData[]>([]);
  const [entityType, setEntityType] = useState<string>(chartEntity);

  const [selectedDateField, setSelectedDateField] = useState<string>(dateField);
  const [fields, setFields] = useState<IEntityField[]>([]);

  const dateFieldOptions = useMemo(() => {
    return [
      { label: translate('reports.created_at'), value: 'created_at' },
      ...fields
        .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES)
        .map((item) => ({
          label: toHumanReadable(item.title),
          value: item.id,
        })),
    ];
  }, [fields]);

  const chartsData = useMemo(() => {
    const labels = Array.from(new Set(data.flatMap((item) => item.popular_products.map((product) => product.product_name)).filter(Boolean)));

    const datasets = [
      {
        label: translate('reports.popular_products.title'),
        data: labels.map((label) =>
          data.reduce((sum, item) => {
            const product = item.popular_products.find((p) => p.product_name === label);
            return product ? sum + product.product_count : sum;
          }, 0),
        ),
      },
    ];
    return {
      labels,
      datasets,
    };
  }, [data, getDateFormat, granularity, dateRange]);

  const handleEntityTypeChange = (value: string) => {
    setEntityType(value);
    onEntityTypeChange?.(value);

    if (chartId) {
      const savedLayouts = localStorage.getItem('grid-layout');
      if (savedLayouts) {
        try {
          const layouts = JSON.parse(savedLayouts);
          const updatedLayouts = {
            ...layouts,
            lg: layouts.lg.map((item: ILayout) => {
              if (item.i === chartId) {
                return {
                  ...item,
                  entityType: value,
                };
              }
              return item;
            }),
          };
          localStorage.setItem('grid-layout', JSON.stringify(updatedLayouts));
        } catch (error) {
          () => {};
        }
      }
    }
  };

  const handleDateFieldChange = (value: string) => {
    setSelectedDateField(value);
    onDateFieldChange?.(value);
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !entityType) return;
    getEntityFields(selectedBusiness?.id, entityType).then((res) => {
      setFields(res.data);
    });
  }, [selectedBusiness?.id, entityType]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityType || !dateRange) return;
    setIsLoading(true);
    getPopularProductsReport({
      business_id: selectedBusiness?.id,
      start_date: dateRange?.[0]?.format('YYYY-MM-DD') || '',
      end_date: dateRange?.[1]?.format('YYYY-MM-DD') || '',
      entity_type: entityType,
      date_argument: selectedDateField,
      granularity,
    }).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [selectedBusiness?.id, granularity, dateRange, entityType, selectedDateField]);

  return (
    <>
      <div className='chart-container'>
        <div className='selects-container'>
          {allowEntityChange && (
            <Select size={'small'} style={{ width: 150 }} value={entityType} onChange={(value) => handleEntityTypeChange(value)} disabled={isLoading}>
              {entityTypes.entityTypes.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {toHumanReadable(item.name)}
                </Select.Option>
              ))}
            </Select>
          )}
          <Select
            size={'small'}
            style={{ width: 150 }}
            value={selectedDateField}
            onChange={handleDateFieldChange}
            options={dateFieldOptions}
            disabled={isLoading}
          />
        </div>

        <div className='relative h-full'>
          <Spin spinning={isLoading} className='spin-container' />

          {!isLoading && chartsData.labels && chartsData.labels.length > 0 && (
            <>
              <div className='chart'>
                <ChartComponent
                  type={'pie'}
                  data={chartsData}
                  options={{
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          stepSize: 1,
                        },
                      },
                    },
                    plugins: {
                      title: {
                        display: true,
                        text: `${translate('reports.popular_products.title')}`,
                      },
                    },
                  }}
                />
              </div>
            </>
          )}
          {!isLoading && (!chartsData.labels || chartsData?.labels?.length) === 0 && (
            <Row justify='center' align={'middle'} className='h-full'>
              <p>{translate('reports.popular_products.no_data')}</p>
            </Row>
          )}
        </div>
      </div>
    </>
  );
}
export default PopularProductsChart;
