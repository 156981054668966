import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Col, Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EConnectionProvider, EEmailConnectionType, IEmailConnection } from '@aduvi/types/connected-account';
import { EPaymentGateways } from '@aduvi/types/payments-method';
import { Notification } from '@aduvi/utils/notification';

import { getUserData } from 'store/features/auth-slice';
import { connectEmail } from 'store/features/connected-account-slice';
import { squareAuthorizationCodeCallback, stripeAuthorizationCodeCallback } from 'store/features/payment-gateway-settings-slice';
import { useAppDispatch } from 'store/hooks';

export const PaymentConnect = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const provider = searchParams.get('state');
  const error = searchParams.get('error');

  useEffect(() => {
    if (provider === EPaymentGateways.SQUARE) {
      if (error) {
        Notification.error({ title: translate('payments.paymentDetailsDescription.squareConnectionFailed') });
        navigate('/settings/payments');
        return;
      } else {
        if (!code) return;

        dispatch(
          squareAuthorizationCodeCallback({
            business_id: selectedBusiness?.id!,
            code,
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(getUserData({}));
            Notification.success({ title: translate('payments.paymentDetailsDescription.squareConnectionSuccess') });
          })
          .catch(() => {})
          .finally(() => {
            setIsLoading(false);
            navigate('/settings/payments');
          });
      }
    } else if (provider === EConnectionProvider.MICROSOFT) {
      const payload: IEmailConnection = {
        business_id: selectedBusiness?.id || '',
        user_id: selectedBusiness?.user_id || '',
        name: 'MICROSOFT',
        provider: EConnectionProvider.MICROSOFT,
        type: EEmailConnectionType.SEND_EMAIL,
        code: code as string,
        is_connected: true,
      };

      dispatch(connectEmail({ businessId: selectedBusiness?.id!, body: payload }))
        .unwrap()
        .then(() => {
          navigate('/settings/connected-accounts');
        })
        .catch(() => {});
    } else {
      if (!code) return;

      dispatch(
        stripeAuthorizationCodeCallback({
          business_id: selectedBusiness?.id!,
          code,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getUserData({}));
          Notification.success({ title: translate('payments.paymentDetailsDescription.stripeConnectionSuccess') });
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
          navigate('/settings/payments');
        });
    }
  }, [selectedBusiness?.id!, code]);

  return <Col style={{ textAlign: 'center', marginTop: '50px' }}>{isLoading && <Spin size='large' />}</Col>;
};
