import { ISettingsLocale } from '@aduvi/types/constants';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_NUMBER_FORMAT = 'plain';
export const DEFAULT_LIST_FORMAT = '';
export const DEFAULT_QUOTE_FORMAT = '';
export const DEFAULT_QUOTE_NUMBER_FORMAT = 'plain';
export const DEFAULT_MAX_LENGTH = 100;
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const DEFAULT_SHIFT_FORMAT = 'text';

export const PREDEFINED_NUMBER_FORMATS = [
  { label: '1300', value: 'plain' },
  { label: '1,300', value: 'comma' },
  { label: '$1300.00', value: 'currency' },
  { label: '1300%', value: 'percentage' },
];

export const PREDEFINED_LIST_FORMATS = [
  { label: 'None', value: 'none' },
  { label: 'Comma-Separated', value: 'comma' },
  { label: 'Numbered', value: 'numbered' },
  { label: 'Bullet Points', value: 'bullet' },
  { label: 'Brackets', value: 'brackets' },
];

export const PREDEFINED_QUOTE_FORMATS = [
  { label: 'None', value: 'none' },
  { label: 'Comma-Separated', value: 'comma_separated' },
  { label: 'Numbered', value: 'numbered' },
  { label: 'Bullet Points', value: 'bullet_list' },
  { label: 'Circle Image', value: 'circle_image' },
];
export const PREDEFINED_SHIFT_FORMATS = [
  { label: 'Text', value: 'text' },
  { label: 'List', value: 'list' },
  { label: 'Circle Image', value: 'circle_image' },
];

export const PREDEFINED_DATE_FORMATS = [
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'MMM D[th] YYYY',
  'MMMM D[th] YYYY',
  'D[th] MMM YYYY',
  'D[th] MMMM YYYY',
  'dddd, D[th] MMMM YYYY',
  'DD/MM/YY HH:mm',
  'MM/DD/YY HH:mm',
  'ha, MMM Do YYYY',
];

export const PREDEFINED_LABEL_SETTINGS = [
  { value: 'HIDDEN', label: 'formatFields.hidden' },
  { value: 'ON_TOP', label: 'formatFields.onTop' },
  { value: 'INLINE', label: 'formatFields.inline' },
];

export const ACTION_BUTTON_SETTINGS = [
  { value: 'CURRENT_TAB', label: 'action-buttons.openInCurrentTab' },
  { value: 'NEW_TAB', label: 'action-buttons.openInNewTab' },
  { value: 'MODAL', label: 'action-buttons.openInModal' },
];
export const FIRST_DAY_OF_WEEK_OPTIONS: ISettingsLocale[] = [
  { value: 'sunday', label: 'Sunday' },
  { value: 'monday', label: 'Monday' },
];

export const DATE_FORMAT_OPTIONS: ISettingsLocale[] = [
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
];

export const TIME_FORMAT_OPTIONS: ISettingsLocale[] = [
  { value: 'HH:mm', label: '24 Hour' },
  { value: 'hh:mm A', label: '12 Hour' },
];

export const TIME_BETWEEN_JOBS = [
  {
    label: '1 hour',
    value: '01:00',
  },
  {
    label: '2 hours',
    value: '02:00',
  },
  {
    label: '3 hours',
    value: '03:00',
  },
  {
    label: '4 hours',
    value: '04:00',
  },
  {
    label: '5 hours',
    value: '05:00',
  },
  {
    label: '6 hours',
    value: '06:00',
  },
  {
    label: '7 hours',
    value: '07:00',
  },
  {
    label: '8 hours',
    value: '08:00',
  },
  {
    label: '9 hours',
    value: '09:00',
  },
  {
    label: '10 hours',
    value: '10:00',
  },
  {
    label: '11 hours',
    value: '11:00',
  },
  {
    label: '12 hours',
    value: '12:00',
  },
];
