import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectOutlined, SettingOutlined } from '@ant-design/icons';

import { Col, Row, Tabs, Typography } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import './Project.scss';

import { EPersonalizedViewOrigin, EProjectDrawerTabs } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

import { Activity } from './project-tabs/activity/Activity';
import { ProjectContacts } from './project-tabs/contact/Contact';
import { Custom } from './project-tabs/custom/Custom';
import { Files } from './project-tabs/files/Files';
import { Quote } from './project-tabs/quote/Quote';
import { Tasks } from './project-tabs/tasks/Tasks';
import { Timeline } from './project-tabs/timeline/Timeline';

export const Project = () => {
  const { t: translate } = useTranslation();

  const {
    entities: { selectedEntity },
  } = useAppSelector((state) => state.entity);

  const [form] = useForm();
  useWatch('project_style', form);

  const [tab, setTab] = useState<string>('timeline');
  const headerStyle = useMemo(
    () => ({
      backgroundImage: selectedEntity?.custom_fields?.find((field) => field?.title === 'Background Image')?.field_data?.[0]?.url,
      backgroundColor: selectedEntity?.custom_fields?.find((field) => field?.title === 'Background Color')?.field_data?.[0]?.value,
      backgroundMode: selectedEntity?.custom_fields?.find((field) => field?.title === 'Background Mode')?.field_data?.[0]?.value,
    }),
    [selectedEntity],
  );

  const projectTabs = [
    {
      key: EProjectDrawerTabs.TIMELINE,
      label: translate('projects.tabs.timeline'),
      children: <Timeline origin={EPersonalizedViewOrigin.PROJECTS} />,
    },
    { key: EProjectDrawerTabs.QUOTE, label: translate('projects.tabs.quote'), children: <Quote /> },
    { key: EProjectDrawerTabs.FILES, label: translate('projects.tabs.files'), children: <Files /> },
    { key: EProjectDrawerTabs.TASKS, label: translate('projects.tabs.tasks'), children: <Tasks /> },
    { key: EProjectDrawerTabs.ACTIVITY, label: translate('projects.tabs.activity'), children: <Activity /> },
    { key: EProjectDrawerTabs.CUSTOM, label: translate('projects.tabs.custom'), children: <Custom /> },
  ];

  const bannerStyle = useMemo(() => {
    if (!selectedEntity) return;
    if (!headerStyle?.backgroundColor && !headerStyle?.backgroundImage && !headerStyle?.backgroundMode) {
      return {
        backgroundColor: 'rgb(124, 192, 255)',
      };
    }

    if (headerStyle.backgroundMode === 'Color') {
      return {
        background: String(headerStyle?.backgroundColor) || 'rgb(124, 192, 255)',
      };
    } else {
      if (!headerStyle?.backgroundImage) {
        return {
          background: String(headerStyle?.backgroundColor) || 'rgb(124, 192, 255)',
        };
      } else {
        return {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.451), rgba(0, 0, 0, 0.451)), url(${headerStyle.backgroundImage})`,
        };
      }
    }
  }, [selectedEntity]);

  return (
    <div className='project-wrapper'>
      <Row className='banner' style={bannerStyle}>
        <Col span={24} className='p-20'>
          <Typography.Title level={3}>
            {selectedEntity?.custom_fields?.find((field) => field?.title === 'Name')?.field_data?.[0]?.value}
          </Typography.Title>
          <Typography.Paragraph className='fs-18 mb-0'>
            <ProjectOutlined />{' '}
            {translate('projects.projectId', {
              projectId: selectedEntity?.custom_fields?.find((field) => field?.title === 'Project ID')?.field_data?.[0]?.value,
            })}
          </Typography.Paragraph>
          <Typography.Paragraph className='fs-18 '>
            {`${translate('projects.status')}:${selectedEntity?.custom_fields?.find((field) => field?.title === 'Status')?.field_data?.[0]?.value}`}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={16}>
          <Row>
            <Col span={24} className='pl-30'>
              <Tabs
                defaultActiveKey={'Timeline'}
                activeKey={tab}
                onChange={setTab}
                items={projectTabs}
                tabBarExtraContent={{ right: <SettingOutlined /> }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <ProjectContacts />
        </Col>
      </Row>
    </div>
  );
};
