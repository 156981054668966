import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';

import { Input, Row } from 'antd';

import { useIsMobile } from '@aduvi/hooks';

export const SearchInput = ({ ...props }: React.ComponentProps<typeof Input>) => {
  const isMobile = useIsMobile();

  const [searchInputVisible, setSearchInputVisible] = useState(false);

  const onSearchIconClick = () => {
    setSearchInputVisible(true);
  };
  return (
    <>
      {isMobile ? (
        searchInputVisible ? (
          <Input.Search {...props} style={{ width: searchInputVisible ? 'auto' : 0 }} autoFocus />
        ) : (
          <Row justify='center' align='middle'>
            <SearchOutlined style={{ cursor: 'pointer', fontSize: 16 }} className='mr-10' onClick={onSearchIconClick} />
          </Row>
        )
      ) : (
        <Input.Search {...props} allowClear className='mr-10' style={{ maxWidth: 'auto' }} />
      )}
    </>
  );
};
