import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';

import { Button, Col, Divider, Form, Input, message, Row, Switch, Typography } from 'antd';
import { FormInstance } from 'antd/lib';

import { useBusiness } from '@aduvi/hooks';
import { EFormPurpose } from '@aduvi/types/form';
import { generateFormIFrame } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

import { LimitVisibilityForm } from './LimitVisibilityForm';

export const Share = ({ form }: { form: FormInstance }) => {
  const formUrl = Form.useWatch('form_url');
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const { selectedEntityForm } = useAppSelector((state) => state.forms);

  const redirectUrl = useMemo(() => {
    const brand = selectedBusiness?.brandings?.[0];
    return brand?.vanity_domain || brand?.system_domain || window.location.origin;
  }, [selectedBusiness]);

  useEffect(() => {
    if (formUrl && redirectUrl) {
      form.setFieldValue('embed_form_code', generateFormIFrame(formUrl, redirectUrl));
    }
  }, [formUrl, redirectUrl]);

  return (
    <Col span={10} className='share-tab-wrapper mb-20'>
      <Form.Item name='form_url' label={translate('forms.forms.formUrl')}>
        <Input addonBefore={redirectUrl + '/forms/'} />
      </Form.Item>
      <Row justify={'end'}>
        <Button
          type='link'
          onClick={() => navigator.clipboard.writeText(`${redirectUrl}/forms/${form.getFieldValue('form_url')}`)}
          icon={<CopyOutlined />}>
          {translate('forms.forms.copyFormLink')}
        </Button>
      </Row>
      <Divider />
      {selectedEntityForm?.purpose === EFormPurpose.CREATE ? (
        <>
          {selectedEntityForm?.widget?.id ? (
            <>
              <Typography.Text type='secondary'>{translate('forms.forms.embedWidget')}</Typography.Text>
              <Row justify={'space-between'} className='mt-15'>
                <Typography.Text className='mt-5'>{translate('forms.forms.removeBackgroundStyling')}</Typography.Text>
                <Form.Item name='embed_wiget_remove_styling'>
                  <Switch defaultValue={selectedEntityForm?.embed_widget_remove_styling} />
                </Form.Item>
              </Row>
              <Form.Item name='embed_wiget_code' label={translate('forms.forms.embedCode')}>
                <Input.TextArea
                  readOnly
                  rows={4}
                  placeholder='<div id=”...”></div><script>....</script>'
                  defaultValue={selectedEntityForm?.embed_widget_code}
                />
              </Form.Item>
              <Row justify={'end'}>
                <Button type='link'>
                  <CopyOutlined /> {translate('forms.forms.copyWidgetCode')}
                </Button>
              </Row>
              <Divider />
            </>
          ) : null}
          <Typography.Text type='secondary'>{translate('forms.forms.embedWholeForm')}</Typography.Text>
          <Row justify={'space-between'} className='mt-15'>
            <Typography.Text className='mt-5'>{translate('forms.forms.removeBackgroundStyling')}</Typography.Text>
            <Form.Item name='embed_form_remove_styling'>
              <Switch defaultValue={selectedEntityForm?.embed_form_remove_styling} />
            </Form.Item>
          </Row>
          <Form.Item name='embed_form_code' label={translate('forms.forms.embedCode')}>
            <Input.TextArea
              readOnly
              rows={4}
              placeholder='<div id=”...”></div><script>....</script>'
              defaultValue={selectedEntityForm?.embed_form_code}
            />
          </Form.Item>
          <Row justify={'end'}>
            <Button
              type='link'
              onClick={() => {
                navigator.clipboard.writeText(form.getFieldValue('embed_form_code'));
                message.success(translate('widgets.copiedSuccessfully'));
              }}>
              <CopyOutlined /> {translate('forms.forms.copyFormCode')}
            </Button>
          </Row>
        </>
      ) : null}
      {selectedEntityForm?.purpose === EFormPurpose.CAPTURE ? <LimitVisibilityForm form={form} /> : null}
    </Col>
  );
};
