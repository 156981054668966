import { Row, Typography } from 'antd';
import './SelectNode.scss';

import { ENodeType, INode } from '@aduvi/types/automation';

import { getDefaultnodeIcon, getNodeIcon } from '../constants/helper';

const nodeColor: Record<ENodeType, string> = {
  ACTION: '#B37FEB',
  LOGIC: '#69C0FF',
  TRIGGER: '#5DCB9E',
  ACTION_INTERNAL: '#FFC069',
  BRANCH: '',
};

const nodeBackgroundColor: Record<ENodeType, string> = {
  ACTION: '#F4EBFD',
  LOGIC: '#EFF9FF',
  TRIGGER: '#52C41A14',
  ACTION_INTERNAL: '#FFF6E9',
  BRANCH: '',
};

export const NodeCard = ({ id, node_type, title, triggerable_type, index, action, onClick }: INode & { index: number; onClick: () => void }) => {
  return (
    <span key={id}>
      <Typography.Title level={5} className={index === 0 ? 'mt-20' : ''}>
        {index === 0 ? triggerable_type : null}
      </Typography.Title>
      <Row className='node-row cursor-pointer' align={'middle'} onClick={onClick}>
        <span className='node-icon' style={{ color: nodeColor[node_type], background: nodeBackgroundColor[node_type] }}>
          {getNodeIcon[action] || getDefaultnodeIcon[node_type]}
        </span>
        <span className='ml-15'>{title}</span>
      </Row>
    </span>
  );
};
