import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Details } from 'views/assets/services/packages-drawer/details/Details';
import { Pricing } from 'views/assets/services/packages-drawer/pricing/Pricing';

import { Form, Modal, Spin, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { LimitVisibility } from '@aduvi/components/LimitVisibility/LimitVisibility';
import { PERMISSIONS } from '@aduvi/constants';
import { useBusinessId } from '@aduvi/hooks';
import { IPackage } from '@aduvi/types';
import { EPackagesDrawerTabs, IPackageTaxRate } from '@aduvi/types/services';
import { getFormErrors } from '@aduvi/utils/helper';

import { createPackage, getPackageById, updatePackage } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const items = (translate: (key: string) => string, form: FormInstance): TabsProps['items'] => [
  {
    key: EPackagesDrawerTabs.DETAILS,
    label: translate('assets.packageDrawer.details'),
    children: <Details form={form} />,
  },
  {
    key: EPackagesDrawerTabs.PRICING,
    label: translate('assets.packageDrawer.pricing'),
    children: <Pricing form={form} />,
  },
  {
    key: EPackagesDrawerTabs.LIMIT_VISIBILITY,
    label: translate('assets.packageDrawer.limitVisibility'),
    children: <LimitVisibility form={form} />,
  },
];

export const PackagesModal = ({ onClose, open, addPackage }: { open: boolean; onClose: () => void; addPackage?: (item: IPackage) => void }) => {
  const dispatch = useAppDispatch();
  const selectedBusinessId = useBusinessId();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  const [tab, setTab] = useState<string>(EPackagesDrawerTabs.DETAILS);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [isCustom, setIsCustom] = useState<boolean>(false);

  const { selectedService, selectedPackage, creating, updating, loadingPackage } = useAppSelector((state) => state.services);

  const createSinglePackage = () => {
    dispatch(
      createPackage({
        business_id: selectedBusinessId,
        service_id: form.getFieldValue('business_service_id'),
        body: {
          ...form.getFieldsValue(),
          custom: isCustom,
        },
      }),
    )
      .unwrap()
      .then((response) => {
        if (addPackage) {
          addPackage(response.data);
        }
        closeModal();
      })
      .catch((error) => {
        form.setFields(getFormErrors(error));
      });
  };

  const updateSinglePackage = () => {
    if (!selectedPackage?.id || !selectedService?.id) return;

    dispatch(
      updatePackage({
        business_id: selectedBusinessId,
        service_id: selectedService.id,
        package_id: selectedPackage.id,
        body: {
          ...form.getFieldsValue(),
          custom: isCustom,
        },
      }),
    )
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        form.setFields(getFormErrors(error));
      });
  };

  const submitFormHandler = () => {
    selectedPackage?.id ? updateSinglePackage() : createSinglePackage();
  };

  const closeModal = () => {
    form.resetFields();
    onClose();
  };

  const handleCustomSelection = (custom: boolean) => {
    setIsCustom(custom);
    setIsCustomModalOpen(false);
    submitFormHandler();
  };

  useEffect(() => {
    if (!selectedPackage?.id || !selectedPackage?.business_service_id || !selectedBusinessId) return;
    dispatch(
      getPackageById({
        business_id: selectedBusinessId,
        package_id: selectedPackage.id,
        service_id: selectedPackage.business_service_id,
      }),
    )
      .unwrap()
      .then((response) => {
        form.setFieldsValue(response?.data);
        form.setFieldValue(
          'products',
          response.data.products.map((item) => item.id),
        );
        form.setFieldValue('tax_rate_ids', response.data.tax_rates?.map((taxRate: IPackageTaxRate) => taxRate.tax_rate_id));
      });
  }, [selectedPackage?.id, selectedPackage?.business_service_id, selectedBusinessId]);

  return (
    <>
      <Modal
        title={translate('quotes.createPackage')}
        visible={open}
        onCancel={closeModal}
        footer={[
          <Button key='cancel' onClick={closeModal} disabled={creating || updating}>
            {translate('buttons.cancel')}
          </Button>,
          <Button
            key='save'
            type='primary'
            requiredPermission={PERMISSIONS.SERVICES.CREATE}
            onClick={() => setIsCustomModalOpen(true)}
            disabled={creating || updating}
            loading={creating || updating}>
            {translate('buttons.save')}
          </Button>,
        ]}
        width={720}>
        <Spin spinning={loadingPackage}>
          <Form form={form} layout='vertical' requiredMark={false}>
            <Tabs defaultActiveKey={EPackagesDrawerTabs.DETAILS} activeKey={tab} items={items(translate, form)} onChange={setTab} />
          </Form>
        </Spin>
      </Modal>
      <Modal
        title={translate('assets.packageDrawer.saveConfirmation')}
        visible={isCustomModalOpen}
        onCancel={() => setIsCustomModalOpen(false)}
        footer={[
          <Button key='cancel' onClick={() => setIsCustomModalOpen(false)}>
            {translate('buttons.cancel')}
          </Button>,
          <Button key='global' type='primary' onClick={() => handleCustomSelection(false)}>
            {translate('assets.packageDrawer.globally')}
          </Button>,
          <Button key='custom' type='primary' danger onClick={() => handleCustomSelection(true)}>
            {translate('assets.packageDrawer.custom')}
          </Button>,
        ]}>
        {translate('assets.packageDrawer.customPackage')}
      </Modal>
    </>
  );
};
