import { useEffect, useMemo, useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleOutlined, ExpandOutlined, PictureOutlined, ProjectOutlined } from '@ant-design/icons';

import { Col, Divider, Drawer, Form, Modal, notification, Popover, Radio, Row, Select, Space, Spin, Tabs, Typography } from 'antd';
import './UpsertEntityDrawer.scss';

import { Button } from '@aduvi/components/Button/Button';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { createEntityPermission, editEntityPermission } from '@aduvi/constants/entity-type';
import { EPersonalizedViewOrigin, IEntityField } from '@aduvi/types';
import { EntityDrawerTabs, IEntityType } from '@aduvi/types/entity';
import { determinePickerColor, getRepLinkIdFromCookies } from '@aduvi/utils/helper';
import { toHumanReadable } from '@aduvi/utils/helper';

import { checkEntityAvailability } from 'store/features/common-slice';
import { createEntity, editEntity, setSelectedEntity } from 'store/features/entity-slice';
import { getQuotes, setSelectedQuote } from 'store/features/quote-slice';
import { useAppSelector } from 'store/hooks';

import { mapEditedEntityFieldsToPayload, mapEntityFieldsToPayload } from '../helper';

import { useUpsertEntityDrawer } from './UpsertEntityDrawer.hooks';

interface IProps {
  open: boolean;
  entityType?: IEntityType;
  fields: IEntityField[];
  formOnlyFields: IEntityField[];
  origin: EPersonalizedViewOrigin;
  onClose: () => void;
}

export const UpsertEntityDrawer = ({ open, entityType, origin, fields, formOnlyFields, onClose }: IProps) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const { dispatch, tabItems, selectedEntity, creating, updating, loadingEntity, form, selectedBusiness } = useUpsertEntityDrawer({
    origin,
    entityType,
    fields,
    formOnlyFields,
  });
  const { entities } = useAppSelector((state) => state.entity);
  const { quotes } = useAppSelector((state) => state.quotes);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedQuoteId, setSelectedQuoteId] = useState<string | null>(null);

  const entityFormFields = useMemo(() => {
    if (!entities?.selectedEntity?.custom_fields) return [];

    return entities?.selectedEntity?.custom_fields?.map((item) => (item?.form_only ? item.id : null))?.filter((item) => item);
  }, [entities?.selectedEntity]);

  const entityManagementFields = useMemo(() => {
    if (!formOnlyFields) return [];

    return formOnlyFields?.filter((field) => entityFormFields?.includes(field?.id));
  }, [entityFormFields, formOnlyFields]);
  const formFields = useMemo(() => [...fields, ...entityManagementFields], [fields, entityManagementFields]);
  const headerFields = useMemo(
    () => ({
      imageField: formFields.find((field) => field?.title === 'Background Image'),
      imageFieldIndex: formFields.findIndex((field) => field?.title === 'Background Image'),
      colorField: formFields.find((field) => field?.title === 'Background Color'),
      colorFieldIndex: formFields.findIndex((field) => field?.title === 'Background Color'),
      backgroundMode: formFields.find((field) => field?.title === 'Background Mode'),
      backgroundModeIndex: formFields.findIndex((field) => field?.title === 'Background Mode'),
    }),
    [selectedEntity],
  );

  const backgroundColor = Form.useWatch(['custom_fields', headerFields.colorFieldIndex!, headerFields.colorField?.id!], form);
  const backgroundImage = Form.useWatch(['custom_fields', headerFields.imageFieldIndex!, headerFields.imageField?.id!], form);
  const backgroundMode = Form.useWatch(['custom_fields', headerFields.backgroundModeIndex!, headerFields.backgroundMode?.id!], form);

  const options = useMemo(() => {
    return headerFields.backgroundMode?.list_values
      ?.filter((item) => !item?.is_archived)
      ?.map((item) => ({ label: item?.value, value: item?.id, is_default: item?.is_default }));
  }, [headerFields.backgroundMode?.list_values]);

  const bannerStyle = useMemo(() => {
    if (!selectedEntity) return;
    if (backgroundMode?.[0] === options?.filter((option) => option.label === 'Color')[0].value) {
      return { background: String(backgroundColor) || 'rgb(124, 192, 255)' };
    } else {
      if (!backgroundImage) {
        return { background: String(backgroundColor) || 'rgb(124, 192, 255)' };
      } else {
        let _backgroundUrl = backgroundImage?.url ?? backgroundImage?.file?.url ?? backgroundImage;

        return {
          background: 'none',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.451), rgba(0, 0, 0, 0.451)), url("${_backgroundUrl}")`,
        };
      }
    }
  }, [selectedEntity, backgroundColor, form, backgroundImage, backgroundMode, options]);

  const currentStatusId = useMemo(() => {
    return selectedEntity?.custom_fields?.find((field) => field.title === 'Status')?.field_data?.[0]?.id;
  }, [selectedEntity?.custom_fields]);

  const unconfirmedStatus = useMemo(() => {
    return fields?.find((f) => f?.title === 'Status')?.list_values?.find((lv) => lv.value_type === 'UNCONFIRMED');
  }, [fields]);

  const confirmedStatus = useMemo(() => {
    return fields?.find((f) => f?.title === 'Status')?.list_values?.find((lv) => lv.value_type === 'CONFIRMED');
  }, [fields]);

  const closeDrawer = () => {
    form.resetFields();
    dispatch(setSelectedEntity(undefined));
    onClose();
  };

  const updateEntity = () => {
    if (!selectedBusiness?.id || !entityType?.id || !selectedEntity?.id) return;

    dispatch(
      editEntity({
        id: selectedEntity.id,
        business_id: selectedBusiness.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEditedEntityFieldsToPayload(form.getFieldValue('custom_fields'), [...fields, ...formOnlyFields], selectedEntity),
      }),
    )
      .unwrap()
      .then(() => onClose())
      .catch(() => {});
  };

  const createNewEntity = () => {
    if (!selectedBusiness?.id || !entityType?.id) return;

    dispatch(
      createEntity({
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEntityFieldsToPayload(form.getFieldValue('custom_fields'), [...fields, ...formOnlyFields]),
        rep_link_id: getRepLinkIdFromCookies(),
      }),
    )
      .unwrap()
      .then(() => onClose())
      .catch(() => {});
  };

  const submitFormHandler = () => {
    // const statusFieldIndex = fields.findIndex((f) => f.title === 'Status');
    // const statusFieldId = fields.find((f) => f.title === 'Status')?.id;

    // const newStatusArr = form.getFieldValue(['custom_fields', statusFieldIndex, statusFieldId]);
    // const newStatusId = Array.isArray(newStatusArr) ? newStatusArr[0] : undefined;

    selectedEntity?.id ? updateEntity() : createNewEntity();
  };

  const handleConvertToBooking = async () => {
    if (unconfirmedStatus && confirmedStatus && currentStatusId === unconfirmedStatus.id) {
      const statusFieldIndex = fields.findIndex((f) => f.title === 'Status');
      const statusFieldId = fields.find((f) => f.title === 'Status')?.id;
      form.setFieldsValue({
        custom_fields: {
          [statusFieldIndex!]: {
            [statusFieldId!]: [confirmedStatus.id],
          },
        },
      });

      form.validateFields([['custom_fields', statusFieldIndex!, statusFieldId!]]);
    }

    let finalQuoteId = selectedQuoteId;
    if (quotes?.length === 1) {
      finalQuoteId = quotes[0].id;
    }
    if (!finalQuoteId) {
      Modal.error({
        title: translate('orders.layoutDrawer.missingQuote'),
        content: translate('orders.layoutDrawer.noQuotesMessage'),
      });
      return;
    }

    try {
      const availabilityResp = await dispatch(
        checkEntityAvailability({
          businessId: selectedBusiness?.id!,
          entityTypeId: entityType?.id!,
          entityId: selectedEntity?.id!,
          quoteId: finalQuoteId,
        }),
      ).unwrap();

      if (!availabilityResp?.data?.available) {
        Modal.error({
          title: translate('orders.layoutDrawer.notAvailable'),
          content: availabilityResp.data.message,
        });
        return;
      }

      await dispatch(
        editEntity({
          id: selectedEntity?.id!,
          business_id: selectedBusiness?.id!,
          entity_type_id: entityType?.id!,
          quote_id: finalQuoteId,
          custom_fields: mapEditedEntityFieldsToPayload(form.getFieldValue('custom_fields'), [...fields, ...formOnlyFields], selectedEntity),
        }),
      )
        .unwrap()
        .then(() => {
          setIsModalVisible(false);
          onClose();
        })
        .catch(() => {});

      notification.success({ message: translate('orders.layoutDrawer.confirmedOrderNotificationMessage') });
    } catch (err) {
      () => {};
    }
  };

  const handleQuoteSelect = (quoteId: string) => {
    setSelectedQuoteId(quoteId);
    const selected = quotes?.find((quote) => quote?.id === quoteId);
    if (selected) {
      dispatch(setSelectedQuote(selected));
    }
  };

  useEffect(() => {
    if (isModalVisible && selectedEntity?.id && selectedBusiness?.id) {
      dispatch(
        getQuotes({
          businessId: selectedBusiness.id,
          entityId: selectedEntity.id,
        }),
      );
    }
  }, [isModalVisible, selectedBusiness?.id, selectedEntity?.id, dispatch]);

  return (
    <Drawer
      className='edit-job-drawer-wrapper'
      title={
        selectedEntity?.id
          ? `Edit ${entityType?.name && toHumanReadable(entityType?.name)}`
          : `Create ${entityType?.name && toHumanReadable(entityType?.name)}`
      }
      open={open}
      width={720}
      onClose={closeDrawer}
      bodyStyle={{ ...DRAWER_BODY_STYLE, width: '100%' }}
      extra={
        <Space>
          <Button disabled={creating || updating} onClick={closeDrawer}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={creating || updating}
            loading={creating || updating}
            type='primary'
            requiredPermission={
              selectedEntity ? entityType && editEntityPermission[entityType?.name] : entityType && createEntityPermission[entityType?.name]
            }
            disabledButton
            onClick={submitFormHandler}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Spin spinning={loadingEntity}>
        <Form form={form} layout='vertical' requiredMark={false}>
          {origin === EPersonalizedViewOrigin.PROJECTS && selectedEntity && (
            <Col className='project-banner' style={selectedEntity && bannerStyle}>
              <Col className='expand-icon cursor-pointer' onClick={() => navigate(`/project/${selectedEntity?.id}`)}>
                <ExpandOutlined />
              </Col>
              <Col className='image-icon cursor-pointer'>
                <Popover
                  trigger={'click'}
                  content={
                    <>
                      <Radio.Group
                        onChange={(event) =>
                          form.setFieldValue(
                            ['custom_fields', headerFields.backgroundModeIndex!, headerFields.backgroundMode?.id!],
                            [event.target.value],
                          )
                        }
                        className='flex-center mb-10'
                        value={backgroundMode?.[0]}
                        defaultValue={backgroundMode?.[0]}>
                        {options?.map((item) => (
                          <Radio.Button key={item?.value} style={{ width: '100px', textAlign: 'center' }} value={item.value}>
                            {item.label}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                      {backgroundMode?.[0] === options?.filter((option) => option.label === 'Color')?.[0]?.value ? (
                        <Form.Item
                          name={['custom_fields', headerFields.colorFieldIndex!, headerFields.colorField?.id!]}
                          className='flex-center'
                          getValueFromEvent={determinePickerColor}>
                          <ColorPicker
                            onChange={(value) => {
                              form.setFieldValue(['custom_fields', headerFields.colorFieldIndex!, headerFields.colorField?.id!], value);
                            }}
                            width={230}
                            height={150}
                          />
                        </Form.Item>
                      ) : (
                        <>
                          <Form.Item name={['custom_fields', headerFields.imageFieldIndex!, headerFields.imageField?.id!]}>
                            <UploadImage
                              name={'file'}
                              url={form?.getFieldValue(['custom_fields', headerFields.imageFieldIndex!, headerFields.imageField?.id!])?.file?.url}
                              onUploadChange={(value) => {
                                form?.setFieldValue(['custom_fields', headerFields.imageFieldIndex!, headerFields.imageField?.id!], value);
                              }}
                              uploadSettings={headerFields.imageField?.settings}
                            />
                            <Typography.Text type='secondary'>{translate('forms.forms.selectFromLibrary')}</Typography.Text>
                            {Array.from({ length: 4 }).map((_, index) => (
                              <Col
                                style={{
                                  border:
                                    form.getFieldValue(['custom_fields', headerFields.imageFieldIndex!, headerFields.imageField?.id!])?.file?.url ===
                                    `assets/images/theme-${index + 1}.png`
                                      ? `2px dashed '#7cc0ff'} `
                                      : '',
                                }}
                                key={index}
                                className='mt-10 cursor-pointer'
                                onClick={() =>
                                  form.setFieldValue(
                                    ['custom_fields', headerFields.imageFieldIndex!, headerFields.imageField?.id!],
                                    require(`assets/images/theme-${index + 1}.png`),
                                  )
                                }>
                                <img src={require(`assets/images/theme-${index + 1}.png`)} />
                              </Col>
                            ))}
                          </Form.Item>
                        </>
                      )}
                    </>
                  }
                  placement='leftTop'>
                  <PictureOutlined className='icon' />
                </Popover>
              </Col>
              <Typography.Title level={3}>
                {selectedEntity?.custom_fields?.find((field) => field?.title === 'Name')?.field_data?.[0]?.value}
              </Typography.Title>
              <Typography.Paragraph className='fs-18 mb-0'>
                <ProjectOutlined />{' '}
                {translate('projects.projectId', {
                  projectId: selectedEntity?.custom_fields?.find((field) => field?.title === 'Project ID')?.field_data?.[0]?.value ?? 0,
                })}
              </Typography.Paragraph>
              <Typography.Paragraph className='fs-18 '>
                {`${translate('projects.status')}: ${
                  selectedEntity?.custom_fields?.find((field) => field?.title === 'Status')?.field_data?.[0]?.value ?? ''
                }`}
              </Typography.Paragraph>
            </Col>
          )}
          <Col span={24}>
            <Tabs defaultActiveKey={EntityDrawerTabs.DETAILS} items={tabItems} />
          </Col>
        </Form>
      </Spin>
      {isModalVisible && (
        <Modal
          title={translate('orders.layoutDrawer.modalTitle')}
          open={isModalVisible}
          onOk={handleConvertToBooking}
          onCancel={() => setIsModalVisible(false)}
          okText={translate('orders.layoutDrawer.convert')}
          footer={[
            <Divider key='divider-footer' />,
            <Button key='convert' type='primary' onClick={handleConvertToBooking}>
              {translate('orders.layoutDrawer.convert')}
            </Button>,
          ]}>
          <Divider />
          <Row align='middle' style={{ display: 'flex', alignItems: 'center' }}>
            <Col style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
              <ExclamationCircleOutlined style={{ fontSize: '25px', marginRight: '10px' }} />
              <Typography.Text>{translate('orders.layoutDrawer.confirmMessage')}</Typography.Text>
            </Col>
          </Row>

          {quotes?.length > 1 && (
            <div>
              <Typography.Text>{translate('orders.layoutDrawer.multipleQuotesMessage')}</Typography.Text>
              <Select
                value={selectedQuoteId}
                onChange={handleQuoteSelect}
                placeholder={translate('orders.layoutDrawer.selectQuote')}
                style={{ width: '100%' }}>
                {quotes.map((quote) => (
                  <Select.Option key={quote.id} value={quote.id}>
                    {`${quote.name ?? 'Quote'} : $${quote.total}`}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        </Modal>
      )}
    </Drawer>
  );
};
