import React from 'react';

import { Col } from 'antd';
import { FormInstance } from 'antd/lib';

import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { EReferenceEntityType, IEntityField } from '@aduvi/types';

import { IBookingFormFieldProps } from '../helper';

import { AssetCustomField } from './AssetCustomField';
import { EditField } from './EditField';
import { PageBreak } from './PageBreak';
import { TravelFee } from './TravelFee';

interface IProps {
  form: FormInstance;
  id: string;
  isDragging: boolean;
  index: number;
  fields: IBookingFormFieldProps[];
  readonly?: boolean;
  isPageBreak: boolean;
  disabledFields: string[];
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
}

export const SortableItem = ({ form, id, isDragging, fields, index, readonly, isPageBreak, disabledFields, setFields }: IProps) => {
  const pageBreakFields = fields.filter((item) => item.isPageBreak);

  if (isPageBreak)
    return <PageBreak id={id} order={pageBreakFields.findIndex((item) => item.field_id === id) + 1} index={index} setFields={setFields} />;

  if (fields[index].isTravelFee) {
    return (
      <EditField formSettings={form} id={id} isDragging={isDragging} fields={fields} setFields={setFields} index={index} readonly={readonly}>
        <TravelFee field={fields[index]} />
      </EditField>
    );
  }

  return (
    <EditField formSettings={form} id={id} isDragging={isDragging} fields={fields} setFields={setFields} index={index} readonly={readonly}>
      {![EReferenceEntityType.PRODUCT, EReferenceEntityType.SERVICE].includes(fields[index]?.field?.reference_entity_type as EReferenceEntityType) ? (
        <Col className='w-full px-10'>
          <CustomField
            hideMediaLibraryModal={true}
            field={fields?.[index]?.field as IEntityField}
            isRequired={fields?.[index]?.field_instance?.required}
            form={form}
            index={index}
            contactFields={fields[index]}
            isFormField
            disabledFields={disabledFields}
          />
        </Col>
      ) : (
        <AssetCustomField field={fields?.[index]?.field} form={form} index={index} fieldInstance={fields?.[index]?.field_instance} />
      )}
    </EditField>
  );
};
