import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingOutlined } from '@ant-design/icons';
import { ChartType } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';

import { Button, Col, DatePicker, Row, Select } from 'antd';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import { EDatePeriod, EGranularity } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

import Chart from './Charts/Chart';
import FinancialChart from './Charts/FinancialChart';
import FinancialOverviewChart from './Charts/FinancialOverviewChart';
import LeadsByFieldChart from './Charts/LeadsByFieldChart';
import PopularProductsChart from './Charts/PopularProductsChart';
import PopularServicesChart from './Charts/PopularServicesChart';
import StaffShiftsChart from './Charts/StaffShiftsChart';
import TransactionsChart from './Charts/TransactionsChart';

const DATE_PERIOD_OPTIONS = [
  { value: EDatePeriod.LAST_7_DAYS, label: 'reports.last_7_days' },
  { value: EDatePeriod.LAST_4_WEEKS, label: 'reports.last_4_weeks' },
  { value: EDatePeriod.LAST_3_MONTHS, label: 'reports.last_3_months' },
  { value: EDatePeriod.LAST_6_MONTHS, label: 'reports.last_6_months' },
  { value: EDatePeriod.LAST_12_MONTHS, label: 'reports.last_12_months' },
  { value: EDatePeriod.CUSTOM, label: 'reports.custom_date_range' },
];

const GRANULARITY_OPTIONS = [
  { value: EGranularity.DAILY, label: 'reports.daily' },
  { value: EGranularity.WEEKLY, label: 'reports.weekly' },
  { value: EGranularity.MONTHLY, label: 'reports.monthly' },
  { value: EGranularity.YEARLY, label: 'reports.yearly' },
];

type ChartComponentType =
  | 'Financial'
  | 'Entity'
  | 'FinancialOverview'
  | 'Transactions'
  | 'PopularProducts'
  | 'PopularServices'
  | 'LeadsByField'
  | 'StaffShifts';

export type ILayout = Layout & {
  type: ChartType;
  entityType: string;
  componentType: ChartComponentType;
  allowEntityChange?: boolean;
  jobType?: 'leads' | 'bookings';
  fieldId?: string;
  dateField?: string;
};

const getLayouts = (layout: ILayout[]) => {
  const savedLayouts = localStorage.getItem('grid-layout');

  if (savedLayouts) {
    const parsed = JSON.parse(savedLayouts);
    parsed.lg = parsed.lg.map((item: ILayout) => {
      const originalItem = layout.find((l) => l.i === item.i);
      return {
        ...item,
        type: item.type || originalItem?.type,
        entityType: item.entityType || originalItem?.entityType,
        componentType: item.componentType || originalItem?.componentType,
        allowEntityChange: item.allowEntityChange ?? originalItem?.allowEntityChange,
        jobType: item.jobType ?? originalItem?.jobType,
        fieldId: item.fieldId ?? originalItem?.fieldId,
        dateField: item.dateField ?? originalItem?.dateField,
      };
    });
    return parsed;
  }
  return { lg: layout };
};

const Grid = () => {
  const { t: translate } = useTranslation();
  const { entityTypes } = useAppSelector((state) => state.entity);
  const [isEditMode, setIsEditMode] = useState(false);
  const [granularity, setGranularity] = useState(EGranularity.MONTHLY);
  const [datePeriod, setDatePeriod] = useState(EDatePeriod.LAST_12_MONTHS);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  const layout: ILayout[] = useMemo(
    () => [
      {
        i: 'Chart-0',
        x: 0,
        y: 0,
        w: 2,
        h: 1,
        minW: 2,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.JOB?.id || '',
        componentType: 'Entity',
        allowEntityChange: true,
        jobType: 'leads',
        dateField: 'created_at',
      },
      {
        i: 'Chart-1',
        x: 3,
        y: 1,
        w: 2,
        h: 1,
        minW: 2,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.ORDER?.id!,
        componentType: 'Financial',
      },
      {
        i: 'PieChart-2',
        x: 0,
        y: 1,
        w: 2,
        h: 1,
        minW: 1,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.VENUE?.id!,
        componentType: 'Transactions',
      },
      {
        i: 'PieChart-3',
        x: 0,
        y: 2,
        w: 2,
        h: 1,
        minW: 1,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.CONTACT?.id!,
        componentType: 'FinancialOverview',
      },
      {
        i: 'PieChart-4',
        x: 3,
        y: 0,
        w: 2,
        h: 1,
        minW: 1,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.EVENT?.id!,
        componentType: 'Entity',
        allowEntityChange: true,
      },
      {
        i: 'PieChart-5',
        x: 3,
        y: 2,
        w: 1,
        h: 1,
        minW: 1,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.JOB?.id!,
        componentType: 'PopularProducts',
        allowEntityChange: true,
      },
      {
        i: 'PieChart-6',
        x: 2,
        y: 2,
        w: 1,
        h: 1,
        minW: 1,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.JOB?.id!,
        componentType: 'PopularServices',
        allowEntityChange: true,
      },
      {
        i: 'PieChart-7',
        x: 2,
        y: 0,
        w: 1,
        h: 1,
        minW: 1,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.JOB?.id!,
        componentType: 'LeadsByField',
        allowEntityChange: true,
      },
      {
        i: 'PieChart-8',
        x: 2,
        y: 1,
        w: 1,
        h: 1,
        minW: 1,
        minH: 1,
        maxW: 5,
        maxH: 2,
        type: 'bar' as ChartType,
        entityType: entityTypes.data.JOB?.id!,
        componentType: 'StaffShifts',
        allowEntityChange: true,
      },
    ],
    [entityTypes.data],
  );
  const setDateRangeByPeriod = useCallback(() => {
    if (datePeriod === EDatePeriod.LAST_7_DAYS) {
      setDateRange([dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')]);
    } else if (datePeriod === EDatePeriod.LAST_4_WEEKS) {
      setDateRange([dayjs().subtract(27, 'days').startOf('day'), dayjs().endOf('day')]);
    } else if (datePeriod === EDatePeriod.LAST_3_MONTHS) {
      setDateRange([dayjs().subtract(89, 'days').startOf('day'), dayjs().endOf('day')]);
    } else if (datePeriod === EDatePeriod.LAST_6_MONTHS) {
      setDateRange([dayjs().subtract(179, 'days').startOf('day'), dayjs().endOf('day')]);
    } else if (datePeriod === EDatePeriod.LAST_12_MONTHS) {
      setDateRange([dayjs().subtract(364, 'days').startOf('day'), dayjs().endOf('day')]);
    } else if (datePeriod === EDatePeriod.LAST_2_YEARS) {
      setDateRange([dayjs().subtract(730, 'days').startOf('day'), dayjs().endOf('day')]);
    } else if (datePeriod === EDatePeriod.CUSTOM) {
      setDateRange(dateRange);
    }
  }, [datePeriod, dateRange]);

  useEffect(() => {
    setDateRangeByPeriod();
  }, [datePeriod]);

  const [layouts, setLayouts] = useState<{ lg: ILayout[] }>(() => getLayouts(layout));

  const handleLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
    const updatedLayouts = {
      ...allLayouts,
      lg: currentLayout.map((item) => {
        const existingItem = layouts.lg.find((l) => l.i === item.i);

        return {
          ...item,
          type: existingItem?.type ?? 'bar',
          entityType: existingItem?.entityType ?? '',
          componentType: existingItem?.componentType ?? 'Entity',
          allowEntityChange: existingItem?.allowEntityChange,
          jobType: existingItem?.jobType ?? 'leads',
          fieldId: existingItem?.fieldId ?? '',
          dateField: existingItem?.dateField ?? 'created_at',
        } as ILayout;
      }),
    } as { lg: ILayout[] };

    setLayouts(updatedLayouts);

    localStorage.setItem('grid-layout', JSON.stringify(updatedLayouts));
  };

  const handleEntityTypeChange = useCallback((chartId: string, newEntityType: string) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = {
        ...prevLayouts,
        lg: prevLayouts.lg.map((item) => {
          if (item.i === chartId) {
            return {
              ...item,
              entityType: newEntityType,
            };
          }
          return item;
        }),
      };

      localStorage.setItem('grid-layout', JSON.stringify(updatedLayouts));
      return updatedLayouts;
    });
  }, []);

  const handleJobTypeChange = useCallback((chartId: string, newJobType: 'leads' | 'bookings') => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = {
        ...prevLayouts,
        lg: prevLayouts.lg.map((item) => {
          if (item.i === chartId) {
            return {
              ...item,
              jobType: newJobType,
            };
          }
          return item;
        }),
      };

      localStorage.setItem('grid-layout', JSON.stringify(updatedLayouts));
      return updatedLayouts;
    });
  }, []);

  const handleFieldChange = useCallback((chartId: string, newFieldId: string) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = {
        ...prevLayouts,
        lg: prevLayouts.lg.map((item) => {
          if (item.i === chartId) {
            return {
              ...item,
              fieldId: newFieldId,
            };
          }
          return item;
        }),
      };

      localStorage.setItem('grid-layout', JSON.stringify(updatedLayouts));
      return updatedLayouts;
    });
  }, []);

  const handleDateFieldChange = useCallback((chartId: string, newDateField: string) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = {
        ...prevLayouts,
        lg: prevLayouts.lg.map((item) => {
          if (item.i === chartId) {
            return {
              ...item,
              dateField: newDateField,
            };
          }
          return item;
        }),
      };

      localStorage.setItem('grid-layout', JSON.stringify(updatedLayouts));
      return updatedLayouts;
    });
  }, []);

  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = isEditMode
      ? `
        .react-grid-placeholder {
          background: rgba(0, 128, 0, 0.2) !important;
          border: 2px dashed rgba(0, 128, 0, 0.5) !important;
        }
      `
      : `
        .react-grid-placeholder {
          background: rgba(255, 0, 0, 0.2) !important;
          border: 2px dashed rgba(255, 0, 0, 0.5) !important;
        }
      `;
    document.head.appendChild(styleTag);

    return () => {
      document.head.removeChild(styleTag);
    };
  }, [isEditMode]);

  const CHART_COMPONENTS = useMemo(
    () => ({
      StaffShifts: (item: ILayout) => (
        <StaffShiftsChart
          granularity={granularity}
          dateRange={dateRange}
          chartEntity={item.entityType}
          allowEntityChange={item.allowEntityChange || false}
          onEntityTypeChange={() => handleEntityTypeChange(item.i, item.entityType)}
          onJobTypeChange={() => handleJobTypeChange(item.i, item.jobType || 'leads')}
          onDateFieldChange={(newDateField) => handleDateFieldChange(item.i, newDateField)}
          chartId={item.i}
          jobType={item.jobType || 'leads'}
          dateField={item.dateField || 'created_at'}
        />
      ),
      LeadsByField: (item: ILayout) => (
        <LeadsByFieldChart
          fieldId={item.fieldId || ''}
          dateField={item.dateField || 'created_at'}
          granularity={granularity}
          dateRange={dateRange}
          allowEntityChange={item.allowEntityChange || false}
          chartId={item.i}
          onFieldChange={(newFieldId) => handleFieldChange(item.i, newFieldId)}
          onDateFieldChange={(newDateField) => handleDateFieldChange(item.i, newDateField)}
        />
      ),
      PopularServices: (item: ILayout) => (
        <PopularServicesChart
          granularity={granularity}
          dateRange={dateRange}
          chartEntity={item.entityType}
          allowEntityChange={item.allowEntityChange || false}
          onEntityTypeChange={() => handleEntityTypeChange(item.i, item.entityType)}
          onJobTypeChange={() => handleJobTypeChange(item.i, item.jobType || 'leads')}
          onDateFieldChange={(newDateField) => handleDateFieldChange(item.i, newDateField)}
          chartId={item.i}
          jobType={item.jobType || 'leads'}
          dateField={item.dateField || 'created_at'}
        />
      ),
      PopularProducts: (item: ILayout) => (
        <PopularProductsChart
          granularity={granularity}
          dateRange={dateRange}
          chartEntity={item.entityType}
          allowEntityChange={item.allowEntityChange || false}
          onEntityTypeChange={() => handleEntityTypeChange(item.i, item.entityType)}
          onJobTypeChange={() => handleJobTypeChange(item.i, item.jobType || 'leads')}
          onDateFieldChange={(newDateField) => handleDateFieldChange(item.i, newDateField)}
          chartId={item.i}
          jobType={item.jobType || 'leads'}
          dateField={item.dateField || 'created_at'}
        />
      ),
      Transactions: () => <TransactionsChart granularity={granularity} dateRange={dateRange} />,
      Financial: () => <FinancialChart granularity={granularity} dateRange={dateRange} />,
      FinancialOverview: () => <FinancialOverviewChart granularity={granularity} dateRange={dateRange} />,
      Entity: (item: ILayout) => (
        <Chart
          granularity={granularity}
          dateRange={dateRange}
          chartEntity={item.entityType}
          allowEntityChange={item.allowEntityChange}
          chartId={item.i}
          jobType={item.jobType || 'leads'}
          dateField={item.dateField || 'created_at'}
          onEntityTypeChange={(newEntityType) => handleEntityTypeChange(item.i, newEntityType)}
          onJobTypeChange={(newJobType) => handleJobTypeChange(item.i, newJobType)}
          onDateFieldChange={(newDateField) => handleDateFieldChange(item.i, newDateField)}
        />
      ),
    }),
    [entityTypes.data, dateRange, granularity, handleEntityTypeChange, handleJobTypeChange, handleDateFieldChange, handleFieldChange],
  );

  const renderChartComponent = (item: ILayout) => {
    const ChartComponent = CHART_COMPONENTS[item.componentType as keyof typeof CHART_COMPONENTS];
    return ChartComponent ? ChartComponent(item) : null;
  };

  return (
    <div>
      {isEditMode ? (
        <Button className='mb-10 edit-layout-button' onClick={() => setIsEditMode(false)}>
          Save
        </Button>
      ) : (
        <SettingOutlined className='mb-10 fs-24 edit-layout-button icon absolute' onClick={() => setIsEditMode(true)} />
      )}
      <Row justify='end' gutter={10} className='px-10 mb-10'>
        <Col>
          <div>
            <Select
              size={'small'}
              style={{ width: 270 }}
              value={datePeriod}
              onChange={(value) => {
                setDatePeriod(value);
                if (value === EDatePeriod.LAST_7_DAYS) {
                  setGranularity(EGranularity.DAILY);
                } else if (value === EDatePeriod.LAST_4_WEEKS) {
                  setGranularity(EGranularity.WEEKLY);
                }
              }}>
              {DATE_PERIOD_OPTIONS.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {translate(option.label)}
                </Select.Option>
              ))}
            </Select>
          </div>
          {datePeriod === EDatePeriod.CUSTOM && (
            <div className='mt-10'>
              <DatePicker.RangePicker
                style={{ width: 270 }}
                size={'small'}
                value={dateRange as [Dayjs | null, Dayjs | null]}
                onChange={(dates: [Dayjs | null, Dayjs | null] | null) => setDateRange(dates)}
              />
            </div>
          )}
        </Col>
        <Col>
          <Select size={'small'} value={granularity} onChange={(value) => setGranularity(value)}>
            {GRANULARITY_OPTIONS.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {translate(option.label)}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <ResponsiveGridLayout
        layouts={layouts}
        cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
        rowHeight={500}
        width={1000}
        isResizable={isEditMode}
        isDraggable={isEditMode}
        onLayoutChange={handleLayoutChange}>
        {layouts.lg.map((item: ILayout) => (
          <div className='grid-item-wrapper' key={item.i}>
            {renderChartComponent(item)}
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
};

export default Grid;
