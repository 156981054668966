import { useTranslation } from 'react-i18next';

import { Col, Divider, Row, Typography } from 'antd';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { Title } from '@aduvi/components/Title';
import { useBusinessCurrency } from '@aduvi/hooks';
import { IQuote } from '@aduvi/types';

export const QuoteSummary = ({ selectedQuote }: { selectedQuote?: IQuote }) => {
  const { t: translate } = useTranslation();
  const businessCurrency = useBusinessCurrency();

  return (
    <Row justify='end' className='mt-20 pr-15'>
      <Col span={8}>
        <Row justify={'space-between'}>
          <Title className='mb-0' level={5}>
            {translate('quotes.subtotal')}
          </Title>
          <Typography.Text>
            <PriceDisplay price={Number(selectedQuote?.sub_total ?? 0).toFixed(2)} currencySign={businessCurrency?.format} />
          </Typography.Text>
        </Row>

        <Divider className='my-5' />

        <Row justify='space-between'>
          <Title className='mb-0' level={5}>
            {translate('quotes.salesTax')}
          </Title>
          <Typography.Text>
            <PriceDisplay
              price={(Number(selectedQuote?.total ?? 0) - Number(selectedQuote?.sub_total ?? 0)).toFixed(2)}
              currencySign={businessCurrency?.format}
            />
          </Typography.Text>
        </Row>

        <Divider className='my-5' />

        <Row justify='space-between'>
          <Title className='mb-0' level={5}>
            {translate('quotes.total')}
          </Title>
          <Typography.Text>
            <PriceDisplay price={Number(selectedQuote?.total ?? 0).toFixed(2)} currencySign={businessCurrency?.format} />
          </Typography.Text>
        </Row>
      </Col>
    </Row>
  );
};
