import React, { useMemo, useState } from 'react';
import { CalendarOutlined, ClockCircleOutlined, EditOutlined, EnvironmentOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Avatar, Button, Col, Rate, Row, Tag } from 'antd';
import Link from 'antd/es/typography/Link';

import { EditableCustomField } from '@aduvi/components/EditableCustomField/EditableCustomField';
import { EditableSelect } from '@aduvi/components/EditableSelect/EditableSelect';
import { getQuoteFieldNames } from '@aduvi/components/PersonalizedViewComponents/QuotesTable/helper';
import { QuotesTable } from '@aduvi/components/PersonalizedViewComponents/QuotesTable/QuotesTable';
import { DEFAULT_CELL_STYLE } from '@aduvi/constants';
import { useBusinessCurrency } from '@aduvi/hooks';
import {
  EEntityFieldListType,
  EFieldDataValueType,
  EFieldLabelAligment,
  EFieldSettings,
  EListFormat,
  EListValueType,
  EPersonalizedViewOrigin,
  EReferenceEntityType,
  ETextAlignment,
  ICustomFieldSpecificStyle,
  ICustomFieldViewStyle,
  IEntityField,
  IQuote,
} from '@aduvi/types';
import { ICustomField, IEntityType, IEntityWithFields } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';

import { TaskWarningIcon } from '../../Entity/components/drawer-tabs/tasks/@components/helper';
import { applyNumberFormat, dummyFieldValues, formatListItem, formatWithOrdinal, trimText } from '../helper';

import { getFieldInlineStyles, getFieldLayoutStyles, getLabelAlignmentStyles } from './helper';
import ShiftReferenceContent from './ShiftReferenceContent';

interface ICustomFieldProps {
  field?: ICustomField;
  record?: IEntityWithFields;
  managementFields?: IEntityField[];
  origin?: EPersonalizedViewOrigin;
  entityType?: IEntityType;
  column?: ICustomFieldViewStyle;
  fieldId?: string;
  style?: ICustomFieldSpecificStyle;
  quotes?: IQuote[];
  showDummyData?: boolean;
}

export const CustomFieldValue = ({
  field,
  style,
  record,
  managementFields,
  showDummyData,
  origin,
  entityType,
  column,
  quotes,
  fieldId,
}: ICustomFieldProps) => {
  const businessCurrency = useBusinessCurrency();

  const fieldData = field?.field_data?.[0];
  const fieldLabel = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabel;
  const fieldSetting = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style.fieldSetting;

  const isLabelHidden = fieldSetting === EFieldSettings.HIDDEN;
  const isLabelOnTop = fieldSetting === EFieldSettings.ON_TOP;
  const isLabelInline = fieldSetting === EFieldSettings.INLINE;

  const fieldLabelAlignment = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabelAlignment;
  const fieldLayoutStyles = getFieldLayoutStyles(isLabelOnTop, isLabelInline);

  const quoteFields = getQuoteFieldNames(quotes);
  const quoteField = useMemo(() => quoteFields?.find((field) => field?.id === fieldId), [quoteFields, fieldId]);

  const { users } = useAppSelector((state) => state.user);
  const { services } = useAppSelector((state) => state.services);
  const { products } = useAppSelector((state) => state.products);
  const { action_buttons } = useAppSelector((state) => state.entity.entityTypes);
  const { settings } = useAppSelector((state) => state.shiftScheduler);

  const actionButton = useMemo(() => {
    return action_buttons?.find((button) => button?.id === fieldId);
  }, [action_buttons, fieldId]);

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const useCustomField = useMemo(() => {
    const address = [fieldData?.street_one, fieldData?.city, fieldData?.country, fieldData?.province, fieldData?.zip_code].filter((item) => item);

    return {
      [EFieldDataValueType.FIELD_DATA_ADDRESSES]: (() => {
        const addressValue = address.length > 0 ? address.join(', ') : showDummyData ? dummyFieldValues?.address : '';
        const hasFieldData = !!addressValue;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span
              style={{
                order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
              }}>
              {address.length > 0 && <EnvironmentOutlined />}
              <span className='ml-5'>{addressValue}</span>
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_ADDRESSES}
              value={addressValue}
              record={record}
              managementFields={managementFields}
              field={field}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_EMAILS]: (() => {
        const emailValue = fieldData?.value || (showDummyData ? dummyFieldValues.email : '');
        const hasFieldData = !!emailValue;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span
              style={{
                order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
              }}>
              <Link onClick={(e) => e.stopPropagation()} href={`mailto:${emailValue}`}>
                {emailValue}
              </Link>
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_EMAILS}
              value={emailValue}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS]: (() => {
        const IDs = fieldData?.value || (showDummyData ? dummyFieldValues.sequentialId : '');
        const hasFieldData = !!IDs;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span
              style={{
                order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
              }}>
              {IDs}
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS}
              value={IDs}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_IMAGES]: (() => {
        const imageUrl = fieldData?.url || (showDummyData ? dummyFieldValues.image : '');
        const hasFieldData = !!imageUrl;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              <a href={imageUrl} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>
                <Avatar size='default' src={imageUrl} />
              </a>
            </span>
          </div>
        );

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_LISTS]: (() => {
        const hasFieldData = (field && field?.field_data?.length > 0) || showDummyData;
        const format = style?.listFormat?.toString() ?? DEFAULT_CELL_STYLE.listFormat;

        if (!hasFieldData) {
          return <></>;
        }

        const formattedList =
          field?.field_data?.map((option, index) => {
            const value = String(option?.value);
            const formattedValue = formatListItem(value, index, format);
            return { formattedValue, color: option?.color };
          }) ?? [];

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              {format === EListFormat.NUMBERED || format === EListFormat.BULLET ? (
                <div className='flex flex-direction-column'>
                  {formattedList.map((item, index) => (
                    <Tag
                      key={index}
                      style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }}
                      color={format === EListFormat.BULLET || format === EListFormat.NUMBERED ? undefined : item?.color}>
                      {item.formattedValue}
                    </Tag>
                  ))}
                </div>
              ) : (
                formattedList?.map((item, index) => (
                  <Tag key={index} style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }} color={item?.color}>
                    {item.formattedValue}
                  </Tag>
                ))
              )}
            </span>
          </div>
        );

        if (origin === EPersonalizedViewOrigin.TASKS) {
          return field?.field_data?.map((option, index) => (
            <div key={index} className='list-options-wrapper' onClick={(e) => e.stopPropagation()}>
              {!isEditing ? (
                <>
                  <span>{option?.value}</span>
                  {style?.isEditable && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
                </>
              ) : (
                <EditableSelect
                  field={field}
                  managementFields={managementFields}
                  option={field?.field_data?.[index]}
                  record={record}
                  entityType={entityType}
                  setIsEditing={setIsEditing}
                />
              )}
            </div>
          ));
        }

        if (showDummyData && !fieldData?.value) {
          if ((field as unknown as IEntityField)?.list_type === EEntityFieldListType.CHECK_BOX) {
            return (
              <div>
                <Tag style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }} color='green'>
                  {(field as unknown as IEntityField)?.list_values?.[0]?.value || 'Approved'}
                </Tag>
              </div>
            );
          }
        }

        if (showDummyData && !fieldData?.value) {
          if (
            (field as unknown as IEntityField)?.list_type === EEntityFieldListType.LIST &&
            (field as unknown as IEntityField)?.title === EListValueType.STATUS
          ) {
            return (
              <div>
                <Tag style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }} color='black'>
                  {(field as unknown as IEntityField)?.list_values?.[0]?.value || 'Proposal Sent'}
                </Tag>
              </div>
            );
          }
        }

        if (showDummyData && !fieldData?.value) {
          if ((field as unknown as IEntityField)?.list_type === EEntityFieldListType.LIST) {
            return (
              <div>
                <Tag style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }} color='blue'>
                  {(field as unknown as IEntityField)?.list_values?.[0]?.value || field?.title}
                </Tag>
              </div>
            );
          }
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_STAR_RATINGS]: (() => {
        const initialRating = Number(fieldData?.value) || dummyFieldValues.starRating.value;
        const hasFieldData = !!initialRating;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              <Rate value={initialRating} disabled />
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_STAR_RATINGS}
              value={initialRating}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_PHONES]: (() => {
        const phoneNumber = (fieldData?.value as string) || (showDummyData ? dummyFieldValues.phone : '');
        const hasFieldData = !!phoneNumber;

        if (!hasFieldData) {
          return <></>;
        }

        const formattedPhone = typeof phoneNumber === 'string' && phoneNumber.startsWith('+') ? phoneNumber : `+${String(phoneNumber || '')}`;

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              <Link onClick={(e) => e.stopPropagation()} href={`tel:${phoneNumber}`}>
                {formattedPhone}
              </Link>
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_PHONES}
              value={phoneNumber}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_LONG_TEXTS]: (() => {
        const maxLength = typeof style?.maxLength === 'number' ? style?.maxLength : DEFAULT_CELL_STYLE.maxLength;
        const textValue = fieldData?.value || (showDummyData && dummyFieldValues.textMultiLine);
        const hasFieldData = !!textValue;

        if (!hasFieldData && !showDummyData) {
          return <></>;
        }

        const trimmedText = trimText(String(textValue), maxLength);

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && hasFieldData && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span className='flex' style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              <span style={{ marginTop: '0.88rem' }} dangerouslySetInnerHTML={{ __html: trimmedText }} />
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_LONG_TEXTS}
              value={textValue}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_TEXTS]: (() => {
        const textValue = (fieldData?.value as string) || (showDummyData ? dummyFieldValues.textSingleLine : '');
        const parsedValue = parseFloat(textValue || '');

        const alignment = style?.alignment ?? DEFAULT_CELL_STYLE.alignment;
        const format = style?.numberFormat?.toString() ?? DEFAULT_CELL_STYLE.numberFormat;
        const isNumber = !isNaN(parsedValue);
        const displayValue = isNumber
          ? applyNumberFormat(parsedValue, format, alignment as ETextAlignment, {
              code: businessCurrency?.code as string,
              format: businessCurrency?.format as string,
            })
          : textValue;

        const hasFieldData = !!textValue;

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && hasFieldData && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              {displayValue}
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_TEXTS}
              value={textValue}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_TIMES]: (() => {
        const timeValue = fieldData?.value || (showDummyData ? dummyFieldValues.time : '');
        const hasFieldData = !!timeValue;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span className='flex align-center' style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              <ClockCircleOutlined />
              <span className='ml-5'>{timeValue}</span>
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_TIMES}
              value={timeValue}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_DATE_TIMES]: (() => {
        const hasFieldData = !!fieldData?.value || showDummyData;
        const formattedDate = fieldData?.value
          ? formatWithOrdinal(fieldData?.value?.toString(), style?.dateFormat?.toString() ?? DEFAULT_CELL_STYLE.dateFormat?.toString())
          : dummyFieldValues.date;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span className='flex align-center' style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              {origin === EPersonalizedViewOrigin.TASKS ? (
                <Row style={{ gap: '3px' }}>
                  <TaskWarningIcon dueDate={dayjs(fieldData?.value)} record={record} />
                </Row>
              ) : (
                <Row>
                  <CalendarOutlined />
                  <span className='ml-5'>{formattedDate}</span>
                </Row>
              )}
            </span>
          </div>
        );

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_URLS]: (() => {
        const urlValue = fieldData?.value || (showDummyData ? dummyFieldValues.url : '');
        const hasFieldData = !!urlValue;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span
              style={{
                order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
              }}>
              <Link onClick={(e) => e.stopPropagation()} href={`${urlValue}`} target='_blank'>
                {urlValue}
              </Link>
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_URLS}
              value={urlValue}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_NUMBERS]: (() => {
        const parsedValue = parseFloat(fieldData?.value as string) || (showDummyData ? parseFloat(dummyFieldValues.number) : NaN);
        const hasFieldData = !isNaN(parsedValue);

        if (!hasFieldData) {
          return <></>;
        }

        const alignment = style?.alignment ?? DEFAULT_CELL_STYLE.alignment;
        const format = style?.numberFormat?.toString() ?? DEFAULT_CELL_STYLE.numberFormat;

        const formattedValue = applyNumberFormat(parsedValue, format, alignment as ETextAlignment, {
          code: businessCurrency?.code as string,
          format: businessCurrency?.format as string,
        });

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment as EFieldLabelAligment)}>{fieldLabel}</span>}
            <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              {formattedValue}
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_NUMBERS}
              value={parsedValue}
              field={field}
              record={record}
              managementFields={managementFields}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_FILES]: (() => {
        const fileUrl = fieldData?.url || (showDummyData ? dummyFieldValues.image : '');
        const hasFieldData = !!fileUrl;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              <a href={fileUrl} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>
                <span>{fileUrl}</span>
              </a>
              {style?.isEditable && !showDummyData && !isEditing && <EditOutlined className='cursor-pointer fs-16 ml-10' onClick={handleEditClick} />}
            </span>
          </div>
        );

        if (style?.isEditable && !showDummyData && isEditing) {
          return (
            <EditableCustomField
              fieldType={EFieldDataValueType.FIELD_DATA_FILES}
              value={fileUrl}
              record={record}
              managementFields={managementFields}
              field={field}
              entityType={entityType}
              setIsEditing={setIsEditing}
            />
          );
        }

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_SONGS]: (() => {
        const artist = fieldData?.artist || (showDummyData ? dummyFieldValues.song : '');
        const track = fieldData?.track;
        const hasFieldData = !!artist || !!track;

        if (!hasFieldData) {
          return <></>;
        }

        const fieldContent = (
          <div style={getFieldInlineStyles(isLabelInline)}>
            {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
            <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>
              <span className='song-details'>
                {artist && <span>{artist}</span>}
                {track && <span> - {track}</span>}
              </span>
            </span>
          </div>
        );

        return (
          <div style={fieldLayoutStyles} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5'>{fieldLabel}</span>}
            {fieldContent}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_REFERENCE]: (() => {
        const fieldData = field?.field_data || [];
        const hasFieldData = fieldData?.length > 0;

        if (!hasFieldData) {
          return <></>;
        }

        const renderReferenceContent = () => {
          if (!fieldData?.length) {
            return <></>;
          }

          if (field?.reference_entity_type === EReferenceEntityType.SHIFT) {
            return (
              <ShiftReferenceContent fieldData={fieldData} users={users} services={services} products={products} settings={settings} style={style} />
            );
          }

          return fieldData?.map((item, index) => {
            const referenceType = field?.reference_entity_type;

            if (referenceType === EReferenceEntityType.TEAM_USER) {
              if (item?.profile_picture) {
                return (
                  <Row key={item?.id}>
                    <Avatar src={item?.profile_picture} />
                  </Row>
                );
              }
            }
            const displayName =
              (item.name || item.custom_fields?.find((field) => field.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]?.value) ??
              item.custom_fields?.find((field) => field?.field_data?.[0]?.value)?.field_data?.[0]?.value ??
              `${item?.first_name || ''} ${item?.last_name || ''}`;

            return (
              <Row key={item.id}>
                {index + 1} {displayName}
              </Row>
            );
          });
        };
        const fieldContent = <span>{renderReferenceContent()}</span>;

        return (
          <div style={isLabelOnTop ? { display: 'block' } : getFieldInlineStyles(isLabelInline)} className='editable-container'>
            {isLabelOnTop && !isLabelHidden && <span className='mb-5'>{fieldLabel}</span>}
            <div style={getFieldInlineStyles(isLabelInline)}>
              {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
              <span style={{ order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2 }}>{fieldContent}</span>
            </div>
          </div>
        );
      })(),
    };
  }, [field, fieldId, column, fieldData?.value, record, style, quoteFields, action_buttons]);

  if (quoteField) {
    return <QuotesTable fieldId={fieldId} style={style as unknown as ICustomFieldSpecificStyle} column={column} quotes={quotes} />;
  }

  if (actionButton) {
    const hasFieldData = !!actionButton?.title;

    return (
      <Col className='custom-field-value' style={fieldLayoutStyles}>
        {isLabelOnTop && !isLabelHidden && hasFieldData && <span className='mb-5 d-block'>{fieldLabel}</span>}

        <div style={getFieldInlineStyles(isLabelInline)}>
          {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}
          <Button
            size='small'
            style={{
              ...(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style,
              order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
              flexShrink: 0,
              margin: 0,
            }}>
            {actionButton?.title}
          </Button>
        </div>
      </Col>
    );
  }

  if (!field && !actionButton && !quoteField) return <></>;

  return (
    <Col className='custom-field-value'>
      {field?.value_type && useCustomField[field.value_type as keyof typeof useCustomField]
        ? useCustomField[field.value_type as keyof typeof useCustomField]
        : null}
    </Col>
  );
};
