import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ManualPaymentModal } from 'views/settings/payments/payment-gateway-settings/components/ManualPaymentModal';

import { Button } from 'antd';

import { getFieldInlineStyles, getLabelAlignmentStyles } from '@aduvi/components/CustomField/CustomFieldValue/helper';
import { EActionButtonSettings, EActionButtonTitle, EFieldLabelAligment, EFieldSettings, ICustomFieldSpecificStyle, IQuote } from '@aduvi/types';

import { resolveUrl } from '../../helper';

import { ActionButtonModal } from './ActionButtonModal';

interface ActionButtonProps {
  actionButton: {
    id: string;
    title: string;
    url: string;
  };
  column: ICustomFieldSpecificStyle;
  fieldId: string;
  quotes?: IQuote[];
}

export const ActionButton = ({ actionButton, column, fieldId, quotes }: ActionButtonProps) => {
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalUrl, setModalUrl] = useState('');
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  const fieldLabel = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabel;
  const fieldSetting = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style.fieldSetting;

  const isLabelHidden = fieldSetting === EFieldSettings.HIDDEN;
  const isLabelOnTop = fieldSetting === EFieldSettings.ON_TOP;
  const isLabelInline = fieldSetting === EFieldSettings.INLINE;
  const fieldLabelAlignment = (column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.fieldLabelAlignment;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (actionButton?.title === EActionButtonTitle.ADD_PAYMENT) {
      if (quotes && quotes.length > 0) {
        setPaymentModalVisible(true);
      }
      return;
    }

    const resolvedUrl = resolveUrl(actionButton?.url || '');
    const target = (column?.[fieldId]?.style?.target as EActionButtonSettings) || EActionButtonSettings.CURRENT_TAB;

    switch (target) {
      case EActionButtonSettings.CURRENT_TAB:
        if (resolvedUrl.startsWith('/') && actionButton?.title !== EActionButtonTitle.ADD_PAYMENT) {
          try {
            navigate(resolvedUrl);
          } catch (err) {
            return;
          }
        }
        break;

      case EActionButtonSettings.NEW_TAB:
        if (actionButton?.title !== EActionButtonTitle.ADD_PAYMENT) {
          window.open(resolvedUrl, '_blank');
        }
        break;

      case EActionButtonSettings.MODAL:
        if (actionButton?.title !== EActionButtonTitle.ADD_PAYMENT) {
          setModalUrl(resolvedUrl);
          setModalVisible(true);
        }
        break;
    }
  };

  const containerContent = (
    <div style={getFieldInlineStyles(isLabelInline)}>
      {!isLabelHidden && isLabelInline && <span style={getLabelAlignmentStyles(fieldLabelAlignment)}>{fieldLabel}</span>}

      <span
        style={{
          order: fieldLabelAlignment === EFieldLabelAligment.RIGHT ? 1 : 2,
          flexShrink: 0,
        }}>
        <Button
          size='small'
          style={column?.[fieldId]?.style}
          onClick={handleClick}
          disabled={actionButton?.title === EActionButtonTitle.ADD_PAYMENT && (!quotes || quotes.length === 0)}>
          {actionButton?.title}
        </Button>
      </span>
    </div>
  );

  return (
    <div
      style={{
        display: isLabelOnTop ? 'flex' : undefined,
        flexDirection: isLabelOnTop ? 'column' : undefined,
        alignItems: isLabelOnTop ? 'flex-start' : undefined,
      }}
      className='editable-container'
      onClick={(e) => e.stopPropagation()}>
      {isLabelOnTop && !isLabelHidden && (
        <span
          style={{
            display: 'block',
            marginBottom: '8px',
          }}>
          {fieldLabel}
        </span>
      )}
      {containerContent}
      <ActionButtonModal visible={modalVisible} url={modalUrl} onClose={() => setModalVisible(false)} />
      {quotes && quotes?.length > 0 && (
        <ManualPaymentModal isOpen={paymentModalVisible} quoteId={quotes?.[0]?.id} onClose={() => setPaymentModalVisible(false)} />
      )}
    </div>
  );
};
