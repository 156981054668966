import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, FormInstance, Input, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import { SelectUser } from '@aduvi/components/SelectUser/SelectUser';
import { useBusiness, useUser } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { EConversationSendType } from '@aduvi/types/automation';

import { getBusinessTeams } from 'store/features/business-slice';
import { getChannels } from 'store/features/conversation-slice';
import { getBusinessUsers } from 'store/features/user-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const SendConversationMessage = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();
  const currentUser = useUser();

  const { channels, loading } = useAppSelector((state) => state.conversation);
  const { loading: usersLoading, users } = useAppSelector((state) => state.user);
  const { businessTeams, loading: teamsLoading } = useAppSelector((state) => state.business);

  const fromValue = useWatch('from', form);

  const onGetBusinessUsers = useCallback(
    (page = 1, size = 50) => {
      if (!selectedBusiness?.id) return;
      dispatch(
        getBusinessUsers({
          business_id: selectedBusiness?.id,
          params: {
            page,
            size,
          },
        }),
      );
    },
    [selectedBusiness?.id],
  );

  useEffect(() => {
    if (!selectedBusiness?.id || !currentUser?.id) return;

    dispatch(getChannels({ businessId: selectedBusiness?.id, userId: currentUser?.id }));
    dispatch(getBusinessTeams({ businessId: selectedBusiness?.id }));
    onGetBusinessUsers();
  }, [selectedBusiness?.id]);

  return (
    <>
      <Form.Item name={'from'} label={translate('automations.from')} className='mt-20'>
        <Select
          options={Object.values(EConversationSendType).map((item) => ({
            label: translate(`automations.fromType.${item}`),
            value: item,
          }))}
        />
      </Form.Item>
      {fromValue === EConversationSendType.USER ? (
        <Form.Item name={'author_id'} label={translate('automations.fromType.USER')}>
          <SelectUser />
        </Form.Item>
      ) : null}
      <Form.Item name={'to'} label={translate('automations.to')} className='mt-20'>
        <Select
          loading={loading || teamsLoading || usersLoading}
          disabled={loading || teamsLoading || usersLoading}
          mode='multiple'
          options={[
            {
              label: <span>{translate('automations.users')}</span>,
              title: translate('automations.users'),
              options: users?.data?.map((item) => ({ label: item.first_name + ' ' + item.last_name, value: item.id })),
            },
            {
              label: <span>{translate('automations.teams')}</span>,
              title: translate('automations.teams'),
              options: businessTeams?.teams?.map((item) => ({ label: item.name, value: item.id })),
            },
            {
              label: <span>{translate('automations.channel')}</span>,
              title: translate('automations.channel'),
              options: channels?.map((item) => ({ label: item.name, value: item.id })),
            },
          ]}
        />
      </Form.Item>
      <Form.Item name={'subject'} label={translate('automations.subject')} className='mt-20'>
        <Input />
      </Form.Item>
      <Form.Item name={'message'} label={translate('automations.message')}>
        <Tiptap content={form.getFieldValue('message')} onUpdate={(value) => form.setFieldValue('message', value.getHTML())} />
      </Form.Item>
    </>
  );
};
