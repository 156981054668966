import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Menu, Row } from 'antd';
import './Footer.scss';

import { navigationConfig } from '@aduvi/constants/navigation-config';
import { useActiveTheme, useIsBusinessOwner, useSelectedPlan, useUserBusinessPermissions } from '@aduvi/hooks';
import { hasUserPermission, isAppIncludedInPlan } from '@aduvi/utils/helper';

import { usePartner } from '../../../hooks/usePartner';

export const Footer = () => {
  const navigate = useNavigate();
  const isBusinessOwner = useIsBusinessOwner();
  const userBusinessPermissions = useUserBusinessPermissions();
  const partner = usePartner();

  const { selectedPlan, planApps } = useSelectedPlan();
  const activeTheme = useActiveTheme();

  const onNavigate = (url: string) => {
    navigate(`/${url}`);
  };

  const navigationItems = useMemo(() => {
    return navigationConfig
      ?.filter(
        (item) =>
          hasUserPermission(isBusinessOwner, userBusinessPermissions, item?.permission) &&
          isAppIncludedInPlan(selectedPlan?.data, planApps, item?.key, partner),
      )
      .map((navigation) => {
        return {
          label: navigation.label,
          title: navigation.label,
          key: navigation.key,
          icon: navigation.icon,
          onClick: () => onNavigate(navigation.path),
        };
      });
  }, [selectedPlan?.data?.id]);

  return (
    <Row className='menu-items-wrapper w-full footer-menu' align='middle'>
      <Menu
        mode='horizontal'
        className='w-full h-full align-center'
        style={{ justifyContent: 'space-evenly', backgroundColor: activeTheme?.menuBg, color: activeTheme?.menuText }}
        items={navigationItems}
        inlineCollapsed={true}
      />
    </Row>
  );
};
