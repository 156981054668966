import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import { BranchesOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { Button, Card, Col, Form, FormInstance, Radio, Row, Select, Typography } from 'antd';
import './Conditions.scss';

import { useActiveTheme } from '@aduvi/hooks';
import { EFieldDataValueType, IEntityField } from '@aduvi/types';

import { ConditionInput } from './ConditionInput';
import { operatorOptions } from './helper';

const hiddenFields = [
  EFieldDataValueType.FIELD_DATA_IMAGES,
  EFieldDataValueType.FIELD_DATA_FILES,
  EFieldDataValueType.FIELD_DATA_SIGNATURES,
  EFieldDataValueType.FIELD_DATA_SONGS,
];

export const Conditions = ({ form, fields }: { form: FormInstance; fields?: IEntityField[] }) => {
  const { t: translate } = useTranslation();
  const activeTheme = useActiveTheme();

  Form.useWatch([], form);

  const branches = form.getFieldValue('branches') || [];

  const getEntityType = (branchIndex: number, setIndex: number, conditionIndex: number) => {
    const selectedEntity = form.getFieldValue(['branches', branchIndex, 'sets', setIndex, 'conditions', conditionIndex, 'entity']);
    const selectedEntityType = fields?.find((e) => e.id === selectedEntity);
    return selectedEntityType?.value_type;
  };

  const handleFieldChange = (branchIndex: number, setIndex: number, conditionIndex: number) => {
    const formPath = ['branches', branchIndex, 'sets', setIndex, 'conditions', conditionIndex];
    const currentValues = form.getFieldValue(formPath);

    form.setFieldsValue({
      branches: {
        [branchIndex]: {
          sets: {
            [setIndex]: {
              conditions: {
                [conditionIndex]: {
                  ...currentValues,
                  operator: undefined,
                  value: undefined,
                  start_value: undefined,
                  end_value: undefined,
                  start_date: undefined,
                  end_date: undefined,
                  date: undefined,
                },
              },
            },
          },
        },
      },
    });
  };

  const initBranchWithSet = () => ({
    id: uuidv4(),
    sets: [
      {
        conditions: [],
        condition_set_operator: 'all',
      },
    ],
  });

  const handleAddBranch = () => {
    const newBranch = initBranchWithSet();
    form.setFieldsValue({ branches: [...branches, newBranch] });
  };

  return (
    <Col span={24} className='if-else-conditions-wrapper mt-15'>
      <Form.List name='branches'>
        {(branches, { remove }) => (
          <>
            {branches.map((branch, branchIndex) => (
              <React.Fragment key={branch.key}>
                <Row justify={'space-between'}>
                  <Col>
                    <BranchesOutlined style={{ color: '#1890FF' }} />
                    {branchIndex === 0 ? ` ${translate('automations.if')}` : ` ${translate('automations.elseIf')}`}
                  </Col>
                  {branchIndex === 0 ? null : (
                    <Col span={1}>
                      <DeleteFilled className='pl-10 delete-icon' onClick={() => remove(branch.name)} />
                    </Col>
                  )}
                </Row>
                <Card className={`mb-20 mt-5`}>
                  <Form.List name={[branch.name, 'sets']}>
                    {(sets, { add: addSet, remove: removeSet }) => (
                      <>
                        {sets.map((set, setIndex) => (
                          <React.Fragment key={set.key}>
                            {setIndex > 0 && (
                              <Row justify='center' className='my-15'>
                                <Form.Item name={[setIndex - 1, 'condition_set_operator']} noStyle>
                                  <Radio.Group className='p-5 and-or-radio'>
                                    <Radio value='all'>
                                      <Typography.Text className='fw-bold'>{translate('limitVisibility.and')}</Typography.Text>
                                    </Radio>
                                    <Radio value='any'>
                                      <Typography.Text className='fw-bold'>{translate('limitVisibility.or')}</Typography.Text>
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Row>
                            )}
                            <Card
                              title={
                                <Row>
                                  <Form.Item name={[set.name, 'match']} initialValue='any' noStyle>
                                    <Radio.Group name='match' defaultValue='any' size='middle'>
                                      <Radio value='any'>
                                        <Typography.Text className='fw-400'>
                                          {translate('limitVisibility.match')} <b>{translate('limitVisibility.any')} </b>
                                          {translate('limitVisibility.ofTheBelow')}
                                        </Typography.Text>
                                      </Radio>
                                      <Radio value='all'>
                                        <Typography.Text className='fw-400'>
                                          {translate('limitVisibility.match')} <b>{translate('limitVisibility.all')} </b>
                                          {translate('limitVisibility.ofTheBelow')}
                                        </Typography.Text>
                                      </Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Row>
                              }
                              extra={setIndex === 0 ? null : <DeleteFilled className='delete-icon' onClick={() => removeSet(set.name)} />}>
                              <Form.List name={[set.name, 'conditions']}>
                                {(conditions, { add: addCondition, remove: removeCondition }) => (
                                  <>
                                    {conditions.length ? (
                                      conditions.map((condition, conditionIndex) => (
                                        <Row key={condition.key} className='condition-row pl-20 py-10 mx-0 w-full' gutter={4} align={'middle'}>
                                          <Col span={1}>
                                            <Typography.Text>{translate('limitVisibility.if')}</Typography.Text>
                                          </Col>
                                          <Col span={5}>
                                            <Form.Item
                                              noStyle
                                              name={[condition.name, 'entity']}
                                              rules={[{ required: true, message: translate('automations.pleaseSelectAnEntity') }]}>
                                              <Select
                                                placeholder={translate('limitVisibility.placeholder.entity')}
                                                className='w-full'
                                                onChange={() => handleFieldChange(branchIndex, setIndex, conditionIndex)}>
                                                {fields
                                                  ?.filter((entity) => !hiddenFields.includes(entity.value_type))
                                                  .map((entity) => {
                                                    return (
                                                      <Select.Option key={entity.id} value={entity.id}>
                                                        {entity.title}
                                                      </Select.Option>
                                                    );
                                                  })}
                                              </Select>
                                            </Form.Item>
                                          </Col>

                                          <Col span={5}>
                                            <Form.Item
                                              noStyle
                                              name={[condition.name, 'operator']}
                                              rules={[{ required: true, message: translate('automations.pleaseSelectAnOperator') }]}>
                                              <Select
                                                placeholder={translate('limitVisibility.placeholder.operator')}
                                                disabled={
                                                  !form.getFieldValue([
                                                    'branches',
                                                    branchIndex,
                                                    'sets',
                                                    setIndex,
                                                    'conditions',
                                                    conditionIndex,
                                                    'entity',
                                                  ])
                                                }
                                                className='w-full'>
                                                {(
                                                  operatorOptions[
                                                    getEntityType(branchIndex, setIndex, conditionIndex) as keyof Record<
                                                      EFieldDataValueType,
                                                      string[]
                                                    >
                                                  ] || []
                                                ).map((operator) => (
                                                  <Select.Option key={operator} value={operator}>
                                                    {operator}
                                                  </Select.Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </Col>

                                          {getEntityType(branchIndex, setIndex, conditionIndex) && (
                                            <ConditionInput
                                              field={fields?.find(
                                                (f) =>
                                                  f.id ===
                                                  form.getFieldValue([
                                                    'branches',
                                                    branchIndex,
                                                    'sets',
                                                    setIndex,
                                                    'conditions',
                                                    conditionIndex,
                                                    'entity',
                                                  ]),
                                              )}
                                              namePrefix={[condition.name]}
                                              disabled={
                                                !form.getFieldValue([
                                                  'branches',
                                                  branchIndex,
                                                  'sets',
                                                  setIndex,
                                                  'conditions',
                                                  conditionIndex,
                                                  'operator',
                                                ])
                                              }
                                              operator={form.getFieldValue([
                                                'branches',
                                                branchIndex,
                                                'sets',
                                                setIndex,
                                                'conditions',
                                                conditionIndex,
                                                'operator',
                                              ])}
                                            />
                                          )}

                                          <Col span={1}>
                                            <DeleteFilled className='pl-10 delete-icon' onClick={() => removeCondition(condition.name)} />
                                          </Col>
                                        </Row>
                                      ))
                                    ) : (
                                      <Row className='condition-row pl-20 py-10  w-full' justify={'start'} align={'middle'}>
                                        {translate('automations.noCondition')}
                                      </Row>
                                    )}

                                    <Button
                                      type='link'
                                      className='flex align-center'
                                      onClick={() => addCondition()}
                                      block
                                      icon={<PlusCircleFilled style={{ color: `${activeTheme?.menuActiveText}` }} />}>
                                      {translate('limitVisibility.addNewCondition')}
                                    </Button>
                                  </>
                                )}
                              </Form.List>
                            </Card>
                          </React.Fragment>
                        ))}
                        <Button
                          type='link'
                          className='flex align-center'
                          onClick={() => addSet({ condition_set_operator: 'and' })}
                          block
                          icon={<PlusCircleFilled style={{ color: `${activeTheme?.menuActiveText}` }} />}>
                          {translate('limitVisibility.addNewConditionSet')}
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>
              </React.Fragment>
            ))}

            {branches.length > 4 ? null : (
              <Button
                type='link'
                className='flex align-center p-0'
                onClick={handleAddBranch}
                block
                icon={<PlusCircleFilled style={{ color: `${activeTheme?.menuActiveText}` }} />}>
                {translate('automations.addElseIf')}
              </Button>
            )}

            <div className='mt-15'>
              <BranchesOutlined style={{ color: '#1890FF' }} /> <span> {translate('automations.else')}</span>
            </div>
          </>
        )}
      </Form.List>
    </Col>
  );
};
