export enum EShiftIncrements {
  ONE_MINUTE = 1,
  FIVE_MINUTES = 5,
  FIFTEEN_MINUTES = 15,
  THIRTY_MINUTES = 30,
}

export enum EWageType {
  PER_HOUR = 'PER_HOUR',
  PER_SHIFT = 'PER_SHIFT',
}

export enum ETravelType {
  PER_HOUR = 'PER_HOUR',
  PER_MILE = 'PER_MILE',
}

export enum EWageTypeShift {
  PER_HOUR = 'PER_HOUR',
  PER_SHIFT = 'PER_SHIFT',
}

export enum EPositionRole {
  ATTENDANT = 'Attendant',
  MANAGER = 'Manager',
  TRAINEE = 'Trainee',
}

export enum EWageStatus {
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
}

export interface ISelectedItem {
  id?: string;
  name?: string;
  image?: string;
  position?: string;
}
export interface ISelectedUser {
  id?: string;
  first_name?: string;
  profile_picture?: string;
}
export interface IShiftSchedulerState {
  settings?: IShiftSchedulerSettings;
  shifts: IShiftResponse[];
  loading: boolean;
  creating: boolean;
  updating: boolean;
  visiblePopovers: { [key: string]: number | undefined };
}
export interface IShiftSchedulerSettings {
  id: string;
  shift_increments: number;
  business_id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  assign_user_to_services: boolean;
  assign_user_to_products: boolean;
  assign_user_to_positions: boolean;
  auto_calculate_wages: boolean;
  positions: IPosition[];
  wages: IWage[];
}

export interface IUpdateShiftSettingsPayload {
  business_id: string;
  setting_id: string;
  shift_increments: number;
  assign_user_to_services: boolean;
  assign_user_to_products: boolean;
  assign_user_to_positions: boolean;
  auto_calculate_wages: boolean;
  positions: UpdatePosition[];
  wages: UpdateWage[];
}
export interface UpdatePosition {
  id: string;
  position: string;
  weight: number;
}

export interface UpdateWage {
  id: string;
  segmentable_id: string;
  segmentable_type: string;
  wage_amount: number;
  wage_type: EWageType;
  travel_amount: number;
  travel_type: ETravelType;
}

export interface IUpdateShiftSettingsResponse {
  id: string;
  shift_increments: number;
  business_id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  assign_user_to_services: boolean;
  assign_user_to_products: boolean;
  assign_user_to_positions: boolean;
  auto_calculate_wages: boolean;
  positions: IPosition[];
  wages: IWage[];
  message: string;
}

export interface ICreateShiftSettingsPayload extends IUpdateShiftSettingsPayload {}

export interface CreatePosition {
  position: string;
  weight: number;
}

export interface CreateWage {
  segmentable_id?: string;
  segmentable_type?: string;
  wage_amount: number;
  wage_type: EWageType;
  travel_amount: number;
  travel_type: ETravelType;
}

export interface ICreateShiftSettingsResponse extends IUpdateShiftSettingsResponse {}

export interface IPosition {
  id: string;
  position: string;
  weight: number;
  business_id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export interface IReorderPositionPayload {
  business_id: string;
  fields: {
    id: string;
    weight: string;
  }[];
}

export interface IWage {
  id: string;
  segmentable_id?: string;
  segmentable_type?: string;
  wage_amount: number;
  wage_type: EWageType;
  travel_amount: number;
  travel_type: ETravelType;
  business_id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export interface SegmentOption {
  key: string;
  label: string;
  value: string;
  segmentable_type: string;
}

export interface IShiftPayload {
  field_id?: string;
  business_id: string;
  entity_id: string;
  id?: string;
  user_id?: string;
  assigned_id?: string;
  start_shift?: string;
  end_shift?: string;
  status?: string;
  wage_amount?: string;
  notes?: string;
  wage_type?: string;
  travel_amount?: string;
  travel_type?: string;
  duration?: string;
  wage_total?: string;
}

export interface IShiftResponse {
  id: string;
  entity_type_id: string;
  entity_id: string;
  field_id: string;
  business_id: string;
  delta: number;
  user_id: string;
  assigned_type: string;
  assigned_id: string;
  start_shift: string;
  end_shift: string;
  status: string;
  wage_amount: string;
  wage_type: string;
  travel_amount: string;
  travel_type: string;
  notes: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  message: string;
}

export interface ISegmentGroup {
  label: string;
  options?: SegmentOption[];
}
export interface IWageItem {
  segmentable_type?: string;
  segmentable_id?: string;
  wage_amount?: number;
  wage_type?: EWageType;
  travel_amount?: number;
  travel_type?: ETravelType;
}

export interface ICalculateWageParams {
  businessId: string;
  startShift: string;
  endShift: string;
  userId?: string;
  position?: string;
}

export interface IWageResponse {
  wage_total: number;
  duration: number;
}

export interface IDeleteShiftPayload {
  businessId: string;
  entityId: string;
  shiftId: string;
}
