import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Button, Col, Divider, Row } from 'antd';
import './PageBreak.scss';

import { useActiveTheme } from '@aduvi/hooks';

import { IBookingFormFieldProps } from '../helper';

interface IProps {
  id: string;
  order: number;
  index: number;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
}

export const PageBreak = ({ id, order, index, setFields }: IProps) => {
  const { t: translate } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const activeTheme = useActiveTheme();

  const style = {
    transform: CSS.Transform.toString({ x: Number(transform?.x), y: Number(transform?.y), scaleX: 1, scaleY: 1 }),
    transition,
    '--highlight-color': activeTheme?.buttonBg,
    '--button-text-color': activeTheme?.buttonText,
    '--button-hover-color': activeTheme?.buttonBgHover,
  };

  return (
    <Row className='w-full page-break-wrapper' justify={'space-between'} align={'middle'} style={style} {...attributes}>
      <Col className='drag-icon' ref={setNodeRef} {...listeners} style={{ cursor: 'grab' }}>
        <Button icon={<DragOutlined />} size='small' type='primary' />
      </Col>
      <Col className='divider-step'>
        <Divider>
          <Button className='custom-button' type='primary' size='small'>
            {translate('forms.forms.step')} {order}
          </Button>
        </Divider>
      </Col>
      <Col className='delete-icon'>
        <Button icon={<DeleteOutlined />} size='small' type='primary' onClick={() => setFields((prev) => prev.filter((_, indx) => indx !== index))} />
      </Col>
    </Row>
  );
};
